/**
 * @generated SignedSource<<7c1fdc53e1056d07e4238521c8a89d0c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EmploymentInput = {
  code?: string | null;
  dateOfBirth?: string | null;
  email?: string | null;
  employmentRate?: number | null;
  employmentType?: string | null;
  employmentTypeCode?: string | null;
  employmentTypeSmallId?: number | null;
  external?: boolean | null;
  firstName?: string | null;
  imageId?: string | null;
  lastName?: string | null;
  mutedFeatures?: ReadonlyArray<string> | null;
  nickname?: string | null;
  payrollCode?: string | null;
  rating?: number | null;
  schoolId?: string | null;
  securityRole?: string | null;
  ssoCode?: string | null;
};
export type UpdateEmploymentMutation$variables = {
  businessId: string;
  id: string;
  input: EmploymentInput;
};
export type UpdateEmploymentMutation$data = {
  readonly updateEmployment: {
    readonly acceptedInvite: boolean;
    readonly businessInvite: {
      readonly activationCode: string;
      readonly attemptsRemaining: number;
      readonly expiryTime: string;
      readonly id: string;
      readonly inviteToken: string;
    } | null;
    readonly code: string | null;
    readonly createdAt: string;
    readonly dateOfBirth: string;
    readonly during: string | null;
    readonly email: string;
    readonly employmentRate: number;
    readonly employmentTypeCode: string;
    readonly external: boolean;
    readonly firstName: string;
    readonly id: string;
    readonly imageId: string | null;
    readonly lastName: string;
    readonly mutedFeatures: ReadonlyArray<string> | null;
    readonly nickname: string | null;
    readonly payrollCode: string | null;
    readonly rating: number;
    readonly schoolId: string | null;
    readonly securityRole: string;
    readonly updatedAt: string;
    readonly userEmail: string | null;
    readonly userId: string | null;
  };
};
export type UpdateEmploymentMutation = {
  response: UpdateEmploymentMutation$data;
  variables: UpdateEmploymentMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "businessId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "businessId",
        "variableName": "businessId"
      },
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "Employment",
    "kind": "LinkedField",
    "name": "updateEmployment",
    "plural": false,
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "userId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "createdAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "acceptedInvite",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "during",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "updatedAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "userEmail",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "BusinessInvite",
        "kind": "LinkedField",
        "name": "businessInvite",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "activationCode",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "attemptsRemaining",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "expiryTime",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "inviteToken",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "firstName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "nickname",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "dateOfBirth",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "imageId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "schoolId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "external",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "rating",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "employmentRate",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "securityRole",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "code",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "employmentTypeCode",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "payrollCode",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mutedFeatures",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateEmploymentMutation",
    "selections": (v4/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "UpdateEmploymentMutation",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "1e6ee61f94fe8a1af94ad474a203247d",
    "id": null,
    "metadata": {},
    "name": "UpdateEmploymentMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateEmploymentMutation(\n  $input: EmploymentInput!\n  $id: ID!\n  $businessId: ID!\n) {\n  updateEmployment(businessId: $businessId, id: $id, input: $input) {\n    id\n    userId\n    createdAt\n    acceptedInvite\n    during\n    updatedAt\n    userEmail\n    businessInvite {\n      id\n      activationCode\n      attemptsRemaining\n      expiryTime\n      inviteToken\n    }\n    email\n    firstName\n    lastName\n    nickname\n    dateOfBirth\n    imageId\n    schoolId\n    external\n    rating\n    employmentRate\n    securityRole\n    code\n    employmentTypeCode\n    payrollCode\n    mutedFeatures\n  }\n}\n"
  }
};
})();

(node as any).hash = "86406489611c499328b56a3638c196f4";

export default node;
