import React, { useCallback, useEffect, useState } from "react";

import { FormikContext, FormikContextType } from "formik";
import omit from "lodash/omit";
import { useTranslation } from "react-i18next";
import {
  createFragmentContainer,
  Environment,
  fetchQuery,
  graphql,
} from "react-relay";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";

import { useBusinessContext } from "../../../contexts/BusinessContext";
import { useModal } from "../../../contexts/ModalContext";
import properties from "../../../data/csv-settings/aos-config-template-create-settings.json";
import { useAppRouter } from "../../../hooks/useAppRouter";
import DynamicInputGroup from "../../common/Form/DynamicInputGroup";
import FormLayout from "../../common/Form/FormLayout";
import FormLayoutFooter from "../../common/Form/FormLayoutFooter";
import {
  getFieldsByNames,
  getSettingsByGroup,
  ServerValidationConfig,
} from "../../common/Form/formUtilities";
import { IProperty, TodoType } from "../../common/Form/models";
import Loader from "../../common/Loader";
import HeaderPortal from "../../common/Portal/HeaderPortal";
import { UpdateAosConfigTemplateMutation$data as UpdateAosConfigTemplateMutationResponse } from "../mutations/__generated__/UpdateAosConfigTemplateMutation.graphql";
import CreateAosConfigTemplateMutation from "../mutations/CreateAosConfigTemplateMutation";
import UpdateAosConfigTemplateMutation from "../mutations/UpdateAosConfigTemplateMutation";
import AdvancedFields from "../Schedule/AOS/Advanced/AdvancedFields";
import { getBasicAOSConfigSchema } from "../Schedule/AOS/aosUtils";
import BasicFields from "../Schedule/AOS/Basic/BasicFields";
import { TransformedAosConfigBasic } from "../Schedule/AOS/models";
import {
  DaypartRanksObject,
  fromFormData,
  toFormData,
  WeeklyDaypartObject,
} from "../Schedule/AOS/TimeRangeRanks";
import AOSTemplateService from "../Services/AOSTemplateService";
import { Profile_ScheduleAOSConfigQuery } from "./__generated__/Profile_ScheduleAOSConfigQuery.graphql";
import { Profile_template$data } from "./__generated__/Profile_template.graphql";
import AOSTemplateModal, { AOSModalEnum } from "./AOSTemplateModal";

const query = graphql`
  query Profile_ScheduleAOSConfigQuery($businessId: ID!, $scheduleId: ID!) {
    schedules(businessId: $businessId, ids: [$scheduleId]) {
      nodes {
        id
        scheduleName
        aosConfig {
          schedulingDayStart
          addUnderstaffingShifts
          weeklyWorkhoursMin
          weeklyWorkhoursMax
          shiftMin
          shiftMax
          shiftMinAbsolute
          shiftGapMin
          shiftMaxStart
          maxRolesPerShift
          minShiftPartLength
          breakTrigger
          breakLength
          breakMinStart
          breakMaxStart
          breakEndPad
          includeRoles
          demandOnly
          bonusRoles
          ignoreBreakRoles
          flexibleRole
          coreObeysAosRules
          skipSchedulingManagers
          skipSchedulingCore
          addCoreBreaks
          optimizeCoreBreaks
          weeklyDayparts
          daypartRanks
          planningOrder
          overstaffingPenalty
          skillPreference
          shiftLengthPreference
          penaltyShortParts
          penaltyShortPartsCutoff
          costEqualLabor
          weightEqualLaborByRating
          applyRule24HoursRest
          maxWorkdaysCalweek
          maxWorkdaysWorkweek
          overstaffingByRole
          understaffingByRole
          shiftConfig
          aosTimeout
          aosCombinedRoles
          aosOrchestratorConfig
          replanningTimeout
          replanningOrchestratorConfig
        }
      }
    }
  }
`;

type NodesType =
  {} & Profile_ScheduleAOSConfigQuery["response"]["schedules"]["nodes"];
type ScheduleAosConfig = {} & NodesType[number];

interface MatchParams {
  stack_id: string;
  business_id: string;
  aos_template_id?: string;
  schedule_id?: string; // defined if create new template from schedule settings
  clone_from_id?: string; // defined if clone from another template
}

type Props = {
  template: Profile_template$data | null;
  environment: Environment;
};

type FormData = Profile_template$data & {
  aosConfig: TransformedAosConfigBasic;
};

function Profile(props: Props) {
  const { business, environment } = useBusinessContext();
  const { showModal } = useModal();
  const { t } = useTranslation("aos");

  const router = useAppRouter<MatchParams>();
  const {
    params: {
      aos_template_id: aosTemplateId,
      business_id: businessId,
      stack_id: stackId,
      schedule_id: scheduleId,
      clone_from_id: cloneTemplateId,
    },
  } = router;

  const {
    validationRules,
    setTemplate,
    template,
    isCreate,
    scheduleAosConfig,
    loaded,
  } = useAosConfigTemplate(props);

  const handleSave = (
    changes: Partial<FormData>,
    onError: (err: Error) => void,
    event?: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    values?: FormData,
  ) => {
    const toServerData = (
      aosConfigFormData?: Partial<TransformedAosConfigBasic>,
    ) => {
      if (!aosConfigFormData) {
        return {};
      }
      const temp = omit(aosConfigFormData, [
        "weeklyDaypartsData",
        "useGlobalPreference",
        "globalPreference",
      ]);

      const extraData = fromFormData(values?.aosConfig);
      return {
        ...temp,
        ...extraData,
      };
    };

    if (aosTemplateId) {
      const aosConfig = toServerData(changes.aosConfig);
      return UpdateAosConfigTemplateMutation(
        environment,
        businessId,
        aosTemplateId,
        {
          ...changes,
          aosConfig,
        },
        (response: UpdateAosConfigTemplateMutationResponse) => {
          setTemplate(
            response.updateAosConfigTemplate as unknown as Profile_template$data,
          );
          toast(t("aosTemplate.saved"));
        },
        onError,
      );
    }

    const aosConfig = toServerData(values?.aosConfig);
    return CreateAosConfigTemplateMutation(
      environment,
      businessId,
      {
        ...values,
        aosConfig,
      },
      () => {
        router.push(
          `/stack/${stackId}/business/${businessId}/profile/aos_templates`,
        );
      },
      onError,
    );
  };

  const showConfirmDeleteModal = () => {
    showModal(
      <AOSTemplateModal
        modal={AOSModalEnum.ComfirmDelete}
        businessId={businessId}
        template={template}
        onDeletedCallback={() => {
          router.history.push(
            `/stack/${stackId}/business/${businessId}/profile/aos_templates`,
          );
        }}
      />,
    );
  };

  const { aosConfig } = template ?? {};

  const extraData = toFormData(
    aosConfig?.weeklyDayparts as WeeklyDaypartObject,
    aosConfig?.daypartRanks as DaypartRanksObject,
  );

  const formBase: FormData = {
    ...template,
    aosConfig: {
      ...aosConfig,
      ...extraData,
    },
  } as FormData;

  const getBreadcrumbTitle = () => {
    if (aosTemplateId) {
      return template?.templateName;
    }

    if (scheduleId) {
      return t("aosTemplate.saveAsNewTemplate");
    }

    if (cloneTemplateId) {
      return t("aosTemplate.cloneTemplate");
    }

    return t("aosTemplate.newTemplate");
  };

  return (
    <div className="panel">
      <HeaderPortal>
        <Link to={`/stack/${stackId}/business/${businessId}/profile`}>
          {business.businessName}
        </Link>
        <span className="ml-2 mr-2">&gt;</span>
        {!scheduleAosConfig ? (
          <span>
            <Link
              to={`/stack/${stackId}/business/${businessId}/profile/aos_templates`}
            >
              <span>{t("aosTemplate.aosTemplates")}</span>
            </Link>
          </span>
        ) : (
          <span>
            <Link
              to={`/stack/${stackId}/business/${businessId}/schedule/${scheduleAosConfig.id}/aos`}
            >
              <span>{scheduleAosConfig.scheduleName}</span>
            </Link>
          </span>
        )}

        <span className="ml-2 mr-2">&gt;</span>
        <span id="schedule-header">{getBreadcrumbTitle()}</span>
      </HeaderPortal>
      {!loaded && <Loader />}
      {loaded && !template && <div>{t("aosTemplate.templateNotFound")}</div>}
      {loaded && template && (
        <FormLayout<FormData>
          isCreate={isCreate}
          base={formBase}
          onSave={handleSave}
          propertyList={properties as unknown as IProperty[]}
          validationRules={validationRules}
        >
          <FormikContext.Consumer>
            {(context: FormikContextType<FormData>) => {
              const { values } = context;

              return values ? (
                <>
                  <fieldset>
                    <DynamicInputGroup
                      hideGroupName
                      fields={getSettingsByGroup(
                        getFieldsByNames(properties as unknown as IProperty[], [
                          "templateName",
                          "isDefault",
                        ]),
                      )}
                    />
                  </fieldset>
                  <BasicFields
                    values={values}
                    properties={properties as unknown as IProperty[]}
                    disabled={false}
                  />
                  <AdvancedFields
                    values={values}
                    properties={properties as unknown as IProperty[]}
                    disabled={false}
                  />

                  <FormLayoutFooter
                    isCreate={isCreate}
                    onDelete={showConfirmDeleteModal}
                  />
                </>
              ) : null;
            }}
          </FormikContext.Consumer>
        </FormLayout>
      )}
    </div>
  );
}

/**
 * LK-13564 - this was refactored to a functional component - if there are any commits where this file was converted
 * There's some procedural logic in this hook that still needs to be refactored - separated it into this hook to make it easier
 */
function useAosConfigTemplate(props: Props) {
  const { business, environment } = useBusinessContext();
  const [validationRules, setValidationRules] = useState<
    yup.ObjectSchema<TodoType>
  >(yup.object({}));
  const { t } = useTranslation("aos");
  const [defaults, setDefaults] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [template, setTemplate] = useState(props.template);
  const [scheduleAosConfig, setScheduleAosConfig] =
    useState<ScheduleAosConfig | null>(null);
  const [cloneTemplateAosConfig, setCloneTemplateAosConfig] =
    useState<any>(null);

  const {
    params: {
      aos_template_id: aosTemplateId,
      business_id: businessId,
      schedule_id: scheduleId,
      clone_from_id: cloneTemplateId,
    },
  } = useAppRouter<MatchParams>();

  const fetchScheduleAosSetting = useCallback(async () => {
    if (!scheduleId) {
      return;
    }
    const result = await fetchQuery<Profile_ScheduleAOSConfigQuery>(
      environment,
      query,
      {
        businessId,
        scheduleId: scheduleId ?? null,
      },
    ).toPromise();

    setScheduleAosConfig(result?.schedules.nodes[0] ?? null);
  }, [scheduleId, environment, businessId]);

  const fetchTemplateToCloneFrom = useCallback(async () => {
    if (cloneTemplateId) {
      const result = await AOSTemplateService.getTemplateById(
        environment,
        businessId,
        cloneTemplateId,
      );

      setCloneTemplateAosConfig(result ?? undefined);
    }
  }, [cloneTemplateId, environment, businessId]);

  const setupAOSConfigSchema = useCallback(
    (aosConfigSchema: ServerValidationConfig) => {
      const result = getBasicAOSConfigSchema(
        t,
        aosConfigSchema,
        properties as unknown as IProperty[],
        true,
      );

      setValidationRules(
        yup.object({
          templateName: yup
            .string()
            .required()
            .label(t("aos:aosTemplate.templateName")),
          aosConfig: result.aosConfigValidationRules,
        }),
      );

      setDefaults(result.defaults);
    },
    [t],
  );

  useEffect(() => {
    async function fetchInitialData() {
      setupAOSConfigSchema(business.aosConfigSchema as ServerValidationConfig);

      if (scheduleId && environment) {
        await fetchScheduleAosSetting();
      } else if (cloneTemplateId && environment) {
        await fetchTemplateToCloneFrom();
      }

      setLoaded(true);
    }

    fetchInitialData();
  }, [
    scheduleId,
    cloneTemplateId,
    environment,
    setupAOSConfigSchema,
    business.aosConfigSchema,
    fetchScheduleAosSetting,
    fetchTemplateToCloneFrom,
  ]);

  const isCreate = aosTemplateId == null || cloneTemplateId != null;

  const getDefaultTemplate = (
    aosConfig: Record<string, any> | null = {},
    otherProps = {},
  ) => {
    return {
      templateName: "",
      isDefault: false,
      ...otherProps,
      aosConfig,
    } as Profile_template$data;
  };

  // A bit hacky, should probably be refactored later on
  let item = template ? { ...template } : null;
  if (!item) {
    if (scheduleAosConfig) {
      item = getDefaultTemplate(scheduleAosConfig.aosConfig);
    }

    if (cloneTemplateId && cloneTemplateAosConfig) {
      item = getDefaultTemplate(cloneTemplateAosConfig.aosConfig, {
        templateName: `${cloneTemplateAosConfig.templateName} copy`,
      });
    }

    if (!scheduleId && !cloneTemplateId) {
      item = getDefaultTemplate(defaults);
    }
  }

  return {
    validationRules,
    isCreate,
    setTemplate,
    template: item,
    scheduleAosConfig,
    loaded,
  };
}

export default createFragmentContainer(
  Profile,
  // Each key specified in this object will correspond to a prop available to the component
  {
    template: graphql`
      # As a convention, we name the fragment as '<ComponentFileName>_<propName>'
      fragment Profile_template on AosConfigTemplate {
        id
        ### Replaceable content start
        templateName
        isDefault
        aosConfig {
          shiftConfig
          schedulingDayStart
          addUnderstaffingShifts
          weeklyWorkhoursMin
          weeklyWorkhoursMax
          shiftMin
          shiftMax
          shiftMinAbsolute
          shiftGapMin
          shiftMaxStart
          maxRolesPerShift
          minShiftPartLength
          breakTrigger
          breakLength
          breakMinStart
          breakMaxStart
          breakEndPad
          includeRoles
          demandOnly
          bonusRoles
          ignoreBreakRoles
          flexibleRole
          coreObeysAosRules
          skipSchedulingManagers
          skipSchedulingCore
          addCoreBreaks
          optimizeCoreBreaks
          weeklyDayparts
          daypartRanks
          planningOrder
          overstaffingPenalty
          skillPreference
          shiftLengthPreference
          penaltyShortParts
          penaltyShortPartsCutoff
          costEqualLabor
          weightEqualLaborByRating
          applyRule24HoursRest
          maxWorkdaysCalweek
          maxWorkdaysWorkweek
          overstaffingByRole
          understaffingByRole
          aosTimeout
          aosCombinedRoles
          aosOrchestratorConfig
          replanningTimeout
          replanningOrchestratorConfig
        }
        ### Replaceable content finish
      }
    `,
  },
);
