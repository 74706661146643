/**
 * @generated SignedSource<<7179253f4062ee10e18c175e5646ac4e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AuditLogActionTypeEnum = "created" | "deleted" | "specific" | "updated" | "%future added value";
export type AuditLogSortField = "id" | "objType" | "occurredAt" | "%future added value";
export type Order = "asc" | "desc" | "%future added value";
export type AuditLogSort = {
  field: AuditLogSortField;
  order: Order;
};
export type AuditLogsQueries_ListQuery$variables = {
  actionType?: AuditLogActionTypeEnum | null;
  after?: string | null;
  businessId: string;
  endDateTime?: string | null;
  objId?: string | null;
  objectType?: string | null;
  pageSize: number;
  performedById?: string | null;
  relatedToId?: string | null;
  requestId?: string | null;
  scheduleId?: string | null;
  sort?: ReadonlyArray<AuditLogSort> | null;
  specificAction?: string | null;
  startDateTime?: string | null;
};
export type AuditLogsQueries_ListQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AuditLogsQueries_ListFragment">;
};
export type AuditLogsQueries_ListQuery = {
  response: AuditLogsQueries_ListQuery$data;
  variables: AuditLogsQueries_ListQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "actionType"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "businessId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endDateTime"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "objId"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "objectType"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pageSize"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "performedById"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "relatedToId"
},
v9 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "requestId"
},
v10 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "scheduleId"
},
v11 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sort"
},
v12 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "specificAction"
},
v13 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startDateTime"
},
v14 = [
  {
    "kind": "Variable",
    "name": "actionType",
    "variableName": "actionType"
  },
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "businessId",
    "variableName": "businessId"
  },
  {
    "kind": "Variable",
    "name": "endDateTime",
    "variableName": "endDateTime"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "pageSize"
  },
  {
    "kind": "Variable",
    "name": "objId",
    "variableName": "objId"
  },
  {
    "kind": "Variable",
    "name": "objType",
    "variableName": "objectType"
  },
  {
    "kind": "Variable",
    "name": "performedById",
    "variableName": "performedById"
  },
  {
    "kind": "Variable",
    "name": "relatedToId",
    "variableName": "relatedToId"
  },
  {
    "kind": "Variable",
    "name": "requestId",
    "variableName": "requestId"
  },
  {
    "kind": "Variable",
    "name": "scheduleId",
    "variableName": "scheduleId"
  },
  {
    "kind": "Variable",
    "name": "sort",
    "variableName": "sort"
  },
  {
    "kind": "Variable",
    "name": "specificAction",
    "variableName": "specificAction"
  },
  {
    "kind": "Variable",
    "name": "startDateTime",
    "variableName": "startDateTime"
  }
],
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/),
      (v11/*: any*/),
      (v12/*: any*/),
      (v13/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AuditLogsQueries_ListQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "AuditLogsQueries_ListFragment"
      }
    ],
    "type": "InternalQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v6/*: any*/),
      (v1/*: any*/),
      (v11/*: any*/),
      (v10/*: any*/),
      (v13/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v12/*: any*/),
      (v5/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v4/*: any*/),
      (v9/*: any*/)
    ],
    "kind": "Operation",
    "name": "AuditLogsQueries_ListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v14/*: any*/),
        "concreteType": "AuditLogConnection",
        "kind": "LinkedField",
        "name": "auditLogs",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AuditLogEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AuditLog",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v15/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "actionType",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "code",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "objId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "objType",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "occurredAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Employment",
                    "kind": "LinkedField",
                    "name": "performedBy",
                    "plural": false,
                    "selections": [
                      (v15/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "computedName",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Schedule",
                    "kind": "LinkedField",
                    "name": "schedule",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "scheduleName",
                        "storageKey": null
                      },
                      (v15/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "performedById",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "relatedToId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "scheduleId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "specificAction",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v14/*: any*/),
        "filters": [
          "businessId",
          "sort",
          "scheduleId",
          "startDateTime",
          "endDateTime",
          "actionType",
          "specificAction",
          "objType",
          "performedById",
          "relatedToId",
          "objId",
          "requestId"
        ],
        "handle": "connection",
        "key": "AuditLogTable_auditLogs",
        "kind": "LinkedHandle",
        "name": "auditLogs"
      }
    ]
  },
  "params": {
    "cacheID": "9414e5aa6d27544bc07ab179bb093887",
    "id": null,
    "metadata": {},
    "name": "AuditLogsQueries_ListQuery",
    "operationKind": "query",
    "text": "query AuditLogsQueries_ListQuery(\n  $businessId: ID!\n  $pageSize: Int!\n  $after: String\n  $sort: [AuditLogSort!]\n  $scheduleId: ID\n  $startDateTime: ISO8601DateTime\n  $endDateTime: ISO8601DateTime\n  $actionType: AuditLogActionTypeEnum\n  $specificAction: String\n  $objectType: String\n  $performedById: ID\n  $relatedToId: ID\n  $objId: ID\n  $requestId: ID\n) {\n  ...AuditLogsQueries_ListFragment\n}\n\nfragment AuditLogsQueries_ListFragment on InternalQuery {\n  auditLogs(businessId: $businessId, first: $pageSize, after: $after, sort: $sort, scheduleId: $scheduleId, startDateTime: $startDateTime, endDateTime: $endDateTime, actionType: $actionType, specificAction: $specificAction, objType: $objectType, performedById: $performedById, relatedToId: $relatedToId, objId: $objId, requestId: $requestId) {\n    edges {\n      node {\n        id\n        actionType\n        code\n        objId\n        objType\n        occurredAt\n        performedBy {\n          id\n          computedName\n        }\n        schedule {\n          scheduleName\n          id\n        }\n        performedById\n        relatedToId\n        scheduleId\n        specificAction\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1756557190cb0ccff8c1cff1c14313f9";

export default node;
