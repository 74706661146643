import { Environment, fetchQuery, graphql } from "react-relay";

import { Id } from "../../../data/models/common";
import {
  AOSTemplateService_TemplateByIdQuery,
  AOSTemplateService_TemplateByIdQuery$data,
} from "./__generated__/AOSTemplateService_TemplateByIdQuery.graphql";
import { AOSTemplateService_TemplatesQuery } from "./__generated__/AOSTemplateService_TemplatesQuery.graphql";

const templateByIdQuery = graphql`
  query AOSTemplateService_TemplateByIdQuery(
    $businessId: ID!
    $ids: [ID!]
    $search: String
  ) {
    aosConfigTemplates(businessId: $businessId, ids: $ids, search: $search) {
      edges {
        node {
          id
          ### Replaceable content start
          templateName
          isDefault
          aosConfig {
            shiftConfig
            schedulingDayStart
            addUnderstaffingShifts
            weeklyWorkhoursMin
            weeklyWorkhoursMax
            shiftMin
            shiftMax
            shiftMinAbsolute
            shiftGapMin
            shiftMaxStart
            maxRolesPerShift
            minShiftPartLength
            breakTrigger
            breakLength
            breakMinStart
            breakMaxStart
            breakEndPad
            includeRoles
            demandOnly
            bonusRoles
            ignoreBreakRoles
            flexibleRole
            coreObeysAosRules
            skipSchedulingManagers
            skipSchedulingCore
            addCoreBreaks
            optimizeCoreBreaks
            weeklyDayparts
            daypartRanks
            planningOrder
            overstaffingPenalty
            skillPreference
            shiftLengthPreference
            penaltyShortParts
            penaltyShortPartsCutoff
            costEqualLabor
            weightEqualLaborByRating
            applyRule24HoursRest
            maxWorkdaysCalweek
            maxWorkdaysWorkweek
            overstaffingByRole
            understaffingByRole
            aosTimeout
            aosCombinedRoles
            aosOrchestratorConfig
            replanningTimeout
            replanningOrchestratorConfig
          }
          ### Replaceable content finish
        }
      }
    }
  }
`;

const templatesQuery = graphql`
  query AOSTemplateService_TemplatesQuery(
    $businessId: ID!
    $search: String
    $isDefault: Boolean
  ) {
    aosConfigTemplates(
      businessId: $businessId
      search: $search
      isDefault: $isDefault
      sort: [{ field: templateName, order: asc }]
    ) {
      edges {
        node {
          id
          ### Replaceable content start
          templateName
          isDefault
          aosConfig {
            shiftConfig
            schedulingDayStart
            addUnderstaffingShifts
            weeklyWorkhoursMin
            weeklyWorkhoursMax
            shiftMin
            shiftMax
            shiftMinAbsolute
            shiftGapMin
            shiftMaxStart
            maxRolesPerShift
            minShiftPartLength
            breakTrigger
            breakLength
            breakMinStart
            breakMaxStart
            breakEndPad
            includeRoles
            demandOnly
            bonusRoles
            ignoreBreakRoles
            flexibleRole
            coreObeysAosRules
            skipSchedulingManagers
            skipSchedulingCore
            addCoreBreaks
            optimizeCoreBreaks
            weeklyDayparts
            daypartRanks
            planningOrder
            overstaffingPenalty
            skillPreference
            shiftLengthPreference
            penaltyShortParts
            penaltyShortPartsCutoff
            costEqualLabor
            weightEqualLaborByRating
            applyRule24HoursRest
            maxWorkdaysCalweek
            maxWorkdaysWorkweek
            overstaffingByRole
            understaffingByRole
            aosTimeout
            aosCombinedRoles
            aosOrchestratorConfig
            replanningTimeout
            replanningOrchestratorConfig
          }
          ### Replaceable content finish
        }
      }
    }
  }
`;

type GetAOSTemplateFilter = {
  search?: string;
  isDefault?: boolean;
};

export type AOSConfigTemplate =
  AOSTemplateService_TemplateByIdQuery$data["aosConfigTemplates"]["edges"][number]["node"];

export default class AOSTemplateService {
  /**
   * Fetch Template matching specified id
   * @param environment environment to connect to
   * @param templateId an id of template
   * @returns a template object
   */
  public static async getTemplateById(
    environment: Environment,
    businessId: Id,
    templateId: Id,
  ): Promise<AOSConfigTemplate | null> {
    const result = await fetchQuery<AOSTemplateService_TemplateByIdQuery>(
      environment,
      templateByIdQuery,
      {
        businessId,
        ids: [templateId],
      },
    ).toPromise();

    return result?.aosConfigTemplates.edges[0]?.node ?? null;
  }

  /**
   * Fetch Template matching specified id
   * @param environment environment to connect to
   * @param filter an object to filter templates
   * @returns a template object
   */
  public static async getTemplates(
    environment: Environment,
    businessId: Id,
    filter: GetAOSTemplateFilter = {},
  ): Promise<AOSConfigTemplate[]> {
    const result = await fetchQuery<AOSTemplateService_TemplatesQuery>(
      environment,
      templatesQuery,
      {
        businessId,
        ...filter,
      },
    ).toPromise();

    return (
      result?.aosConfigTemplates?.edges
        ?.reduce((r, i) => {
          if (i && i.node) {
            r.push(i.node);
          }

          return r;
        }, [] as AOSConfigTemplate[])
        .sort((a, b) => {
          // always show default template first
          if (a?.isDefault) {
            return -1;
          }
          if (b?.isDefault) {
            return 1;
          }

          // then sort alphabetically by template name
          const aName = a?.templateName.toLocaleUpperCase() || "";
          const bName = b?.templateName.toLocaleUpperCase() || "";

          if (aName < bName) {
            return -1;
          }

          if (aName > bName) {
            return 1;
          }

          return 0;
        }) || []
    );
  }
}
