import React, { Suspense, useEffect } from "react";

import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { PreloadedQuery, useQueryLoader } from "react-relay";
import { Link } from "react-router-dom";

import { useAppRouter } from "../../../../hooks/useAppRouter";
import { useScheduleBusinessContextCheck } from "../../../../hooks/useScheduleBusinessContextCheck";
import HeaderPortal, {
  HeaderPortalBreadcrumbs,
} from "../../../common/Portal/HeaderPortal";
import { EmploymentTypesQueries_GetSingleEmploymentTypeConfig_Query } from "../__generated__/EmploymentTypesQueries_GetSingleEmploymentTypeConfig_Query.graphql";
import { GetSingleEmploymentTypeConfigQuery } from "../EmploymentTypesQueries";
import EmploymentTypesProfileForm from "./EmploymentTypesProfileForm";

type MatchParams = {
  employment_type_id: string;
};

type Props = {
  initialQueryRef: PreloadedQuery<EmploymentTypesQueries_GetSingleEmploymentTypeConfig_Query>;
};
export default function EmploymentTypesProfile({ initialQueryRef }: Props) {
  const { businessId } = useScheduleBusinessContextCheck();
  const { t } = useTranslation("employment-types");
  const {
    params: { employment_type_id: employmentTypeId },
  } = useAppRouter<MatchParams>();

  const [employmentTypeQueryRef, loadEmploymentTypeConfigQuery, disposeQuery] =
    useQueryLoader(GetSingleEmploymentTypeConfigQuery, initialQueryRef);
  useEffect(() => {
    if (businessId) {
      loadEmploymentTypeConfigQuery(
        {
          businessId,
          deleted: null,
          id: employmentTypeId ?? "",
        },
        {
          fetchPolicy: "network-only",
        },
      );
    }
    return () => {
      disposeQuery();
    };
  }, [
    employmentTypeId,
    businessId,
    loadEmploymentTypeConfigQuery,
    disposeQuery,
  ]);
  return (
    <Suspense
      fallback={
        <HeaderPortal as="span" elementId="sub-header-portal">
          <HeaderPortalBreadcrumbs
            breadcrumbs={[
              <Link to="#">
                <span>{t("nav.employmentTypes")}</span>
              </Link>,
            ]}
          />
        </HeaderPortal>
      }
    >
      {employmentTypeQueryRef != null && (
        <Card body>
          <EmploymentTypesProfileForm
            queryReference={employmentTypeQueryRef}
            employmentTypeId={employmentTypeId}
          />
        </Card>
      )}
    </Suspense>
  );
}
