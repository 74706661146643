import React, { useEffect } from "react";

import Card from "react-bootstrap/Card";
import { useTranslation } from "react-i18next";
import { useQueryLoader } from "react-relay";

import {
  JobTitleSortField,
  Order,
} from "../../../data/generated/stack_internal_schema";
import { useAppRouter } from "../../../hooks/useAppRouter";
import Loader from "../../common/Loader";
import HeaderPortal from "../../common/Portal/HeaderPortal";
import { JobTitlesQueries_InternalQuery } from "./__generated__/JobTitlesQueries_InternalQuery.graphql";
import { GetJobTitlesQuery } from "./JobTitlesQueries";
import JobTitlesTable from "./JobTitlesTable";

type MatchParams = {
  business_id: string;
};

export default function JobTitles() {
  const { t } = useTranslation("job-titles");
  const {
    params: { business_id: businessId },
  } = useAppRouter<MatchParams>();

  const [queryReference, loadQuery, disposeQuery] =
    useQueryLoader<JobTitlesQueries_InternalQuery>(GetJobTitlesQuery);

  useEffect(() => {
    loadQuery(
      {
        businessId,
        pageSize: 30,
        sort: [
          {
            field: JobTitleSortField.Code,
            order: Order.Asc,
          },
        ],
      },
      { fetchPolicy: "network-only" },
    );
    return () => {
      disposeQuery();
    };
  }, [loadQuery, disposeQuery, businessId]);

  return (
    <>
      <HeaderPortal>
        <span className="ml-2 mr-2">&gt;</span>
        <span>{t("nav.jobTitles")}</span>
      </HeaderPortal>

      <Card body>
        <React.Suspense fallback={<Loader />}>
          {queryReference != null && (
            <JobTitlesTable queryReference={queryReference} />
          )}
        </React.Suspense>
      </Card>
    </>
  );
}
