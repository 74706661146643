import React from "react";

import styled, { css } from "styled-components";

import { normalPillMixin, pillMixin } from "../../styles/mixins";

export enum BadgeColor {
  yellow = "yellow",
  red = "red",
  grey = "grey",
  green = "green",
  limeGreen = "limeGreen",
}

const StyledBadge = styled.div<{ variant: BadgeColor; saturated: boolean }>`
  display: flex;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  max-height: 20px;
  text-align: center;

  padding: 2px 6px;
  border-radius: 3px;
  flex-grow: 0;

  ${(props) => {
    if (props.saturated) {
      switch (props.variant) {
        case BadgeColor.green:
          return pillMixin(props.theme.generic.green);
        case BadgeColor.limeGreen:
          return pillMixin(props.theme.generic.limeGreen);
        case BadgeColor.red:
          return pillMixin(props.theme.generic.red);
        case BadgeColor.yellow:
          return pillMixin(props.theme.generic.yellow);
        // note: grey doesn't match the same pattern as the other colours. Easy enough to change for now though
        case BadgeColor.grey:
        default:
          return css`
            .pill-text {
              color: ${props.theme.generic.text.darkGrey};
            }
            background: ${props.theme.generic.grey};
          `;
      }
    }
    return "";
  }}

  // Normal - non saturated
  ${(props) => {
    if (!props.saturated) {
      switch (props.variant) {
        case BadgeColor.green:
          return normalPillMixin(props.theme.generic.green, true);
        case BadgeColor.limeGreen:
          return normalPillMixin(props.theme.generic.limeGreen, false);
        case BadgeColor.red:
          return normalPillMixin(props.theme.generic.red, true);
        case BadgeColor.yellow:
          return normalPillMixin(props.theme.generic.yellow, false);
        case BadgeColor.grey:
        default:
          return normalPillMixin(props.theme.generic.border.default, false);
      }
    }
    return "";
  }}
`;

export enum PillboxVariantEnum {
  Active = "Active",
  Upcoming = "Upcoming",
  Progress = "Progress",
  Error = "Error",
  Rejected = "Rejected",
  Declined = "Declined",
  Removed = "Removed",
  Completed = "Completed",
  External = "External",
  Overwritten = "Overwritten",
}

export type PillboxVariant = keyof typeof PillboxVariantEnum;

type Props = {
  text: string;
  variant: PillboxVariant;
  saturated?: boolean;
  className?: string;
};

export function Pillbox({ text, variant, className, saturated = true }: Props) {
  const mapVariantToColour = () => {
    switch (variant) {
      case PillboxVariantEnum.Active:
        return BadgeColor.green;
      case PillboxVariantEnum.Upcoming:
        return BadgeColor.limeGreen;
      case PillboxVariantEnum.Progress:
        return BadgeColor.yellow;
      case PillboxVariantEnum.Error:
      case PillboxVariantEnum.Declined:
      case PillboxVariantEnum.Removed:
      case PillboxVariantEnum.Rejected:
        return BadgeColor.red;
      case PillboxVariantEnum.External:
      case PillboxVariantEnum.Overwritten:
      case PillboxVariantEnum.Completed:
      default:
        return BadgeColor.grey;
    }
  };

  return (
    <div className={`d-flex ${className ?? ""}`}>
      <StyledBadge variant={mapVariantToColour()} saturated={saturated}>
        <span className="pill-text">{text}</span>
      </StyledBadge>
    </div>
  );
}
