import { graphql } from "react-relay";

export const GetTimeOffTypesQuery = graphql`
  query TimeOffTypesQueries_GetTimeOffTypes_Query($businessId: ID!) {
    timeOffTypes(businessId: $businessId) {
      nodes {
        id
        deleted
        createdAt
        updatedAt
        ### Replaceable content start
        name
        shortName
        code
        paid
        ignoreAutoReject
        isManagerByProxyOnly
        employmentTypeCodes
        ### Replaceable content finish
      }
    }
  }
`;

export const GetSingleTimeOffTypeQuery = graphql`
  query TimeOffTypesQueries_GetSingleTimeOffType_Query(
    $ids: [ID!]
    $businessId: ID!
    $skip: Boolean!
  ) {
    timeOffTypes(ids: $ids, businessId: $businessId) @skip(if: $skip) {
      nodes {
        id
        ### Replaceable content start
        name
        shortName
        code
        paid
        ignoreAutoReject
        isManagerByProxyOnly
        employmentTypeCodes
        ### Replaceable content finish
      }
    }
  }
`;

export const UpdateTimeOffTypeMutation = graphql`
  mutation TimeOffTypesQueries_UpdateTimeOffType_Mutation(
    $businessId: ID!
    $id: ID!
    $input: TimeOffTypeUpdateInput!
  ) {
    updateTimeOffType(businessId: $businessId, id: $id, input: $input) {
      id
      ### Replaceable content start
      name
      shortName
      code
      paid
      ignoreAutoReject
      isManagerByProxyOnly
      employmentTypeCodes
      ### Replaceable content finish
    }
  }
`;

export const CreateTimeOffTypeTypeMutation = graphql`
  mutation TimeOffTypesQueries_CreateTimeOffType_Mutation(
    $businessId: ID!
    $input: TimeOffTypeCreateInput!
  ) {
    createTimeOffType(businessId: $businessId, input: $input) {
      id
      ### Replaceable content start
      name
      shortName
      code
      paid
      ignoreAutoReject
      isManagerByProxyOnly
      employmentTypeCodes
      ### Replaceable content finish
    }
  }
`;

export const DeleteTimeOffTypeMutation = graphql`
  mutation TimeOffTypesQueries_DeleteDeleteTimeOffType_Mutation(
    $businessId: ID!
    $id: ID!
  ) {
    deleteTimeOffType(businessId: $businessId, id: $id)
  }
`;

// Keep this query light since it's only for fetching the count at this stage...
export const GetTimeOffBalancesForTypeQuery = graphql`
  query TimeOffTypesQueries_GetTimeOffBalances_Query(
    $businessId: ID!
    $timeOffTypeIds: [ID!]
  ) {
    timeOffBalances(businessId: $businessId, timeOffTypeIds: $timeOffTypeIds) {
      nodes {
        id
        hoursBalance
      }
    }
  }
`;
