import React, { createContext } from "react";

import * as yup from "yup";

import { IProperty } from "../components/common/Form/models";

export type DynamicFormContextProps = {
  propertyList: IProperty[];
  validationRules?: yup.ObjectSchema<any>;
  componentRules?: Record<string, any>;
};

export const DynamicFormContext = createContext<DynamicFormContextProps>({
  propertyList: [],
});

export const useDynamicFormContext = () => React.useContext(DynamicFormContext);
