/**
 * @generated SignedSource<<8fcaf94412f7e4073ac4a032c26add3b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateStackMutation$variables = {
  deleted: boolean;
  domainName: string;
  stackCode: string;
};
export type UpdateStackMutation$data = {
  readonly updateStack: {
    readonly deleted: boolean;
    readonly domainName: string;
    readonly id: string;
    readonly stackCode: string;
  };
};
export type UpdateStackMutation = {
  response: UpdateStackMutation$data;
  variables: UpdateStackMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "deleted"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "domainName"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "stackCode"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "deleted",
        "variableName": "deleted"
      },
      {
        "kind": "Variable",
        "name": "domainName",
        "variableName": "domainName"
      },
      {
        "kind": "Variable",
        "name": "stackCode",
        "variableName": "stackCode"
      }
    ],
    "concreteType": "Stack",
    "kind": "LinkedField",
    "name": "updateStack",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "domainName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "stackCode",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deleted",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateStackMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "UpdateStackMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "b98406e46a4572c2e972d7b716815c3b",
    "id": null,
    "metadata": {},
    "name": "UpdateStackMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateStackMutation(\n  $domainName: String!\n  $stackCode: String!\n  $deleted: Boolean!\n) {\n  updateStack(domainName: $domainName, stackCode: $stackCode, deleted: $deleted) {\n    id\n    domainName\n    stackCode\n    deleted\n  }\n}\n"
  }
};
})();

(node as any).hash = "0d91cc80c4d2f7bcd8246921732b8b55";

export default node;
