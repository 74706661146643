import React from "react";

import { Card, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { useAppRouter } from "../../../../hooks/useAppRouter";
import { formatDate } from "../../../../utils/utility";
import Loader from "../../../common/Loader";
import HeaderPortal, {
  HeaderPortalBreadcrumbs,
} from "../../../common/Portal/HeaderPortal";
import { useKpiScreenConfigs } from "./KpiComponentsQueries";

export default function KpiComponents() {
  const { t } = useTranslation("kpi-components");

  return (
    <>
      <HeaderPortal as="span">
        <HeaderPortalBreadcrumbs
          breadcrumbs={[
            <span>{t("businesses:layout.nav.kpiComponents")}</span>,
          ]}
        />
      </HeaderPortal>
      <Card border="none" body>
        <React.Suspense fallback={<Loader />}>
          <KpiComponentsTable />
        </React.Suspense>
      </Card>
    </>
  );
}

function KpiComponentsTable() {
  const { t } = useTranslation("kpi-components");
  const router = useAppRouter<{ business_id: string }>();

  const kpiScreenConfigs = useKpiScreenConfigs({
    businessId: router.params.business_id,
  });

  return (
    <Table hover size="sm">
      <thead>
        <tr>
          <th>{t("table.headers.screen")}</th>
          <th>{t("table.headers.metrics")}</th>
          <th>{t("table.headers.lastUpdated")}</th>
          <th>{t("table.headers.updatedBy")}</th>
        </tr>
      </thead>
      <tbody>
        {kpiScreenConfigs.map((kpiScreenConfig) => (
          <tr key={kpiScreenConfig.screenCode}>
            <td>
              <Link to={`${router.pathname}/${kpiScreenConfig.screenCode}`}>
                {t(`screen.${kpiScreenConfig.screenCode}`)}
              </Link>
            </td>
            <td style={{ maxWidth: 493 }}>
              {kpiScreenConfig.kpiDisplayConfig
                .map((displayConfig) => displayConfig.kpi?.code)
                .join(", ")}
            </td>
            <td>
              {formatDate(kpiScreenConfig.updatedAt, {
                toFormat: "dd MMM yyyy",
                showRelative: true,
              })}
            </td>
            <td>{kpiScreenConfig.updatedByName}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
