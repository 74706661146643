/**
 * @generated SignedSource<<7d338ee69ccc76e8be33a19412ef58f9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type SkillLevelAssignmentActionEnum = "none" | "warn" | "%future added value";
export type SkillLevels_InternalQuery$variables = {
  businessId: string;
};
export type SkillLevels_InternalQuery$data = {
  readonly skillLevels: {
    readonly nodes: ReadonlyArray<{
      readonly aosLevel: number | null;
      readonly assignmentAction: SkillLevelAssignmentActionEnum;
      readonly createdAt: string;
      readonly id: string;
      readonly isDefault: boolean;
      readonly level: string;
      readonly name: string;
      readonly updatedAt: string;
    }>;
  };
};
export type SkillLevels_InternalQuery = {
  response: SkillLevels_InternalQuery$data;
  variables: SkillLevels_InternalQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "businessId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "businessId",
        "variableName": "businessId"
      }
    ],
    "concreteType": "SkillLevelConnection",
    "kind": "LinkedField",
    "name": "skillLevels",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SkillLevel",
        "kind": "LinkedField",
        "name": "nodes",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "level",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "aosLevel",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "assignmentAction",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isDefault",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "updatedAt",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SkillLevels_InternalQuery",
    "selections": (v1/*: any*/),
    "type": "InternalQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SkillLevels_InternalQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "72c36ccb1f5098347d17269733c4153c",
    "id": null,
    "metadata": {},
    "name": "SkillLevels_InternalQuery",
    "operationKind": "query",
    "text": "query SkillLevels_InternalQuery(\n  $businessId: ID!\n) {\n  skillLevels(businessId: $businessId) {\n    nodes {\n      id\n      name\n      level\n      aosLevel\n      assignmentAction\n      isDefault\n      createdAt\n      updatedAt\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "7bd3b1d4891caffd611995ccf49a766f";

export default node;
