import React, { Suspense } from "react";

import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Table from "react-bootstrap/Table";
import Tooltip from "react-bootstrap/Tooltip";
import { useTranslation } from "react-i18next";
import {
  PreloadedQuery,
  RelayEnvironmentProvider,
  usePaginationFragment,
  usePreloadedQuery,
} from "react-relay";
import { Link, RouteComponentProps } from "react-router-dom";

import { useBusinessContext } from "../../../contexts/BusinessContext";
import { useModal } from "../../../contexts/ModalContext";
import { useAppRouter } from "../../../hooks/useAppRouter";
import { formatDate, toRelative } from "../../../utils/utility";
import LazyLoader from "../../common/LazyLoader";
import {
  AuditLogsQueries_ListFragment$data,
  AuditLogsQueries_ListFragment$key,
} from "./__generated__/AuditLogsQueries_ListFragment.graphql";
import { AuditLogsQueries_ListQuery } from "./__generated__/AuditLogsQueries_ListQuery.graphql";
import { AuditLogTablePaginationQuery } from "./__generated__/AuditLogTablePaginationQuery.graphql";
import AuditLogDetails from "./Modals/AuditLogDetailsModal";
import { auditLogFragment, auditLogsQuery } from "./AuditLogsQueries";

interface MatchParams {
  business_id: string;
  stack_id: string;
}

type Props = RouteComponentProps<MatchParams> & {
  queryReference: PreloadedQuery<AuditLogsQueries_ListQuery>;
};

type EdgeType = {} & AuditLogsQueries_ListFragment$data["auditLogs"]["edges"];
type Edge = {} & EdgeType[number];
type AuditLog = {} & Edge["node"];

const AuditLogTable = ({ queryReference }: Props) => {
  const query = usePreloadedQuery(auditLogsQuery, queryReference);

  const { data, loadNext, hasNext, isLoadingNext } = usePaginationFragment<
    AuditLogTablePaginationQuery,
    AuditLogsQueries_ListFragment$key
  >(auditLogFragment, query);

  const {
    params: { business_id: businessId, stack_id: stackId },
  } = useAppRouter<MatchParams>();

  const { t } = useTranslation("audit-logs");
  const { showModal, hideModal } = useModal();
  const { environment } = useBusinessContext();

  const onViewDetailsClicked = (id: string) => {
    if (!data || !environment) {
      return;
    }

    showModal(
      <RelayEnvironmentProvider environment={environment}>
        <Suspense fallback="">
          <AuditLogDetails
            onClose={hideModal}
            businessId={businessId}
            id={id}
          />
        </Suspense>
      </RelayEnvironmentProvider>,
    );
  };

  // data mapping
  const auditLogs: AuditLog[] = (data.auditLogs.edges || []).reduce(
    (acc: AuditLog[], edge) => {
      if (edge?.node) {
        acc.push(edge.node);
      }
      return acc;
    },
    [],
  );

  return (
    <>
      <Table hover size="sm">
        <thead>
          <tr>
            <th>{t("table.headers.schedule")}</th>
            <th>{t("table.headers.objectType")}</th>
            <th>{t("table.headers.actionType")}</th>
            <th>{t("table.headers.specificAction")}</th>
            <th>{t("table.headers.performedBy")}</th>
            <th>{t("table.headers.relatedTo")}</th>
            <th>{t("table.headers.updatedAt")}</th>
          </tr>
        </thead>
        <tbody>
          {auditLogs.map((auditLog) => {
            const {
              id,
              objType,
              schedule,
              scheduleId,
              actionType,
              performedBy,
              performedById,
              relatedToId,
              specificAction,
              occurredAt,
            } = auditLog;
            const { computedName } = performedBy || {};
            return (
              <tr key={id}>
                <td>
                  {scheduleId ? (
                    <Link
                      to={`/stack/${stackId}/business/${businessId}/schedule/${scheduleId}`}
                    >
                      {schedule?.scheduleName}
                    </Link>
                  ) : (
                    t("table.body.blank")
                  )}
                </td>
                <td>
                  <Button
                    variant="link"
                    onClick={() => onViewDetailsClicked(id)}
                  >
                    {objType}
                  </Button>
                </td>
                <td>{actionType}</td>
                <td>{specificAction}</td>
                <td>
                  {performedById ? (
                    <Link
                      to={`/stack/${stackId}/business/${businessId}/employee/${performedById}`}
                    >
                      {computedName}
                    </Link>
                  ) : (
                    <span className="font-italic">
                      {t("table.body.system")}
                    </span>
                  )}
                </td>
                <td>
                  {relatedToId ? (
                    <Link
                      to={`/stack/${stackId}/business/${businessId}/employee/${relatedToId}`}
                    >
                      {relatedToId}
                    </Link>
                  ) : (
                    <span className="font-italic">{t("table.body.blank")}</span>
                  )}
                </td>
                <td>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip">
                        {formatDate(occurredAt, {
                          toFormat: "dd MMM yyyy hh:mm a z",
                          showRelative: false,
                        })}
                      </Tooltip>
                    }
                  >
                    <span>
                      {toRelative(occurredAt as string, {
                        defaultValue: t("table.body.blank"),
                      })}
                    </span>
                  </OverlayTrigger>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <LazyLoader
        isLoading={() => isLoadingNext}
        hasMore={() => hasNext}
        loadMore={loadNext}
      />
    </>
  );
};

export default AuditLogTable;
