/**
 * @generated SignedSource<<2f585b916786c7c348032ad0c6a8d39f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ValueTypeEnum = "currency" | "float" | "hours" | "int" | "percentage" | "seconds" | "string" | "%future added value";
export type EmploymentPerformanceRatingsQueries_EmploymentPerformanceRatingsQuery$variables = {
  businessId: string;
};
export type EmploymentPerformanceRatingsQueries_EmploymentPerformanceRatingsQuery$data = {
  readonly employmentPerformanceReviewRatings: {
    readonly nodes: ReadonlyArray<{
      readonly crewAdjustmentAmount: any | null;
      readonly crewAdjustmentType: ValueTypeEnum;
      readonly deleted: boolean;
      readonly id: string;
      readonly managerAdjustmentAmount: any | null;
      readonly managerAdjustmentType: ValueTypeEnum;
      readonly name: string;
      readonly rank: number;
      readonly updatedAt: string | null;
    }>;
  };
};
export type EmploymentPerformanceRatingsQueries_EmploymentPerformanceRatingsQuery = {
  response: EmploymentPerformanceRatingsQueries_EmploymentPerformanceRatingsQuery$data;
  variables: EmploymentPerformanceRatingsQueries_EmploymentPerformanceRatingsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "businessId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "businessId",
        "variableName": "businessId"
      }
    ],
    "concreteType": "EmploymentPerformanceReviewRatingConnection",
    "kind": "LinkedField",
    "name": "employmentPerformanceReviewRatings",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "EmploymentPerformanceReviewRating",
        "kind": "LinkedField",
        "name": "nodes",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rank",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "crewAdjustmentType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "managerAdjustmentType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "crewAdjustmentAmount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "managerAdjustmentAmount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "deleted",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "updatedAt",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EmploymentPerformanceRatingsQueries_EmploymentPerformanceRatingsQuery",
    "selections": (v1/*: any*/),
    "type": "InternalQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EmploymentPerformanceRatingsQueries_EmploymentPerformanceRatingsQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "b636ec50898d9785d959e4b07f7743f9",
    "id": null,
    "metadata": {},
    "name": "EmploymentPerformanceRatingsQueries_EmploymentPerformanceRatingsQuery",
    "operationKind": "query",
    "text": "query EmploymentPerformanceRatingsQueries_EmploymentPerformanceRatingsQuery(\n  $businessId: ID!\n) {\n  employmentPerformanceReviewRatings(businessId: $businessId) {\n    nodes {\n      id\n      name\n      rank\n      crewAdjustmentType\n      managerAdjustmentType\n      crewAdjustmentAmount\n      managerAdjustmentAmount\n      deleted\n      updatedAt\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e59ad3fc6afae5ef3cb850bd81d486e6";

export default node;
