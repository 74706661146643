import { graphql } from "react-relay";

export const auditLogsQuery = graphql`
  query AuditLogsQueries_ListQuery(
    $businessId: ID!
    $pageSize: Int!
    $after: String
    $sort: [AuditLogSort!]
    $scheduleId: ID
    $startDateTime: ISO8601DateTime
    $endDateTime: ISO8601DateTime
    $actionType: AuditLogActionTypeEnum
    $specificAction: String
    $objectType: String
    $performedById: ID
    $relatedToId: ID
    $objId: ID
    $requestId: ID
  ) {
    ...AuditLogsQueries_ListFragment
  }
`;

export const auditLogFragment = graphql`
  fragment AuditLogsQueries_ListFragment on InternalQuery
  @refetchable(queryName: "AuditLogTablePaginationQuery") {
    auditLogs(
      businessId: $businessId
      first: $pageSize
      after: $after
      sort: $sort
      scheduleId: $scheduleId
      startDateTime: $startDateTime
      endDateTime: $endDateTime
      actionType: $actionType
      specificAction: $specificAction
      objType: $objectType
      performedById: $performedById
      relatedToId: $relatedToId
      objId: $objId
      requestId: $requestId
    ) @connection(key: "AuditLogTable_auditLogs") {
      edges {
        node {
          id
          actionType
          code
          objId
          objType
          occurredAt
          performedBy {
            id
            computedName
          }
          schedule {
            scheduleName
          }
          performedById
          relatedToId
          scheduleId
          specificAction
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

export const detailsQuery = graphql`
  query AuditLogsQueries_DetailsQuery($businessId: ID!, $ids: [ID!]) {
    auditLogs(businessId: $businessId, ids: $ids) {
      nodes {
        id
        actionType
        code
        objId
        objType
        occurredAt
        performedBy {
          id
          computedName
        }
        performedById
        schedule {
          scheduleName
        }
        relatedToId
        requestId
        scheduleId
        specificAction
        related
        meta
      }
    }
  }
`;
