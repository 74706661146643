import React from "react";

import { useFormikContext } from "formik";
import isString from "lodash/isString";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Row from "react-bootstrap/Row";
import Tooltip from "react-bootstrap/Tooltip";
import { WithTranslation, withTranslation } from "react-i18next";
import styled from "styled-components";

import { InvalidClassName } from "../../../data/models/common";
import { Pillbox, PillboxVariantEnum } from "../Pillbox";
import { FormGroupProperties } from "./models";

const StyledLabel = styled(Form.Label)<{ $boldLabel: boolean }>`
  font-weight: ${(props) => (props.$boldLabel ? "bold" : "initial")};
`;

type Props = FormGroupProperties & {
  fieldKey: string;
  tooltipText?: string;
  isRequired?: boolean;
  isExternal?: boolean;
  boldLabel?: boolean;
} & WithTranslation;

const fieldLabelKey = "field-label";
const fieldFeedbackKey = "field-feedback";

function FormGroup({
  fieldKey,
  label,
  children,
  xs,
  md,
  lg,
  hideLabel,
  hideError,
  description,
  horizontal,
  hideDescription,
  className,
  tooltipText,
  isRequired,
  isExternal,
  boldLabel,
  formGroupClassName,
  t,
}: Props) {
  const formikContext = useFormikContext();
  const meta = formikContext.getFieldMeta(fieldKey);
  const { error } = meta;

  return (
    <Col xs={xs || 6} md={md || 6} lg={lg || 3} className={formGroupClassName}>
      <Form.Group
        as={horizontal ? Row : undefined}
        className={`${className ?? ""} ${isRequired ? "required" : ""}`}
      >
        <span className="d-flex flex-nowrap ">
          {tooltipText ? (
            <OverlayTrigger
              defaultShow={false}
              placement="top"
              overlay={<Tooltip id="tooltip">{tooltipText}</Tooltip>}
              trigger={undefined}
              delay={undefined}
              flip={undefined}
              onHide={undefined}
              onToggle={undefined}
              popperConfig={undefined}
              show={undefined}
              target={undefined}
            >
              <Form.Label
                as={horizontal ? Col : undefined}
                htmlFor={fieldKey}
                key={fieldLabelKey}
                className={`${error ? InvalidClassName : ""}
                  ${hideLabel || label == null ? "d-none" : ""}`}
              >
                {label || ""}
              </Form.Label>
            </OverlayTrigger>
          ) : (
            <StyledLabel
              $boldLabel={boldLabel}
              as={horizontal ? Col : undefined}
              htmlFor={fieldKey}
              key={fieldLabelKey}
              className={`${error ? InvalidClassName : ""}
                  ${hideLabel || label == null ? "d-none" : ""}`}
            >
              {label || ""}
            </StyledLabel>
          )}
          {isExternal && (
            <span className="ml-1">
              <Pillbox
                text={t("pillbox.external_short")}
                variant={PillboxVariantEnum.External}
              />
            </span>
          )}
        </span>

        {children}
        {description && !hideDescription ? (
          <Form.Text className="text-muted">{description}</Form.Text>
        ) : null}
        {hideError ? null : (
          <Form.Control.Feedback
            type="invalid"
            key={fieldFeedbackKey}
            className={horizontal ? "col" : ""}
          >
            {isString(error) ? error : ""}
          </Form.Control.Feedback>
        )}
      </Form.Group>
    </Col>
  );
}

export default withTranslation()(FormGroup);
