import React from "react";

import { Alert } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Nav from "react-bootstrap/Nav";
import { useTranslation } from "react-i18next";
import { LinkContainer } from "react-router-bootstrap";
import { Redirect, Route, RouteComponentProps } from "react-router-dom";
import styled from "styled-components";

import { useBusinessContext } from "../../../../contexts/BusinessContext";
import { useAppRouter } from "../../../../hooks/useAppRouter";
import HeaderPortal, {
  HeaderPortalBreadcrumbs,
} from "../../../common/Portal/HeaderPortal";
import { useBusinessPerformanceSettingsQuery } from "../../Employment/PerformanceSettings";
import Commendation, {
  useCommendationQuery,
} from "./Commendation/Commendation";
import DisciplinaryAction, {
  useDisciplinaryActionQuery,
} from "./DisciplinaryAction/DisciplinaryAction";

type Props = RouteComponentProps;

const StyledCard = styled(Card)`
  border-top: none;
  border-radius: 0px;
`;

export default function FeedbackReasons({ match }: Props) {
  const { t } = useTranslation("feedback-reasons");
  const { business } = useBusinessContext();
  const businessData = useBusinessPerformanceSettingsQuery(business.id);
  const router = useAppRouter();

  const [disciplinaryActionsQueryReference, disciplinaryActionsFilters] =
    useDisciplinaryActionQuery();

  const [commendationsQueryReference, commendationsFilters] =
    useCommendationQuery();

  if (!businessData) {
    return (
      <Alert variant="danger">
        No business performance settings could be found.
      </Alert>
    );
  }

  if (
    !router.pathname.endsWith("/feedback_reasons/disciplinary_actions") &&
    !router.pathname.endsWith("/feedback_reasons/commendations")
  ) {
    if (
      !businessData.disciplinaryActionEnabled &&
      !businessData.commendationEnabled
    ) {
      return <Redirect to={router.getGoBackUrl("/profile")} />;
    }
    return (
      <Redirect
        to={`${match.url}/${
          businessData.disciplinaryActionEnabled
            ? "disciplinary_actions"
            : "commendations"
        }`}
      />
    );
  }

  return (
    <>
      <HeaderPortal as="span" elementId="sub-header-portal">
        <HeaderPortalBreadcrumbs
          breadcrumbs={[
            <span>{t("businesses:layout.nav.feedbackReasons")}</span>,
          ]}
        />
      </HeaderPortal>
      <Nav variant="tabs">
        <LinkContainer exact to={`${match.url}/disciplinary_actions`}>
          <Nav.Link
            className="nav-item"
            disabled={!businessData.disciplinaryActionEnabled}
          >
            {t("disciplinary_action.title")}
          </Nav.Link>
        </LinkContainer>
        <LinkContainer to={`${match.url}/commendations`}>
          <Nav.Link
            className="nav-item"
            disabled={!businessData.commendationEnabled}
          >
            {t("commendation.title")}
          </Nav.Link>
        </LinkContainer>
      </Nav>

      <StyledCard body>
        <Route
          exact
          path={`${match.path}/disciplinary_actions`}
          render={(props: any) =>
            disciplinaryActionsQueryReference !== null && (
              <DisciplinaryAction
                queryReference={disciplinaryActionsQueryReference}
                filters={disciplinaryActionsFilters}
              />
            )
          }
        />
        <Route
          path={`${match.path}/commendations`}
          render={(props: any) =>
            commendationsQueryReference !== null && (
              <Commendation
                queryReference={commendationsQueryReference}
                filters={commendationsFilters}
              />
            )
          }
        />
      </StyledCard>
    </>
  );
}
