import React from "react";

import { DateTime } from "luxon";
import DatePicker, { ReactDatePickerProps } from "react-datepicker";
import styled from "styled-components";

import { stringToNumber, valueToDateTime } from "../../../utils/utility";

import "react-datepicker/dist/react-datepicker.css";

const defaultProps = {
  timezone: "utc",
  className: "",
  isInvalid: false,
  showTimeSelect: false,
  showTimeSelectOnly: false,
  maxDate: new Date("01-01-9999"),
};

// eslint-disable-next-line @typescript-eslint/no-use-before-define
type Props = Omit<ReactDatePickerProps, "onChange"> & {
  value?: string | Date | number;
  fieldKey: string;
  onChange: (newValue: string | number | null) => void;
  valueFormat?: string;
  displayFormat: string;
  timezone?: string;
  className?: string;
  disabled?: boolean;
  dateTimePickerClassName?: string;
  size?: "small" | "large";
};

const DatePickerWrapper = styled("div")<{
  $size?: string;
  $showTimeSelect: boolean;
}>`
  max-width: ${(props) => (props.$size === "small" ? "120px" : "240px")};
  .react-datepicker-wrapper {
    width: 100%;
  }
  .react-datepicker-popper {
    z-index: 10 !important;
  }

  // LK-8971: Annoying the time selector component when resizing the page, this ensures that it survives the journey
  ${(props) =>
    props.$showTimeSelect &&
    `
    .react-datepicker__input-container {
      width: 100%;
    }
    .react-datepicker {
      width: 328px;
    }
   .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list {
    padding-left: unset;
    padding-right: unset;
    width: 100px;
  }
  `}
`;

export default function DateTimePicker(p: Props) {
  const props = { ...defaultProps, ...p };
  const dateTimeFromLocalZone = (date: Date, timezone: string) => {
    const dateWithoutZone = DateTime.fromJSDate(date).toISO({
      includeOffset: false,
    });

    return DateTime.fromISO(dateWithoutZone, { zone: timezone });
  };

  const toJSDateLocalZone = (
    rawValue?: string | number | Date,
    timezone = "utc",
  ) => {
    const dateTime = valueToDateTime(
      rawValue as any,
      props.valueFormat,
      timezone,
    );
    if (!dateTime || !dateTime.isValid) {
      return null;
    }

    const dateWithoutZone = dateTime
      .setZone(timezone)
      .toISO({ includeOffset: false });

    // get local zone
    const localZone = DateTime.local().toFormat("z");
    const d = DateTime.fromISO(dateWithoutZone, { zone: localZone });
    return d.isValid ? d.toJSDate() : null;
  };

  const toValue = (dateTime?: DateTime | null): string | number | null => {
    if (!dateTime || !dateTime.isValid) {
      return null;
    }
    const { valueFormat } = props;
    if (!valueFormat) {
      return dateTime.toISO();
    }

    const stringValue = dateTime.toFormat(valueFormat);
    switch (valueFormat) {
      case "x":
      case "HH": {
        // special formats convert result to number if possible
        // invalid value should fall through as string so that it can be caught by validation
        return stringToNumber(stringValue);
      }

      default:
        // javascript use milliseconds
        return stringValue;
    }
  };

  const {
    fieldKey,
    value,
    onChange,
    displayFormat,
    timezone,
    valueFormat,
    className,
    dateTimePickerClassName,
    size,
    maxDate,
    ...rest
  } = props;

  const dateValue = toJSDateLocalZone(value, timezone);
  return (
    <DatePickerWrapper
      $showTimeSelect={rest.showTimeSelect && !rest.showTimeSelectOnly}
      $size={size as string}
      className={dateTimePickerClassName}
    >
      <DatePicker
        selected={dateValue || null}
        name={fieldKey}
        autoComplete="off"
        className={`form-control ${className}`}
        maxDate={maxDate}
        {...rest}
        dateFormat={displayFormat}
        onChange={(v: Date | [Date, Date] | null) => {
          if (!v) {
            onChange(v);
          }

          const newDate = dateTimeFromLocalZone(v as Date, timezone);
          const newValue = toValue(newDate);
          const oldDateTime = valueToDateTime(
            value as any,
            valueFormat,
            timezone,
          );
          const oldValue = toValue(oldDateTime);
          if (oldValue !== newValue) {
            onChange(newValue);
          }
        }}
      />
    </DatePickerWrapper>
  );
}
