/**
 * @generated SignedSource<<443b2da9fa016043694b389fdc622835>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type BusinessService_GlobalBusinesses_Query$variables = {};
export type BusinessService_GlobalBusinesses_Query$data = {
  readonly globalBusinesses: {
    readonly nodes: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
      readonly used: boolean;
    }>;
  };
};
export type BusinessService_GlobalBusinesses_Query = {
  response: BusinessService_GlobalBusinesses_Query$data;
  variables: BusinessService_GlobalBusinesses_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "GlobalBusinessConnection",
    "kind": "LinkedField",
    "name": "globalBusinesses",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "GlobalBusiness",
        "kind": "LinkedField",
        "name": "nodes",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "used",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "BusinessService_GlobalBusinesses_Query",
    "selections": (v0/*: any*/),
    "type": "InternalQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "BusinessService_GlobalBusinesses_Query",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "35d2f4ab08311bce3ca0341b739f7f91",
    "id": null,
    "metadata": {},
    "name": "BusinessService_GlobalBusinesses_Query",
    "operationKind": "query",
    "text": "query BusinessService_GlobalBusinesses_Query {\n  globalBusinesses {\n    nodes {\n      id\n      name\n      used\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d9808aac2b3b358abe2b6c7bbe689ea2";

export default node;
