import React from "react";

import Nav from "react-bootstrap/Nav";
import { useTranslation } from "react-i18next";
import { LinkContainer } from "react-router-bootstrap";
import { Route, RouteComponentProps } from "react-router-dom";

import AOSTemplateProfile from "./AOSTemplateProfile";

interface MatchParams {
  business_id: string;
  aos_template_id?: string;
  stack_id: string;
  clone_from_id?: string;
}

type Props = RouteComponentProps<MatchParams>;

export default function AOSTemplateLayout({ match }: Props) {
  const { t } = useTranslation("aos");
  return (
    <>
      <header className="sub-header-container">
        <Nav variant="tabs">
          <LinkContainer exact to={`${match.url}`}>
            <Nav.Link>{t("layout.title")}</Nav.Link>
          </LinkContainer>
        </Nav>
      </header>
      <div className="mt-3">
        <Route
          exact
          path={`${match.path}`}
          render={(p: RouteComponentProps<any>) => (
            <AOSTemplateProfile {...p} />
          )}
        />
      </div>
    </>
  );
}
