import React, { useState } from "react";

import { Formik, FormikHelpers, FormikState } from "formik";
import { TFunction } from "i18next";
import { Button, Modal, ModalProps } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useMutation } from "react-relay";
import { toast } from "react-toastify";
import * as yup from "yup";

import { useBusinessContext } from "../../../../contexts/BusinessContext";
import { useModal } from "../../../../contexts/ModalContext";
import {
  EmploymentFeedbackReason,
  EmploymentFeedbackTypeEnum,
} from "../../../../data/generated/stack_internal_schema";
import { getHandleServerValidationErrorFn } from "../../../../utils/utility";
import Textarea from "../../../common/Form/Textarea";
import {
  PerformanceSettingResponse,
  useBusinessPerformanceSettingsQuery,
} from "../../Employment/PerformanceSettings";
import {
  CreateEmploymentFeedbackReasonMutation,
  DeactivateEmploymentFeedbackReasonMutation,
  ReactivateEmploymentFeedbackReasonMutation,
  UpdateEmploymentFeedbackReasonMutation,
} from "./FeedbackReasonsQueries";

export enum FeedbackReasonsModalAction {
  Create = "create",
  Edit = "edit",
}

type EditableEmploymentFeedbackReason = Partial<EmploymentFeedbackReason>;

type Props = {
  action: FeedbackReasonsModalAction;
  feedbackType: EmploymentFeedbackTypeEnum;
  feedbackReason?: EditableEmploymentFeedbackReason;
  businessSettings?: PerformanceSettingResponse;
  modalProps: ModalProps;
  onFeedbackReasonUpdate: any;
  mutations: {
    onCreate: any;
    onUpdate: any;
    onActivate: any;
    onDeactivate: any;
  };
  t: TFunction;
};

export default function FeedbackReasonsModal({
  action,
  feedbackType,
  feedbackReason,
  businessSettings,
  modalProps,
  onFeedbackReasonUpdate,
  mutations,
  t,
}: Props) {
  const { show, hideModal } = modalProps;
  const initialFormValues: EditableEmploymentFeedbackReason =
    feedbackReason ?? {
      id: "",
      description: "",
      defaultMessage: "",
    };

  const [triggered] = useState(false);

  const submit = (
    values: EditableEmploymentFeedbackReason,
    helpers: FormikHelpers<EditableEmploymentFeedbackReason>,
  ) => {
    const { setSubmitting, resetForm } = helpers;

    if (!businessSettings) {
      console.warn("No business settings available", businessSettings);
      return;
    }

    setSubmitting(true);

    const successI18nKey = `translation:form.notifications.${
      action === FeedbackReasonsModalAction.Create
        ? "created_successfully"
        : "saved_successfully"
    }`;

    const onCompleted = (response: any) => {
      setSubmitting(false);
      const updatedFeedbackReason: EmploymentFeedbackReason =
        response.createEmploymentFeedbackReason ??
        response.updateEmploymentFeedbackReason;
      onFeedbackReasonUpdate(updatedFeedbackReason);

      toast(t(successI18nKey));
      resetForm(
        initialFormValues as Partial<
          FormikState<EditableEmploymentFeedbackReason>
        >,
      );
      hideModal();
    };

    const onError = (error: Error) => {
      if (error) {
        getHandleServerValidationErrorFn(helpers);
      }
    };

    action === FeedbackReasonsModalAction.Create
      ? mutations.onCreate({
          variables: {
            businessId: businessSettings?.id,
            input: {
              feedbackType,
              description: String(values.description),
              defaultMessage: values.defaultMessage ?? undefined,
            },
          },
          onCompleted,
          onError,
        })
      : mutations.onUpdate({
          variables: {
            id: String(values.id),
            businessId: businessSettings?.id,
            input: {
              description: String(values.description),
              defaultMessage: values.defaultMessage ?? undefined,
            },
          },
          onCompleted,
          onError,
        });
  };

  const toggleActivation = () => {
    if (!feedbackReason?.id || !businessSettings) {
      console.warn("No business settings available", businessSettings);
      return;
    }

    const toggle = feedbackReason.deleted
      ? mutations.onActivate
      : mutations.onDeactivate;

    const successI18nKey = `translation:form.notifications.${
      feedbackReason.deleted
        ? "activated_successfully"
        : "deactivated_successfully"
    }`;

    toggle({
      variables: {
        id: feedbackReason.id,
        businessId: businessSettings.id,
      },
      onCompleted(response: any) {
        const updatedFeedbackReason: EmploymentFeedbackReason =
          response.deactivateEmploymentFeedbackReason ??
          response.activateEmploymentFeedbackReason;
        onFeedbackReasonUpdate(updatedFeedbackReason);

        toast(t(successI18nKey));
      },
      onError(error: Error) {
        alert(error);
      },
    });
  };

  const validationRules = yup.object({
    description: yup.string().max(50).required().label(t("modal.reason")),
    defaultMessage: yup.string().max(500).label(t("modal.predefinedComment")),
  });

  const showDeactivateButton: boolean =
    action === FeedbackReasonsModalAction.Edit && Boolean(feedbackReason?.id);
  const showFeedbackComment = businessSettings?.feedbackCommentEnabled;

  return (
    <Formik<EditableEmploymentFeedbackReason>
      validationSchema={validationRules}
      validateOnChange={triggered}
      validateOnBlur={triggered}
      onSubmit={submit}
      initialValues={initialFormValues}
    >
      {({
        handleSubmit,
        handleChange,
        isValid,
        values,
        errors,
        resetForm,
        validateForm,
        setFieldValue,
      }) => (
        <Modal show={show} onHide={hideModal}>
          <Form onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>
                {t(`${feedbackType}.modal.${action}.title`)}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Control
                type="hidden"
                name="id"
                readOnly
                value={values.id}
              />
              <Form.Group controlId="feedback-reason">
                <Form.Label>{t("modal.reason")}</Form.Label>
                <Form.Control
                  type="text"
                  name="description"
                  value={values.description}
                  onChange={handleChange}
                  isInvalid={errors.description != null}
                />
                <Form.Text className="text-muted mb-2">
                  {t("translation:form.max_chars", { count: 50 })}
                </Form.Text>
                <Form.Control.Feedback type="invalid">
                  {errors.description}
                </Form.Control.Feedback>
              </Form.Group>

              {showFeedbackComment && (
                <Form.Group controlId="feedback-predefined-comment">
                  <Form.Label>{t("modal.predefinedComment")}</Form.Label>
                  <Textarea
                    fieldKey="defaultMessage"
                    value={values.defaultMessage ?? ""}
                    onChange={(v: string) => {
                      setFieldValue("defaultMessage", v);
                    }}
                  />
                  <Form.Text className="text-muted mb-2">
                    {t("translation:form.max_chars", { count: 500 })}
                  </Form.Text>
                  <Form.Control.Feedback type="invalid">
                    {errors.defaultMessage}
                  </Form.Control.Feedback>
                </Form.Group>
              )}
            </Modal.Body>
            <Modal.Footer>
              {showDeactivateButton ? (
                <Button
                  variant="link"
                  className="mr-auto"
                  onClick={() => {
                    toggleActivation();
                    resetForm(
                      initialFormValues as Partial<
                        FormikState<EditableEmploymentFeedbackReason>
                      >,
                    );
                    hideModal();
                  }}
                >
                  {t(
                    `table.action.${
                      values?.deleted ? "activate" : "deactivate"
                    }`,
                  )}
                </Button>
              ) : null}
              <Button
                variant="link"
                className="pl-5 pr-5 mr-2"
                onClick={() => {
                  resetForm(
                    initialFormValues as Partial<
                      FormikState<EditableEmploymentFeedbackReason>
                    >,
                  );
                  hideModal();
                }}
              >
                {t("translation:form.actions.cancel")}
              </Button>
              <Button variant="primary" className="pl-5 pr-5" type="submit">
                {t("translation:form.actions.save")}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      )}
    </Formik>
  );
}

export function useFeedbackReasonsCreateEditModal(
  action: FeedbackReasonsModalAction,
  feedbackType: EmploymentFeedbackTypeEnum,
  onFeedbackReasonUpdate: any,
  t: TFunction,
) {
  const { showModal } = useModal();
  const modalProps = useModal();
  const { business } = useBusinessContext();
  const businessData = useBusinessPerformanceSettingsQuery(business.id);

  if (!businessData) {
    alert("No business performance settings could be found");
  }

  const [reactivateFeedbackReason] = useMutation(
    ReactivateEmploymentFeedbackReasonMutation,
  );
  const [deactivateFeedbackReason] = useMutation(
    DeactivateEmploymentFeedbackReasonMutation,
  );
  const [createFeedbackReason] = useMutation(
    CreateEmploymentFeedbackReasonMutation,
  );
  const [updateFeedbackReason] = useMutation(
    UpdateEmploymentFeedbackReasonMutation,
  );

  const mutations = {
    onCreate: createFeedbackReason,
    onUpdate: updateFeedbackReason,
    onActivate: reactivateFeedbackReason,
    onDeactivate: deactivateFeedbackReason,
  };

  return (feedbackReason?: EmploymentFeedbackReason) => () => {
    showModal(
      <FeedbackReasonsModal
        businessSettings={businessData}
        action={action}
        feedbackReason={feedbackReason}
        feedbackType={feedbackType}
        modalProps={modalProps}
        mutations={mutations}
        onFeedbackReasonUpdate={onFeedbackReasonUpdate}
        t={t}
      />,
    );
  };
}
