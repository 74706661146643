import { FormikContextType, useFormikContext } from "formik";
import { useTranslation } from "react-i18next";

import {
  MetadataType,
  MetadataTypeDataTypeEnum,
} from "../../../../../data/generated/stack_internal_schema";
import Field from "../../../../common/Form/Field";
import {
  JSONIntListType,
  JSONStringListType,
} from "../../../../common/Form/models";

type Props = {
  fieldKey: string;
  regulatingMetadataTypes: Pick<MetadataType, "id" | "dataType">[];
  referenceRegulatingFieldDataType?: boolean;
  componentProps: any;
};

export function MetadataValueList(props: Props) {
  const { t } = useTranslation();

  // To ensure that the list allows only strings or numbers, we need to watch the
  // data type and update the schemaFieldType
  const dataType = useMetadataDataTypeReference(props);

  if (dataType === MetadataTypeDataTypeEnum.Boolean) {
    // A string (JSONStringListType) list is used here, but the value will get cast
    // to a primitive boolean
    return (
      <Field
        xs={12}
        lg={12}
        md={12}
        {...{
          ...props,
          componentProps: {
            options: [
              {
                value: true,
                label: t("common.true"),
              },
              {
                value: false,
                label: t("common.false"),
              },
            ],
            defaultValue: null,
          },
        }}
        schemaFieldType={JSONStringListType}
      />
    );
  }

  return (
    <Field
      xs={12}
      lg={12}
      md={12}
      {...props}
      schemaFieldType={
        dataType === MetadataTypeDataTypeEnum.Number
          ? JSONIntListType
          : JSONStringListType
      }
    />
  );
}

/**
 * This hook returns the data type of either the current metadata type or a selected
 * regulating data type (regulatingMetadataTypeId)
 */
function useMetadataDataTypeReference(props: Props) {
  const formikContext = useFormikContext() as FormikContextType<MetadataType>;

  if (props.referenceRegulatingFieldDataType && props.regulatingMetadataTypes) {
    const metadataTypeId = formikContext.values.regulatingMetadataTypeId;
    return props.regulatingMetadataTypes.find((x) => x.id === metadataTypeId)
      ?.dataType;
  }

  return formikContext?.values?.dataType;
}
