import React, { useEffect, useState } from "react";

import ReactDOM from "react-dom";

type Props = {
  elementId: string;
  as: string;
} & React.PropsWithChildren<any>;

export default function HeaderPortal({
  elementId = "header-portal",
  as = "div",
  children,
}: Props) {
  const [el, setEl] = useState<HTMLElement | null>(null);
  const [portalRoot, setPortalRoot] = useState<HTMLElement | null>(null);

  useEffect(() => {
    if (!el) {
      setEl(document.createElement(as));
    }
    if (!portalRoot) {
      setPortalRoot(document.getElementById(elementId));
    }
  }, [as, el, portalRoot, elementId]);

  useEffect(() => {
    if (portalRoot && el) {
      portalRoot.appendChild(el);
    }
    return () => {
      if (portalRoot && el) {
        portalRoot.removeChild(el);
      }
    };
  }, [el, portalRoot]);

  if (!el) {
    return null;
  }

  return ReactDOM.createPortal(children, el);
}

type HeaderPortalBreadCrumbsProps = {
  breadcrumbs: React.ReactElement[];
  prefixChevron?: boolean | undefined;
};

// Utility to be used with the header portal in order to quickly create breadcrumbs without manually concatenating jsx elements
export function HeaderPortalBreadcrumbs({
  breadcrumbs,
  prefixChevron = true,
}: HeaderPortalBreadCrumbsProps) {
  return (
    <>
      {prefixChevron && (
        <span key="chevron" className="ml-2 mr-2">
          &gt;
        </span>
      )}
      {breadcrumbs
        .map((breadcrumb: React.ReactElement, index: number) =>
          React.cloneElement(breadcrumb, {
            // eslint-disable-next-line react/no-array-index-key
            key: `breadcrumb-${index}`,
          }),
        )
        .reduce(
          (
            acc: React.ReactElement,
            curr: React.ReactElement,
            index: number,
          ) => {
            return (
              <>
                {acc}
                {/* eslint-disable-next-line react/no-array-index-key */}
                <span key={`chevron-${index}`} className="ml-2 mr-2">
                  &gt;
                </span>
                {curr}
              </>
            );
          },
        )}
    </>
  );
}
