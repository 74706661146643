/**
 * @generated SignedSource<<fc93cdae1034361b8a94ea35815d9db5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CorporatesQueries_GetCorporatesByName_InternalQuery$variables = {
  excludeDeleted?: boolean | null;
  nameIncludes?: string | null;
};
export type CorporatesQueries_GetCorporatesByName_InternalQuery$data = {
  readonly corporates: {
    readonly nodes: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
    }>;
  };
};
export type CorporatesQueries_GetCorporatesByName_InternalQuery = {
  response: CorporatesQueries_GetCorporatesByName_InternalQuery$data;
  variables: CorporatesQueries_GetCorporatesByName_InternalQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "excludeDeleted"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "nameIncludes"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "excludeDeleted",
        "variableName": "excludeDeleted"
      },
      {
        "kind": "Variable",
        "name": "nameIncludes",
        "variableName": "nameIncludes"
      }
    ],
    "concreteType": "CorporateConnection",
    "kind": "LinkedField",
    "name": "corporates",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Corporate",
        "kind": "LinkedField",
        "name": "nodes",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CorporatesQueries_GetCorporatesByName_InternalQuery",
    "selections": (v2/*: any*/),
    "type": "InternalQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "CorporatesQueries_GetCorporatesByName_InternalQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "e219755a22630801bc447f0d2634c88b",
    "id": null,
    "metadata": {},
    "name": "CorporatesQueries_GetCorporatesByName_InternalQuery",
    "operationKind": "query",
    "text": "query CorporatesQueries_GetCorporatesByName_InternalQuery(\n  $nameIncludes: String\n  $excludeDeleted: Boolean\n) {\n  corporates(nameIncludes: $nameIncludes, excludeDeleted: $excludeDeleted) {\n    nodes {\n      id\n      name\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "535cec1f14704b66e09d15d29a30e442";

export default node;
