/**
 * @generated SignedSource<<0110e1b7e14f11cb5d590b47a7274873>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type TimeOffTypesQueries_GetSingleTimeOffType_Query$variables = {
  businessId: string;
  ids?: ReadonlyArray<string> | null;
  skip: boolean;
};
export type TimeOffTypesQueries_GetSingleTimeOffType_Query$data = {
  readonly timeOffTypes?: {
    readonly nodes: ReadonlyArray<{
      readonly code: string | null;
      readonly employmentTypeCodes: ReadonlyArray<string>;
      readonly id: string;
      readonly ignoreAutoReject: boolean;
      readonly isManagerByProxyOnly: boolean;
      readonly name: string;
      readonly paid: boolean;
      readonly shortName: string | null;
    }>;
  };
};
export type TimeOffTypesQueries_GetSingleTimeOffType_Query = {
  response: TimeOffTypesQueries_GetSingleTimeOffType_Query$data;
  variables: TimeOffTypesQueries_GetSingleTimeOffType_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "businessId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "ids"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "skip"
},
v3 = [
  {
    "condition": "skip",
    "kind": "Condition",
    "passingValue": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "businessId",
            "variableName": "businessId"
          },
          {
            "kind": "Variable",
            "name": "ids",
            "variableName": "ids"
          }
        ],
        "concreteType": "TimeOffTypeConnection",
        "kind": "LinkedField",
        "name": "timeOffTypes",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TimeOffType",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "shortName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "code",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "paid",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "ignoreAutoReject",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isManagerByProxyOnly",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "employmentTypeCodes",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "TimeOffTypesQueries_GetSingleTimeOffType_Query",
    "selections": (v3/*: any*/),
    "type": "InternalQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "TimeOffTypesQueries_GetSingleTimeOffType_Query",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "05fb12fc5515f9b4e18aaef97bffb556",
    "id": null,
    "metadata": {},
    "name": "TimeOffTypesQueries_GetSingleTimeOffType_Query",
    "operationKind": "query",
    "text": "query TimeOffTypesQueries_GetSingleTimeOffType_Query(\n  $ids: [ID!]\n  $businessId: ID!\n  $skip: Boolean!\n) {\n  timeOffTypes(ids: $ids, businessId: $businessId) @skip(if: $skip) {\n    nodes {\n      id\n      name\n      shortName\n      code\n      paid\n      ignoreAutoReject\n      isManagerByProxyOnly\n      employmentTypeCodes\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "549dc149564d4608b8b37454924577df";

export default node;
