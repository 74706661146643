import BugsnagPluginReact, {
  BugsnagPluginReactResult,
} from "@bugsnag/plugin-react";
import React, { Suspense, useMemo } from "react";

import bugsnag, { BrowserConfig, Client } from "@bugsnag/js";
import { createRoot } from "react-dom/client";
import { ThemeProvider } from "styled-components";

import "./i18n";

import App from "./components/App";
import { ModalProvider } from "./contexts/ModalContext";
import defaultTheme from "./styles/theme";
import ConfigurationService from "./configuration-service";
import { RelayEnvironmentConfig } from "./environment";
import * as serviceWorker from "./serviceWorker";

import "./styles/index.scss";

const getBugsnagClient = (
  configuration: typeof ConfigurationService.config,
): Client => {
  // Create the configuration for the client
  const bugsnagClientConfiguration: BrowserConfig = {
    apiKey: configuration.bugsnag.apiKey || "",
    appVersion: configuration.version.release,
    autoDetectErrors: configuration.bugsnag.notify,
    autoTrackSessions: configuration.bugsnag.notify,
    enabledReleaseStages: [],
    releaseStage: configuration.target,
  };

  // Only put in notify stage if we want it
  if (configuration.bugsnag.notify) {
    if (bugsnagClientConfiguration.enabledReleaseStages?.push) {
      bugsnagClientConfiguration.enabledReleaseStages.push(
        configuration.target,
      );
    }
  }

  // Create the client and add react plugin to Bugsnag
  const bugsnagClient = bugsnag.start({
    ...bugsnagClientConfiguration,
    plugins: [new BugsnagPluginReact(React)],
  });

  // Allow access via window.Bugsnag
  (window as any).Bugsnag = bugsnagClient;

  // set release version
  (RelayEnvironmentConfig as any).VERSION = configuration.version.release;

  return bugsnagClient;
};

function PlatformAdmin() {
  const configuration = ConfigurationService.config;

  const ErrorBoundary = useMemo(
    () =>
      (
        getBugsnagClient(configuration).getPlugin(
          "react",
        ) as BugsnagPluginReactResult
      ).createErrorBoundary(),
    [configuration],
  );

  return (
    <ErrorBoundary>
      <ThemeProvider theme={defaultTheme}>
        <Suspense fallback="loading">
          <ModalProvider>
            <App configuration={configuration} />
          </ModalProvider>
        </Suspense>
      </ThemeProvider>
    </ErrorBoundary>
  );
}

const container = document.getElementById("root");
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
root.render(<PlatformAdmin />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
