/**
 * @generated SignedSource<<9987123d6b118ce94de1b71666e6d79f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type PayPeriods_Employees_Query$variables = {
  businessId: string;
  ids?: ReadonlyArray<string> | null;
};
export type PayPeriods_Employees_Query$data = {
  readonly employments: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly firstName: string;
        readonly id: string;
        readonly lastName: string;
      };
    }>;
  };
};
export type PayPeriods_Employees_Query = {
  response: PayPeriods_Employees_Query$data;
  variables: PayPeriods_Employees_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "businessId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ids"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "businessId",
        "variableName": "businessId"
      },
      {
        "kind": "Variable",
        "name": "ids",
        "variableName": "ids"
      }
    ],
    "concreteType": "EmploymentConnection",
    "kind": "LinkedField",
    "name": "employments",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "EmploymentEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Employment",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "firstName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastName",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PayPeriods_Employees_Query",
    "selections": (v1/*: any*/),
    "type": "InternalQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PayPeriods_Employees_Query",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "1a1eb70c5c0af613cc3c85eda3c3544a",
    "id": null,
    "metadata": {},
    "name": "PayPeriods_Employees_Query",
    "operationKind": "query",
    "text": "query PayPeriods_Employees_Query(\n  $businessId: ID!\n  $ids: [ID!]\n) {\n  employments(businessId: $businessId, ids: $ids) {\n    edges {\n      node {\n        id\n        firstName\n        lastName\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "768e8d2b219d442f18776c49d84dbc15";

export default node;
