/**
 * @generated SignedSource<<959abca7434de3ca3ac943527a3d18a9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type BusinessService_SearchStackForEmployments_Query$variables = {
  userId?: string | null;
};
export type BusinessService_SearchStackForEmployments_Query$data = {
  readonly searchStackForEmployments: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly acceptedInvite: boolean;
        readonly business: {
          readonly businessName: string;
          readonly id: string;
        };
        readonly computedName: string;
        readonly createdAt: string;
        readonly deleted: boolean;
        readonly during: string | null;
        readonly email: string;
        readonly id: string;
        readonly updatedAt: string;
      };
    }>;
  };
};
export type BusinessService_SearchStackForEmployments_Query = {
  response: BusinessService_SearchStackForEmployments_Query$data;
  variables: BusinessService_SearchStackForEmployments_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "userId",
        "variableName": "userId"
      }
    ],
    "concreteType": "EmploymentConnection",
    "kind": "LinkedField",
    "name": "searchStackForEmployments",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "EmploymentEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Employment",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "computedName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "acceptedInvite",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "deleted",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "during",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "createdAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "updatedAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Business",
                "kind": "LinkedField",
                "name": "business",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "businessName",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BusinessService_SearchStackForEmployments_Query",
    "selections": (v2/*: any*/),
    "type": "InternalQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BusinessService_SearchStackForEmployments_Query",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "c24d8d7b38900a91d6ad510f34928a83",
    "id": null,
    "metadata": {},
    "name": "BusinessService_SearchStackForEmployments_Query",
    "operationKind": "query",
    "text": "query BusinessService_SearchStackForEmployments_Query(\n  $userId: ID\n) {\n  searchStackForEmployments(userId: $userId) {\n    edges {\n      node {\n        id\n        computedName\n        acceptedInvite\n        deleted\n        email\n        during\n        createdAt\n        updatedAt\n        business {\n          id\n          businessName\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "781ab91e11b40722b7b17905211816ff";

export default node;
