/**
 * @generated SignedSource<<87a4af69c1c3c3cb8ef021b34fbe24dd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AccountService_Users_Query$variables = {
  ids?: ReadonlyArray<string> | null;
};
export type AccountService_Users_Query$data = {
  readonly users: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly confirmedAt: string | null;
        readonly email: string;
        readonly id: string;
      };
    }>;
  };
};
export type AccountService_Users_Query = {
  response: AccountService_Users_Query$data;
  variables: AccountService_Users_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ids"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "ids",
        "variableName": "ids"
      }
    ],
    "concreteType": "UserConnection",
    "kind": "LinkedField",
    "name": "users",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "confirmedAt",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountService_Users_Query",
    "selections": (v1/*: any*/),
    "type": "InternalQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AccountService_Users_Query",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "ba3d357c57ba576d802c96c3e0ea6134",
    "id": null,
    "metadata": {},
    "name": "AccountService_Users_Query",
    "operationKind": "query",
    "text": "query AccountService_Users_Query(\n  $ids: [ID!]\n) {\n  users(ids: $ids) {\n    edges {\n      node {\n        id\n        email\n        confirmedAt\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "31170375d1ffccd9ef9545d818a2e996";

export default node;
