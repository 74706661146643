import React from "react";

import { DateTime } from "luxon";

import { GraphQLDateFormat } from "../data/models/common";
import { modulo } from "../utils/utility";

interface ISchedule {
  readonly id: string;
  readonly scheduleName: string;
  readonly timeZone: string | null;
  readonly payPeriodEnabled?: boolean | null;
  readonly populateScheduleEnabled?: boolean | null;
  readonly dayStartTime?: string | null;
  readonly dayEndTime?: string | null;
  readonly firstDayOfWeek?: number | null;
}

export type ScheduleContextValue = {
  schedule: ISchedule;
  getDayStart: (startDateTime: DateTime) => DateTime;
  getDayEnd: (startDateTime: DateTime) => DateTime;
  getWeekStart: (dateTime: DateTime) => DateTime;
};

const ScheduleContext = React.createContext<ScheduleContextValue>({
  getDayStart: () => DateTime.local(),
  getDayEnd: () => DateTime.local(),
  getWeekStart: () => DateTime.local(),
  schedule: {} as ISchedule,
});

type Props = {
  schedule: ISchedule;
  children?: React.ReactNode;
};

function ScheduleContextProvider({ schedule, children }: Props) {
  const getDayStartHour = () => {
    if (!schedule) {
      return null;
    }
    const { dayStartTime } = schedule;
    const dayStartDateTime = DateTime.fromFormat(
      dayStartTime as string,
      GraphQLDateFormat,
    ).toUTC();

    return dayStartDateTime.get("hour");
  };

  const getWeekStart = (dateTime: DateTime): DateTime => {
    if (!schedule || !dateTime.isValid) {
      return dateTime;
    }
    const { firstDayOfWeek } = schedule;
    if (firstDayOfWeek == null) {
      return dateTime;
    }

    const weekday = modulo(dateTime.weekday - firstDayOfWeek, 7);
    let result = dateTime.startOf("day").minus({ day: weekday });
    const hour = getDayStartHour();

    if (hour != null) {
      result = result.set({ hour });
    }

    return result;
  };

  const getDayStart = (startDateTime: DateTime): DateTime => {
    if (!schedule) {
      return startDateTime;
    }

    const { timeZone, dayStartTime } = schedule;
    if (!timeZone) {
      return startDateTime;
    }

    startDateTime = startDateTime.startOf("day").setZone(timeZone);

    if (!dayStartTime) {
      return startDateTime;
    }

    // schedule dayStartTime is in UTC date time format
    const dayStartDateTime = DateTime.fromFormat(
      dayStartTime as string,
      GraphQLDateFormat,
    ).toUTC();

    const dayStartHour = dayStartDateTime.get("hour");
    const dayStartMinute = dayStartDateTime.get("minute");

    return startDateTime.plus({
      hour: dayStartHour,
      minute: dayStartMinute,
    });
  };

  const getDayEnd = (startDateTime: DateTime): DateTime => {
    if (!schedule || !startDateTime.isValid) {
      return DateTime.local();
    }

    const { timeZone, dayEndTime } = schedule;
    let endDateTime = startDateTime.startOf("day");
    if (!timeZone) {
      return endDateTime;
    }

    endDateTime = endDateTime.setZone(timeZone);

    if (!dayEndTime) {
      return endDateTime;
    }

    // schedule dayEndTime is in UTC date time format
    const dayEndDateTime = DateTime.fromFormat(
      dayEndTime as string,
      GraphQLDateFormat,
    ).toUTC();
    const dayEndHour = dayEndDateTime.get("hour");
    const dayEndMinute = dayEndDateTime.get("minute");

    endDateTime = endDateTime
      .setZone(timeZone)
      .set({ hour: dayEndHour, minute: dayEndMinute });

    if (endDateTime <= startDateTime) {
      // end date is same or before start date
      // add 1 day
      endDateTime = endDateTime.plus({ day: 1 });
    }

    return endDateTime;
  };

  return (
    <ScheduleContext.Provider
      value={{
        schedule,
        getDayStart,
        getDayEnd,
        getWeekStart,
      }}
    >
      {children}
    </ScheduleContext.Provider>
  );
}

const useScheduleContext = () => React.useContext(ScheduleContext);

export { ScheduleContext, ScheduleContextProvider, useScheduleContext };
