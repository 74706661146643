/**
 * @generated SignedSource<<8dd174c1e81bc0f657867708be7e7508>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ScheduleLayout_Query$variables = {
  businessId: string;
  scheduleId: string;
};
export type ScheduleLayout_Query$data = {
  readonly schedules: {
    readonly nodes: ReadonlyArray<{
      readonly dayEndTime: string | null;
      readonly dayStartTime: string | null;
      readonly firstDayOfWeek: number | null;
      readonly id: string;
      readonly isGroup: boolean;
      readonly payPeriodEnabled: boolean;
      readonly populateScheduleEnabled: boolean;
      readonly scheduleName: string;
      readonly timeZone: string | null;
    }>;
  };
};
export type ScheduleLayout_Query = {
  response: ScheduleLayout_Query$data;
  variables: ScheduleLayout_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "businessId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "scheduleId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "businessId",
        "variableName": "businessId"
      },
      {
        "items": [
          {
            "kind": "Variable",
            "name": "ids.0",
            "variableName": "scheduleId"
          }
        ],
        "kind": "ListValue",
        "name": "ids"
      }
    ],
    "concreteType": "ScheduleConnection",
    "kind": "LinkedField",
    "name": "schedules",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Schedule",
        "kind": "LinkedField",
        "name": "nodes",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "scheduleName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isGroup",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "timeZone",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "payPeriodEnabled",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "populateScheduleEnabled",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "dayStartTime",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "dayEndTime",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstDayOfWeek",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ScheduleLayout_Query",
    "selections": (v1/*: any*/),
    "type": "InternalQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ScheduleLayout_Query",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "7e3bfc6f3073d5c9981df8d15c285ebe",
    "id": null,
    "metadata": {},
    "name": "ScheduleLayout_Query",
    "operationKind": "query",
    "text": "query ScheduleLayout_Query(\n  $businessId: ID!\n  $scheduleId: ID!\n) {\n  schedules(businessId: $businessId, ids: [$scheduleId]) {\n    nodes {\n      id\n      scheduleName\n      isGroup\n      timeZone\n      payPeriodEnabled\n      populateScheduleEnabled\n      dayStartTime\n      dayEndTime\n      firstDayOfWeek\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "abbfef958c32d4da9831c24249a2ac49";

export default node;
