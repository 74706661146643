/**
 * @generated SignedSource<<4a9f7e99ff41067b92e23d4a0d79e63d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type EmploymentSortField = "firstName" | "id" | "lastName" | "%future added value";
export type Order = "asc" | "desc" | "%future added value";
export type EmploymentSort = {
  field: EmploymentSortField;
  order: Order;
};
export type EmploymentService_EmploymentsSearch_Query$variables = {
  businessId: string;
  pageSize?: number | null;
  search?: string | null;
  sort?: ReadonlyArray<EmploymentSort> | null;
};
export type EmploymentService_EmploymentsSearch_Query$data = {
  readonly employments: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly computedName: string;
        readonly id: string;
      };
    }>;
  };
};
export type EmploymentService_EmploymentsSearch_Query = {
  response: EmploymentService_EmploymentsSearch_Query$data;
  variables: EmploymentService_EmploymentsSearch_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "businessId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pageSize"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sort"
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "businessId",
        "variableName": "businessId"
      },
      {
        "kind": "Variable",
        "name": "first",
        "variableName": "pageSize"
      },
      {
        "kind": "Variable",
        "name": "search",
        "variableName": "search"
      },
      {
        "kind": "Variable",
        "name": "sort",
        "variableName": "sort"
      }
    ],
    "concreteType": "EmploymentConnection",
    "kind": "LinkedField",
    "name": "employments",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "EmploymentEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Employment",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "computedName",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "EmploymentService_EmploymentsSearch_Query",
    "selections": (v4/*: any*/),
    "type": "InternalQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "EmploymentService_EmploymentsSearch_Query",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "d5a4e2e40d0953b4cfeb5fcc63590a5d",
    "id": null,
    "metadata": {},
    "name": "EmploymentService_EmploymentsSearch_Query",
    "operationKind": "query",
    "text": "query EmploymentService_EmploymentsSearch_Query(\n  $businessId: ID!\n  $search: String\n  $pageSize: Int\n  $sort: [EmploymentSort!]\n) {\n  employments(businessId: $businessId, search: $search, first: $pageSize, sort: $sort) {\n    edges {\n      node {\n        id\n        computedName\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "00a42b8521a6c70e005a2adbd9a81555";

export default node;
