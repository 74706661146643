import React from "react";

import Card from "react-bootstrap/Card";
import Nav from "react-bootstrap/Nav";
import { useTranslation } from "react-i18next";
import { LinkContainer } from "react-router-bootstrap";
import { Route, RouteComponentProps } from "react-router-dom";
import styled from "styled-components";

import HeaderPortal from "../../../common/Portal/HeaderPortal";
import AdvancedProfile from "./Advanced/AdvancedProfile";
import BasicProfile from "./Basic/BasicProfile";

type Props = RouteComponentProps & {};

const StyledCard = styled(Card)`
  border-top: none;
  border-radius: 0px;
`;

export default function AOSSettingsLayout({ match }: Props) {
  const { t } = useTranslation("aos");
  return (
    <>
      <HeaderPortal as="span" elementId="sub-header-portal">
        <span className="ml-2 mr-2">&gt;</span>
        <span>{t("aos")}</span>
      </HeaderPortal>
      <Nav variant="tabs">
        <LinkContainer exact to={`${match.url}`}>
          <Nav.Link className="nav-item">{t("basic.title")}</Nav.Link>
        </LinkContainer>
        <LinkContainer to={`${match.url}/advanced`}>
          <Nav.Link className="nav-item">{t("advanced.title")}</Nav.Link>
        </LinkContainer>
      </Nav>

      <StyledCard body>
        <Route
          exact
          path={`${match.path}`}
          render={(props: any) => <BasicProfile {...props} />}
        />
        <Route
          path={`${match.path}/advanced`}
          render={(props: any) => <AdvancedProfile {...props} />}
        />
      </StyledCard>
    </>
  );
}
