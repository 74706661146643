import { rgba } from "polished";
import { css } from "styled-components";

export const pillMixin = (pillColor: string) => {
  return css`
    .pill-text {
      white-space: nowrap;

      background: linear-gradient(
          0deg,
          ${(props) => rgba(props.theme.generic.text.darkGrey, 0.7)},
          ${(props) => rgba(props.theme.generic.text.darkGrey, 0.7)}
        ),
        linear-gradient(0deg, ${pillColor}, ${pillColor});

      -webkit-background-clip: text;
      color: transparent;
    }

    background: linear-gradient(
        0deg,
        ${(props) => rgba(props.theme.white, 0.7)},
        ${(props) => rgba(props.theme.white, 0.7)}
      ),
      linear-gradient(0deg, ${pillColor}, ${pillColor});
  `;
};

export const normalPillMixin = (color: string, onDark: boolean) => {
  return css`
    .pill-text {
      color: ${(props) =>
        onDark ? props.theme.white : props.theme.generic.text.darkGrey};
    }
    background: ${color};
  `;
};
