/**
 * @generated SignedSource<<2e93586aaafdbaf3da57b026d5a318e8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserCorporatesPaginationQuery$variables = {
  after?: string | null;
  corporateIds?: ReadonlyArray<string> | null;
  emailIncludes?: string | null;
  pageSize?: number | null;
};
export type UserCorporatesPaginationQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"CorporatesQueries_ListFragment">;
};
export type UserCorporatesPaginationQuery = {
  response: UserCorporatesPaginationQuery$data;
  variables: UserCorporatesPaginationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "corporateIds"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "emailIncludes"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "pageSize"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "corporateIds",
    "variableName": "corporateIds"
  },
  {
    "kind": "Variable",
    "name": "emailIncludes",
    "variableName": "emailIncludes"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "pageSize"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserCorporatesPaginationQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "CorporatesQueries_ListFragment"
      }
    ],
    "type": "InternalQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserCorporatesPaginationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UserCorporateConnection",
        "kind": "LinkedField",
        "name": "userCorporates",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserCorporateEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserCorporate",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "email",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "personKey",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastAccessedAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Business",
                    "kind": "LinkedField",
                    "name": "business",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "businessName",
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Corporate",
                    "kind": "LinkedField",
                    "name": "corporate",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "filters": [
          "corporateIds",
          "emailIncludes"
        ],
        "handle": "connection",
        "key": "CorporatesTable_userCorporates",
        "kind": "LinkedHandle",
        "name": "userCorporates"
      }
    ]
  },
  "params": {
    "cacheID": "fb1896abfe9ab880b810a4dd70d68acd",
    "id": null,
    "metadata": {},
    "name": "UserCorporatesPaginationQuery",
    "operationKind": "query",
    "text": "query UserCorporatesPaginationQuery(\n  $after: String\n  $corporateIds: [ID!]\n  $emailIncludes: String\n  $pageSize: Int\n) {\n  ...CorporatesQueries_ListFragment\n}\n\nfragment CorporatesQueries_ListFragment on InternalQuery {\n  userCorporates(corporateIds: $corporateIds, emailIncludes: $emailIncludes, first: $pageSize, after: $after) {\n    edges {\n      node {\n        id\n        user {\n          id\n          email\n        }\n        personKey\n        lastAccessedAt\n        business {\n          businessName\n          id\n        }\n        corporate {\n          id\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "cace93f5e23ee9b0f0ce08f6d2b349bf";

export default node;
