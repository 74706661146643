import React from "react";

import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import styled from "styled-components";

import { useModal } from "../../../../contexts/ModalContext";
import { IStack, IUser } from "../../../../data/models/common";
import {
  idmExternalEnvironment,
  idmInternalEnvironment,
} from "../../../../environment";
import OkCancelModal from "../../../common/OkCancelModal";
import { SendConfirmationEmailMutation$data as SendConfirmationEmailMutationResponse } from "../../external/mutations/__generated__/SendConfirmationEmailMutation.graphql";
import { SendResetPasswordEmailMutation$data as SendResetPasswordEmailMutationResponse } from "../../external/mutations/__generated__/SendResetPasswordEmailMutation.graphql";
import SendConfirmationEmailMutation from "../../external/mutations/SendConfirmationEmailMutation";
import SendResetPasswordEmailMutation from "../../external/mutations/SendResetPasswordEmailMutation";
import { UnlockAccountMutation$data as UnlockAccountMutationResponse } from "../mutations/__generated__/UnlockAccountMutation.graphql";
import UnlockAccountMutation from "../mutations/UnlockAccountMutation";
import ChangePasswordDialog from "./ChangePasswordDialog";

export type UserLayoutInfo = IUser & {
  lockedAt?: string;
};

type Props = {
  user?: UserLayoutInfo;
  stack?: IStack;
};

const StyledDropdown = styled(DropdownButton)`
  position: absolute;
  right: 0;
`;

export default function AccountActionsDropdown(props: Props) {
  const { t } = useTranslation();
  const { showModal, hideModal } = useModal();

  const { user } = props;

  const handleUnlockAccount = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (user && user.email) {
      UnlockAccountMutation(
        idmInternalEnvironment,
        user.id,
        (response: UnlockAccountMutationResponse) => {
          hideModal();
          toast(
            t("accounts:dropdown.unlockAccount.toast", {
              email: response.unlockAccount.email,
            }),
          );
        },
        (error: Error) => {
          // eslint-disable-next-line no-console
          console.error(error);
        },
      );
    }

    event.stopPropagation();
    event.preventDefault();
  };

  const handleSendConfirmAccountEmail = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    if (user && user.email) {
      SendConfirmationEmailMutation(
        idmExternalEnvironment,
        user.email,
        (response: SendConfirmationEmailMutationResponse) => {
          if (response.sendConfirmationEmail) {
            hideModal();
            toast(
              t("accounts:dropdown.accountConfirmation.toast", {
                email: user?.email,
              }),
            );
          }
        },
        (error: Error) => {
          // eslint-disable-next-line no-console
          console.error(error);
        },
      );
    }

    event.stopPropagation();
    event.preventDefault();
  };

  const handleSendResetPasswordEmail = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    if (user && user.email) {
      SendResetPasswordEmailMutation(
        idmExternalEnvironment,
        user.email,
        (response: SendResetPasswordEmailMutationResponse) => {
          if (response.sendResetPasswordEmail) {
            hideModal();
            toast(
              t("accounts:dropdown.resetPassword.toast", {
                email: user?.email,
              }),
            );
          }
        },
        (error: Error) => {
          // eslint-disable-next-line no-console
          console.error(error);
        },
      );
    }

    event.stopPropagation();
    event.preventDefault();
  };

  const showSendResetPasswordConfirmation = () => {
    showModal(
      <OkCancelModal
        title={t("accounts:dropdown.resetPassword.title")}
        onOk={handleSendResetPasswordEmail}
      >
        <p>
          {t("accounts:dropdown.resetPassword.message", { email: user?.email })}
        </p>
      </OkCancelModal>,
    );
  };

  const showUnlockAccountConfirmation = () => {
    showModal(
      <OkCancelModal
        title={t("accounts:dropdown.unlockAccount.title")}
        onOk={handleUnlockAccount}
      >
        {t("accounts:dropdown.unlockAccount.message", { email: user?.email })}
      </OkCancelModal>,
    );
  };

  const showSendConfirmAccountConfirmation = () => {
    showModal(
      <OkCancelModal
        title={t("accounts:dropdown.accountConfirmation.title")}
        onOk={handleSendConfirmAccountEmail}
      >
        {t("accounts:dropdown.accountConfirmation.message", {
          email: user?.email,
        })}
      </OkCancelModal>,
    );
  };

  const showSetPasswordModal = () => {
    if (user) {
      showModal(<ChangePasswordDialog user={user} />);
    }
  };

  if (!user) {
    return null;
  }

  return (
    <StyledDropdown
      alignRight
      size="sm"
      title={t("user_actions_dropdown.title")}
    >
      <Dropdown.Item onSelect={showSetPasswordModal}>
        {t("user_actions_dropdown.actions.change_password")}
      </Dropdown.Item>
      <Dropdown.Item
        onSelect={() => {
          showSendResetPasswordConfirmation();
        }}
      >
        {t("user_actions_dropdown.actions.send_reset_password")}
      </Dropdown.Item>
      <Dropdown.Item
        onSelect={() => {
          showSendConfirmAccountConfirmation();
        }}
      >
        {t("user_actions_dropdown.actions.send_confirmation")}
      </Dropdown.Item>
      <Dropdown.Divider />
      <Dropdown.Item
        disabled={user?.lockedAt === null}
        onSelect={showUnlockAccountConfirmation}
      >
        {t("user_actions_dropdown.actions.unlock_account")}
      </Dropdown.Item>
    </StyledDropdown>
  );
}
