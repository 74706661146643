/**
 * @generated SignedSource<<dffeb0bbe60f2b1b67dc1f6206b29207>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type MetadataTypeDataTypeEnum = "boolean" | "date" | "number" | "object" | "string" | "timestamp" | "%future added value";
export type PeopleSettingsQueries_GetBusinessMetadataTypes_Query$variables = {
  businessId: string;
};
export type PeopleSettingsQueries_GetBusinessMetadataTypes_Query$data = {
  readonly metadataTypes: {
    readonly nodes: ReadonlyArray<{
      readonly dataType: MetadataTypeDataTypeEnum;
      readonly displayName: string | null;
      readonly encrypted: boolean;
      readonly external: boolean;
      readonly id: string;
      readonly internalAccess: boolean;
      readonly name: string;
    }>;
  };
};
export type PeopleSettingsQueries_GetBusinessMetadataTypes_Query = {
  response: PeopleSettingsQueries_GetBusinessMetadataTypes_Query$data;
  variables: PeopleSettingsQueries_GetBusinessMetadataTypes_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "businessId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "businessId",
        "variableName": "businessId"
      }
    ],
    "concreteType": "MetadataTypeConnection",
    "kind": "LinkedField",
    "name": "metadataTypes",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "MetadataType",
        "kind": "LinkedField",
        "name": "nodes",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "displayName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "dataType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "encrypted",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "external",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "internalAccess",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PeopleSettingsQueries_GetBusinessMetadataTypes_Query",
    "selections": (v1/*: any*/),
    "type": "InternalQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PeopleSettingsQueries_GetBusinessMetadataTypes_Query",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f0bff8eab4d7b7422e2382470f53ba0e",
    "id": null,
    "metadata": {},
    "name": "PeopleSettingsQueries_GetBusinessMetadataTypes_Query",
    "operationKind": "query",
    "text": "query PeopleSettingsQueries_GetBusinessMetadataTypes_Query(\n  $businessId: ID!\n) {\n  metadataTypes(businessId: $businessId) {\n    nodes {\n      id\n      displayName\n      name\n      dataType\n      encrypted\n      external\n      internalAccess\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0576511910ad61ba1a3dbc494fca1939";

export default node;
