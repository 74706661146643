import React from "react";

import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { useModal } from "../../../../contexts/ModalContext";
import { DynamicFieldsLayoutView } from "../../../../data/generated/stack_internal_schema";
import EditGroupsModal from "./Modals/EditGroupModal/EditGroupModal";
import EditViewModal from "./Modals/EditViewModal/EditViewModal";
import { useDynamicFieldsLayout } from "./MetadataLayoutQueries";

export default function MetadataViewsProfile() {
  const { t } = useTranslation("metadata-types");
  const { showModal, hideModal } = useModal();
  const [dynamicBusinessLayout, { getGroupByName }] = useDynamicFieldsLayout();

  const onEditGroup = (
    e: React.MouseEvent<HTMLButtonElement>,
    groupName: string,
  ) => {
    e.stopPropagation();
    const group = getGroupByName(groupName);
    showModal(
      <EditGroupsModal
        dynamicBusinessLayout={dynamicBusinessLayout}
        group={group}
        onClose={hideModal}
        onOk={hideModal}
      />,
    );
  };

  const onEditView = (view: DynamicFieldsLayoutView) => {
    showModal(
      <EditViewModal
        dynamicBusinessLayout={dynamicBusinessLayout}
        view={view}
        onClose={hideModal}
        onOk={hideModal}
      />,
    );
  };

  return (
    <Table hover size="sm">
      <thead>
        <tr>
          <th>{t("metadataLayout.views.table.headers.view")}</th>
          <th>{t("metadataLayout.views.table.headers.groups")}</th>
        </tr>
      </thead>
      <tbody>
        {dynamicBusinessLayout.views.map(
          (view: DynamicFieldsLayoutView, index: number) => (
            <tr
              key={view.name}
              style={{ cursor: "pointer" }}
              onClick={() => onEditView(view)}
            >
              <td>{view.name}</td>
              <td>
                {view.groups.map((groupName) => (
                  <React.Fragment key={groupName}>
                    <button
                      key={groupName}
                      onClick={(e) => onEditGroup(e, groupName)}
                      type="button"
                      className="btn btn-link"
                    >
                      {groupName}
                    </button>
                    <br />
                  </React.Fragment>
                ))}
              </td>
            </tr>
          ),
        )}
      </tbody>
    </Table>
  );
}
