/**
 * @generated SignedSource<<b2e575703673f5df9427bbf5e2d0decf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type EmploymentSchedules_InternalQuery$variables = {
  businessId: string;
  employmentId: string;
};
export type EmploymentSchedules_InternalQuery$data = {
  readonly employments: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly employmentSchedules: ReadonlyArray<{
          readonly defined: boolean;
          readonly groupAdmin: boolean;
          readonly inherited: boolean;
          readonly inheritedGroupAdmin: boolean;
          readonly inheritedSchedulable: boolean;
          readonly inheritedScheduleManager: boolean;
          readonly inheritedScheduleManagerWithPay: boolean;
          readonly inheritedShiftManager: boolean;
          readonly schedulable: boolean;
          readonly scheduleId: string;
          readonly scheduleManager: boolean;
          readonly scheduleManagerWithPay: boolean;
          readonly shiftManager: boolean;
          readonly updatedAt: string;
        }> | null;
        readonly id: string;
      };
    }>;
  };
};
export type EmploymentSchedules_InternalQuery = {
  response: EmploymentSchedules_InternalQuery$data;
  variables: EmploymentSchedules_InternalQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "businessId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "employmentId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "businessId",
    "variableName": "businessId"
  },
  {
    "items": [
      {
        "kind": "Variable",
        "name": "ids.0",
        "variableName": "employmentId"
      }
    ],
    "kind": "ListValue",
    "name": "ids"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "scheduleId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "defined",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "inherited",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "groupAdmin",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "inheritedGroupAdmin",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "scheduleManager",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "inheritedScheduleManager",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "scheduleManagerWithPay",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "inheritedScheduleManagerWithPay",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "shiftManager",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "inheritedShiftManager",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "schedulable",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "inheritedSchedulable",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updatedAt",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EmploymentSchedules_InternalQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EmploymentConnection",
        "kind": "LinkedField",
        "name": "employments",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "EmploymentEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Employment",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EmploymentSchedule",
                    "kind": "LinkedField",
                    "name": "employmentSchedules",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/),
                      (v14/*: any*/),
                      (v15/*: any*/),
                      (v16/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "InternalQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EmploymentSchedules_InternalQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EmploymentConnection",
        "kind": "LinkedField",
        "name": "employments",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "EmploymentEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Employment",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EmploymentSchedule",
                    "kind": "LinkedField",
                    "name": "employmentSchedules",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/),
                      (v14/*: any*/),
                      (v15/*: any*/),
                      (v16/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f06f9e98f09a53c3fdc60f9d20a9958e",
    "id": null,
    "metadata": {},
    "name": "EmploymentSchedules_InternalQuery",
    "operationKind": "query",
    "text": "query EmploymentSchedules_InternalQuery(\n  $businessId: ID!\n  $employmentId: ID!\n) {\n  employments(businessId: $businessId, ids: [$employmentId]) {\n    edges {\n      node {\n        id\n        employmentSchedules {\n          scheduleId\n          defined\n          inherited\n          groupAdmin\n          inheritedGroupAdmin\n          scheduleManager\n          inheritedScheduleManager\n          scheduleManagerWithPay\n          inheritedScheduleManagerWithPay\n          shiftManager\n          inheritedShiftManager\n          schedulable\n          inheritedSchedulable\n          updatedAt\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5cd5353bf18b38bd226b722147be907d";

export default node;
