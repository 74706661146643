/**
 * @generated SignedSource<<e0ad00858f30cfddfe606bdc61fdc392>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ValueTypeEnum = "currency" | "float" | "hours" | "int" | "percentage" | "seconds" | "string" | "%future added value";
export type EmploymentPerformanceReviewRatingCreateInput = {
  crewAdjustmentAmount?: any | null;
  crewAdjustmentType?: ValueTypeEnum | null;
  managerAdjustmentAmount?: any | null;
  managerAdjustmentType?: ValueTypeEnum | null;
  name?: string | null;
};
export type EmploymentPerformanceRatingsQueries_CreateEmploymentPerformanceRating_Mutation$variables = {
  businessId: string;
  input: EmploymentPerformanceReviewRatingCreateInput;
};
export type EmploymentPerformanceRatingsQueries_CreateEmploymentPerformanceRating_Mutation$data = {
  readonly createEmploymentPerformanceReviewRating: {
    readonly crewAdjustmentAmount: any | null;
    readonly crewAdjustmentType: ValueTypeEnum;
    readonly deleted: boolean;
    readonly id: string;
    readonly managerAdjustmentAmount: any | null;
    readonly managerAdjustmentType: ValueTypeEnum;
    readonly name: string;
    readonly rank: number;
    readonly updatedAt: string | null;
  };
};
export type EmploymentPerformanceRatingsQueries_CreateEmploymentPerformanceRating_Mutation = {
  response: EmploymentPerformanceRatingsQueries_CreateEmploymentPerformanceRating_Mutation$data;
  variables: EmploymentPerformanceRatingsQueries_CreateEmploymentPerformanceRating_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "businessId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "businessId",
        "variableName": "businessId"
      },
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "EmploymentPerformanceReviewRating",
    "kind": "LinkedField",
    "name": "createEmploymentPerformanceReviewRating",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "rank",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "crewAdjustmentType",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "managerAdjustmentType",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "crewAdjustmentAmount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "managerAdjustmentAmount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deleted",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "updatedAt",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EmploymentPerformanceRatingsQueries_CreateEmploymentPerformanceRating_Mutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EmploymentPerformanceRatingsQueries_CreateEmploymentPerformanceRating_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f9009bbfc8dc4c985769f7816acf45ab",
    "id": null,
    "metadata": {},
    "name": "EmploymentPerformanceRatingsQueries_CreateEmploymentPerformanceRating_Mutation",
    "operationKind": "mutation",
    "text": "mutation EmploymentPerformanceRatingsQueries_CreateEmploymentPerformanceRating_Mutation(\n  $businessId: ID!\n  $input: EmploymentPerformanceReviewRatingCreateInput!\n) {\n  createEmploymentPerformanceReviewRating(businessId: $businessId, input: $input) {\n    id\n    name\n    rank\n    crewAdjustmentType\n    managerAdjustmentType\n    crewAdjustmentAmount\n    managerAdjustmentAmount\n    deleted\n    updatedAt\n  }\n}\n"
  }
};
})();

(node as any).hash = "0e27e3ac678c4d0dd2e2327bfedbbf77";

export default node;
