import React, { useState } from "react";

import { ConfigurationObject, SessionInfo } from "../data/models/common";
import { idmExternalEnvironment } from "../environment";
import Authentication from "../utils/authentication";
import ServerError, { ServerErrorCode } from "../utils/server-error";
import AuthenticatedApp from "./IDM/external/AuthenticatedApp";
import Login from "./IDM/external/Login";
import { SignOutMutation } from "./IDM/external/mutations/__generated__/SignOutMutation.graphql";
import SignOutMutationQuery from "./IDM/external/mutations/SignOutMutation";
import StackService from "./IDM/internal/Stack/StackService";

import "../styles/App.scss";
import "react-toastify/dist/ReactToastify.css";

type Props = {
  configuration: ConfigurationObject;
};

function App(props: Props) {
  const [session, setSession] = useState<SessionInfo | null>(
    Authentication.restoreSession(),
  );

  async function handleLogin(i: SessionInfo) {
    Authentication.storeSession(i);
    try {
      await StackService.getAllStacks();
      // if user has access to internal query
      setSession(i);
    } catch (e) {
      const { source } = (e as any) || {};
      if (source) {
        const serverError = new ServerError(source);
        if (serverError.code === ServerErrorCode.AuthorizationError) {
          Authentication.clearSession();
          setSession(null);
          return;
        }
      }

      // unhandled
      throw e;
    }
  }

  function handleLogout() {
    SignOutMutationQuery(
      idmExternalEnvironment,
      (response: SignOutMutation["response"], errors?: any) => {
        if (errors) {
          // eslint-disable-next-line no-console
          console.debug("error signing out: ", errors);
          return;
        }

        const { signOut } = response;
        if (signOut) {
          Authentication.clearSession();
          setSession(null);
        }
      },
    );
  }

  const { configuration } = props;

  if (session === null || session.authToken == null || session.user == null) {
    return (
      <Login
        deploymentConfiguration={configuration}
        onLoggedIn={(s: SessionInfo) => handleLogin(s)}
      />
    );
  }
  return (
    <AuthenticatedApp
      user={session.user}
      handleLogout={handleLogout}
      stackCode={configuration?.stack}
    />
  );
}

export default App;
