export type ScheduleRulesInfo = {
  ruleSetName: string | null;
  ruleSetVersion: string | null;
  ruleSetSubscriptionStatus: string;
  ruleSetMeta: Record<string, any>;
};

export type RuleSetFetch = {
  loaded: boolean;
  error: Array<RuleSetFetchError> | null;
  loading: boolean;
  data: Array<RuleSet>;
};

export type RuleSetFetchError = {
  message: string;
  extensions?: object;
  original: object;
};

export type RuleSet = {
  name: string;
  description: string;
  contents: Array<RuleSetContents>;
};

export type RuleSetContents = {
  version: string;
  level: string;
};

export enum SubscriptionStatus {
  STAGING = "staging",
  PRODUCTION = "production",
  NOT_SUBSCRIBED = "not_subscribed",
}
