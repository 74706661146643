/**
 * @generated SignedSource<<5b24ef7245367df383ef5b4b64069042>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type EmploymentHistoryStatusEnum = "active" | "leave_of_absence" | "suspended" | "terminated" | "%future added value";
export type BusinessEmploymentStatusChangeReason_StatusChangeReasons_Query$variables = {
  businessId: string;
  skip: boolean;
  status: EmploymentHistoryStatusEnum;
};
export type BusinessEmploymentStatusChangeReason_StatusChangeReasons_Query$data = {
  readonly employmentStatusChangeReasons?: {
    readonly nodes: ReadonlyArray<{
      readonly code: string | null;
      readonly deleted: boolean;
      readonly displayText: string;
      readonly id: string;
      readonly status: EmploymentHistoryStatusEnum;
    }>;
  };
};
export type BusinessEmploymentStatusChangeReason_StatusChangeReasons_Query = {
  response: BusinessEmploymentStatusChangeReason_StatusChangeReasons_Query$data;
  variables: BusinessEmploymentStatusChangeReason_StatusChangeReasons_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "businessId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "skip"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "status"
},
v3 = [
  {
    "condition": "skip",
    "kind": "Condition",
    "passingValue": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "businessId",
            "variableName": "businessId"
          },
          {
            "kind": "Literal",
            "name": "deleted",
            "value": false
          },
          {
            "items": [
              {
                "kind": "Variable",
                "name": "status.0",
                "variableName": "status"
              }
            ],
            "kind": "ListValue",
            "name": "status"
          }
        ],
        "concreteType": "EmploymentStatusChangeReasonConnection",
        "kind": "LinkedField",
        "name": "employmentStatusChangeReasons",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "EmploymentStatusChangeReason",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "deleted",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "code",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "displayText",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "BusinessEmploymentStatusChangeReason_StatusChangeReasons_Query",
    "selections": (v3/*: any*/),
    "type": "InternalQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "BusinessEmploymentStatusChangeReason_StatusChangeReasons_Query",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "2c1a37ed2fafd38c56956bb244347118",
    "id": null,
    "metadata": {},
    "name": "BusinessEmploymentStatusChangeReason_StatusChangeReasons_Query",
    "operationKind": "query",
    "text": "query BusinessEmploymentStatusChangeReason_StatusChangeReasons_Query(\n  $businessId: ID!\n  $status: EmploymentHistoryStatusEnum!\n  $skip: Boolean!\n) {\n  employmentStatusChangeReasons(businessId: $businessId, deleted: false, status: [$status]) @skip(if: $skip) {\n    nodes {\n      id\n      deleted\n      code\n      status\n      displayText\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f7bc751102b3195cd67ba8165b07d0d6";

export default node;
