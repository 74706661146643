/**
 * @generated SignedSource<<b695c4f52ea3d5ab873808a1b8a001a4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type MetadataTypesQueries_DeleteBusinessMetadataType_Mutation$variables = {
  businessId: string;
  id: string;
};
export type MetadataTypesQueries_DeleteBusinessMetadataType_Mutation$data = {
  readonly deleteMetadataType: boolean;
};
export type MetadataTypesQueries_DeleteBusinessMetadataType_Mutation = {
  response: MetadataTypesQueries_DeleteBusinessMetadataType_Mutation$data;
  variables: MetadataTypesQueries_DeleteBusinessMetadataType_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "businessId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "businessId",
        "variableName": "businessId"
      },
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "kind": "ScalarField",
    "name": "deleteMetadataType",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MetadataTypesQueries_DeleteBusinessMetadataType_Mutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MetadataTypesQueries_DeleteBusinessMetadataType_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "b05c1336d4891a3052e2a7b78b5e8a2f",
    "id": null,
    "metadata": {},
    "name": "MetadataTypesQueries_DeleteBusinessMetadataType_Mutation",
    "operationKind": "mutation",
    "text": "mutation MetadataTypesQueries_DeleteBusinessMetadataType_Mutation(\n  $businessId: ID!\n  $id: ID!\n) {\n  deleteMetadataType(businessId: $businessId, id: $id)\n}\n"
  }
};
})();

(node as any).hash = "8d1b25f04c30a785bf04dcedf232b43c";

export default node;
