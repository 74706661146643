/**
 * @generated SignedSource<<0dfcb7f74c62eb3d8a0e461d168b4008>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CorporatesQueries_GetCorporateStacks_InternalQuery$variables = {};
export type CorporatesQueries_GetCorporateStacks_InternalQuery$data = {
  readonly corporateStacks: {
    readonly nodes: ReadonlyArray<{
      readonly corporateId: string;
      readonly id: string;
      readonly stack: {
        readonly stackCode: string;
      };
      readonly stackId: string;
    }>;
  };
};
export type CorporatesQueries_GetCorporateStacks_InternalQuery = {
  response: CorporatesQueries_GetCorporateStacks_InternalQuery$data;
  variables: CorporatesQueries_GetCorporateStacks_InternalQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "stackId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "corporateId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "stackCode",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "CorporatesQueries_GetCorporateStacks_InternalQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CorporateStackConnection",
        "kind": "LinkedField",
        "name": "corporateStacks",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CorporateStack",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Stack",
                "kind": "LinkedField",
                "name": "stack",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "InternalQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "CorporatesQueries_GetCorporateStacks_InternalQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CorporateStackConnection",
        "kind": "LinkedField",
        "name": "corporateStacks",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CorporateStack",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Stack",
                "kind": "LinkedField",
                "name": "stack",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v0/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "27247b83481a83abc8f134053b53e2a4",
    "id": null,
    "metadata": {},
    "name": "CorporatesQueries_GetCorporateStacks_InternalQuery",
    "operationKind": "query",
    "text": "query CorporatesQueries_GetCorporateStacks_InternalQuery {\n  corporateStacks {\n    nodes {\n      id\n      stackId\n      corporateId\n      stack {\n        stackCode\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ec97c6658704379ddeb5018e0b7892c5";

export default node;
