import React, { useEffect, useState } from "react";

import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";
import { Environment, graphql, QueryRenderer } from "react-relay";
import { RouteComponentProps } from "react-router-dom";

import { Id, IStack } from "../../../data/models/common";
import { getRegionalStackEnvironment } from "../../../environment";
import DynamicSelect from "../../common/Form/DynamicSelect";
import Loader from "../../common/Loader";
import HeaderPortal from "../../common/Portal/HeaderPortal";
import Searchable from "../../common/Searchable";
import StackService from "../../IDM/internal/Stack/StackService";
import {
  EmploymentStatus,
  getCurrentlyEmployedParam,
  getDeletedParam,
} from "../Employment/EmploymentTable";
import { StackPeople_InternalQuery } from "./__generated__/StackPeople_InternalQuery.graphql";
import Table, { SearchByEnum } from "./StackPeopleTable";

const StackPeopleQuery = graphql`
  query StackPeople_InternalQuery(
    $email: String
    $userId: ID
    $deleted: Boolean
    $acceptedInvite: Boolean
    $currentlyEmployed: Boolean
    $unlinkedUser: Boolean
    $pageSize: Int!
    $after: String
  ) {
    ...StackPeopleTable_viewer
  }
`;

interface MatchParams {
  stack_id: Id;
}

const defaultFilter = {
  email: "",
  userId: "",
  employmentStatus: EmploymentStatus.Any,
  searchBy: SearchByEnum.Email,
  unlinked: false,
};

type Props = RouteComponentProps<MatchParams>;

export default function StackPeople({ match }: Props) {
  const { t } = useTranslation("stacks");
  const [filters, setFilters] = useState({
    email: "",
    userId: "",
    employmentStatus: EmploymentStatus.Any,
    searchBy: SearchByEnum.Email,
    unlinked: false,
  });
  const [stack, setStack] = useState<IStack | undefined>(undefined);
  const [environment, setEnvironment] = useState<Environment | undefined>(
    undefined,
  );
  const {
    params: { stack_id: stackId },
  } = match;

  useEffect(() => {
    async function setupInitialStack() {
      const stackResult = await StackService.getStackById(stackId);
      if (!stackResult) {
        return;
      }
      const environmentResult = getRegionalStackEnvironment(
        stackResult.domainName,
      );

      setStack(stackResult);
      setEnvironment(environmentResult);
    }

    if (stackId) {
      setupInitialStack();
    }
  }, [stackId]);

  if (!environment) {
    return null;
  }

  return (
    <>
      <Row className="my-2">
        <Col>
          <Form inline>
            <Form.Group>
              <Form.Label className="mr-2 ml-0">
                {t("table.searchBy.label")}
              </Form.Label>
              <DynamicSelect<SearchByEnum>
                className="mr-2"
                options={[
                  {
                    label: t("table.searchBy.email"),
                    value: SearchByEnum.Email,
                  },
                  {
                    label: t("table.searchBy.userId"),
                    value: SearchByEnum.UserId,
                  },
                ]}
                value={filters.searchBy}
                name="employment-status"
                onChange={(newValue: SearchByEnum | null | undefined) => {
                  setFilters((prev) => ({
                    ...prev,
                    searchBy: newValue ?? SearchByEnum.Email,
                  }));
                }}
              />

              {filters.searchBy === SearchByEnum.Email ? (
                <Searchable
                  searchValue={filters.email}
                  className="email"
                  onSearchChange={(value: string) => {
                    setFilters((prev) => ({
                      ...prev,
                      email: value,
                    }));
                  }}
                  placeholder={t("table.searchByEmail")}
                />
              ) : (
                <Searchable
                  searchValue={filters.userId}
                  className="user-id"
                  onSearchChange={(value: string) => {
                    setFilters((prev) => ({
                      ...prev,
                      userId: value,
                    }));
                  }}
                  placeholder={t("table.searchByUserId")}
                />
              )}
            </Form.Group>
            <Form.Group className="border-right">
              <Form.Check
                className="mr-4 ml-4"
                type="checkbox"
                id="unlinked"
                label={t("table.unlinked")}
                name="unlinked"
                checked={filters.unlinked}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const { currentTarget } = e;
                  const { checked } = currentTarget;
                  setFilters((prev) => ({
                    ...prev,
                    unlinked: checked,
                  }));
                }}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label className="ml-4 mr-2">{t("table.status")}</Form.Label>
              <DynamicSelect<EmploymentStatus>
                options={[
                  {
                    label: t("table.statuses.any"),
                    value: EmploymentStatus.Any,
                  },
                  {
                    label: t("table.statuses.employed"),
                    value: EmploymentStatus.Employed,
                  },
                  {
                    label: t("table.statuses.invited"),
                    value: EmploymentStatus.Invited,
                  },
                  {
                    label: t("table.statuses.terminated"),
                    value: EmploymentStatus.Terminated,
                  },
                ]}
                value={filters.employmentStatus}
                name="employment-status"
                onChange={(newValue: EmploymentStatus | null | undefined) => {
                  setFilters((prev) => ({
                    ...prev,
                    employmentStatus: newValue ?? EmploymentStatus.Employed,
                  }));
                }}
              />
            </Form.Group>
          </Form>
        </Col>
        <Col>
          <Button
            variant="outline-primary"
            onClick={() => {
              setFilters((prev) => ({
                ...prev,
                ...defaultFilter,
              }));
            }}
          >
            {t("table.clearFilters")}
          </Button>
        </Col>
      </Row>

      <Card body>
        <QueryRenderer<StackPeople_InternalQuery>
          environment={environment}
          query={StackPeopleQuery}
          variables={{
            email:
              filters.searchBy === SearchByEnum.Email
                ? filters.email
                : undefined,
            userId:
              filters.searchBy === SearchByEnum.UserId
                ? filters.userId
                : undefined,
            acceptedInvite:
              filters.employmentStatus === EmploymentStatus.Invited
                ? false
                : undefined,
            deleted: getDeletedParam(filters.employmentStatus),
            currentlyEmployed: getCurrentlyEmployedParam(
              filters.employmentStatus,
            ),
            unlinkedUser: filters.unlinked ? true : undefined,
            pageSize: 50,
          }}
          render={({ error, props }) => {
            if (error) {
              return <div>Error!</div>;
            }
            if (!props) {
              return <Loader />;
            }
            return (
              <Table
                email={filters.email}
                userId={filters.userId}
                employmentStatus={filters.employmentStatus}
                stackId={stackId}
                searchBy={filters.searchBy}
                unlinked={filters.unlinked}
                viewer={props}
              />
            );
          }}
        />
      </Card>

      <HeaderPortal as="span">{stack?.domainName}</HeaderPortal>
    </>
  );
}
