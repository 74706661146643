import { ConfigurationObject } from "./data/models/common";

export default class ConfigurationService {
  private static configCache: ConfigurationObject | null = null;

  public static get config(): ConfigurationObject {
    if (this.configCache) {
      return this.configCache;
    }
    // fetch the config from the DOM
    const configElement = document.getElementById(
      "lz-config",
    ) as HTMLScriptElement;
    if (!configElement) {
      throw new Error("unable to find embedded config");
    }
    // store it in the cache for quicker (non-DOM) retrieval in the future
    this.configCache = JSON.parse(
      configElement.innerHTML,
    ) as ConfigurationObject;

    // return the loaded config
    return this.configCache;
  }
}
