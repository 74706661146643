import React from "react";

import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";
import { LinkContainer } from "react-router-bootstrap";
import styled from "styled-components";

import { useAppContext } from "../../IDM/external/Context/AppContext";

import "./Sidebar.scss";

const SidebarContainer = styled.div`
  position: fixed;
  z-index: 1000;
  height: calc(100vh);
  color: ${(props) => props.theme.white};
  border-right: 1px solid ${(props) => props.theme.borderColor};
  width: 200px;

  .sidebar-logo {
    .title {
      font-size: 14px;
      color: ${(props) => props.theme.bluePrimary};
      margin: 0;
    }
    .subtitle {
      color: ${(props) => props.theme.generic.border.dark};
      margin: 0;
      font-size: 12px;
    }
    .version {
      font-size: 11px;
      display: block;
      padding-top: 3px;
      padding-right: 15px;
      font-weight: 500;
    }
  }
`;

export default function Sidebar() {
  const appContext = useAppContext();
  const { t } = useTranslation();

  return (
    <SidebarContainer className="side-bar bg-dark">
      <div className="text-left my-2 ml-3">
        <Row noGutters>
          <Col className="sidebar-logo">
            <p className="title">{t("logo.title")}</p>
            <p className="subtitle">{t("logo.subtitle")}</p>
          </Col>
        </Row>
      </div>
      <Nav className="flex-column">
        <LinkContainer
          to="/stacks"
          isActive={(match, location) => {
            return (
              location.pathname.match(new RegExp("/stacks?/([^/?]+)$")) != null
            );
          }}
        >
          <Nav.Link>{t("sidebar.nav.stacks")}</Nav.Link>
        </LinkContainer>
        <LinkContainer
          to="/accounts"
          isActive={(match, location) => {
            return (
              location.pathname.match(new RegExp("/accounts?([^/?]+)$")) != null
            );
          }}
        >
          <Nav.Link>{t("sidebar.nav.accounts")}</Nav.Link>
        </LinkContainer>
        <LinkContainer
          to="/corporates"
          isActive={(match, location) => {
            return location.pathname.match(new RegExp("/corporates")) != null;
          }}
        >
          <Nav.Link>{t("sidebar.nav.corporates")}</Nav.Link>
        </LinkContainer>
        {appContext.stack?.id ? (
          <LinkContainer
            to={`/stack/${appContext.stack?.id}/businesses`}
            isActive={(match, location) => {
              return (
                location.pathname.match(
                  new RegExp("/stack/([^/?]+)/business(es)?"),
                ) != null
              );
            }}
          >
            <Nav.Link>{t("sidebar.nav.businesses")}</Nav.Link>
          </LinkContainer>
        ) : null}
      </Nav>
    </SidebarContainer>
  );
}
