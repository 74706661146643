import { commitMutation, Environment, graphql } from "react-relay";

const mutation = graphql`
  mutation LinkUserToEmploymentMutation(
    $businessId: ID!
    $userId: ID!
    $employmentId: ID!
  ) {
    linkUserToEmployment(
      businessId: $businessId
      userId: $userId
      employmentId: $employmentId
    ) {
      id
      firstName
      lastName
      userId
    }
  }
`;

export default (
  environment: Environment,
  businessId: string,
  userId: string,
  employmentId: string,
  callback: Function,
  onError: (error: Error) => void = (err: Error) => {
    alert(err);
  },
) => {
  const variables = {
    businessId,
    userId,
    employmentId,
  };

  return commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response: {}, errors: readonly any[] | null | undefined) => {
      callback(response, errors);
    },
    onError,
  });
};
