import React from "react";

import { Button, Modal } from "react-bootstrap";
import { ButtonVariant } from "react-bootstrap/types";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const StyledModalTitle = styled(Modal.Title)`
  font-size: 20px;
`;

const StyledModalBody = styled(Modal.Body)`
  white-space: pre-line;
`;

type Props = {
  onClose: () => void;
  okClicked: () => any;
  okText?: string;
  title: string;
  hideCancel?: boolean;
  children: React.ReactNode;
  variant?: ButtonVariant;
};

// Generic confirmation modal
export function ConfirmationModal({
  onClose,
  okClicked,
  okText,
  title,
  hideCancel = false,
  variant = "primary",
  children,
}: Props) {
  const { t } = useTranslation();
  return (
    <Modal onHide={onClose} show>
      <Modal.Header closeButton>
        <StyledModalTitle>{title}</StyledModalTitle>
      </Modal.Header>
      <StyledModalBody>{children}</StyledModalBody>
      <Modal.Footer>
        {!hideCancel && (
          <Button variant="link" className="mr-4" onClick={onClose}>
            {t("form.actions.cancel")}
          </Button>
        )}
        <Button variant={variant} onClick={okClicked}>
          {okText ?? t("form.actions.ok")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
