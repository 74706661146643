import { useCallback, useState } from "react";

import { useLazyLoadQuery } from "react-relay";
import {
  CacheConfig,
  FetchPolicy,
  GraphQLTaggedNode,
  OperationType,
  RenderPolicy,
} from "relay-runtime";

export function useRefreshableLazyLoadQuery<TQuery extends OperationType>(
  gqlQuery: GraphQLTaggedNode,
  variables: TQuery["variables"],
  options?: {
    fetchKey?: string | number | undefined;
    fetchPolicy?: FetchPolicy | undefined;
    networkCacheConfig?: CacheConfig | undefined;
    UNSTABLE_renderPolicy?: RenderPolicy | undefined;
  },
) {
  const [refreshedQueryOptions, setRefreshedQueryOptions] = useState<{
    fetchKey?: string | number;
    fetchPolicy?: FetchPolicy;
  }>({
    fetchPolicy: "network-only",
  });

  const refresh = useCallback(() => {
    // Trigger a re-render of useLazyLoadQuery with the same variables,
    // but an updated fetchKey and fetchPolicy.
    // The new fetchKey will ensure that the query is fully
    // re-evaluated and refetched.
    // The fetchPolicy ensures that we always fetch from the network
    // and skip the local data cache.
    setRefreshedQueryOptions((prev) => ({
      fetchKey: ((prev?.fetchKey ?? 0) as number) + 1,
      fetchPolicy: "network-only",
    }));
  }, []);

  const data = useLazyLoadQuery(gqlQuery, variables, {
    ...options,
    ...refreshedQueryOptions,
  });

  return [data, { refresh, refreshedQueryOptions }] as const;
}
