/**
 * @generated SignedSource<<59dc4b67c6a0a4b28d4f8f899d998cf0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Profile_stack$data = {
  readonly deleted: boolean;
  readonly domainName: string;
  readonly id: string;
  readonly stackCode: string;
  readonly " $fragmentType": "Profile_stack";
};
export type Profile_stack$key = {
  readonly " $data"?: Profile_stack$data;
  readonly " $fragmentSpreads": FragmentRefs<"Profile_stack">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Profile_stack",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "domainName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "stackCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "deleted",
      "storageKey": null
    }
  ],
  "type": "Stack",
  "abstractKey": null
};

(node as any).hash = "1741f2fff2cedc8b40a9ff98026b84dd";

export default node;
