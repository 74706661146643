/**
 * @generated SignedSource<<44e6eb5ef37eab930d6249a70cd5c101>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type EmploymentFeedbackTypeEnum = "commendation" | "disciplinary_action" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type FeedbackReasonsQueries_EmploymentFeedbackReasonFragment$data = {
  readonly defaultMessage: string | null;
  readonly deleted: boolean;
  readonly description: string;
  readonly feedbackType: EmploymentFeedbackTypeEnum;
  readonly id: string;
  readonly " $fragmentType": "FeedbackReasonsQueries_EmploymentFeedbackReasonFragment";
};
export type FeedbackReasonsQueries_EmploymentFeedbackReasonFragment$key = {
  readonly " $data"?: FeedbackReasonsQueries_EmploymentFeedbackReasonFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"FeedbackReasonsQueries_EmploymentFeedbackReasonFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FeedbackReasonsQueries_EmploymentFeedbackReasonFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "feedbackType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "defaultMessage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "deleted",
      "storageKey": null
    }
  ],
  "type": "EmploymentFeedbackReason",
  "abstractKey": null
};

(node as any).hash = "1a83af31d205cffc0e0d78600c57e899";

export default node;
