/**
 * @generated SignedSource<<786ac89359104a3c50b5fbb9cdb6cd95>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ScheduleService_SchedulesByName_Query$variables = {
  businessId: string;
  search?: string | null;
};
export type ScheduleService_SchedulesByName_Query$data = {
  readonly schedules: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly scheduleName: string;
      };
    }>;
  };
};
export type ScheduleService_SchedulesByName_Query = {
  response: ScheduleService_SchedulesByName_Query$data;
  variables: ScheduleService_SchedulesByName_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "businessId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "search"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "businessId",
        "variableName": "businessId"
      },
      {
        "kind": "Literal",
        "name": "isGroup",
        "value": false
      },
      {
        "kind": "Variable",
        "name": "search",
        "variableName": "search"
      },
      {
        "kind": "Literal",
        "name": "sort",
        "value": [
          {
            "field": "scheduleName",
            "order": "desc"
          }
        ]
      }
    ],
    "concreteType": "ScheduleConnection",
    "kind": "LinkedField",
    "name": "schedules",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ScheduleEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Schedule",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "scheduleName",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ScheduleService_SchedulesByName_Query",
    "selections": (v1/*: any*/),
    "type": "InternalQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ScheduleService_SchedulesByName_Query",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "63232ab0ba6c721b53083030d213fa07",
    "id": null,
    "metadata": {},
    "name": "ScheduleService_SchedulesByName_Query",
    "operationKind": "query",
    "text": "query ScheduleService_SchedulesByName_Query(\n  $businessId: ID!\n  $search: String\n) {\n  schedules(businessId: $businessId, search: $search, sort: [{field: scheduleName, order: desc}], isGroup: false) {\n    edges {\n      node {\n        id\n        scheduleName\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5d2b614e7200e2ed03dd0be0da82603f";

export default node;
