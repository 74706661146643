import React, { createContext, useContext, useState } from "react";

import i18next from "i18next";

export const BreakTypePaidThreshold = {
  // Platform UI will assume anything over this value is unpaid, but when the user selects 'always' via platform ui, we will set the
  // value to 1440. (this just covers backend changing this value for whatever reason)
  AlwaysPaidMinimum: 1240,
  AlwaysPaidDefault: 1440,

  // User has selected 'never' for the paidThreshold
  NeverPaid: 0,

  // Default custom input amount
  DefaultUnpaidCustomAmount: 15,
};

export enum BreakTypePaymentDropdownValue {
  DefaultToBusiness = "DefaultToBusiness",
  Always = "Always",
  Never = "Never",
  Custom = "Custom",
}

export enum FormUpdateMethod {
  OverridingBreakType,
  UpdateBreakType,
  UpdateScheduleBreakType,
  CreateBreakType,
}

export function getPaidUnpaidRadioOptions(businessValue: string | null) {
  return [
    {
      label: businessValue
        ? i18next.t("break-types:form.paidUnpaidRadioGroup.default", {
            value: businessValue,
          })
        : i18next.t("break-types:form.paidUnpaidRadioGroup.default_empty"),
      radioValue: "default",
      value: null,
    },
    {
      label: i18next.t("break-types:form.paidUnpaidRadioGroup.custom"),
      postfix: i18next.t("break-types:form.paidThresholdFormGroup.mins"),
      radioValue: "custom",
      customDisplayValue: {
        values: [0, ""],
        displayAs: "-",
      },
      customFallbackValue: 0,
      // value: N/A handled by custom input
      custom: true,
    },
  ];
}

export function getBreakTypePaymentSelectOptions(
  allowBusinessDefault: boolean,
  businessValue: number,
) {
  return [
    // Only show Default value if a schedule context
    ...(allowBusinessDefault
      ? [
          {
            label: getPaidThresholdDescription(businessValue as number),
            value: BreakTypePaymentDropdownValue.DefaultToBusiness,
          },
        ]
      : []),
    {
      label: i18next.t("break-types:form.paidThresholdFormGroup.options.paid"),
      value: BreakTypePaymentDropdownValue.Always,
    },
    {
      label: i18next.t(
        "break-types:form.paidThresholdFormGroup.options.unpaid",
      ),
      value: BreakTypePaymentDropdownValue.Never,
    },
    {
      label: i18next.t(
        "break-types:form.paidThresholdFormGroup.options.unpaid_when_longer",
      ),
      value: BreakTypePaymentDropdownValue.Custom,
    },
  ];
}

export function getDefaultRadioButtonValue(
  value: number | null,
  showDefaultOption: boolean,
) {
  if (showDefaultOption && value == null) {
    return BreakTypePaymentDropdownValue.DefaultToBusiness;
  }
  if (value! === BreakTypePaidThreshold.NeverPaid) {
    return BreakTypePaymentDropdownValue.Never;
  }
  if (value! >= BreakTypePaidThreshold.AlwaysPaidMinimum) {
    return BreakTypePaymentDropdownValue.Always;
  }
  return BreakTypePaymentDropdownValue.Custom;
}

export const shouldShowPaidAfter = (
  dropdownValue: BreakTypePaymentDropdownValue | null,
  businessDropdownValue: BreakTypePaymentDropdownValue | null,
) =>
  (dropdownValue === BreakTypePaymentDropdownValue.DefaultToBusiness &&
    (businessDropdownValue === BreakTypePaymentDropdownValue.Never ||
      businessDropdownValue === BreakTypePaymentDropdownValue.Custom)) ||
  dropdownValue === BreakTypePaymentDropdownValue.Never ||
  dropdownValue === BreakTypePaymentDropdownValue.Custom;

export const shouldShowUnpaidAfter = (
  dropdownValue: BreakTypePaymentDropdownValue | null,
  businessDropdownValue: BreakTypePaymentDropdownValue | null,
) =>
  (dropdownValue === BreakTypePaymentDropdownValue.DefaultToBusiness &&
    businessDropdownValue === BreakTypePaymentDropdownValue.Always) ||
  dropdownValue === BreakTypePaymentDropdownValue.Always;

export function getDefaultInputFieldValue(
  value: number,
  businessValue?: number | null,
) {
  const actualValue = value ?? businessValue;
  if (actualValue === BreakTypePaidThreshold.NeverPaid) {
    return BreakTypePaidThreshold.DefaultUnpaidCustomAmount;
  }
  if (actualValue >= BreakTypePaidThreshold.AlwaysPaidMinimum) {
    return BreakTypePaidThreshold.DefaultUnpaidCustomAmount;
  }

  return actualValue;
}

export const getPaidThresholdDescription = (paidThreshold: number) => {
  // If the value is 0 assume it's unpaid
  if (paidThreshold === BreakTypePaidThreshold.NeverPaid) {
    return i18next.t(
      "break-types:form.paidThresholdFormGroup.options.default.unpaid",
    );
  }

  if (paidThreshold >= BreakTypePaidThreshold.AlwaysPaidMinimum) {
    return i18next.t(
      "break-types:form.paidThresholdFormGroup.options.default.paid",
    );
  }

  return i18next.t(
    "break-types:form.paidThresholdFormGroup.options.default.unpaid_when_x",
    {
      paidThreshold,
    },
  );
};

export const PaidThresholdContext = createContext<{
  dropdownValue: BreakTypePaymentDropdownValue | null;
  businessDropdownValue: BreakTypePaymentDropdownValue | null;
  setDropdownValue: (val: BreakTypePaymentDropdownValue) => void;
}>({
  dropdownValue: null,
  businessDropdownValue: null,
  setDropdownValue: () => ({}),
});

export function PaidThresholdContextProvider(props: {
  defaultValue: number | null;
  businessValue: number | null;
  showDefaultOption: boolean;
  children: React.ReactNode;
}) {
  const [dropdownValue, setDropdownValue] =
    useState<BreakTypePaymentDropdownValue>(
      getDefaultRadioButtonValue(props.defaultValue, props.showDefaultOption),
    );

  return (
    <PaidThresholdContext.Provider
      value={{
        dropdownValue,
        businessDropdownValue: getDefaultRadioButtonValue(
          props.businessValue,
          false,
        ),
        setDropdownValue,
      }}
    >
      {props.children}
    </PaidThresholdContext.Provider>
  );
}

export const usePaidThresholdContext = () => useContext(PaidThresholdContext);
