/**
 * @generated SignedSource<<a550bee3c6f72e0cbd07d4e8e63a9093>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EmploymentProfileForm_employment$data = {
  readonly acceptedInvite: boolean;
  readonly businessInvite: {
    readonly activationCode: string;
    readonly attemptsRemaining: number;
    readonly expiryTime: string;
    readonly id: string;
    readonly inviteToken: string;
  } | null;
  readonly code: string | null;
  readonly createdAt: string;
  readonly dateOfBirth: string;
  readonly during: string | null;
  readonly email: string;
  readonly employmentRate: number;
  readonly employmentTypeCode: string;
  readonly external: boolean;
  readonly firstName: string;
  readonly id: string;
  readonly imageId: string | null;
  readonly lastName: string;
  readonly mutedFeatures: ReadonlyArray<string> | null;
  readonly nickname: string | null;
  readonly payrollCode: string | null;
  readonly rating: number;
  readonly schoolId: string | null;
  readonly securityRole: string;
  readonly updatedAt: string;
  readonly userEmail: string | null;
  readonly userId: string | null;
  readonly " $fragmentType": "EmploymentProfileForm_employment";
};
export type EmploymentProfileForm_employment$key = {
  readonly " $data"?: EmploymentProfileForm_employment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EmploymentProfileForm_employment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EmploymentProfileForm_employment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "acceptedInvite",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "during",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "updatedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userEmail",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BusinessInvite",
      "kind": "LinkedField",
      "name": "businessInvite",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "activationCode",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "attemptsRemaining",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "expiryTime",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "inviteToken",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "nickname",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dateOfBirth",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "imageId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "schoolId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "external",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rating",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "employmentRate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "securityRole",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "code",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "employmentTypeCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "payrollCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mutedFeatures",
      "storageKey": null
    }
  ],
  "type": "Employment",
  "abstractKey": null
};
})();

(node as any).hash = "f50f41976d299bd734b612a4453ed533";

export default node;
