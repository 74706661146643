import React, { useCallback, useEffect, useState } from "react";

import { faCloudUploadAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { getRequestBlobUrl } from "../../../environment";
import { useAppContext } from "../../IDM/external/Context/AppContext";

const StyledPreviewImg = styled("img")`
  width: 88px;
  background-color: ${(props) => props.theme.grey100};
`;

export enum ImagePreviewState {
  Saved, // Image present on initial load or after reset
  Preview, // New image selected
  Empty, // Image not present
}

type Props = {
  imagePath: string | null;
  previewDataUrl: string | ArrayBuffer | null;
  imagePreviewState: ImagePreviewState;
};

export default function ImagePreview({
  previewDataUrl,
  imagePreviewState,
  imagePath,
}: Props) {
  const [imagePathPreviewBlob, setImagePathPreviewBlob] = useState<
    string | null
  >(null);
  const [prevImagePath, setPrevImagePath] = useState<string | null>(null);

  const { stack } = useAppContext();
  const { t } = useTranslation();

  const revokePreviewObjectUrl = useCallback(() => {
    if (imagePathPreviewBlob) {
      // Revoke the data uris to avoid memory leaks
      URL.revokeObjectURL(imagePathPreviewBlob);
    }
  }, [imagePathPreviewBlob]);

  /** Revokes any existing url if applicable and then fetches the latest image preview url blob */
  const setImagePreviewUrl = useCallback(
    async (newImagePath: string | null) => {
      revokePreviewObjectUrl();

      if (newImagePath) {
        const url = await getRequestBlobUrl(stack?.domainName, newImagePath);
        setImagePathPreviewBlob(url);
      } else {
        setImagePathPreviewBlob(null);
      }
    },
    [stack?.domainName, revokePreviewObjectUrl],
  );

  useEffect(() => {
    // Any time the imagePath changes, revoke the existing url and update the preview blob
    // Note: Because this is being provided by the formik context, keep track of the previous value, otherwise
    // we could get a LOT of extra re-renders
    if (imagePath !== prevImagePath) {
      setPrevImagePath(imagePath);
      setImagePreviewUrl(imagePath);
    }
    return () => {
      revokePreviewObjectUrl();
    };
  }, [imagePath, prevImagePath, revokePreviewObjectUrl, setImagePreviewUrl]);

  switch (imagePreviewState) {
    case ImagePreviewState.Empty:
      return (
        <FontAwesomeIcon
          icon={faCloudUploadAlt}
          size="2x"
          className="text-black-50"
        />
      );

    default:
      return (
        <StyledPreviewImg
          src={
            imagePreviewState === ImagePreviewState.Preview
              ? (previewDataUrl as string)
              : (imagePathPreviewBlob as string)
          }
          alt={t("imagePreview.preview")}
        />
      );
  }
}
