/**
 * @generated SignedSource<<781c50a6d1ea20b7864e32f811f920bd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type BusinessService_Businesses_Query$variables = {
  userId?: string | null;
};
export type BusinessService_Businesses_Query$data = {
  readonly businesses: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly businessName: string;
        readonly id: string;
      };
    }>;
  };
};
export type BusinessService_Businesses_Query = {
  response: BusinessService_Businesses_Query$data;
  variables: BusinessService_Businesses_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "userId",
        "variableName": "userId"
      }
    ],
    "concreteType": "BusinessConnection",
    "kind": "LinkedField",
    "name": "businesses",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BusinessEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Business",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "businessName",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BusinessService_Businesses_Query",
    "selections": (v1/*: any*/),
    "type": "InternalQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BusinessService_Businesses_Query",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "90a153355bb467751107f2a0523e5e14",
    "id": null,
    "metadata": {},
    "name": "BusinessService_Businesses_Query",
    "operationKind": "query",
    "text": "query BusinessService_Businesses_Query(\n  $userId: ID\n) {\n  businesses(userId: $userId) {\n    edges {\n      node {\n        id\n        businessName\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "07c487813e59a3e6da6d3af5246428a4";

export default node;
