import React, { useState } from "react";

import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { useFormikContext } from "formik";
import { flatten, uniq } from "lodash";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Dropzone, { DropzoneProps, DropzoneRootProps } from "react-dropzone";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { Id } from "../../../data/models/common";
import ButtonIcon from "../ButtonIcon";
import ImagePreview, { ImagePreviewState } from "./ImagePreview";

type Props<T> = {
  value?: string;
  fieldKey: string;
  onChange: (v: any) => void;
  // if provided, turns on image preview mode
  getImagePreviewUrl?: (obj: T, imageId: Id | null) => string;
  disabled?: boolean;
  setValueKey?: string;
  returnAsFile?: boolean;
  dropzoneProps?: DropzoneProps;
};

const getColor = (props: DropzoneRootProps) => {
  if (props.isDragAccept) {
    return props.theme.bluePrimary;
  }
  if (props.isDragReject) {
    return props.theme.error;
  }
  if (props.isDragActive) {
    return props.theme.success;
  }
  return props.theme.borderColor;
};

const StyledDiv = styled.div<DropzoneRootProps>`
  border: 2px dashed ${(props) => props.theme.borderColor};
  border-color: ${(props) => getColor(props)};
  transition: border 0.24s ease-in-out;
  border-radius: 2px;
  padding: 16px 8px;
  max-width: 280px;
  outline: none;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default function FileUpload<T>(props: Props<T>) {
  const { t } = useTranslation();
  const [imagePreviewUrl, setImagePreviewUrl] = useState<string | null>(null);
  const {
    initialValues,
    setFieldValue,
    getFieldMeta,
    setFieldError,
    setErrors,
  } = useFormikContext<any>(); // make optional?

  const {
    getImagePreviewUrl,
    value,
    onChange,
    disabled,
    returnAsFile,
    setValueKey,
    fieldKey,
    dropzoneProps,
  } = props;

  const hasImagePreview = !!getImagePreviewUrl;

  const initialValue = fieldKey ? getFieldMeta(fieldKey).initialValue : null;
  const path =
    value === initialValue && value && hasImagePreview
      ? getImagePreviewUrl(initialValues, value)
      : null;

  const onImagePreviewClear = () => {
    setImagePreviewUrl(null);
    onChange(null);
    if (setValueKey) {
      setFieldValue(setValueKey, null);
    }
  };

  const onDropzoneDrop = (files: File[]) => {
    const reader = new FileReader();
    const selectedFile = files[0] ?? null;
    if (!selectedFile) {
      setFieldError(fieldKey, t("fileUpload.invalidFile"));
      return;
    }
    setErrors({});

    if (returnAsFile) {
      onChange(selectedFile);
    } else {
      reader.onloadend = () => {
        if (hasImagePreview) {
          setImagePreviewUrl(reader.result as unknown as string);
        }
        onChange(reader.result);
        if (setValueKey) {
          setFieldValue(setValueKey, reader.result);
        }
      };

      reader.readAsDataURL(selectedFile);
    }
  };

  let imagePreviewState = ImagePreviewState.Preview;
  if (value == null) {
    imagePreviewState = ImagePreviewState.Empty;
  } else if (value === initialValue) {
    imagePreviewState = ImagePreviewState.Saved;
  }

  return (
    <div>
      <Row>
        {hasImagePreview ? (
          <Col md="auto" className="text-center">
            <ImagePreview
              previewDataUrl={imagePreviewUrl}
              imagePath={path}
              imagePreviewState={imagePreviewState}
            />
            {imagePreviewState !== ImagePreviewState.Empty ? (
              <ButtonIcon
                className="ml-1 text-black-50"
                icon={faTimesCircle}
                disabled={disabled}
                onClick={onImagePreviewClear}
              />
            ) : null}
          </Col>
        ) : null}
        <Col>
          <Dropzone onDrop={onDropzoneDrop} {...dropzoneProps}>
            {({
              getRootProps,
              getInputProps,
              isDragActive,
              isDragAccept,
              isDragReject,
            }) => (
              <StyledDiv
                {...getRootProps({
                  isDragActive,
                  isDragAccept,
                  isDragReject,
                })}
              >
                <input {...getInputProps()} />
                <div>
                  <span className="text-primary mr-1">
                    {t("fileUpload.browse")}
                  </span>
                  <span>{t("fileUpload.dragAndDrop")}</span>
                </div>
                <div className="text-muted text-align-center">
                  {dropzoneProps?.maxSize ? (
                    <small>
                      {t("fileUpload.maxFileSize", {
                        sizeKb: dropzoneProps.maxSize / 1000,
                      })}
                    </small>
                  ) : null}
                  {dropzoneProps?.accept ? (
                    <div>
                      <small>
                        {t("fileUpload.acceptedFileTypes", {
                          // each file type has an array of formats, which need to be flattened
                          formats: uniq(
                            flatten([...Object.values(dropzoneProps.accept)]),
                          ),
                        })}
                      </small>
                    </div>
                  ) : null}
                </div>
              </StyledDiv>
            )}
          </Dropzone>
        </Col>
      </Row>
    </div>
  );
}
