/**
 * @generated SignedSource<<1214f963f7ce8f329df807119ed7893d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EmploymentTypeEnum = "casual" | "full_time" | "part_time" | "permanent" | "%future added value";
export type TimeOffTypeCreateInput = {
  code?: string | null;
  employmentTypeCodes?: ReadonlyArray<string> | null;
  employmentTypeSmallIds?: ReadonlyArray<number> | null;
  employmentTypes?: ReadonlyArray<EmploymentTypeEnum> | null;
  ignoreAutoReject?: boolean | null;
  isManagerByProxyOnly: boolean;
  name: string;
  paid?: boolean | null;
  shortName?: string | null;
};
export type TimeOffTypesQueries_CreateTimeOffType_Mutation$variables = {
  businessId: string;
  input: TimeOffTypeCreateInput;
};
export type TimeOffTypesQueries_CreateTimeOffType_Mutation$data = {
  readonly createTimeOffType: {
    readonly code: string | null;
    readonly employmentTypeCodes: ReadonlyArray<string>;
    readonly id: string;
    readonly ignoreAutoReject: boolean;
    readonly isManagerByProxyOnly: boolean;
    readonly name: string;
    readonly paid: boolean;
    readonly shortName: string | null;
  };
};
export type TimeOffTypesQueries_CreateTimeOffType_Mutation = {
  response: TimeOffTypesQueries_CreateTimeOffType_Mutation$data;
  variables: TimeOffTypesQueries_CreateTimeOffType_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "businessId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "businessId",
        "variableName": "businessId"
      },
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "TimeOffType",
    "kind": "LinkedField",
    "name": "createTimeOffType",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "shortName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "code",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "paid",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ignoreAutoReject",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isManagerByProxyOnly",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "employmentTypeCodes",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TimeOffTypesQueries_CreateTimeOffType_Mutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TimeOffTypesQueries_CreateTimeOffType_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "fbcc4d55706a6be54c05e9fc19adfa2b",
    "id": null,
    "metadata": {},
    "name": "TimeOffTypesQueries_CreateTimeOffType_Mutation",
    "operationKind": "mutation",
    "text": "mutation TimeOffTypesQueries_CreateTimeOffType_Mutation(\n  $businessId: ID!\n  $input: TimeOffTypeCreateInput!\n) {\n  createTimeOffType(businessId: $businessId, input: $input) {\n    id\n    name\n    shortName\n    code\n    paid\n    ignoreAutoReject\n    isManagerByProxyOnly\n    employmentTypeCodes\n  }\n}\n"
  }
};
})();

(node as any).hash = "f82e08c0eda0952d937395cc2f29dd92";

export default node;
