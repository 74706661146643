import {
  FetchPolicy,
  graphql,
  useLazyLoadQuery,
  useMutation,
} from "react-relay";

import { BusinessesQueries_GetCorporatesBusinessesByIdsQuery } from "./__generated__/BusinessesQueries_GetCorporatesBusinessesByIdsQuery.graphql";
import { BusinessesQueries_GetCorporatesBusinessesQuery } from "./__generated__/BusinessesQueries_GetCorporatesBusinessesQuery.graphql";
import { BusinessesQueries_LinkBusinessToCorporate_Mutation } from "./__generated__/BusinessesQueries_LinkBusinessToCorporate_Mutation.graphql";

export const GetCorporatesBusinessesByIds = graphql`
  query BusinessesQueries_GetCorporatesBusinessesByIdsQuery(
    $ids: [ID!]
    $skip: Boolean!
  ) {
    businesses(ids: $ids) @skip(if: $skip) {
      nodes {
        businessName
        id
        corporate {
          id
          name
        }
      }
    }
  }
`;

export const GetCorporatesBusinesses = graphql`
  query BusinessesQueries_GetCorporatesBusinessesQuery {
    businesses {
      nodes {
        businessName
        id
        corporate {
          id
          name
        }
      }
    }
  }
`;

export const LinkBusinessToCorporate = graphql`
  mutation BusinessesQueries_LinkBusinessToCorporate_Mutation(
    $id: ID!
    $corporateId: ID
  ) {
    linkBusinessToCorporate(id: $id, corporateId: $corporateId) {
      id
    }
  }
`;

type GetCorporatesBusinessesQuery_ResponseType =
  {} & BusinessesQueries_GetCorporatesBusinessesQuery["response"]["businesses"];
type GetCorporatesBusinessesQuery_NodeType =
  {} & GetCorporatesBusinessesQuery_ResponseType["nodes"];
export type GetCorporatesBusinessesQuery_Business =
  {} & GetCorporatesBusinessesQuery_NodeType[number];

export function useIdmBusinessCorporateLazyLoad(networkPolicy = false) {
  const queryData =
    useLazyLoadQuery<BusinessesQueries_GetCorporatesBusinessesQuery>(
      GetCorporatesBusinesses,
      {},
      {
        ...(networkPolicy && { fetchPolicy: "network-only" }),
      },
    );

  const items = queryData.businesses.nodes ?? [];
  return [items] as const;
}

type GetCorporatesBusinessesByIdsQuery_ResponseType =
  {} & BusinessesQueries_GetCorporatesBusinessesByIdsQuery["response"]["businesses"];
type GetCorporatesBusinessesByIdsQueryResponse_NodeType =
  {} & GetCorporatesBusinessesByIdsQuery_ResponseType["nodes"];
export type GetCorporatesBusinessesByIdsQuery_Business =
  {} & GetCorporatesBusinessesByIdsQueryResponse_NodeType[number];

export type QueryOptions = {
  fetchKey?: string | number;
  fetchPolicy?: FetchPolicy;
};

export function useIdmBusinessById(id?: string, queryOptions?: QueryOptions) {
  const queryData =
    useLazyLoadQuery<BusinessesQueries_GetCorporatesBusinessesByIdsQuery>(
      GetCorporatesBusinessesByIds,
      { ids: id ? [id] : [], skip: id == null },
      queryOptions ?? { fetchPolicy: "store-and-network" },
    );

  return queryData.businesses?.nodes?.[0] ?? null;
}

export function useIdmBusinessData(
  businessId?: string,
  queryOptions?: QueryOptions,
) {
  const [linkBusinessToCorporateMutation] = useMutation<{
    response: BusinessesQueries_LinkBusinessToCorporate_Mutation["response"];
    variables: BusinessesQueries_LinkBusinessToCorporate_Mutation["variables"];
  }>(LinkBusinessToCorporate);
  const business = useIdmBusinessById(businessId, queryOptions);

  return [
    business,
    {
      linkBusinessToCorporateMutation,
    },
  ] as const;
}
