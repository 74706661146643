import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useFormikContext } from "formik";
import { Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import styled from "styled-components";

import DurationInput from "./DurationInput";

const StyledLabel = styled(Form.Label)`
  margin: 0;
  align-items: center;
  ${(props) => props.$disabled && `color: ${props.theme.grey600}`}
`;

const StyledRadio = styled(Form.Check).attrs({ custom: true })`
  .custom-control-input {
    z-index: 100;
  }
  .custom-control-input:disabled:checked ~ .custom-control-label::before {
    border-color: transparent;
  }
  &.custom-radio .custom-control-input:checked ~ .custom-control-label {
    font-weight: bold;
  }
`;

type RadioOption = {
  label: string;
  value?: string | number | null;
  radioValue: string;
  postfix?: string;
  /** Whether a custom input field should be displayed for the current radio button */
  custom?: boolean;
  customPostfixLabel?: string;
  customPrefixLabel?: string;
  customDisplayValue?: {
    values: any[];
    displayAs: string | number;
  };
  customFallbackValue?: number | null;
};

type Props = {
  options: RadioOption[];
  fieldKey: string;
  value: string | number | null;
  onChange: (v: string | number | undefined | null) => void;
};

export default function RadioButtonGroup({
  options,
  fieldKey,
  value,
  onChange,
}: Props) {
  const [radioValue, setRadioValue] = useState(
    getDefaultRadioButton(options, value),
  );
  const [inputValue, setInputValue] = useState(value);

  const { dirty, initialValues } = useFormikContext<any>();

  const defaultValue = useMemo(
    () => getDefaultRadioButton(options, initialValues[fieldKey]),
    [fieldKey, initialValues, options],
  );

  useEffect(() => {
    if (!dirty) {
      setRadioValue(defaultValue);
      setInputValue(value);
    }
  }, [dirty, setRadioValue, setInputValue, value, defaultValue]);

  const onRadioChange =
    (option: RadioOption, customValue: string | number | null) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const isChecked = e.currentTarget.checked;
      if (isChecked) {
        setRadioValue(option.radioValue);
        if (option.custom) {
          if (customValue == null && option.customFallbackValue != null) {
            onChange(option.customFallbackValue);
            return;
          }
          onChange(customValue);
        } else {
          onChange(option.value);
        }
      }
    };

  const onCustomInputChange = useCallback(
    (v: string | number | null) => {
      const val = v != null ? Number(v) : null;
      setInputValue(val);
      onChange(val);
    },
    [setInputValue, onChange],
  );

  return (
    <Col>
      {options.map((option, index) => (
        <Row key={`radio-${option.value}`}>
          <StyledRadio
            label={option.label}
            className={`${
              option.custom && option.customPrefixLabel ? "" : "mr-3"
            } mb-1`}
            type="radio"
            id={`${fieldKey}-${index}`}
            value={option.radioValue}
            checked={radioValue === option.radioValue}
            onChange={onRadioChange(option, inputValue)}
          />
          {option.custom && (
            <StyledLabel
              $disabled={radioValue !== option.radioValue}
              className="d-flex justify-content-md-center"
            >
              {option.customPrefixLabel && (
                <span id={`${fieldKey}-prefix`} className="mr-2">
                  {option.customPrefixLabel}
                </span>
              )}
              <DurationInput
                fieldKey={fieldKey}
                postfix={option.postfix}
                size="sm"
                placeholder="-"
                value={inputValue ?? null}
                onChange={onCustomInputChange}
                disabled={radioValue !== option.radioValue}
                aria-labelledby={`${fieldKey}-prefix ${fieldKey}-postfix`}
                customDisplayValues={option.customDisplayValue}
                fallbackValue={option.customFallbackValue}
              />
              {option.customPostfixLabel && (
                <span id={`${fieldKey}-postfix`} className="ml-2">
                  {option.customPostfixLabel}
                </span>
              )}
            </StyledLabel>
          )}
        </Row>
      ))}
    </Col>
  );
}

/**
 * Get the currently selected radio button, assumes custom options have a dynamic value and will be the fallback
 * option if none of the static option values are selected
 */
function getDefaultRadioButton(
  options: RadioOption[],
  value: string | number | null,
) {
  let option = options.find((x) => x.value === value);

  if (!option) {
    option = options.find((x) => x.custom);
  }

  return option?.radioValue;
}
