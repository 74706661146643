import React from "react";

import { Button, Modal } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { pillMixin } from "../../../../../styles/mixins";
import { MetadataUtility } from "../MetadataUtility";
import {
  TimeboxedDataTable,
  TimeboxedTableRowData,
} from "./TimeboxedDataTable";

const StyledExternalWarningMessage = styled.span`
  ${(props) => pillMixin(props.theme.generic.yellow)}

  margin: 0px 12px 16px 12px;
  padding: 7px 0 7px 16px;
  width: 100%;
  font-weight: bold;
  border-radius: 7px;
`;

type Props = {
  onClose: () => void;
  onOk: () => void;
  tableRowData: TimeboxedTableRowData;
};

export default function RemoveMetadataConfirmModal({
  onClose,
  onOk,
  tableRowData,
}: Props) {
  const { metadataType, employmentMetadata, employeeHomeStore } = tableRowData;
  const { t } = useTranslation("employment");
  return (
    <Modal centered onHide={onClose} show>
      <Modal.Header closeButton>
        <Modal.Title as="h3">
          {t("metadata.timeboxed.removeModal.title", {
            name: MetadataUtility.getDisplayName(metadataType),
          })}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {metadataType?.external && (
          <Row>
            <StyledExternalWarningMessage>
              <span className="pill-text">
                {t("metadata.timeboxed.removeModal.externalWarning", {
                  metadataTypeName:
                    MetadataUtility.getDisplayName(metadataType),
                })}
              </span>
            </StyledExternalWarningMessage>
          </Row>
        )}
        <Row>
          <Col xs={12}>
            <p>{t("metadata.timeboxed.removeModal.body")}</p>
            <TimeboxedDataTable
              data={{
                metadataType,
                employmentMetadata: employmentMetadata
                  ? [employmentMetadata]
                  : [],
                employeeHomeStore,
              }}
              isReadonly
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="link" className="mr-4" onClick={onClose}>
          {t("metadata.timeboxed.removeModal.buttons.cancel")}
        </Button>
        <Button variant="danger" onClick={onOk}>
          {t("metadata.timeboxed.removeModal.buttons.ok")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
