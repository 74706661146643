/**
 * @generated SignedSource<<b814ce0b72f95d83c5d67f3fddb9f917>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AccountTable_viewer$data = {
  readonly users: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly " $fragmentSpreads": FragmentRefs<"AccountRow_viewer">;
      };
    }>;
    readonly pageInfo: {
      readonly endCursor: string | null;
      readonly hasNextPage: boolean;
    };
  };
  readonly " $fragmentType": "AccountTable_viewer";
};
export type AccountTable_viewer$key = {
  readonly " $data"?: AccountTable_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"AccountTable_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "after"
    },
    {
      "kind": "RootArgument",
      "name": "email"
    },
    {
      "kind": "RootArgument",
      "name": "isConfirmed"
    },
    {
      "kind": "RootArgument",
      "name": "isLocked"
    },
    {
      "kind": "RootArgument",
      "name": "pageSize"
    },
    {
      "kind": "RootArgument",
      "name": "stackId"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "pageSize",
        "cursor": "after",
        "direction": "forward",
        "path": [
          "users"
        ]
      }
    ]
  },
  "name": "AccountTable_viewer",
  "selections": [
    {
      "alias": "users",
      "args": [
        {
          "kind": "Variable",
          "name": "email",
          "variableName": "email"
        },
        {
          "kind": "Variable",
          "name": "isConfirmed",
          "variableName": "isConfirmed"
        },
        {
          "kind": "Variable",
          "name": "isLocked",
          "variableName": "isLocked"
        },
        {
          "kind": "Variable",
          "name": "stackId",
          "variableName": "stackId"
        }
      ],
      "concreteType": "UserConnection",
      "kind": "LinkedField",
      "name": "__AccountTable_users_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UserEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "AccountRow_viewer"
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "InternalQuery",
  "abstractKey": null
};

(node as any).hash = "2cfd1068f2661f0fe990ea9b30244404";

export default node;
