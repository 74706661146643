import React from "react";

import isBoolean from "lodash/isBoolean";
import Form from "react-bootstrap/Form";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";

import { Pillbox, PillboxVariantEnum } from "../Pillbox";

type Props<T> = {
  value?: boolean | null;
  fieldKey?: string;
  onChange: (newValue: boolean) => any;
  disabled?: boolean;
  error?: string;
  label?: string;
  toBoolean?: (v: T) => boolean;
  fromBoolean?: (v: boolean) => T;
  tooltipText?: string;
  className?: string;
  isExternal?: boolean;
};

const checkboxBorderBackgroundMixin = css`
  background-repeat: no-repeat;
  height: 21px;
  width: 21px;
  background-color: transparent;
  border: 0;
`;

const StyledCheck = styled(Form.Check)`
  display: flex;
  align-items: center;
  margin-bottom: 5px;

  .custom-control-label {
    padding-top: 2px;
    padding-left: 8px;
    &:hover {
      cursor: pointer;
    }
  }

  .custom-control-input:disabled:checked ~ .custom-control-label::before {
    &:hover {
      cursor: not-allowed;
    }
    ${checkboxBorderBackgroundMixin};
  }

  .custom-control-input ~ .custom-control-label::before {
    padding: 0;
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' y='0.5' width='19' height='19' rx='2.5' fill='white' stroke='%239B9B9B'/%3E%3C/svg%3E%0A");
    ${checkboxBorderBackgroundMixin};
  }
  .custom-control-input[disabled] ~ .custom-control-label::before {
    padding: 0;
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' y='0.5' width='19' height='19' rx='2.5' fill='%23EFEEEE' stroke='%23CBCBCB' /%3E%3C/svg%3E%0A");
    ${checkboxBorderBackgroundMixin};
  }
  .custom-control-input:checked ~ .custom-control-label::before {
    padding: 0;
    background-image: url("data:image/svg+xml,%3Csvg width='21' height='21' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' y='0.5' width='19' height='19' rx='2.5' fill='white' stroke='%239B9B9B'/%3E%3Cpath d='M15.1897 8.50446C15.1897 8.33705 15.1228 8.16964 15.0022 8.04911L14.0915 7.13839C13.971 7.01786 13.8036 6.95089 13.6362 6.95089C13.4688 6.95089 13.3013 7.01786 13.1808 7.13839L8.78795 11.5379L6.8192 9.5625C6.69866 9.44196 6.53125 9.375 6.36384 9.375C6.19643 9.375 6.02902 9.44196 5.90848 9.5625L4.99777 10.4732C4.87723 10.5937 4.81027 10.7612 4.81027 10.9286C4.81027 11.096 4.87723 11.2634 4.99777 11.3839L7.42188 13.808L8.33259 14.7187C8.45313 14.8393 8.62054 14.9062 8.78795 14.9062C8.95536 14.9062 9.12277 14.8393 9.2433 14.7187L10.154 13.808L15.0022 8.95982C15.1228 8.83929 15.1897 8.67188 15.1897 8.50446Z' fill='%232587F7'/%3E%3C/svg%3E%0A");
    ${checkboxBorderBackgroundMixin};
  }
  .custom-control-input[disabled]:checked ~ .custom-control-label::before {
    padding: 0;
    background-image: url("data:image/svg+xml,%3Csvg width='21' height='21' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' y='0.5' width='19' height='19' rx='2.5' fill='%23EFEEEE' stroke='%23CBCBCB' /%3E%3Cpath d='M15.1897 8.50446C15.1897 8.33705 15.1228 8.16964 15.0022 8.04911L14.0915 7.13839C13.971 7.01786 13.8036 6.95089 13.6362 6.95089C13.4688 6.95089 13.3013 7.01786 13.1808 7.13839L8.78795 11.5379L6.8192 9.5625C6.69866 9.44196 6.53125 9.375 6.36384 9.375C6.19643 9.375 6.02902 9.44196 5.90848 9.5625L4.99777 10.4732C4.87723 10.5937 4.81027 10.7612 4.81027 10.9286C4.81027 11.096 4.87723 11.2634 4.99777 11.3839L7.42188 13.808L8.33259 14.7187C8.45313 14.8393 8.62054 14.9062 8.78795 14.9062C8.95536 14.9062 9.12277 14.8393 9.2433 14.7187L10.154 13.808L15.0022 8.95982C15.1228 8.83929 15.1897 8.67188 15.1897 8.50446Z' fill='%23CBCBCB' /%3E%3C/svg%3E%0A");
    ${checkboxBorderBackgroundMixin};
  }
  .custom-control-input:checked ~ .custom-control-label::after {
    background: none;
  }
`;

function Checkbox({
  fieldKey,
  value,
  onChange,
  error,
  label,
  toBoolean,
  fromBoolean,
  tooltipText,
  isExternal,
  ...rest
}: Props<any>) {
  const { t } = useTranslation();
  const transformedValue = toBoolean ? toBoolean(value) : value;

  const renderLabel = () => {
    if (tooltipText) {
      return (
        <OverlayTrigger
          defaultShow={false}
          placement="top"
          overlay={<Tooltip id="tooltip">{tooltipText}</Tooltip>}
          trigger={undefined}
          delay={undefined}
          flip={undefined}
          onHide={undefined}
          onToggle={undefined}
          popperConfig={undefined}
          show={undefined}
          target={undefined}
        >
          <span className="d-flex flex-grow-1">
            <span className="d-flex flex-shrink-0">{label}</span>{" "}
            {isExternal && (
              <span className="ml-1">
                <Pillbox
                  text={t("pillbox.external_short")}
                  variant={PillboxVariantEnum.External}
                />
              </span>
            )}
          </span>
        </OverlayTrigger>
      );
    }
    return <span className="d-flex flex-grow-1">{label}</span>;
  };

  return (
    <StyledCheck
      {...rest}
      key={fieldKey}
      type="checkbox"
      id={fieldKey ?? "checkbox-component"}
      isInvalid={error != null}
      checked={isBoolean(transformedValue) ? transformedValue : false}
      label={renderLabel()}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        const v = e.target.checked;
        onChange(fromBoolean ? fromBoolean(v) : v);
      }}
      custom
    />
  );
}

export default Checkbox;
