import React from "react";

import i18next from "i18next";
import Form from "react-bootstrap/Form";

type Props = {
  onSearchChange: (value: string) => void;
  searchValue: string;
  className?: string;
  placeholder?: string;
};

export default function Searchable(props: Props) {
  const {
    searchValue,
    onSearchChange,
    className = "",
    placeholder = i18next.t(
      "translation:common.components.searchable.placeholder",
    ),
  } = props;

  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { target } = event;
    const { value } = target;
    onSearchChange(value);
  }

  return (
    <div className={className}>
      <Form.Control
        type="search"
        name="searchValue"
        placeholder={placeholder}
        value={searchValue}
        onChange={onChange}
      />
    </div>
  );
}
