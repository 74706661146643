import { commitMutation, Environment, graphql } from "react-relay";

import { CreateGlobalBusinessStackInput } from "./__generated__/CreateGlobalBusinessStackMutation.graphql";

const mutation = graphql`
  mutation CreateGlobalBusinessStackMutation(
    $input: CreateGlobalBusinessStackInput!
  ) {
    createGlobalBusinessStack(input: $input) {
      id
      isSelf
      stackDomainName
    }
  }
`;

export default (
  environment: Environment,
  input: CreateGlobalBusinessStackInput,
  callback: Function,
  onError: (error: Error) => void = (err: Error) => {
    alert(err);
  },
) => {
  const variables = {
    input,
  };

  return commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response: {}, errors: readonly any[] | null | undefined) => {
      callback(response, errors);
    },
    onError,
  });
};
