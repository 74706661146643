import { DayOfWeek } from "../../../../data/models/common";
import { AosQueries_AdvancedProfile_Fragment$data } from "./__generated__/AosQueries_AdvancedProfile_Fragment.graphql";
import { AosQueries_BasicProfile_Fragment$data } from "./__generated__/AosQueries_BasicProfile_Fragment.graphql";

export type DaypartTime = string[];

export type Daypart = {
  name: string;
  time: DaypartTime;
};

export type DaypartRank = {
  preference: number;
  dayOfWeek: DayOfWeek;
};

export type WeeklyDayparts = {
  daypart: Daypart;
  ranks: DaypartRank[];
};

export type TimeRangeRanksConfigs = {
  weeklyDaypartsData: WeeklyDayparts[];
  useGlobalPreference: boolean;
  globalPreference?: number | null;
};

export enum PlanningOrderType {
  Backward = "backward",
  Forward = "forward",
  Custom = "custom",
}

export type AosConfigBasic =
  {} & AosQueries_BasicProfile_Fragment$data["aosConfig"];
export type AosConfigAdvanced =
  {} & AosQueries_AdvancedProfile_Fragment$data["aosConfig"];
export type TransformedAosConfigBasic = AosConfigBasic & TimeRangeRanksConfigs;
