import React, { FunctionComponent, useState } from "react";

import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";
import { createFragmentContainer, Environment, graphql } from "react-relay";
import { Link, RouteComponentProps } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";

import { useModal } from "../../../contexts/ModalContext";
import Field from "../../common/Form/Field";
import FormLayout from "../../common/Form/FormLayout";
import FormLayoutFooter from "../../common/Form/FormLayoutFooter";
import { BigIntFieldType, StringFieldType } from "../../common/Form/models";
import HeaderPortal from "../../common/Portal/HeaderPortal";
import { CreateGlobalBusinessMutation$data } from "../mutations/__generated__/CreateGlobalBusinessMutation.graphql";
import { UpdateGlobalBusinessMutation$data } from "../mutations/__generated__/UpdateGlobalBusinessMutation.graphql";
import CreateGlobalBusinessMutation from "../mutations/CreateGlobalBusinessMutation";
import UpdateGlobalBusinessMutation from "../mutations/UpdateGlobalBusinessMutation";
import { Profile_globalBusiness$data } from "./__generated__/Profile_globalBusiness.graphql";
import GlobalBusinessConfirmDeleteModal from "./GlobalBusinessConfirmDeleteModal";

interface MatchParams {
  stack_id: string;
  global_business_id?: string;
}

type Props = RouteComponentProps<MatchParams> & {
  globalBusiness: Profile_globalBusiness$data | null;
  environment: Environment;
};

const validationRules = yup.object({
  name: yup.string().required(),
  employmentUidStart: yup.number().min(1),
});

const Profile: FunctionComponent<Props> = (props: Props) => {
  const {
    match: {
      params: { global_business_id: globalBusinessId, stack_id: stackId },
    },
    environment,
    globalBusiness,
  } = props;

  const { t } = useTranslation("global-business");
  const { showModal, hideModal } = useModal();

  const [base, setBase] = useState<Profile_globalBusiness$data>(
    globalBusiness || ({} as Profile_globalBusiness$data),
  );

  const isCreate = globalBusiness == null;

  const showDeleteModal = () => {
    showModal(
      <GlobalBusinessConfirmDeleteModal
        item={base}
        hideModal={hideModal}
        onDeletedCallback={() => {
          props.history.push(`/stack_global_business/${stackId}`);
        }}
      />,
    );
  };

  const handleSave = (
    changes: Partial<Profile_globalBusiness$data>,
    onError: (err: Error) => void,
    event: React.MouseEvent<HTMLButtonElement> | undefined,
    values: Profile_globalBusiness$data | undefined,
  ) => {
    if (!values) {
      return;
    }

    if (globalBusinessId) {
      UpdateGlobalBusinessMutation(
        environment,
        globalBusinessId,
        changes,
        (response: UpdateGlobalBusinessMutation$data) => {
          toast(t("form.savedSuccessfully"));
          setBase(response.updateGlobalBusiness as Profile_globalBusiness$data);
        },
        onError,
      );
    } else {
      CreateGlobalBusinessMutation(
        environment,
        values,
        (response: CreateGlobalBusinessMutation$data) => {
          toast(t("form.savedSuccessfully"));
          setBase(response.createGlobalBusiness as Profile_globalBusiness$data);
          props.history.push(`/stack_global_business/${stackId}`);
        },
        onError,
      );
    }
  };

  return (
    <>
      <div className="panel">
        <FormLayout<Profile_globalBusiness$data>
          isCreate={isCreate}
          base={base}
          onSave={handleSave}
          propertyList={[]}
          validationRules={validationRules}
        >
          <fieldset>
            <Row>
              <Field
                label={t("form.name")}
                md={4}
                lg={4}
                fieldKey="name"
                schemaFieldType={StringFieldType}
                description={t("form.nameDescription")}
              />
              <Field
                label={t("form.employmentUidStart")}
                md={4}
                lg={4}
                fieldKey="employmentUidStart"
                schemaFieldType={BigIntFieldType}
                description={t("form.nextUidDescription")}
                disabled={base.used}
              />
            </Row>
          </fieldset>
          <FormLayoutFooter isCreate={isCreate} onDelete={showDeleteModal} />
        </FormLayout>
      </div>
      <HeaderPortal>
        <Link to="/stacks">
          <span>{t("layout.stacks")}</span>
        </Link>
        <span className="ml-2 mr-2">&gt;</span>
        <Link to={`/stack_global_business/${stackId}`}>
          <span>{t("layout.title")}</span>
        </Link>
        <span className="ml-2 mr-2">&gt;</span>
        <span>
          {globalBusiness ? globalBusiness.name : t("layout.newCorporate")}
        </span>
      </HeaderPortal>
    </>
  );
};

export default createFragmentContainer(
  Profile,
  // Each key specified in this object will correspond to a prop available to the component
  {
    globalBusiness: graphql`
      fragment Profile_globalBusiness on GlobalBusiness {
        id
        name
        employmentUidStart
        used
      }
    `,
  },
);
