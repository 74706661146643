import { TFunction } from "i18next";
import intersection from "lodash/intersection";
import * as yup from "yup";

import {
  parseSchemaProperties,
  ServerValidationConfig,
  TransformDataRule,
} from "../../../common/Form/formUtilities";
import { IProperty } from "../../../common/Form/models";

// Specify fields to transform validation message from minutes to hours
export const transformDataRules: Record<string, TransformDataRule> = {
  weeklyWorkhoursMin: {
    from: "minute",
    to: "hour",
  },
  weeklyWorkhoursMax: {
    from: "minute",
    to: "hour",
  },
  shiftMin: {
    from: "minute",
    to: "hour",
    maxReference: yup.ref("shiftMax"),
  },
  shiftMax: {
    from: "minute",
    to: "hour",
  },
  shiftMinAbsolute: {
    from: "minute",
    to: "hour",
  },
  shiftGapMin: {
    from: "minute",
    to: "hour",
  },
  breakTrigger: {
    from: "minute",
    to: "hour",
  },
  breakMinStart: {
    from: "minute",
    to: "hour",
    maxReference: yup.ref("breakMaxStart"),
  },
  breakMaxStart: {
    from: "minute",
    to: "hour",
  },
  breakEndPad: {
    from: "minute",
    to: "hour",
  },
  "shiftConfig.custom_settings.weekly_workhours_min": {
    from: "minute",
    to: "hour",
    maxReference: yup.ref("weekly_workhours_max"),
  },
  "shiftConfig.custom_settings.weekly_workhours_max": {
    from: "minute",
    to: "hour",
  },
  "shiftConfig.custom_settings.shift_min": {
    from: "minute",
    to: "hour",
    maxReference: yup.ref("shift_max"),
  },
  "shiftConfig.custom_settings.shift_max": {
    from: "minute",
    to: "hour",
  },
};

function inIncludeRoles(this: yup.ArraySchema<any>, msg: string) {
  return this.test({
    name: "in-include-roles",
    message: msg,
    test: (value, schema) => {
      const includeRoles = schema.parent?.includeRoles ?? [];
      const valueArray = value ?? [];
      const intersect = intersection(valueArray, includeRoles);
      return intersect.length === valueArray.length;
    },
  });
}

/**
 * Validate max value if only upper limit is set
 * Example. aos break min start must be less than aos break max start (if it exists)
 */
function maxIfSet(
  this: yup.NumberSchema,
  max: number | ReturnType<typeof yup.ref> | null | undefined,
  msg: string,
) {
  return this.test({
    name: "max-if-set",
    message: msg,
    params: { max },
    test: (value, schema) => {
      if (max == null) {
        return true;
      }
      const upperLimit = schema.resolve(max) as number;

      if (upperLimit == null) {
        return true;
      }
      return value == null || value <= upperLimit;
    },
  });
}

yup.addMethod(yup.array, "inIncludeRoles", inIncludeRoles);
yup.addMethod(yup.number, "maxIfSet", maxIfSet);

export const getBasicAOSConfigSchema = (
  t: TFunction,
  aosConfigSchema: ServerValidationConfig,
  properties: IProperty[],
  ignoreMinMaxRule = false,
) => {
  const { properties: schemaProperties } = aosConfigSchema;

  // base rules
  const aosConfigRules = {
    minShiftPartLength: yup
      .number()
      .min(0)
      .test(
        "increment-of",
        t("aos:basic.property.minRoleDurationIncrement"),
        // eslint-disable-next-line func-names
        function (value?: number) {
          if (value == null) {
            return true;
          }
          return value % 30 === 0;
        },
      ),
    includeRoles: yup
      .array()
      .of(yup.string())
      .label(t("aos:basic.property.roles.schedulableRoles"))
      .required()
      .min(
        schemaProperties
          ? (schemaProperties.include_roles.minItems as number)
          : 0,
      )
      .ensure(),
    flexibleRole: yup
      .string()
      .nullable()
      .test(
        "not-in-include-roles",
        t("aos:basic.property.roles.notInIncludedRoles"),
        (value, schema) => {
          const includeRoles = (schema.parent?.includeRoles ?? []) as string[];
          const index = includeRoles.findIndex((i) => i === value);
          return index === -1;
        },
      ),
  };

  const { properties: aosConfigSchemaProperties } = aosConfigSchema;

  const pageProperties = new Set<string>(properties.map((p) => p.name));
  const validationSchema = parseSchemaProperties(
    t,
    aosConfigSchemaProperties as Record<string, ServerValidationConfig>,
    pageProperties,
    transformDataRules,
    ignoreMinMaxRule,
  );

  const serverValidationRules = validationSchema.schemaObject;

  return {
    aosConfigValidationRules: yup.object({
      ...serverValidationRules,
      ...aosConfigRules,
    }),
    defaults: validationSchema.defaults,
  };
};
