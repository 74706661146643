import React, { Suspense } from "react";

import { useTranslation } from "react-i18next";
import { PreloadedQuery } from "react-relay";

import { useBusinessContext } from "../../../../contexts/BusinessContext";
import Loader from "../../../common/Loader";
import HeaderPortal from "../../../common/Portal/HeaderPortal";
import { PeopleSettingsQueries_GetBusinessPeopleSettings_Query } from "./__generated__/PeopleSettingsQueries_GetBusinessPeopleSettings_Query.graphql";
import PeopleSettingsProfileForm from "./PeopleSettingsProfileForm";

type Props = {
  initialQueryRef: PreloadedQuery<PeopleSettingsQueries_GetBusinessPeopleSettings_Query>;
};

export default function PeopleSettingsProfile({ initialQueryRef }: Props) {
  const { t } = useTranslation("people-settings");
  const { business } = useBusinessContext();

  return (
    <>
      <HeaderPortal as="span">
        <span className="ml-2 mr-2">&gt;</span>
        <span>{t("nav.peopleSettings")}</span>
      </HeaderPortal>
      <Suspense fallback={<Loader />}>
        <PeopleSettingsProfileForm businessId={business.id} />
      </Suspense>
    </>
  );
}
