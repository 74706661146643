import { commitMutation, Environment, graphql } from "react-relay";

const mutation = graphql`
  mutation DeleteGlobalBusinessStackMutation($id: ID!) {
    deleteGlobalBusinessStack(id: $id)
  }
`;

export default (
  environment: Environment,
  id: string,
  callback: Function,
  onError: (error: Error) => void = (err: Error) => {
    alert(err);
  },
) => {
  const variables = {
    id,
  };

  return commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response: {}, errors: readonly any[] | null | undefined) => {
      callback(response, errors);
    },
    onError,
  });
};
