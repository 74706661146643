/**
 * @generated SignedSource<<8c29013b164d9ca7466853e20d102975>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type TimeOffTypesQueries_GetTimeOffTypes_Query$variables = {
  businessId: string;
};
export type TimeOffTypesQueries_GetTimeOffTypes_Query$data = {
  readonly timeOffTypes: {
    readonly nodes: ReadonlyArray<{
      readonly code: string | null;
      readonly createdAt: string;
      readonly deleted: boolean;
      readonly employmentTypeCodes: ReadonlyArray<string>;
      readonly id: string;
      readonly ignoreAutoReject: boolean;
      readonly isManagerByProxyOnly: boolean;
      readonly name: string;
      readonly paid: boolean;
      readonly shortName: string | null;
      readonly updatedAt: string;
    }>;
  };
};
export type TimeOffTypesQueries_GetTimeOffTypes_Query = {
  response: TimeOffTypesQueries_GetTimeOffTypes_Query$data;
  variables: TimeOffTypesQueries_GetTimeOffTypes_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "businessId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "businessId",
        "variableName": "businessId"
      }
    ],
    "concreteType": "TimeOffTypeConnection",
    "kind": "LinkedField",
    "name": "timeOffTypes",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "TimeOffType",
        "kind": "LinkedField",
        "name": "nodes",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "deleted",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "updatedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "shortName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "paid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ignoreAutoReject",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isManagerByProxyOnly",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "employmentTypeCodes",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TimeOffTypesQueries_GetTimeOffTypes_Query",
    "selections": (v1/*: any*/),
    "type": "InternalQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TimeOffTypesQueries_GetTimeOffTypes_Query",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "64bf7e9c82d59e97b8d31151967585da",
    "id": null,
    "metadata": {},
    "name": "TimeOffTypesQueries_GetTimeOffTypes_Query",
    "operationKind": "query",
    "text": "query TimeOffTypesQueries_GetTimeOffTypes_Query(\n  $businessId: ID!\n) {\n  timeOffTypes(businessId: $businessId) {\n    nodes {\n      id\n      deleted\n      createdAt\n      updatedAt\n      name\n      shortName\n      code\n      paid\n      ignoreAutoReject\n      isManagerByProxyOnly\n      employmentTypeCodes\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "92cd1dd384566fef03bcc219b126e102";

export default node;
