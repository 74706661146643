import { commitMutation, Environment, graphql } from "react-relay";

import { UpdateGlobalBusinessStackInput } from "./__generated__/UpdateGlobalBusinessStackMutation.graphql";

const mutation = graphql`
  mutation UpdateGlobalBusinessStackMutation(
    $id: ID!
    $input: UpdateGlobalBusinessStackInput!
  ) {
    updateGlobalBusinessStack(id: $id, input: $input) {
      id
      isSelf
      stackDomainName
    }
  }
`;

export default (
  environment: Environment,
  id: string,
  input: UpdateGlobalBusinessStackInput,
  callback: Function,
  onError: (error: Error) => void = (err: Error) => {
    alert(err);
  },
) => {
  const variables = {
    id,
    input,
  };

  return commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response: {}, errors: readonly any[] | null | undefined) => {
      callback(response, errors);
    },
    onError,
  });
};
