import React, { createContext, useCallback, useState } from "react";

import ReactDOM from "react-dom";

export interface ModalState {
  showModal: (component: React.ReactNode) => void;
  hideModal: () => void;
  show: boolean;
}

const ModalContext = createContext<ModalState>({} as ModalState);

const ModalProvider = (props: { children: React.ReactNode }) => {
  const [modal, setModal] = useState<React.ReactNode | undefined>(undefined);

  const showModal = useCallback((component: React.ReactNode) => {
    setModal(component);
  }, []);

  const hideModal = useCallback(() => {
    setModal(undefined);
  }, []);

  return (
    <ModalContext.Provider
      value={{
        showModal,
        hideModal,
        show: modal == null,
      }}
      {...props}
    >
      {props.children}
      {modal &&
        ReactDOM.createPortal(
          modal,
          document.getElementById("modal-root") as HTMLElement,
        )}
    </ModalContext.Provider>
  );
};

function withModalHoc<T extends ModalState = ModalState>(
  WrappedComponent: React.ComponentType<T>,
) {
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || "Component";

  const ComponentWithModal = (props: Omit<T, keyof ModalState>) => {
    return (
      <ModalContext.Consumer>
        {(modalProps: ModalState) => {
          return <WrappedComponent {...modalProps} {...(props as T)} />;
        }}
      </ModalContext.Consumer>
    );
  };

  ComponentWithModal.displayName = `withModal(${displayName})`;

  return ComponentWithModal;
}

export type WithModalHoc = ModalState;

const useModal = () => React.useContext(ModalContext);

export { ModalProvider, useModal, withModalHoc };
