import React from "react";

import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Trans, useTranslation } from "react-i18next";
import { Route } from "react-router-dom";

import ConfigurationService from "../../../configuration-service";
import { useModal } from "../../../contexts/ModalContext";
import { IUser } from "../../../data/models/common";
import { formatDate } from "../../../utils/utility";
import { ConfirmationModal } from "../ConfirmationModal";
import StackSelector from "./StackSelector";

import "./TopNav.scss";

type Props = {
  handleLogout: () => void;
  children?: React.ReactNode;
  user?: IUser;
};

export default function TopNav(props: Props) {
  const { handleLogout, user } = props;
  const { showModal, hideModal } = useModal();
  const { t } = useTranslation();

  const { branch, release, build, date } = ConfigurationService.config.version;
  const isReleaseBuild = branch?.includes("release/");

  const onAboutClick = () => {
    showModal(
      <ConfirmationModal
        onClose={hideModal}
        okClicked={hideModal}
        variant="primary"
        title={t("about.title")}
        okText={t("about.actions.close")}
        hideCancel
      >
        <p>
          {isReleaseBuild ? (
            <Trans
              i18nKey="about.version"
              values={{ version: release }}
              components={{ bold: <strong /> }}
            />
          ) : (
            <Trans
              i18nKey="about.version_dev"
              values={{ version: branch }}
              components={{ bold: <strong /> }}
            />
          )}
        </p>
        <p>
          <Trans
            i18nKey="about.build"
            values={{ buildNumber: build }}
            components={{ bold: <strong /> }}
          />
        </p>
        <p>
          <Trans
            i18nKey="about.date"
            values={{
              buildDate: formatDate(date, {
                toFormat: "dd MMM yyyy hh:mm a ZZZZ",
                showRelative: false,
              }),
            }}
            components={{ bold: <strong /> }}
          />
        </p>
      </ConfirmationModal>,
    );
  };

  return (
    <Navbar bg="white" variant="light" sticky="top" className="top-nav">
      <Navbar.Brand href="#" />

      <Nav className="mr-auto">
        <div className="topnav-header">
          <Route
            path={[
              "/stack/:stack_id/business/create",
              "/stack/:stack_id/businesses",
            ]}
            render={(routeProps: any) => <StackSelector {...routeProps} />}
          />
          {props.children}
        </div>
      </Nav>

      <Nav>
        <NavDropdown title={user?.email} id="basic-nav-dropdown">
          <NavDropdown.Item onSelect={onAboutClick}>
            {t("menuDropdown.about")}
          </NavDropdown.Item>
          <NavDropdown.Divider />

          <NavDropdown.Item onSelect={handleLogout}>
            {t("menuDropdown.logout")}
          </NavDropdown.Item>
        </NavDropdown>
      </Nav>
    </Navbar>
  );
}
