import React from "react";

import { FormikContextType } from "formik";
import cloneDeep from "lodash/cloneDeep";
import Button from "react-bootstrap/Button";
import styled from "styled-components";

const StyledButton = styled(Button)`
  max-width: 160px;
`;

type Props<T> = {
  formikContext: FormikContextType<any>;
  name: string;
  defaultValue?: T;
  maxItems?: number;
  disabled?: boolean;
};

export default function AddButton<T>(props: Props<T>) {
  const { formikContext, name, defaultValue, maxItems, disabled } = props;
  const helpers = formikContext.getFieldHelpers(name);
  const fields = formikContext.getFieldProps(name);

  const canAdd = maxItems == null || (fields.value || []).length < maxItems;
  return (
    <StyledButton
      size="sm"
      block
      variant="light"
      disabled={!canAdd || disabled}
      onClick={() => {
        if (!canAdd) {
          return;
        }

        const temp = cloneDeep(fields.value) || [];
        temp.push(defaultValue);
        helpers.setValue(temp);
      }}
    >
      +
    </StyledButton>
  );
}
