/**
 * @generated SignedSource<<d3dd4faf333b45536f884575117217f9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type EmploymentLayout_Query$variables = {
  businessId: string;
  employmentId: string;
};
export type EmploymentLayout_Query$data = {
  readonly employments: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly acceptedInvite: boolean;
        readonly deleted: boolean;
        readonly firstName: string;
        readonly id: string;
        readonly lastName: string;
        readonly userId: string | null;
      };
    }>;
  };
};
export type EmploymentLayout_Query = {
  response: EmploymentLayout_Query$data;
  variables: EmploymentLayout_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "businessId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "employmentId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "businessId",
        "variableName": "businessId"
      },
      {
        "items": [
          {
            "kind": "Variable",
            "name": "ids.0",
            "variableName": "employmentId"
          }
        ],
        "kind": "ListValue",
        "name": "ids"
      }
    ],
    "concreteType": "EmploymentConnection",
    "kind": "LinkedField",
    "name": "employments",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "EmploymentEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Employment",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "firstName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "userId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "acceptedInvite",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "deleted",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EmploymentLayout_Query",
    "selections": (v1/*: any*/),
    "type": "InternalQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EmploymentLayout_Query",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "4c4895dfe150c0a77d6afe8464956b8f",
    "id": null,
    "metadata": {},
    "name": "EmploymentLayout_Query",
    "operationKind": "query",
    "text": "query EmploymentLayout_Query(\n  $businessId: ID!\n  $employmentId: ID!\n) {\n  employments(businessId: $businessId, ids: [$employmentId]) {\n    edges {\n      node {\n        id\n        firstName\n        lastName\n        userId\n        acceptedInvite\n        deleted\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1b13e65c4538d70cba47846aeb8bdf62";

export default node;
