export type BusinessId = string;
export type Id = string;
export type StackCode = string;
export interface IBusiness {
  id: string;
  businessName: string;
  readonly aosGroups: ReadonlyArray<{
    readonly group: number | null;
    readonly name: string | null;
  }> | null;
}

export interface IUser {
  id: string;
  email: string;
}

export interface IStack {
  id: string;
  domainName: string;
  stackCode: StackCode;
}

export interface IEmployment {
  id: Id;
  firstName: string;
  lastName: string;
  userId: Id;
}

export type SessionInfo = {
  user?: IUser;
  authToken?: string;
  apiUrl: string;
};

export interface BaseOption<V, T> {
  label: string;
  value: V;
  data?: T;
}

export type Option<T> = BaseOption<string, T>;

export enum DayOfWeekEnum {
  Monday = "Monday",
  Tuesday = "Tuesday",
  Wednesday = "Wednesday",
  Thursday = "Thursday",
  Friday = "Friday",
  Saturday = "Saturday",
  Sunday = "Sunday",
}

export enum AvailabilityTypeEnum {
  Availability_NoPermanentWorkSchedule = "Availability_NoPermanentWorkSchedule",
  Availability_TemporaryAvailability = "Availability_TemporaryAvailability",
}

export const availabilityTypeOptions: BaseOption<string, string>[] = [
  {
    value: AvailabilityTypeEnum.Availability_NoPermanentWorkSchedule,
    label: "Permanent Work Schedule",
  },
  {
    value: AvailabilityTypeEnum.Availability_TemporaryAvailability,
    label: "Temporary Availability",
  },
];

export type DayOfWeek =
  | "Monday"
  | "Tuesday"
  | "Wednesday"
  | "Thursday"
  | "Friday"
  | "Saturday"
  | "Sunday";

export const daysOfWeek: Option<DayOfWeek>[] = [
  { value: DayOfWeekEnum.Monday, label: "Monday" },
  { value: DayOfWeekEnum.Tuesday, label: "Tuesday" },
  { value: DayOfWeekEnum.Wednesday, label: "Wednesday" },
  { value: DayOfWeekEnum.Thursday, label: "Thursday" },
  { value: DayOfWeekEnum.Friday, label: "Friday" },
  { value: DayOfWeekEnum.Saturday, label: "Saturday" },
  { value: DayOfWeekEnum.Sunday, label: "Sunday" },
];

export const daysOfWeekAbbreviated: BaseOption<DayOfWeek, number>[] = [
  { value: DayOfWeekEnum.Monday, label: "Mon" },
  { value: DayOfWeekEnum.Tuesday, label: "Tue" },
  { value: DayOfWeekEnum.Wednesday, label: "Wed" },
  { value: DayOfWeekEnum.Thursday, label: "Thu" },
  { value: DayOfWeekEnum.Friday, label: "Fri" },
  { value: DayOfWeekEnum.Saturday, label: "Sat" },
  { value: DayOfWeekEnum.Sunday, label: "Sun" },
];

export const GraphQLDateFormat = "yyyy-MM-dd HH:mm:ss z";
export const InvalidClassName = "is-invalid";

export type IDMConfigServer = {
  url: string;
  name: string;
};

export type VersionConfig = {
  release: string;
  build: string | null;
  branch: string | null;
  date: string | null;
};

export type JabberConfig = {
  xmppPort: number;
};

export type BugsnagConfig = {
  apiKey: string;
  notify: boolean;
};

export type GoogleConfig = {
  mapsApiKey: string;
  analyticsApiKey: string;
};

export type IDMConfig<Servers = Record<string, IDMConfigServer>> = {
  default: keyof Servers;
  servers: Servers;
};

export type MarketingConfig = {
  url: string;
};

export type ConfigurationObject<Servers = Record<string, IDMConfigServer>> = {
  environment: string;
  target: string;
  debug: boolean;
  stack: string;
  version: VersionConfig;
  marketing: MarketingConfig;
  idm: IDMConfig<Servers>;
  bugsnag: BugsnagConfig;
};
