import React from "react";

import { useTranslation } from "react-i18next";
import styled from "styled-components";

import Field from "../components/common/Form/Field";
import {
  BooleanFieldType,
  ComponentRule,
} from "../components/common/Form/models";
import Switch from "../components/common/Form/Switch";

const CheckboxWrapper = styled.div`
  .form-group {
    margin-bottom: 9px;
  }
`;

export function useTimeOffShiftComponentRules(): Record<string, ComponentRule> {
  const { t } = useTranslation("people-settings");

  // These are object keys of the EmplTypeTimeOffComplianceEnabled type
  const employmentTypes = ["partTime", "casual", "fullTime", "permanent"];

  const employmentTypeComponentKeys = employmentTypes.map(
    (employmentType) => `emplTypeTimeOffComplianceEnabled.${employmentType}`,
  );

  return {
    timeOffShiftsEnabled: {
      component: Switch,
      label: undefined,
      hideLabel: true,
      lg: 3,
      componentProps: {
        boldLabels: true,
        onLabel: t("translation:switch.enable"),
        offLabel: t("translation:switch.disable"),
      },
      onValueChanged: (value: boolean, formikContext) => {
        // If time_off_shifts_enabled is set to true, enable all employment types
        // Else disable all of them
        employmentTypeComponentKeys.forEach((key) => {
          formikContext.setFieldValue(key, value);
        });
      },
    },

    // Not sure of an easier way of rendering these in a custom way without passing a flag to the field (isRenderedByParentComponent)
    // If anyone thinks of a better way, feel free to refactor this :)
    ...employmentTypeComponentKeys.reduce((acc, componentKey) => {
      return {
        ...acc,
        [componentKey]: {
          isRenderedByParentComponent: true,
          xs: 12,
          md: 12,
          lg: 12,
          componentProps: {
            xs: 12,
            md: 12,
            lg: 12,
          },
        },
      };
    }, {}),
    timeOffShiftNotificationsEnabled: {
      disabled: (values) => !values.timeOffShiftsEnabled,
    },
    emplTypeTimeOffComplianceEnabled: {
      disabled: (values) => !values.timeOffShiftsEnabled,
      component: (p) => {
        return (
          <CheckboxWrapper>
            {employmentTypes.map((typeCode) => (
              <Field
                key={`emplTypeTimeOffComplianceEnabled.${typeCode}`}
                label={t(`form.employmentTypes.${typeCode}`)}
                fieldKey={`emplTypeTimeOffComplianceEnabled.${typeCode}`}
                schemaFieldType={BooleanFieldType}
                hideLabel={false}
                disabled={p?.disabled}
                xs={12}
                md={12}
                lg={12}
              />
            ))}
          </CheckboxWrapper>
        );
      },
    },
  };
}
