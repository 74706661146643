/**
 * @generated SignedSource<<f2ba8e12e726f086116b17eb0e46546d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type EmploymentTypeEnum = "casual" | "full_time" | "part_time" | "permanent" | "%future added value";
export type LegacyBreakTypeEnum = "meal" | "rest" | "unknown" | "%future added value";
export type BreakTypeQueries_GetBusinessBreakTypes_Query$variables = {
  businessId: string;
  includeDeleted?: boolean | null;
  search?: string | null;
};
export type BreakTypeQueries_GetBusinessBreakTypes_Query$data = {
  readonly breakTypes: {
    readonly nodes: ReadonlyArray<{
      readonly availableRange: {
        readonly rangeEndTime: any;
        readonly rangeStartTime: any;
      } | null;
      readonly breakScreenOrdering: number;
      readonly breakTypeCode: string;
      readonly canOverrideClockedPaidBreak: boolean;
      readonly code: string | null;
      readonly createdAt: string;
      readonly defaultDuration: any | null;
      readonly deleted: boolean;
      readonly description: string | null;
      readonly employmentMetadata: string | null;
      readonly employmentTypeCodes: ReadonlyArray<string>;
      readonly employmentTypes: ReadonlyArray<EmploymentTypeEnum>;
      readonly id: string;
      readonly internalAccess: boolean;
      readonly legacyBreakType: LegacyBreakTypeEnum;
      readonly name: string;
      readonly paidThreshold: any;
      readonly schedulable: boolean;
      readonly updatedAt: string;
    }>;
  };
};
export type BreakTypeQueries_GetBusinessBreakTypes_Query = {
  response: BreakTypeQueries_GetBusinessBreakTypes_Query$data;
  variables: BreakTypeQueries_GetBusinessBreakTypes_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "businessId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "includeDeleted"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "businessId",
        "variableName": "businessId"
      },
      {
        "kind": "Variable",
        "name": "includeDeleted",
        "variableName": "includeDeleted"
      },
      {
        "kind": "Variable",
        "name": "search",
        "variableName": "search"
      }
    ],
    "concreteType": "BreakTypeConnection",
    "kind": "LinkedField",
    "name": "breakTypes",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BreakType",
        "kind": "LinkedField",
        "name": "nodes",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "HoursMinutesRange",
            "kind": "LinkedField",
            "name": "availableRange",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "rangeStartTime",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "rangeEndTime",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "breakScreenOrdering",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "breakTypeCode",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "canOverrideClockedPaidBreak",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "defaultDuration",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "deleted",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "employmentMetadata",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "employmentTypes",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "employmentTypeCodes",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "legacyBreakType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "paidThreshold",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "schedulable",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "updatedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "internalAccess",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "BreakTypeQueries_GetBusinessBreakTypes_Query",
    "selections": (v3/*: any*/),
    "type": "InternalQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "BreakTypeQueries_GetBusinessBreakTypes_Query",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "7cafe12d1afb35c74266a60d30519d45",
    "id": null,
    "metadata": {},
    "name": "BreakTypeQueries_GetBusinessBreakTypes_Query",
    "operationKind": "query",
    "text": "query BreakTypeQueries_GetBusinessBreakTypes_Query(\n  $businessId: ID!\n  $search: String\n  $includeDeleted: Boolean\n) {\n  breakTypes(businessId: $businessId, search: $search, includeDeleted: $includeDeleted) {\n    nodes {\n      availableRange {\n        rangeStartTime\n        rangeEndTime\n      }\n      breakScreenOrdering\n      breakTypeCode\n      canOverrideClockedPaidBreak\n      code\n      createdAt\n      defaultDuration\n      deleted\n      description\n      employmentMetadata\n      employmentTypes\n      employmentTypeCodes\n      id\n      legacyBreakType\n      name\n      paidThreshold\n      schedulable\n      updatedAt\n      internalAccess\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "31f7a751ce96c99189d1770b1f77b061";

export default node;
