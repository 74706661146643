import React from "react";

import Nav from "react-bootstrap/Nav";
import { useTranslation } from "react-i18next";
import { LinkContainer } from "react-router-bootstrap";
import { Link, Route, RouteComponentProps, useParams } from "react-router-dom";

import Loader from "../../../../../common/Loader";
import HeaderPortal, {
  HeaderPortalBreadcrumbs,
} from "../../../../../common/Portal/HeaderPortal";
import CorporatesAuthMethodProfile from "./CorporatesAuthMethodProfile";
import { useCorporateAuthMethodById } from "./CorporatesAuthMethodsQueries";

type Props = RouteComponentProps<{
  auth_method_id?: string;
}>;

export default function CorporateAuthMethodsLayout({ match, location }: Props) {
  const { t } = useTranslation("corporates-auth-methods");
  return (
    <>
      <header className="sub-header-container">
        <Nav variant="tabs">
          <LinkContainer exact to={`${match.url}`}>
            <Nav.Link>{t("layout.nav.settings")}</Nav.Link>
          </LinkContainer>
        </Nav>
      </header>
      <React.Suspense fallback={<Loader />}>
        <RoutesWithData match={match} />
      </React.Suspense>
    </>
  );
}

const RoutesWithData = ({ match }: Pick<Props, "match">) => {
  const { t } = useTranslation("corporates-auth-methods");

  const { auth_method_id: authMethodId } = useParams<{
    auth_method_id?: string;
  }>();
  const goBackUrl = `${match.url.split(`/auth_methods`)[0]}/auth_methods`;

  // No authMethod will be available if we're creating a new one
  const authMethod = useCorporateAuthMethodById(authMethodId);

  return (
    <>
      <HeaderPortal as="span">
        <HeaderPortalBreadcrumbs
          breadcrumbs={[
            <Link to={goBackUrl}>
              <span>{t("layout.title")}</span>
            </Link>,
            <span>
              {authMethod ? authMethod.displayName : t("layout.new")}
            </span>,
          ]}
        />
      </HeaderPortal>
      <div className="mt-3">
        <Route
          exact
          path={`${match.path}`}
          component={CorporatesAuthMethodProfile}
        />
      </div>
    </>
  );
};
