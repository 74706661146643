import React, { useEffect, useState } from "react";

import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { useTranslation } from "react-i18next";
import { Environment } from "react-relay";
import { RouteComponentProps } from "react-router-dom";
import styled from "styled-components";

import { useModal } from "../../../contexts/ModalContext";
import { Id } from "../../../data/models/common";
import UserService from "../../IDM/internal/Account/AccountService";
import LinkToUserModal from "./LinkToUserModal";
import { User } from "./models";
import UnlinkUserModal from "./UnlinkUserModal";

interface EmploymentInfo {
  id: Id;
  firstName: string;
  lastName: string;
  userId: Id;
}

interface MatchParams {
  business_id: string;
  employment_id: string;
  stack_id: string;
}

type Props = RouteComponentProps<MatchParams> & {
  employment?: EmploymentInfo;
  environment: Environment;
};

const StyledDropdown = styled(DropdownButton)`
  position: absolute;
  right: 0;
  top: 4px;
`;

export default function EmploymentActionsDropdown(props: Props) {
  const { t } = useTranslation("employment");
  const [linkedUser, setLinkedUser] = useState<User | null>(null);
  const { showModal, hideModal } = useModal();
  const {
    employment,
    match: {
      params: { business_id: businessId },
    },
  } = props;

  async function fetchLinkedUser(userId: Id | null) {
    if (!userId) {
      return;
    }

    const user = await UserService.fetchUserById(userId);
    setLinkedUser(user);
  }

  const showUnlinkUserModal = () => {
    showModal(
      <UnlinkUserModal
        linkedUser={linkedUser}
        businessId={businessId}
        onOk={() => {
          setLinkedUser(null);
        }}
        hideModal={hideModal}
      />,
    );
  };

  const showLinkUserModal = () => {
    showModal(
      <LinkToUserModal
        hideModal={hideModal}
        linkedUser={linkedUser}
        employment={employment}
        businessId={businessId}
        onOk={(e: React.MouseEvent<HTMLButtonElement>, newLinkedUser: User) => {
          setLinkedUser(newLinkedUser);
        }}
      />,
    );
  };

  useEffect(() => {
    fetchLinkedUser(employment?.userId ?? null);
  }, [employment]);

  return (
    <StyledDropdown
      alignRight
      size="sm"
      title={t("employmentActionsDropdown.title")}
    >
      <Dropdown.Item onSelect={showLinkUserModal}>
        {t("employmentActionsDropdown.actions.linkToUser")}
      </Dropdown.Item>
      {linkedUser ? (
        <Dropdown.Item onSelect={showUnlinkUserModal}>
          {t("employmentActionsDropdown.actions.unlinkFromUser", {
            email: linkedUser.email,
          })}
        </Dropdown.Item>
      ) : null}
    </StyledDropdown>
  );
}
