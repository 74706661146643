import React from "react";

import { FormikHelpers } from "formik";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-relay";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";

import { useModal } from "../../../../../../contexts/ModalContext";
import { ConfirmationModal } from "../../../../../common/ConfirmationModal";
import Field from "../../../../../common/Form/Field";
import FormLayout from "../../../../../common/Form/FormLayout";
import FormLayoutFooter from "../../../../../common/Form/FormLayoutFooter";
import { StringFieldType } from "../../../../../common/Form/models";
import MultiSelectList, {
  MultiSelectType,
} from "../../../../../common/Form/MultiSelectList";
import { useAppContext } from "../../../../external/Context/AppContext";
import {
  CreateCorporateMutation,
  DeleteCorporateMutation,
  UpdateCorporateMutation,
  useCorporateById,
} from "../../CorporatesQueries";

const validationRules = yup.object({
  name: yup.string().required(),
});

export default function CorporatesProfile({ match }: any) {
  const history = useHistory();
  const goBackUrl = `${match.path.split(`/corporates`)[0]}/corporates`;

  const { showModal, hideModal } = useModal();
  const { t } = useTranslation("corporates");
  const { corporate_id: corporateId } = useParams<{ corporate_id?: string }>();

  const [
    corporate,
    formData,
    isCreate,
    { deleteMutation, createOrUpdateMutation },
  ] = useCorporateData(corporateId);
  const stackOptions = useCorporateStackOptions();

  const onSave = (
    changes: Partial<any>,
    errorHandler: (error: Error) => void,
    event?: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    values?: any,
    helpers?: FormikHelpers<any>,
  ) => {
    createOrUpdateMutation({
      variables: {
        ...(!isCreate && { id: corporateId }),
        input: changes,
      },
      onCompleted: () => {
        toast(
          isCreate
            ? t("translation:form.notifications.created_successfully")
            : t("translation:form.notifications.saved_successfully"),
        );
        history.replace(goBackUrl);
      },
      onError(error: Error) {
        errorHandler(error);
      },
    });
  };

  const onDelete = () => {
    if (corporate) {
      showModal(
        <ConfirmationModal
          onClose={hideModal}
          okClicked={() => {
            deleteMutation({
              variables: {
                id: corporateId,
              },
              onCompleted: () => {
                toast(t("translation:form.notifications.deleted_successfully"));
                history.replace(goBackUrl);
              },
              onError(error: Error) {
                alert(error);
              },
            });
          }}
          variant="danger"
          title={t("profile.deleteModal.title", {
            name: corporate.name,
          })}
          okText={t("profile.deleteModal.buttons.yes")}
        >
          {t("profile.deleteModal.message")}
        </ConfirmationModal>,
      );
    }
  };

  return (
    <Card body>
      <FormLayout
        base={formData}
        onSave={onSave}
        validationRules={validationRules}
        propertyList={[]}
        isCreate
      >
        <fieldset>
          <Row>
            <Field
              fieldKey="name"
              label={t("profile.property.name.value")}
              schemaFieldType={StringFieldType}
            />
            <Field
              fieldKey="stackIds"
              label={t("profile.property.stacks.value")}
              component={MultiSelectList}
              componentProps={{
                allOptions: stackOptions,
                selectableOptions: stackOptions,
                menuPlacement: "top",
                multiStyle: MultiSelectType.Pill,
              }}
            />
          </Row>
        </fieldset>
        <FormLayoutFooter onDelete={onDelete} isCreate={isCreate} />
      </FormLayout>
    </Card>
  );
}

function useCorporateStackOptions() {
  const stacks = useAppContext()?.stacks ?? [];

  return stacks.map((stack) => ({
    label: stack.stackCode,
    value: stack.id,
  }));
}

function useCorporateData(corporateId?: string) {
  const [deleteMutation] = useMutation(DeleteCorporateMutation);
  const [createMutation] = useMutation(CreateCorporateMutation);
  const [updateMutation] = useMutation(UpdateCorporateMutation);

  const corporate = useCorporateById(corporateId);

  const baseFormData = corporate
    ? {
        name: corporate.name,
        stackIds: corporate.corporateStacks.map((x) => x.stack.id),
      }
    : {};
  const isCreate = corporate == null;

  return [
    corporate,
    baseFormData,
    isCreate,
    {
      deleteMutation,
      createOrUpdateMutation: isCreate ? createMutation : updateMutation,
    },
  ] as const;
}
