/**
 * @generated SignedSource<<335c33e2e22f3e468dd120b0f7bc69ce>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BusinessQueries_InternalQuery$variables = {
  after?: string | null;
  pageSize: number;
  searchValue: string;
};
export type BusinessQueries_InternalQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"BusinessQueries_ListFragment">;
};
export type BusinessQueries_InternalQuery = {
  response: BusinessQueries_InternalQuery$data;
  variables: BusinessQueries_InternalQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pageSize"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "searchValue"
},
v3 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "pageSize"
  },
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "searchValue"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "BusinessQueries_InternalQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "BusinessQueries_ListFragment"
      }
    ],
    "type": "InternalQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "BusinessQueries_InternalQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "BusinessConnection",
        "kind": "LinkedField",
        "name": "businesses",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BusinessEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Business",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "businessName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "shortDescription",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "globalBusinessId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v3/*: any*/),
        "filters": [
          "search"
        ],
        "handle": "connection",
        "key": "BusinessTable_businesses",
        "kind": "LinkedHandle",
        "name": "businesses"
      }
    ]
  },
  "params": {
    "cacheID": "6297c6a2adebfbcf9f1be00b307540ca",
    "id": null,
    "metadata": {},
    "name": "BusinessQueries_InternalQuery",
    "operationKind": "query",
    "text": "query BusinessQueries_InternalQuery(\n  $searchValue: String!\n  $pageSize: Int!\n  $after: String\n) {\n  ...BusinessQueries_ListFragment\n}\n\nfragment BusinessQueries_ListFragment on InternalQuery {\n  businesses(search: $searchValue, first: $pageSize, after: $after) {\n    edges {\n      node {\n        id\n        businessName\n        shortDescription\n        globalBusinessId\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "09e6f0258e657db964cc60adeecb667e";

export default node;
