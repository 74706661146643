const grey100 = "#FAFAFA";
const grey200 = "#EEEEEE";
const grey300 = "#D8D8D8";
const grey400 = "#B3B3B3";
const grey500 = "#9B9B9B";
const grey600 = "#8C8C8C";
const grey700 = "#6C6C6C";
const grey800 = "#5E5E5E";
const bluePrimary = "#2587F7";
const white = "#FFFFFF";
const defaultRed = "#F84C3B";
const defaultGreen = "#83B324";

const defaultTheme = {
  // generic color variables
  white,
  grey100,
  grey200,
  grey300,
  grey400,
  grey500,
  grey600,
  grey700,
  grey800,
  bluePrimary,
  success: defaultGreen,
  error: defaultRed,

  // generic styles - use across multiple components
  borderColor: grey200,

  // colors from design system
  generic: {
    primary: {
      default: "#2587F7",
    },
    foreground: {
      icon: {
        primary: "#6C6C6C",
      },
    },

    yellow: "#f5a623",
    green: "#83b324",
    limeGreen: "#aeda55",
    red: "#f84c3b",
    grey: "#EFF1F3",
    text: {
      darkGrey: "#333",
    },
    border: {
      default: "#D8D8D8",
      dark: "#CBCBCB",
    },
  },

  // component specific
  sidebar: {
    color: white,
  },
  footer: {
    backgroundColor: grey200,
  },
};

export default defaultTheme;
