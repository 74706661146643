import React, { Suspense, useEffect, useState } from "react";

import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";
import { PreloadedQuery, useQueryLoader } from "react-relay";

import { useAppRouter } from "../../../hooks/useAppRouter";
import { useDebouncedValue } from "../../../hooks/useDebouncedValue";
import {
  LayoutContextType,
  useScheduleBusinessContextCheck,
} from "../../../hooks/useScheduleBusinessContextCheck";
import Checkbox from "../../common/Form/Checkbox";
import Loader from "../../common/Loader";
import HeaderPortal from "../../common/Portal/HeaderPortal";
import Searchable from "../../common/Searchable";
import { BreakTypeQueries_GetBusinessBreakTypes_Query } from "./__generated__/BreakTypeQueries_GetBusinessBreakTypes_Query.graphql";
import { BreakTypeQueries_GetScheduleBreakTypes_Query } from "./__generated__/BreakTypeQueries_GetScheduleBreakTypes_Query.graphql";
import {
  GetBusinessBreakTypesQuery,
  GetScheduleBreakTypesQuery,
} from "./BreakTypeQueries";
import BreakTypesTable from "./BreakTypesTable";

type ScheduleOrBusinessBreakTypesQuery =
  | PreloadedQuery<BreakTypeQueries_GetScheduleBreakTypes_Query>
  | PreloadedQuery<BreakTypeQueries_GetBusinessBreakTypes_Query>;

type Props = {
  initialQueryRef: ScheduleOrBusinessBreakTypesQuery;
};

export default function BreakTypes({ initialQueryRef }: Props) {
  const router = useAppRouter();
  const { t } = useTranslation("break-types");

  const pageContext = useScheduleBusinessContextCheck();
  const isScheduleContext = pageContext.type === LayoutContextType.Schedule;

  const [
    queryReference,
    { search, showRemoved, clearFilters, filtersChanged },
  ] = useBreakTypesPreloadedQuery(initialQueryRef);

  const onNewBreakTypeClicked = () => {
    router.push("break_types/create");
  };

  return (
    <>
      <HeaderPortal>
        <span className="ml-2 mr-2">&gt;</span>
        <span>{t("nav.breakTypes")}</span>
      </HeaderPortal>
      <Row className="align-baseline">
        <Col md={5} className="border-right">
          <Searchable
            className="employments"
            searchValue={search.value}
            onSearchChange={search.setValue}
            placeholder={t("table.search")}
          />
        </Col>
        <Col md={4}>
          <Row>
            {!isScheduleContext && (
              <Checkbox
                fieldKey="deactivated-checkbox"
                className="ml-3 align-self-center"
                value={showRemoved.value}
                label={t("table.remove")}
                onChange={showRemoved.setValue}
              />
            )}
            <Button
              className="ml-4"
              variant="outline-primary"
              onClick={clearFilters}
              disabled={!filtersChanged}
            >
              {t("table.clearFilters")}
            </Button>
          </Row>
        </Col>

        {pageContext.type === LayoutContextType.Business && (
          <Col md={3} className="d-flex justify-content-end">
            <Button variant="primary" onClick={onNewBreakTypeClicked}>
              {t("table.newBreakType")}
            </Button>
          </Col>
        )}
      </Row>

      <Card className="mt-4" body>
        {queryReference != null && (
          <Suspense fallback={<Loader />}>
            <BreakTypesTable
              queryReference={
                queryReference as ScheduleOrBusinessBreakTypesQuery
              }
              isScheduleContext={isScheduleContext}
            />
          </Suspense>
        )}
      </Card>
    </>
  );
}

const defaultFilters = {
  showRemoved: true,
  searchValue: "",
};

function useBreakTypesPreloadedQuery(initialQueryRef: any) {
  const [searchValue, debouncedSearchValue, setSearchValue] = useDebouncedValue(
    defaultFilters.searchValue,
  );
  const [showRemoved, setShowRemoved] = useState(defaultFilters.showRemoved);

  const { businessId, scheduleId } = useScheduleBusinessContextCheck();
  const isScheduleContext = scheduleId != null;

  // Ensure that the right query is used if in schedule / business context
  const [queryReference, loadQuery] = useQueryLoader(
    isScheduleContext ? GetScheduleBreakTypesQuery : GetBusinessBreakTypesQuery,
    initialQueryRef,
  );

  const clearFilters = () => {
    setShowRemoved(true);

    // When clearing filters, you don't want to debounce the value change
    setSearchValue("", false);
  };

  useEffect(() => {
    if (businessId) {
      loadQuery(
        {
          businessId,
          ...(isScheduleContext && { scheduleId }),
          includeDeleted: showRemoved,
          search: debouncedSearchValue,
        },
        {
          fetchPolicy: "network-only",
        },
      );
    }
  }, [
    businessId,
    isScheduleContext,
    scheduleId,
    loadQuery,
    showRemoved,
    debouncedSearchValue,
  ]);

  const filters = {
    search: {
      value: searchValue,
      setValue: setSearchValue,
    },
    showRemoved: {
      value: showRemoved,
      setValue: setShowRemoved,
    },
    filtersChanged:
      showRemoved !== defaultFilters.showRemoved ||
      searchValue !== defaultFilters.searchValue,
    clearFilters,
  };

  return [queryReference, filters] as const;
}
