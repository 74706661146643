/**
 * @generated SignedSource<<f7ffdb93eaf4ea770eebef0f7dc3518b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type EmploymentMetadataQueries_GetEmploymentMetadata_Query$variables = {
  businessId: string;
  employmentId?: string | null;
  timeboxed?: boolean | null;
};
export type EmploymentMetadataQueries_GetEmploymentMetadata_Query$data = {
  readonly employmentMetadata: {
    readonly __id: string;
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly createdAt: string;
        readonly details: any;
        readonly employmentId: string;
        readonly endTime: string | null;
        readonly id: string;
        readonly metadataTypeId: string;
        readonly startTime: string | null;
        readonly updatedAt: string;
      };
    }>;
  };
};
export type EmploymentMetadataQueries_GetEmploymentMetadata_Query = {
  response: EmploymentMetadataQueries_GetEmploymentMetadata_Query$data;
  variables: EmploymentMetadataQueries_GetEmploymentMetadata_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "businessId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "employmentId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "timeboxed"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "businessId",
    "variableName": "businessId"
  },
  {
    "kind": "Variable",
    "name": "employmentId",
    "variableName": "employmentId"
  },
  {
    "kind": "Variable",
    "name": "timeboxed",
    "variableName": "timeboxed"
  }
],
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "EmploymentMetadataEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "EmploymentMetadata",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "updatedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "employmentId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "details",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "metadataTypeId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "startTime",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "endTime",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cursor",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "PageInfo",
    "kind": "LinkedField",
    "name": "pageInfo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "endCursor",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasNextPage",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "kind": "ClientExtension",
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__id",
        "storageKey": null
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EmploymentMetadataQueries_GetEmploymentMetadata_Query",
    "selections": [
      {
        "alias": "employmentMetadata",
        "args": (v1/*: any*/),
        "concreteType": "EmploymentMetadataConnection",
        "kind": "LinkedField",
        "name": "__connection_employmentMetadata_connection",
        "plural": false,
        "selections": (v2/*: any*/),
        "storageKey": null
      }
    ],
    "type": "InternalQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EmploymentMetadataQueries_GetEmploymentMetadata_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EmploymentMetadataConnection",
        "kind": "LinkedField",
        "name": "employmentMetadata",
        "plural": false,
        "selections": (v2/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "filters": [
          "businessId",
          "employmentId",
          "timeboxed"
        ],
        "handle": "connection",
        "key": "connection_employmentMetadata",
        "kind": "LinkedHandle",
        "name": "employmentMetadata"
      }
    ]
  },
  "params": {
    "cacheID": "2394a0f46a9aad532d6fe8e2af55c354",
    "id": null,
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "employmentMetadata"
          ]
        }
      ]
    },
    "name": "EmploymentMetadataQueries_GetEmploymentMetadata_Query",
    "operationKind": "query",
    "text": "query EmploymentMetadataQueries_GetEmploymentMetadata_Query(\n  $businessId: ID!\n  $employmentId: ID\n  $timeboxed: Boolean\n) {\n  employmentMetadata(businessId: $businessId, employmentId: $employmentId, timeboxed: $timeboxed) {\n    edges {\n      node {\n        id\n        createdAt\n        updatedAt\n        employmentId\n        details\n        metadataTypeId\n        startTime\n        endTime\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "568a3eff5ff502a228e79a03581b5bd7";

export default node;
