import i18next from "i18next";

export type ValidationError = {
  parameter: string;
  detail: string;
  label: string;
};

export type ErrorExtensions = {
  code: ServerErrorCode;
  detail: string;
  errors: Record<string, any>[];
};

export type ServerErrorItem = {
  extensions?: ErrorExtensions;
  message: string;
};

export enum ServerErrorCode {
  ValidationError = "VALIDATION_ERROR",
  AuthenticationError = "AUTHENTICATION_ERROR",
  AuthorizationError = "AUTHORIZATION_ERROR",
  GeneralError = "GENERAL_ERROR",
  Unknown = "UNKNOWN",
  GlobalBusinessError = "GLOBAL_BUSINESS_ERROR",
}

export default class ServerError extends Error {
  public errors: ServerErrorItem[] = [];

  public code: ServerErrorCode;

  public invalidParams: Map<string, ValidationError[]> = new Map<
    string,
    ValidationError[]
  >();

  constructor(public source: Record<string, any>) {
    super(
      source && source.errors && source.errors[0]
        ? (source.errors[0] as Error).message
        : i18next.t("common.unknownError"),
    );

    this.errors = source.errors || [];
    this.errors.forEach((item: ServerErrorItem) => {
      const { extensions, message } = item;
      if (extensions) {
        const { code, errors } = extensions;
        switch (code) {
          case ServerErrorCode.ValidationError: {
            if (errors) {
              errors.forEach((i) => {
                const validationError = i as ValidationError;
                const { parameter } = validationError;
                if (!this.invalidParams.has(parameter)) {
                  this.invalidParams.set(parameter, []);
                }

                const parameterError = this.invalidParams.get(parameter);
                if (parameterError) {
                  parameterError.push(validationError);
                }
              });
            }

            break;
          }

          case ServerErrorCode.GeneralError:
          default: {
            this.message = message;
            break;
          }
        }
      }
    });

    this.code = this.errors[0].extensions?.code ?? ServerErrorCode.Unknown;
  }

  public getErrorDetails(): string {
    const validations = this.invalidParams
      ? Array.from(this.invalidParams.values())
      : [];

    if (validations.length === 0) {
      return this.message;
    }
    return validations.reduce((r: string, errors: ValidationError[]) => {
      r += errors.map((i) => ` ${i.label}: ${i.detail}`);
      return r;
    }, "");
  }
}
