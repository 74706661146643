import React, { FunctionComponent } from "react";

import Nav from "react-bootstrap/Nav";
import { useTranslation } from "react-i18next";
import { LinkContainer } from "react-router-bootstrap";
import { Link, Route, RouteComponentProps } from "react-router-dom";

import HeaderPortal from "../../../common/Portal/HeaderPortal";
import GlobalBusiness from "../../../Stack/GlobalBusiness/GlobalBusiness";
import StackPeople from "../../../Stack/StackPeople/StackPeople";
import { useAppContext } from "../../external/Context/AppContext";
import StackProfile from "./StackProfile";

interface MatchParams {
  stack_id: string;
}

type Props = RouteComponentProps<MatchParams> & {};

const StackLayout: FunctionComponent<Props> = (props: Props) => {
  const { match } = props;
  const { stack_id: stackId } = match.params;

  const { t } = useTranslation("stacks");
  const { myStacksById } = useAppContext();
  const hasStackAccess = myStacksById?.has(stackId);

  return (
    <>
      <header className="sub-header-container">
        <Nav variant="tabs">
          <LinkContainer to={`/stack/${stackId}`} exact>
            <Nav.Link>{t("layout.nav.settings")}</Nav.Link>
          </LinkContainer>
          {hasStackAccess && (
            <>
              <LinkContainer to={`/stack_people/${stackId}`} exact>
                <Nav.Link>{t("layout.nav.people")}</Nav.Link>
              </LinkContainer>

              <LinkContainer to={`/stack_global_business/${stackId}`} exact>
                <Nav.Link>{t("layout.nav.globalBusiness")}</Nav.Link>
              </LinkContainer>
            </>
          )}
        </Nav>
      </header>
      <div className="mt-3">
        <Route path="/stack" render={() => <StackProfile {...props} />} />
        <Route
          path="/stack_people"
          render={() => <StackPeople {...(props as any)} />}
        />
        <Route
          path="/stack_global_business"
          render={() => <GlobalBusiness {...props} />}
        />
      </div>
      <footer />
      <HeaderPortal as="span">
        <span className="float-left">
          <Link to="/stacks">
            <span>{t("layout.title")}</span>
          </Link>
          <span className="ml-2 mr-2">&gt;</span>
        </span>
      </HeaderPortal>
    </>
  );
};

export default StackLayout;
