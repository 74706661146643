/**
 * @generated SignedSource<<bf78ba41af539ed36b6e9f43bcca7b4d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type FixedShiftRestrictionEnum = "not_applicable" | "optional" | "required" | "%future added value";
export type EmploymentTypeConfigCreateInput = {
  code?: string | null;
  deleted?: boolean | null;
  employmentTypeCode: string;
  fixedShiftRestriction?: FixedShiftRestrictionEnum | null;
  gmhEnabled?: boolean | null;
  gmhMinimumDurationError?: any | null;
  gmhMinimumDurationWarning?: any | null;
  isDefault?: boolean | null;
  name: string;
  shortName: string;
  timeClockName: string;
  timeOffComplianceEnabled?: boolean | null;
};
export type EmploymentTypesQueries_CreateEmploymentTypeConfig_Mutation$variables = {
  businessId: string;
  input: EmploymentTypeConfigCreateInput;
};
export type EmploymentTypesQueries_CreateEmploymentTypeConfig_Mutation$data = {
  readonly createEmploymentTypeConfig: {
    readonly code: string | null;
    readonly deleted: boolean;
    readonly employmentTypeCode: string;
    readonly fixedShiftRestriction: FixedShiftRestrictionEnum;
    readonly gmhEnabled: boolean;
    readonly gmhMinimumDurationError: any | null;
    readonly gmhMinimumDurationWarning: any | null;
    readonly id: string;
    readonly isDefault: boolean;
    readonly name: string;
    readonly shortName: string;
    readonly smallId: number;
    readonly timeClockName: string;
    readonly timeOffComplianceEnabled: boolean;
  };
};
export type EmploymentTypesQueries_CreateEmploymentTypeConfig_Mutation = {
  response: EmploymentTypesQueries_CreateEmploymentTypeConfig_Mutation$data;
  variables: EmploymentTypesQueries_CreateEmploymentTypeConfig_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "businessId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "businessId",
        "variableName": "businessId"
      },
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "EmploymentTypeConfig",
    "kind": "LinkedField",
    "name": "createEmploymentTypeConfig",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "code",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deleted",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "employmentTypeCode",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "fixedShiftRestriction",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "gmhEnabled",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "gmhMinimumDurationError",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "gmhMinimumDurationWarning",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isDefault",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "shortName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "smallId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "timeClockName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "timeOffComplianceEnabled",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EmploymentTypesQueries_CreateEmploymentTypeConfig_Mutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EmploymentTypesQueries_CreateEmploymentTypeConfig_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d911ce37c14fde2dc770ebc4f5a6148b",
    "id": null,
    "metadata": {},
    "name": "EmploymentTypesQueries_CreateEmploymentTypeConfig_Mutation",
    "operationKind": "mutation",
    "text": "mutation EmploymentTypesQueries_CreateEmploymentTypeConfig_Mutation(\n  $businessId: ID!\n  $input: EmploymentTypeConfigCreateInput!\n) {\n  createEmploymentTypeConfig(businessId: $businessId, input: $input) {\n    id\n    code\n    deleted\n    employmentTypeCode\n    fixedShiftRestriction\n    gmhEnabled\n    gmhMinimumDurationError\n    gmhMinimumDurationWarning\n    isDefault\n    name\n    shortName\n    smallId\n    timeClockName\n    timeOffComplianceEnabled\n  }\n}\n"
  }
};
})();

(node as any).hash = "fbc4922f17094ae68c0cb965da277ca0";

export default node;
