/**
 * @generated SignedSource<<c08424f8f2fb13a1bcc6a72c1f31c744>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AOSTemplateService_TemplatesQuery$variables = {
  businessId: string;
  isDefault?: boolean | null;
  search?: string | null;
};
export type AOSTemplateService_TemplatesQuery$data = {
  readonly aosConfigTemplates: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly aosConfig: {
          readonly addCoreBreaks: boolean;
          readonly addUnderstaffingShifts: boolean;
          readonly aosCombinedRoles: any | null;
          readonly aosOrchestratorConfig: any | null;
          readonly aosTimeout: number | null;
          readonly applyRule24HoursRest: boolean;
          readonly bonusRoles: ReadonlyArray<string> | null;
          readonly breakEndPad: number | null;
          readonly breakLength: number | null;
          readonly breakMaxStart: number | null;
          readonly breakMinStart: number | null;
          readonly breakTrigger: number | null;
          readonly coreObeysAosRules: boolean | null;
          readonly costEqualLabor: number | null;
          readonly daypartRanks: any | null;
          readonly demandOnly: ReadonlyArray<string> | null;
          readonly flexibleRole: string | null;
          readonly ignoreBreakRoles: ReadonlyArray<string> | null;
          readonly includeRoles: ReadonlyArray<string> | null;
          readonly maxRolesPerShift: number | null;
          readonly maxWorkdaysCalweek: number | null;
          readonly maxWorkdaysWorkweek: number | null;
          readonly minShiftPartLength: number | null;
          readonly optimizeCoreBreaks: boolean;
          readonly overstaffingByRole: ReadonlyArray<string> | null;
          readonly overstaffingPenalty: number | null;
          readonly penaltyShortParts: number | null;
          readonly penaltyShortPartsCutoff: number | null;
          readonly planningOrder: ReadonlyArray<string> | null;
          readonly replanningOrchestratorConfig: any | null;
          readonly replanningTimeout: number | null;
          readonly schedulingDayStart: string | null;
          readonly shiftConfig: any | null;
          readonly shiftGapMin: number | null;
          readonly shiftLengthPreference: number | null;
          readonly shiftMax: number | null;
          readonly shiftMaxStart: string | null;
          readonly shiftMin: number | null;
          readonly shiftMinAbsolute: number | null;
          readonly skillPreference: number | null;
          readonly skipSchedulingCore: boolean;
          readonly skipSchedulingManagers: boolean;
          readonly understaffingByRole: ReadonlyArray<string> | null;
          readonly weeklyDayparts: any | null;
          readonly weeklyWorkhoursMax: number | null;
          readonly weeklyWorkhoursMin: number | null;
          readonly weightEqualLaborByRating: ReadonlyArray<number> | null;
        };
        readonly id: string;
        readonly isDefault: boolean;
        readonly templateName: string;
      };
    }>;
  };
};
export type AOSTemplateService_TemplatesQuery = {
  response: AOSTemplateService_TemplatesQuery$data;
  variables: AOSTemplateService_TemplatesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "businessId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isDefault"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "businessId",
        "variableName": "businessId"
      },
      {
        "kind": "Variable",
        "name": "isDefault",
        "variableName": "isDefault"
      },
      {
        "kind": "Variable",
        "name": "search",
        "variableName": "search"
      },
      {
        "kind": "Literal",
        "name": "sort",
        "value": [
          {
            "field": "templateName",
            "order": "asc"
          }
        ]
      }
    ],
    "concreteType": "AosConfigTemplateConnection",
    "kind": "LinkedField",
    "name": "aosConfigTemplates",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AosConfigTemplateEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AosConfigTemplate",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "templateName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isDefault",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AosConfig",
                "kind": "LinkedField",
                "name": "aosConfig",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "shiftConfig",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "schedulingDayStart",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "addUnderstaffingShifts",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "weeklyWorkhoursMin",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "weeklyWorkhoursMax",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "shiftMin",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "shiftMax",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "shiftMinAbsolute",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "shiftGapMin",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "shiftMaxStart",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "maxRolesPerShift",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "minShiftPartLength",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "breakTrigger",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "breakLength",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "breakMinStart",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "breakMaxStart",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "breakEndPad",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "includeRoles",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "demandOnly",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "bonusRoles",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "ignoreBreakRoles",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "flexibleRole",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "coreObeysAosRules",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "skipSchedulingManagers",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "skipSchedulingCore",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "addCoreBreaks",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "optimizeCoreBreaks",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "weeklyDayparts",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "daypartRanks",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "planningOrder",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "overstaffingPenalty",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "skillPreference",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "shiftLengthPreference",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "penaltyShortParts",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "penaltyShortPartsCutoff",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "costEqualLabor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "weightEqualLaborByRating",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "applyRule24HoursRest",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "maxWorkdaysCalweek",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "maxWorkdaysWorkweek",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "overstaffingByRole",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "understaffingByRole",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "aosTimeout",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "aosCombinedRoles",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "aosOrchestratorConfig",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "replanningTimeout",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "replanningOrchestratorConfig",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AOSTemplateService_TemplatesQuery",
    "selections": (v3/*: any*/),
    "type": "InternalQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "AOSTemplateService_TemplatesQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "899c5d580ddd69117628d0209e307994",
    "id": null,
    "metadata": {},
    "name": "AOSTemplateService_TemplatesQuery",
    "operationKind": "query",
    "text": "query AOSTemplateService_TemplatesQuery(\n  $businessId: ID!\n  $search: String\n  $isDefault: Boolean\n) {\n  aosConfigTemplates(businessId: $businessId, search: $search, isDefault: $isDefault, sort: [{field: templateName, order: asc}]) {\n    edges {\n      node {\n        id\n        templateName\n        isDefault\n        aosConfig {\n          shiftConfig\n          schedulingDayStart\n          addUnderstaffingShifts\n          weeklyWorkhoursMin\n          weeklyWorkhoursMax\n          shiftMin\n          shiftMax\n          shiftMinAbsolute\n          shiftGapMin\n          shiftMaxStart\n          maxRolesPerShift\n          minShiftPartLength\n          breakTrigger\n          breakLength\n          breakMinStart\n          breakMaxStart\n          breakEndPad\n          includeRoles\n          demandOnly\n          bonusRoles\n          ignoreBreakRoles\n          flexibleRole\n          coreObeysAosRules\n          skipSchedulingManagers\n          skipSchedulingCore\n          addCoreBreaks\n          optimizeCoreBreaks\n          weeklyDayparts\n          daypartRanks\n          planningOrder\n          overstaffingPenalty\n          skillPreference\n          shiftLengthPreference\n          penaltyShortParts\n          penaltyShortPartsCutoff\n          costEqualLabor\n          weightEqualLaborByRating\n          applyRule24HoursRest\n          maxWorkdaysCalweek\n          maxWorkdaysWorkweek\n          overstaffingByRole\n          understaffingByRole\n          aosTimeout\n          aosCombinedRoles\n          aosOrchestratorConfig\n          replanningTimeout\n          replanningOrchestratorConfig\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a098d62ac936665de2ca158a431483f7";

export default node;
