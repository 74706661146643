/**
 * @generated SignedSource<<99ceae9a27f9f8b4baca41996b511361>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AccountDevices_Query$variables = {
  ids?: ReadonlyArray<string> | null;
};
export type AccountDevices_Query$data = {
  readonly users: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly devices: ReadonlyArray<{
          readonly category: string;
          readonly id: string;
          readonly token: string;
          readonly updatedAt: string;
        }>;
        readonly id: string;
      };
    }>;
  };
};
export type AccountDevices_Query = {
  response: AccountDevices_Query$data;
  variables: AccountDevices_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ids"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "ids",
        "variableName": "ids"
      }
    ],
    "concreteType": "UserConnection",
    "kind": "LinkedField",
    "name": "users",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Device",
                "kind": "LinkedField",
                "name": "devices",
                "plural": true,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "category",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "token",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "updatedAt",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountDevices_Query",
    "selections": (v2/*: any*/),
    "type": "InternalQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AccountDevices_Query",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "a5e64d96d82391bc42b905fd7bc59b11",
    "id": null,
    "metadata": {},
    "name": "AccountDevices_Query",
    "operationKind": "query",
    "text": "query AccountDevices_Query(\n  $ids: [ID!]\n) {\n  users(ids: $ids) {\n    edges {\n      node {\n        id\n        devices {\n          id\n          category\n          token\n          updatedAt\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "edaa2238545603a7f793e136918254e7";

export default node;
