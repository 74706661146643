import React from "react";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

const labelColWidth = 3;
const valueColWidth = 8;

type Props = {
  title: string;
  children: React.ReactNode;
};

const ValueField: React.FunctionComponent<Props> = ({ title, children }) => {
  return (
    <Row className="mb-2">
      <Col sm={labelColWidth} className="col-form-label font-weight-bold">
        {title}
      </Col>
      <Col sm={valueColWidth} className="col-form-label">
        {children}
      </Col>
    </Row>
  );
};

export default ValueField;
