import React, { useCallback, useEffect, useState } from "react";

import { useFormikContext } from "formik";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";

import DurationInput from "../../../../common/Form/DurationInput";
import DynamicSelect from "../../../../common/Form/DynamicSelect";
import FormGroup from "../../../../common/Form/FormGroup";
import {
  BreakTypePaidThreshold,
  BreakTypePaymentDropdownValue,
  getBreakTypePaymentSelectOptions,
  getDefaultInputFieldValue,
  getDefaultRadioButtonValue,
  usePaidThresholdContext,
} from "../../BreakTypeUtility";

type Props = {
  disabled: boolean;
  value: number;
  onChange: any;
  businessValue: number;
  showDefaultOption: boolean;
};

// Maps to a single value (paidThreshold). If the final option is selected, then the value inside of the
// input field will be used.
export function PaidThresholdFormGroup({
  disabled = false,
  value,
  onChange,
  businessValue,
  showDefaultOption,
}: Props) {
  const { t } = useTranslation("break-types");
  const { dirty } = useFormikContext();

  const { dropdownValue, businessDropdownValue, setDropdownValue } =
    usePaidThresholdContext();

  const [paidThresholdInput, setPaidThresholdInput] = useState<
    number | undefined
  >(getDefaultInputFieldValue(value));

  const onCheckboxChange = useCallback(
    (option: BreakTypePaymentDropdownValue) => {
      setDropdownValue(option);
      onChange(getValueForRadioOption(option, paidThresholdInput));
    },
    [setDropdownValue, onChange, paidThresholdInput],
  );

  const onPaidThresholdInputChange = useCallback(
    (newValue: string | number | null) => {
      // Make the field look like an uncontrolled component by allowing everything to be deleted
      if (newValue == null) {
        setPaidThresholdInput(undefined);
        onChange(newValue);
      }

      if (
        newValue != null &&
        newValue > 0 &&
        newValue < BreakTypePaidThreshold.AlwaysPaidMinimum
      ) {
        setPaidThresholdInput(newValue as number);
        onChange(newValue);
      }
    },
    [onChange],
  );

  useEffect(() => {
    // This field requires a bit of finesse to reset nicely, since we also manage state internally inside this component
    // If the form isn't dirty, assume that we want to reset the internal values
    if (!dirty) {
      const newDropdownValue = getDefaultRadioButtonValue(
        value,
        showDefaultOption,
      );
      setDropdownValue(newDropdownValue);
      setPaidThresholdInput(getDefaultInputFieldValue(value, businessValue));
    }
  }, [
    value,
    setDropdownValue,
    setPaidThresholdInput,
    dirty,
    showDefaultOption,
    businessValue,
  ]);

  /**
   * Display logic:
   * - Business/Schedule level: the "unpaid when.." option is selected = shown + enabled
   * - Schedule level: The default business break type is "unpaid when.." = shown + **disabled**
   */
  const showThresholdField =
    dropdownValue === BreakTypePaymentDropdownValue.Custom ||
    (dropdownValue === BreakTypePaymentDropdownValue.DefaultToBusiness &&
      businessDropdownValue === BreakTypePaymentDropdownValue.Custom);

  return (
    <Row>
      <FormGroup
        fieldKey="paidThreshold"
        label={t("form.paidThresholdFormGroup.labels.payment")}
        description={
          dropdownValue === BreakTypePaymentDropdownValue.Custom
            ? t("form.paidThresholdFormGroup.labels.unpaid_after_description", {
                amount: value,
              })
            : undefined
        }
        md={showThresholdField ? 6 : 12}
        lg={showThresholdField ? 6 : 12}
      >
        <DynamicSelect<BreakTypePaymentDropdownValue>
          onChange={(option: BreakTypePaymentDropdownValue) =>
            onCheckboxChange(option)
          }
          options={getBreakTypePaymentSelectOptions(
            showDefaultOption,
            businessValue as number,
          )}
          value={dropdownValue}
          disabled={disabled}
        />
      </FormGroup>

      {showThresholdField && (
        <FormGroup
          fieldKey=""
          label={t("form.paidThresholdFormGroup.labels.threshold")}
          md={6}
          lg={6}
        >
          <DurationInput
            fieldKey="paidThreshold-input"
            postfix={t("form.paidThresholdFormGroup.mins")}
            value={paidThresholdInput}
            onChange={onPaidThresholdInputChange}
            allowDecimals={false}
            disabled={
              dropdownValue ===
                BreakTypePaymentDropdownValue.DefaultToBusiness &&
              businessDropdownValue === BreakTypePaymentDropdownValue.Custom
            }
          />
        </FormGroup>
      )}
    </Row>
  );
}

function getValueForRadioOption(
  radio: BreakTypePaymentDropdownValue,
  paidThresholdInput: number | undefined,
) {
  switch (radio) {
    case BreakTypePaymentDropdownValue.Always:
      return BreakTypePaidThreshold.AlwaysPaidDefault;
    case BreakTypePaymentDropdownValue.Never:
      return BreakTypePaidThreshold.NeverPaid;
    case BreakTypePaymentDropdownValue.DefaultToBusiness:
      return null;
    case BreakTypePaymentDropdownValue.Custom:
      return paidThresholdInput;
    default:
      return undefined;
  }
}
