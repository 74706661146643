import React from "react";

import { useFormikContext } from "formik";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Nav from "react-bootstrap/Nav";
import { Trans, useTranslation } from "react-i18next";

import { useBusinessContext } from "../../../../contexts/BusinessContext";
import { useModal } from "../../../../contexts/ModalContext";
import { useAppRouter } from "../../../../hooks/useAppRouter";
import Footer from "../../../common/Form/Footer";
import OkCancelModal from "../../../common/OkCancelModal";
import AOSTemplateService from "../../Services/AOSTemplateService";
import ApplyTemplateModal from "./ApplyTemplateModal";

type FormData = {
  aosConfig: Record<string, any>;
};

interface MatchParams {
  business_id: string;
  stack_id: string;
  schedule_id: string;
}

type Props = {
  onReset: () => void;
};

const AOSFooter: React.FunctionComponent<Props> = ({ onReset }) => {
  const router = useAppRouter<MatchParams>();
  const { t } = useTranslation();

  const { environment } = useBusinessContext();

  const {
    isSubmitting,
    dirty,
    handleReset,
    values,
    initialValues,
    setFieldValue,
  } = useFormikContext<FormData>();

  const {
    stack_id: stackId,
    business_id: businessId,
    schedule_id: scheduleId,
  } = router.params;

  const { hideModal, showModal } = useModal();

  const onSaveAsTemplateClicked = () => {
    showModal(
      <OkCancelModal
        title={t("aos:saveAsTemplateDialog.title")}
        okLabel={t("aos:saveAsTemplateDialog.okLabel")}
        onOk={() => {
          router.push(
            `/stack/${stackId}/business/${businessId}/aos_template/create/${scheduleId}`,
          );
        }}
      >
        <p>
          <Trans
            i18nKey="aos:saveAsTemplateDialog.body"
            components={{ bold: <strong /> }}
          />
        </p>
      </OkCancelModal>,
    );
  };

  const onResetToDefaultClicked = () => {
    showModal(
      <OkCancelModal
        title={t("aos:setDefaultDialog.title")}
        onOk={() => {
          onReset();
          hideModal();
        }}
      >
        <p>{t("aos:setDefaultDialog.body")}</p>
      </OkCancelModal>,
    );
  };

  const onResetToTemplateClicked = () => {
    // TODO: More investigation needed (LK-19517)
    // For some reason, if this is directly called inside of ApplyTemplateModal, rather than passed in, the schema
    // url is wrong, regardless of if I manually pass in the environment
    const getTemplatesQuery = async (
      businessId: string,
      filters: Record<string, any>,
    ) => {
      return AOSTemplateService.getTemplates(environment, businessId, filters);
    };

    showModal(
      <ApplyTemplateModal
        businessId={businessId}
        onOk={(aosConfigToOverwrite) => {
          const newAosConfig = {
            ...values.aosConfig,
            ...aosConfigToOverwrite.aosConfig,
          };

          setFieldValue("aosConfig", newAosConfig);
          hideModal();
        }}
        hideModal={hideModal}
        currentSettings={initialValues}
        getTemplatesQuery={getTemplatesQuery}
      />,
    );
  };

  return (
    <Footer>
      <Nav className="mr-auto">
        <Button
          variant="primary"
          onClick={onSaveAsTemplateClicked}
          disabled={dirty}
        >
          {t("aos:actions.saveAsTemplate")}
        </Button>
      </Nav>
      <Nav>
        <DropdownButton
          menuAlign="right"
          className="mr-2"
          id="dropdown-split-variants-secondary"
          variant="secondary"
          title={t("aos:actions.setToDefault")}
          drop="up"
        >
          <Dropdown.Item eventKey="1" onClick={onResetToDefaultClicked}>
            {t("aos:actions.useDefault")}
          </Dropdown.Item>
          <Dropdown.Item eventKey="2" onClick={onResetToTemplateClicked}>
            {t("aos:actions.useTemplate")}
          </Dropdown.Item>
        </DropdownButton>
        <Button
          variant="outline-primary"
          onClick={handleReset}
          className="mr-2"
          disabled={!dirty}
        >
          {t("form.actions.reset")}
        </Button>
        <Button
          variant="primary"
          type="submit"
          disabled={isSubmitting || !dirty}
        >
          {t("form.actions.save")}
        </Button>
      </Nav>
    </Footer>
  );
};

export default AOSFooter;
