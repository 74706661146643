import React from "react";

import styled from "styled-components";

const StyledSubgroupName = styled("legend")`
  font-size: 13px;
  font-weight: bold;
  padding-top: 12px;
`;

type Props = {
  groupName?: string;
  className?: string;
  children: React.ReactNode;
};

export default function SubGroupHeader({
  className = "sub-group",
  children,
}: Props) {
  return (
    <StyledSubgroupName className={className}>{children}</StyledSubgroupName>
  );
}
