import React from "react";

import Card from "react-bootstrap/Card";
import { useTranslation } from "react-i18next";
import { graphql, QueryRenderer } from "react-relay";
import { RouteComponentProps } from "react-router-dom";

import { idmInternalEnvironment } from "../../../../environment";
import Loader from "../../../common/Loader";
import { StackProfile_Query } from "./__generated__/StackProfile_Query.graphql";
import Profile from "./Profile";

const StackProfileQuery = graphql`
  query StackProfile_Query($stackId: ID!) {
    stacks(ids: [$stackId]) {
      edges {
        node {
          ...Profile_stack
        }
      }
    }
  }
`;
interface MatchParams {
  stack_id: string;
}

type Props = RouteComponentProps<MatchParams>;

export default function StackProfile(properties: Props) {
  const stackId = properties.match.params.stack_id;
  const environment = idmInternalEnvironment;
  const { t } = useTranslation();

  const getProfile = (stack: any) => (
    <Card body>
      <Profile {...properties} stack={stack} environment={environment} />
    </Card>
  );

  if (!stackId) {
    // Create stack
    return getProfile(null);
  }

  return (
    <QueryRenderer<StackProfile_Query>
      environment={environment}
      query={StackProfileQuery}
      variables={{
        stackId,
      }}
      render={({ error, props }) => {
        if (error) {
          return <div>{t("translation:error")}</div>;
        }
        if (!props) {
          return <Loader />;
        }

        const { edges } = props.stacks;
        if (edges == null || edges.length === 0) {
          return <div>Error! Stack not found</div>;
        }

        return getProfile((edges as any)[0].node);
      }}
    />
  );
}
