/**
 * @generated SignedSource<<87efab8f2eb4bfdeb57245a5ac1a0599>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type LegacyBreakTypeEnum = "meal" | "rest" | "unknown" | "%future added value";
export type ScheduleBreakTypeCreateInput = {
  breakTypeId: string;
  canOverrideClockedPaidBreak?: boolean | null;
  hidden?: boolean | null;
  paidAfter?: any | null;
  paidThreshold?: any | null;
  scheduleId: string;
  unpaidAfter?: any | null;
};
export type BreakTypeQueries_CreateScheduleBreakType_Mutation$variables = {
  businessId: string;
  input: ScheduleBreakTypeCreateInput;
};
export type BreakTypeQueries_CreateScheduleBreakType_Mutation$data = {
  readonly createScheduleBreakType: {
    readonly availableRange: {
      readonly rangeEndTime: any;
      readonly rangeStartTime: any;
    } | null;
    readonly breakScreenOrdering: number;
    readonly breakTypeCode: string;
    readonly canOverrideClockedPaidBreak: boolean;
    readonly code: string | null;
    readonly defaultDuration: any | null;
    readonly description: string | null;
    readonly employmentMetadata: string | null;
    readonly employmentTypeCodes: ReadonlyArray<string>;
    readonly internalAccess: boolean;
    readonly legacyBreakType: LegacyBreakTypeEnum;
    readonly name: string;
    readonly paidAfter: any;
    readonly paidThreshold: any;
    readonly schedulable: boolean;
    readonly unpaidAfter: any;
  };
};
export type BreakTypeQueries_CreateScheduleBreakType_Mutation = {
  response: BreakTypeQueries_CreateScheduleBreakType_Mutation$data;
  variables: BreakTypeQueries_CreateScheduleBreakType_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "businessId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "businessId",
    "variableName": "businessId"
  },
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "breakTypeCode",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "legacyBreakType",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "breakScreenOrdering",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "internalAccess",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "defaultDuration",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "HoursMinutesRange",
  "kind": "LinkedField",
  "name": "availableRange",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rangeStartTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rangeEndTime",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "schedulable",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "paidThreshold",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unpaidAfter",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "paidAfter",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "canOverrideClockedPaidBreak",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "employmentTypeCodes",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "employmentMetadata",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BreakTypeQueries_CreateScheduleBreakType_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ScheduleBreakType",
        "kind": "LinkedField",
        "name": "createScheduleBreakType",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          (v16/*: any*/),
          (v17/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BreakTypeQueries_CreateScheduleBreakType_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ScheduleBreakType",
        "kind": "LinkedField",
        "name": "createScheduleBreakType",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          (v16/*: any*/),
          (v17/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2a01cf089cd46b6d4bfcf70f1caab87e",
    "id": null,
    "metadata": {},
    "name": "BreakTypeQueries_CreateScheduleBreakType_Mutation",
    "operationKind": "mutation",
    "text": "mutation BreakTypeQueries_CreateScheduleBreakType_Mutation(\n  $businessId: ID!\n  $input: ScheduleBreakTypeCreateInput!\n) {\n  createScheduleBreakType(businessId: $businessId, input: $input) {\n    name\n    breakTypeCode\n    legacyBreakType\n    breakScreenOrdering\n    description\n    code\n    internalAccess\n    defaultDuration\n    availableRange {\n      rangeStartTime\n      rangeEndTime\n    }\n    schedulable\n    paidThreshold\n    unpaidAfter\n    paidAfter\n    canOverrideClockedPaidBreak\n    employmentTypeCodes\n    employmentMetadata\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "fb8934e1bd40068dbee08ad459f6fbf0";

export default node;
