/**
 * @generated SignedSource<<be66c353044aa436c9a168c2231aeaad>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type RankChangeEnum = "decrease" | "increase" | "%future added value";
export type ValueTypeEnum = "currency" | "float" | "hours" | "int" | "percentage" | "seconds" | "string" | "%future added value";
export type EmploymentPerformanceRatingsQueries_ChangeRankEmploymentPerformanceRating_Mutation$variables = {
  businessId: string;
  id: string;
  input: RankChangeEnum;
};
export type EmploymentPerformanceRatingsQueries_ChangeRankEmploymentPerformanceRating_Mutation$data = {
  readonly changeEmploymentPerformanceReviewRatingRank: {
    readonly nodes: ReadonlyArray<{
      readonly crewAdjustmentAmount: any | null;
      readonly crewAdjustmentType: ValueTypeEnum;
      readonly deleted: boolean;
      readonly id: string;
      readonly managerAdjustmentAmount: any | null;
      readonly managerAdjustmentType: ValueTypeEnum;
      readonly name: string;
      readonly rank: number;
      readonly updatedAt: string | null;
    }>;
  };
};
export type EmploymentPerformanceRatingsQueries_ChangeRankEmploymentPerformanceRating_Mutation = {
  response: EmploymentPerformanceRatingsQueries_ChangeRankEmploymentPerformanceRating_Mutation$data;
  variables: EmploymentPerformanceRatingsQueries_ChangeRankEmploymentPerformanceRating_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "businessId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "businessId",
        "variableName": "businessId"
      },
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "EmploymentPerformanceReviewRatingConnection",
    "kind": "LinkedField",
    "name": "changeEmploymentPerformanceReviewRatingRank",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "EmploymentPerformanceReviewRating",
        "kind": "LinkedField",
        "name": "nodes",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rank",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "crewAdjustmentType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "managerAdjustmentType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "crewAdjustmentAmount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "managerAdjustmentAmount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "deleted",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "updatedAt",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EmploymentPerformanceRatingsQueries_ChangeRankEmploymentPerformanceRating_Mutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EmploymentPerformanceRatingsQueries_ChangeRankEmploymentPerformanceRating_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "e377400b4fd3131cc65577d3bb22f1af",
    "id": null,
    "metadata": {},
    "name": "EmploymentPerformanceRatingsQueries_ChangeRankEmploymentPerformanceRating_Mutation",
    "operationKind": "mutation",
    "text": "mutation EmploymentPerformanceRatingsQueries_ChangeRankEmploymentPerformanceRating_Mutation(\n  $businessId: ID!\n  $id: ID!\n  $input: RankChangeEnum!\n) {\n  changeEmploymentPerformanceReviewRatingRank(businessId: $businessId, id: $id, input: $input) {\n    nodes {\n      id\n      name\n      rank\n      crewAdjustmentType\n      managerAdjustmentType\n      crewAdjustmentAmount\n      managerAdjustmentAmount\n      deleted\n      updatedAt\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "7522c84a06a291aeae02336013f2c061";

export default node;
