import React, { useEffect, useState } from "react";

import { useFormikContext } from "formik";
import range from "lodash/range";
import { DateTime } from "luxon";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";

import { useScheduleContext } from "../../../../contexts/ScheduleContext";
import { BaseOption, daysOfWeek } from "../../../../data/models/common";
import Field from "../../../common/Form/Field";
import FormGroup from "../../../common/Form/FormGroup";
import OrderedList from "../../../common/Form/OrderedList";
import SelectionGroup from "../../../common/Form/SelectionGroup";
import { AosConfigBasic, PlanningOrderType } from "./models";

type Props = {
  values: AosConfigBasic;
  disabled?: boolean;
};

export type EmployeeGroup = {
  empl_rating: number;
  name: string;
  weekly_workhours_min: number;
  weekly_workhours_max: number;
  shift_min: number;
  shift_max: number;
};

export default function PlanningOrder({ disabled, values: aosConfig }: Props) {
  const [planningOrderType, setPlanningOrderType] = useState(
    PlanningOrderType.Backward,
  );

  const { t } = useTranslation();
  const scheduleContext = useScheduleContext();
  const formikContext = useFormikContext();

  // This initially used getDerivedStateFromProps - but basically, whenever the formik context changes
  // we want to take the latest value from it and overwrite our state with it
  useEffect(() => {
    if (!aosConfig) {
      return;
    }

    const { planningOrder } = aosConfig;

    if (
      planningOrder?.find(
        (i) =>
          i.toLocaleLowerCase() ===
          PlanningOrderType.Backward.toLocaleLowerCase(),
      )
    ) {
      setPlanningOrderType(PlanningOrderType.Backward);
    } else if (
      planningOrder?.find(
        (i) =>
          i.toLocaleLowerCase() ===
          PlanningOrderType.Forward.toLocaleLowerCase(),
      )
    ) {
      setPlanningOrderType(PlanningOrderType.Forward);
    } else {
      setPlanningOrderType(PlanningOrderType.Custom);
    }
  }, [aosConfig]);

  // custom
  const startOfWeek = scheduleContext.getWeekStart(DateTime.local());
  const endOfWeek = startOfWeek.plus({ day: 6 });

  const startOfWeekDay = startOfWeek.toFormat("ccc");
  const endOfWeekDay = endOfWeek.toFormat("ccc");

  const planningOrderOptions: BaseOption<PlanningOrderType, any>[] = [
    {
      value: PlanningOrderType.Backward,
      label: `${t(
        "aos:basic.sections.planning_order.backward",
      )} (${endOfWeekDay}, ${endOfWeek
        .minus({ day: 1 })
        .toFormat("ccc")}, ... ${startOfWeekDay})`,
    },
    {
      value: PlanningOrderType.Forward,
      label: `${t(
        "aos:basic.sections.planning_order.forward",
      )} (${startOfWeekDay}, ${startOfWeek
        .plus({ day: 1 })
        .toFormat("ccc")}, ... ${endOfWeekDay})`,
    },
    {
      value: PlanningOrderType.Custom,
      label: t("aos:basic.sections.planning_order.custom"),
    },
  ];

  const defaultCustomPlanningOrder = range(7).map((day) => {
    return startOfWeek.plus({ day }).toFormat("cccc");
  });

  return (
    <fieldset>
      <Row>
        <FormGroup
          md={4}
          lg={3}
          fieldKey="planningOrderType"
          label={t("aos:basic.sections.planning_order.order")}
        >
          <SelectionGroup<PlanningOrderType>
            fieldKey="planningOrderType"
            options={planningOrderOptions}
            formCheckType="radio"
            value={planningOrderType}
            disabled={disabled}
            onChange={(newValue) => {
              setPlanningOrderType(newValue as PlanningOrderType);

              const helper = formikContext.getFieldHelpers(
                "aosConfig.planningOrder",
              );
              switch (newValue) {
                case PlanningOrderType.Backward:
                  helper.setValue([PlanningOrderType.Backward]);
                  return;
                case PlanningOrderType.Forward:
                  helper.setValue([PlanningOrderType.Forward]);
                  return;
                default: {
                  helper.setValue(defaultCustomPlanningOrder);
                }
              }
            }}
          />
        </FormGroup>

        {/* <Field
                            label={t("aos:basic.sections.planning_order.order")}
                            md={4}
                            lg={3}
                            disabled={disabled}
                            // fieldKey="planningOrderType"
                            value={values.planningOrderType}
                            component={SelectionGroup}
                            componentProps={{
                              options: planningOrderOptions,
                              formCheckType: "radio",
                            }}
                            onValueChanged={(newValue) => {}}
                          /> */}
        {planningOrderType === PlanningOrderType.Custom ? (
          <Field
            label={t("aos:basic.sections.planning_order.custom_order")}
            md={4}
            lg={3}
            disabled={disabled}
            fieldKey="aosConfig.planningOrder"
            value={(aosConfig.planningOrder as string[]) || []}
            component={OrderedList}
            componentProps={{
              options: daysOfWeek,
            }}
          />
        ) : null}
      </Row>
    </fieldset>
  );
}
