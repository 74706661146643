import { commitMutation, Environment, graphql } from "react-relay";

const mutation = graphql`
  mutation SendResetPasswordEmailMutation($email: String!) {
    sendResetPasswordEmail(email: $email)
  }
`;

export default (
  environment: Environment,
  email: string,
  callback: Function,
  onError: (error: Error) => void = (err: Error) => {
    alert(err);
  },
) => {
  const variables = {
    email,
  };

  return commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (e) => {
      callback(e);
    },
    onError,
  });
};
