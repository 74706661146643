import React, { useState } from "react";

import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";
import { RelayEnvironmentProvider } from "react-relay";
import { RouteComponentProps } from "react-router-dom";

import { idmInternalEnvironment } from "../../../../environment";
import { useDebouncedValue } from "../../../../hooks/useDebouncedValue";
import DynamicSelect from "../../../common/Form/DynamicSelect";
import Loader from "../../../common/Loader";
import HeaderPortal from "../../../common/Portal/HeaderPortal";
import Searchable from "../../../common/Searchable";
import { useAppContext } from "../../external/Context/AppContext";
import CorporatesTable from "./CorporatesTable";

type Props = RouteComponentProps;

export default function Corporates(props: Props) {
  const { t } = useTranslation("corporates");
  const stackOptions = useStackOptions();

  const [selectedStack, setSelectedStack] = useState<string | null>(null);
  const [searchValue, debouncedSearchValue, setSearchValue] =
    useDebouncedValue("");

  const onCreateClick = () => {
    props.history.push("corporates/create");
  };

  const onStackDropdownSelected = (value?: string | null) => {
    setSelectedStack(value ?? null);
  };

  return (
    <RelayEnvironmentProvider environment={idmInternalEnvironment}>
      <HeaderPortal>{t("layout.title")}</HeaderPortal>

      <Row className="my-4 align-baseline">
        <Col md={5}>
          <Row>
            <Col md={4}>
              <DynamicSelect<string | null>
                options={stackOptions}
                name="employed-status"
                onChange={onStackDropdownSelected}
                value={selectedStack}
                placeholder={t("table.allStacksSelected")}
                isClearable
              />
            </Col>
            <Col md={8}>
              <Searchable
                searchValue={searchValue}
                onSearchChange={setSearchValue}
                placeholder={t("table.search")}
              />
            </Col>
          </Row>
        </Col>
        <Col md={7} className="d-flex justify-content-end">
          <Button variant="primary" onClick={onCreateClick}>
            {t("table.new")}
          </Button>
        </Col>
      </Row>

      <Card body>
        <React.Suspense fallback={<Loader />}>
          <CorporatesTable
            searchFilters={{
              nameIncludes: debouncedSearchValue,
              stackId: selectedStack,
            }}
          />
        </React.Suspense>
      </Card>
    </RelayEnvironmentProvider>
  );
}

function useStackOptions() {
  const stacks = useAppContext().stacks ?? [];

  return stacks.map((stack) => ({
    label: stack.stackCode,
    value: stack.id,
  }));
}
