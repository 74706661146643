import React, { Suspense, useEffect, useState } from "react";

import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";
import { PreloadedQuery, useQueryLoader } from "react-relay";

import { useBusinessContext } from "../../../contexts/BusinessContext";
import { useAppRouter } from "../../../hooks/useAppRouter";
import Loader from "../../common/Loader";
import HeaderPortal from "../../common/Portal/HeaderPortal";
import Searchable from "../../common/Searchable";
import { TimeOffTypesQueries_GetTimeOffTypes_Query } from "./__generated__/TimeOffTypesQueries_GetTimeOffTypes_Query.graphql";
import { GetTimeOffTypesQuery } from "./TimeOffTypesQueries";
import TimeOffTypesTable from "./TimeOffTypesTable";

type Props = {
  initialQueryRef: PreloadedQuery<TimeOffTypesQueries_GetTimeOffTypes_Query>;
};

export default function TimeOffTypes({ initialQueryRef }: Props) {
  const [searchValue, setSearchValue] = useState("");

  const router = useAppRouter();
  const { t } = useTranslation("time-off-types");
  const { business } = useBusinessContext();

  const [queryReference, loadQuery] = useQueryLoader(
    GetTimeOffTypesQuery,
    initialQueryRef,
  );

  useEffect(() => {
    if (business.id) {
      loadQuery(
        {
          businessId: business.id,
        },
        {
          fetchPolicy: "store-and-network",
        },
      );
    }
  }, [business, loadQuery]);

  const onCreateClick = () => {
    router.push("time_off_types/create");
  };

  return (
    <>
      <HeaderPortal as="span">
        <span className="ml-2 mr-2">&gt;</span>
        <span>{t("nav.timeOffTypes")}</span>
      </HeaderPortal>
      <Row className="align-baseline">
        <Col md={5}>
          <Searchable
            searchValue={searchValue}
            onSearchChange={setSearchValue}
            placeholder={t("table.search")}
          />
        </Col>
        <Col md={7} className="d-flex justify-content-end">
          <Button variant="primary" onClick={onCreateClick}>
            {t("table.actions.create")}
          </Button>
        </Col>
      </Row>
      <Card className="mt-4" body>
        <Suspense fallback={<Loader />}>
          {queryReference != null && (
            <TimeOffTypesTable
              queryReference={queryReference}
              search={searchValue}
            />
          )}
        </Suspense>
      </Card>
    </>
  );
}
