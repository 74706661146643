/**
 * @generated SignedSource<<085057162dfb83293c40cdff3ea93f42>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type BusinessesQueries_GetCorporatesBusinessesByIdsQuery$variables = {
  ids?: ReadonlyArray<string> | null;
  skip: boolean;
};
export type BusinessesQueries_GetCorporatesBusinessesByIdsQuery$data = {
  readonly businesses?: {
    readonly nodes: ReadonlyArray<{
      readonly businessName: string;
      readonly corporate: {
        readonly id: string;
        readonly name: string;
      } | null;
      readonly id: string;
    }>;
  };
};
export type BusinessesQueries_GetCorporatesBusinessesByIdsQuery = {
  response: BusinessesQueries_GetCorporatesBusinessesByIdsQuery$data;
  variables: BusinessesQueries_GetCorporatesBusinessesByIdsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ids"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "skip"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "condition": "skip",
    "kind": "Condition",
    "passingValue": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "ids",
            "variableName": "ids"
          }
        ],
        "concreteType": "BusinessConnection",
        "kind": "LinkedField",
        "name": "businesses",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Business",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "businessName",
                "storageKey": null
              },
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Corporate",
                "kind": "LinkedField",
                "name": "corporate",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BusinessesQueries_GetCorporatesBusinessesByIdsQuery",
    "selections": (v2/*: any*/),
    "type": "InternalQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BusinessesQueries_GetCorporatesBusinessesByIdsQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "46ab6ac7f8c32f4174bcc99d024f77fd",
    "id": null,
    "metadata": {},
    "name": "BusinessesQueries_GetCorporatesBusinessesByIdsQuery",
    "operationKind": "query",
    "text": "query BusinessesQueries_GetCorporatesBusinessesByIdsQuery(\n  $ids: [ID!]\n  $skip: Boolean!\n) {\n  businesses(ids: $ids) @skip(if: $skip) {\n    nodes {\n      businessName\n      id\n      corporate {\n        id\n        name\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f1334011bd794646b4c7ebe1b99ffbdf";

export default node;
