/**
 * @generated SignedSource<<c68812154cd760ec19684d93e0bd1e19>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type EmploymentFeedbackTypeEnum = "commendation" | "disciplinary_action" | "%future added value";
export type FeedbackReasonsQueries_GetEmploymentFeedbackReasonsQuery$variables = {
  businessId: string;
  feedbackType?: ReadonlyArray<EmploymentFeedbackTypeEnum> | null;
  includeDeleted?: boolean | null;
};
export type FeedbackReasonsQueries_GetEmploymentFeedbackReasonsQuery$data = {
  readonly employmentFeedbackReasons: {
    readonly nodes: ReadonlyArray<{
      readonly defaultMessage: string | null;
      readonly deleted: boolean;
      readonly description: string;
      readonly feedbackType: EmploymentFeedbackTypeEnum;
      readonly id: string;
    }>;
  };
};
export type FeedbackReasonsQueries_GetEmploymentFeedbackReasonsQuery = {
  response: FeedbackReasonsQueries_GetEmploymentFeedbackReasonsQuery$data;
  variables: FeedbackReasonsQueries_GetEmploymentFeedbackReasonsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "businessId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "feedbackType"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "includeDeleted"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "businessId",
        "variableName": "businessId"
      },
      {
        "kind": "Variable",
        "name": "feedbackType",
        "variableName": "feedbackType"
      },
      {
        "kind": "Variable",
        "name": "includeDeleted",
        "variableName": "includeDeleted"
      }
    ],
    "concreteType": "EmploymentFeedbackReasonConnection",
    "kind": "LinkedField",
    "name": "employmentFeedbackReasons",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "EmploymentFeedbackReason",
        "kind": "LinkedField",
        "name": "nodes",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "feedbackType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "defaultMessage",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "deleted",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FeedbackReasonsQueries_GetEmploymentFeedbackReasonsQuery",
    "selections": (v1/*: any*/),
    "type": "InternalQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FeedbackReasonsQueries_GetEmploymentFeedbackReasonsQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "c100a4d59cb4b10477663c233cfc3bd9",
    "id": null,
    "metadata": {},
    "name": "FeedbackReasonsQueries_GetEmploymentFeedbackReasonsQuery",
    "operationKind": "query",
    "text": "query FeedbackReasonsQueries_GetEmploymentFeedbackReasonsQuery(\n  $businessId: ID!\n  $feedbackType: [EmploymentFeedbackTypeEnum!]\n  $includeDeleted: Boolean\n) {\n  employmentFeedbackReasons(businessId: $businessId, feedbackType: $feedbackType, includeDeleted: $includeDeleted) {\n    nodes {\n      id\n      feedbackType\n      description\n      defaultMessage\n      deleted\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e7c4d5a28d4a656944edd047dbb401f2";

export default node;
