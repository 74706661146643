/**
 * @generated SignedSource<<b0de725883771c4e4b5a539d433ef2db>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type PerformanceSettingsQueries_PerformanceSettingsQuery$variables = {
  businessId: string;
};
export type PerformanceSettingsQueries_PerformanceSettingsQuery$data = {
  readonly businesses: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly approvalNeededForCommendation: boolean;
        readonly approvalNeededForDisciplinaryAction: boolean;
        readonly approvalRequests: {
          readonly employmentPerformanceReview: {
            readonly autoApprovalEnabled: boolean;
          };
          readonly employmentRateChange: {
            readonly autoApprovalEnabled: boolean;
          };
        };
        readonly commendationEmploymentNotifications: boolean;
        readonly commendationEnabled: boolean;
        readonly disciplinaryActionEmploymentNotifications: boolean;
        readonly disciplinaryActionEnabled: boolean;
        readonly employmentPerformanceReviewDetail: {
          readonly adhocReviewEnabled: boolean;
          readonly adjustJtc: boolean;
          readonly autoPublish: boolean;
          readonly initialReviewEnabled: boolean;
          readonly initialReviewPeriodMonths: number;
          readonly regularReviewPeriodMonths: number;
          readonly regularReviewStartDate: any;
          readonly reviewDueReminderDays: any;
        };
        readonly employmentPerformanceReviewEnabled: boolean;
        readonly employmentRateEditEnabled: boolean;
        readonly feedbackCommentEnabled: boolean;
        readonly feedbackReasonEnabled: boolean;
        readonly id: string;
      };
    }>;
  };
};
export type PerformanceSettingsQueries_PerformanceSettingsQuery = {
  response: PerformanceSettingsQueries_PerformanceSettingsQuery$data;
  variables: PerformanceSettingsQueries_PerformanceSettingsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "businessId"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "autoApprovalEnabled",
    "storageKey": null
  }
],
v2 = [
  {
    "alias": null,
    "args": [
      {
        "items": [
          {
            "kind": "Variable",
            "name": "ids.0",
            "variableName": "businessId"
          }
        ],
        "kind": "ListValue",
        "name": "ids"
      }
    ],
    "concreteType": "BusinessConnection",
    "kind": "LinkedField",
    "name": "businesses",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BusinessEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Business",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "employmentRateEditEnabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ApprovalRequestsConfig",
                "kind": "LinkedField",
                "name": "approvalRequests",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EmploymentRateChangeRequestConfig",
                    "kind": "LinkedField",
                    "name": "employmentRateChange",
                    "plural": false,
                    "selections": (v1/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EmploymentPerformanceReviewRequestConfig",
                    "kind": "LinkedField",
                    "name": "employmentPerformanceReview",
                    "plural": false,
                    "selections": (v1/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "employmentPerformanceReviewEnabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "EmploymentPerformanceReviewDetail",
                "kind": "LinkedField",
                "name": "employmentPerformanceReviewDetail",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "autoPublish",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "adjustJtc",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "reviewDueReminderDays",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "regularReviewStartDate",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "regularReviewPeriodMonths",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "initialReviewEnabled",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "initialReviewPeriodMonths",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "adhocReviewEnabled",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "feedbackReasonEnabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "feedbackCommentEnabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "commendationEnabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "approvalNeededForCommendation",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "commendationEmploymentNotifications",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "disciplinaryActionEnabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "approvalNeededForDisciplinaryAction",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "disciplinaryActionEmploymentNotifications",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PerformanceSettingsQueries_PerformanceSettingsQuery",
    "selections": (v2/*: any*/),
    "type": "InternalQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PerformanceSettingsQueries_PerformanceSettingsQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "ff08b7dea2c6ac10a64f9eba1767295f",
    "id": null,
    "metadata": {},
    "name": "PerformanceSettingsQueries_PerformanceSettingsQuery",
    "operationKind": "query",
    "text": "query PerformanceSettingsQueries_PerformanceSettingsQuery(\n  $businessId: ID!\n) {\n  businesses(ids: [$businessId]) {\n    edges {\n      node {\n        id\n        employmentRateEditEnabled\n        approvalRequests {\n          employmentRateChange {\n            autoApprovalEnabled\n          }\n          employmentPerformanceReview {\n            autoApprovalEnabled\n          }\n        }\n        employmentPerformanceReviewEnabled\n        employmentPerformanceReviewDetail {\n          autoPublish\n          adjustJtc\n          reviewDueReminderDays\n          regularReviewStartDate\n          regularReviewPeriodMonths\n          initialReviewEnabled\n          initialReviewPeriodMonths\n          adhocReviewEnabled\n        }\n        feedbackReasonEnabled\n        feedbackCommentEnabled\n        commendationEnabled\n        approvalNeededForCommendation\n        commendationEmploymentNotifications\n        disciplinaryActionEnabled\n        approvalNeededForDisciplinaryAction\n        disciplinaryActionEmploymentNotifications\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d0d0dc3282666096bf6ea5e41c829928";

export default node;
