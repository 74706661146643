import React from "react";

import { useFormikContext } from "formik";
import Col from "react-bootstrap/Col";
import ListGroup from "react-bootstrap/ListGroup";
import Row from "react-bootstrap/Row";
import Select from "react-select";

import { BaseOption } from "../../../data/models/common";
import DropdownIndicator from "./DropdownIndicator";
import { TodoType } from "./models";
import RemoveIcon from "./RemoveIcon";

export enum MultiSelectType {
  Pill = "multi-select-pills",
  List = "multi-select-list",
}

type Props<T> = {
  allOptions: BaseOption<T, any>[];
  selectableOptions: BaseOption<T, any>[];
  value: T[];
  fieldKey: string;
  onChange: (newValue: T[]) => void;
  disabled?: boolean;
  multiStyle: MultiSelectType;
  placeholder?: string;
  minSelectHeight?: number;
  hideDropdownIndicator?: boolean;
};

export default function MultiSelectList<T>({
  value,
  fieldKey,
  disabled,
  onChange,
  selectableOptions: options,
  allOptions,
  multiStyle,
  placeholder,
  minSelectHeight,
  hideDropdownIndicator = false,
  ...rest
}: Props<T>) {
  const formikContext = useFormikContext();

  const getOptionFromValue = (
    selectableOptions: BaseOption<T, any>[],
    val: T[],
  ) => {
    return (val || []).map((v) =>
      selectableOptions.find((i) => i?.value === v),
    );
  };

  const allOptionsByValue = (allOptions || options || []).reduce(
    (result, i) => {
      result.set(i.value, i);
      return result;
    },
    new Map<T, BaseOption<T, any>>(),
  );

  const selectableOptionsByValue = (options || []).reduce((result, i) => {
    if (i) {
      result.set(i.value, i);
    }
    return result;
  }, new Map<T, BaseOption<T, any>>());

  return (
    <>
      <Select
        {...rest}
        options={options}
        name={fieldKey}
        isDisabled={disabled}
        isClearable={false}
        placeholder={placeholder}
        // placeholder="select roles..."
        closeMenuOnSelect={false}
        styles={{
          multiValue: (base) => ({
            ...base,
            ...(multiStyle !== MultiSelectType.Pill ? { display: "none" } : {}),
          }),
          menu: (base) => ({
            ...base,
            zIndex: 999,
          }),
          control: (base: any) => ({
            ...base,
            height:
              multiStyle !== MultiSelectType.Pill
                ? minSelectHeight ?? 35
                : undefined,
            minHeight: minSelectHeight ?? 35,
            alignItems: "flex-start",
          }),
          container: (base: any) => ({
            ...base,
          }),
        }}
        isMulti
        onChange={(v) => {
          onChange(((v || []) as any[]).map((i: any) => i.value));
        }}
        value={getOptionFromValue(options, value) as any}
        components={{
          DropdownIndicator: hideDropdownIndicator ? null : DropdownIndicator,
          IndicatorSeparator: null,
        }}
      />

      {multiStyle !== MultiSelectType.Pill && (
        <ListGroup className="mt-1">
          {(value || []).map((v, index) => {
            const option = allOptionsByValue.get(v);
            const optionValue = option ? option.value : v;
            const optionLabel = option ? option.label : v;
            return (
              <ListGroup.Item
                key={optionValue as unknown as string}
                className="hoverable"
              >
                <Row>
                  <Col
                    className={
                      selectableOptionsByValue.has(v) ? "" : "text-danger"
                    }
                  >
                    {optionLabel as TodoType}
                  </Col>
                  <Col md="auto">
                    <RemoveIcon
                      className="pt-0 pb-0 pl-0 pr-0 show-on-hover"
                      formikContext={formikContext}
                      fieldKey={fieldKey}
                      index={index}
                      disabled={disabled}
                    />
                  </Col>
                </Row>
              </ListGroup.Item>
            );
          })}
        </ListGroup>
      )}
    </>
  );
}
