/**
 * @generated SignedSource<<18b74dba7334ec50a1ddb9e05074f1c7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type TimeOffTypesQueries_GetTimeOffBalances_Query$variables = {
  businessId: string;
  timeOffTypeIds?: ReadonlyArray<string> | null;
};
export type TimeOffTypesQueries_GetTimeOffBalances_Query$data = {
  readonly timeOffBalances: {
    readonly nodes: ReadonlyArray<{
      readonly hoursBalance: any;
      readonly id: string;
    }>;
  };
};
export type TimeOffTypesQueries_GetTimeOffBalances_Query = {
  response: TimeOffTypesQueries_GetTimeOffBalances_Query$data;
  variables: TimeOffTypesQueries_GetTimeOffBalances_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "businessId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "timeOffTypeIds"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "businessId",
        "variableName": "businessId"
      },
      {
        "kind": "Variable",
        "name": "timeOffTypeIds",
        "variableName": "timeOffTypeIds"
      }
    ],
    "concreteType": "TimeOffBalanceConnection",
    "kind": "LinkedField",
    "name": "timeOffBalances",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "TimeOffBalance",
        "kind": "LinkedField",
        "name": "nodes",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hoursBalance",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TimeOffTypesQueries_GetTimeOffBalances_Query",
    "selections": (v1/*: any*/),
    "type": "InternalQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TimeOffTypesQueries_GetTimeOffBalances_Query",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d0bec212e7befae6dcba764f0ff5fa04",
    "id": null,
    "metadata": {},
    "name": "TimeOffTypesQueries_GetTimeOffBalances_Query",
    "operationKind": "query",
    "text": "query TimeOffTypesQueries_GetTimeOffBalances_Query(\n  $businessId: ID!\n  $timeOffTypeIds: [ID!]\n) {\n  timeOffBalances(businessId: $businessId, timeOffTypeIds: $timeOffTypeIds) {\n    nodes {\n      id\n      hoursBalance\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "679d6b1cb2ccbb84aa1af3976b47c718";

export default node;
