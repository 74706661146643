import React, { useMemo } from "react";

import isFunction from "lodash/isFunction";
import isNumber from "lodash/isNumber";
import isString from "lodash/isString";
import isUndefined from "lodash/isUndefined";
import { DurationUnit } from "luxon";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import styled from "styled-components";

import { convertDuration, stringToNumber } from "../../../utils/utility";

type Props = {
  size?: "sm" | "lg";
  value?: string | number | null;
  placeholder?: string;
  fieldKey: string;
  onChange?: (newValue: string | number | null) => any;
  disabled?: boolean;
  error?: string;
  from?: DurationUnit;
  to?: DurationUnit;
  postfix?: string;
  className?: string;
  allowDecimals?: boolean;
  "aria-labelledby"?: string;
  customDisplayValues?: {
    values: any[];
    displayAs: string | number;
  };
  fallbackValue?: number | null;
};

const StyledNumberInputGroup = styled(InputGroup)`
  max-width: 120px;
`;

export default function DurationInput(props: Props) {
  const {
    fieldKey,
    value,
    customDisplayValues,
    // onChange,
    disabled,
    error,
    from,
    to,
    postfix,
    className,
    allowDecimals = true,
    placeholder,
    fallbackValue,
    ...rest
  } = props;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { onChange } = props;
    const v = e.target.value;

    const numValue = stringToNumber(v, null);

    if (!allowDecimals && v.includes(".")) {
      e.preventDefault();
      return;
    }

    if (isFunction(onChange)) {
      if (isString(numValue)) {
        onChange(v);
        return;
      }

      if (numValue == null || isNumber(numValue)) {
        const newValue = convertDuration(numValue, to, from);
        onChange(newValue ?? fallbackValue ?? null);
      }
    }
  };

  const durationValue = convertDuration(value, from, to);

  const displayValue = useMemo(() => {
    const v =
      !isUndefined(durationValue) && isNumber(durationValue)
        ? durationValue
        : "";

    // Esentially a value transformer - display a value as something else (eg. display 0 or "" as a dash (-))
    if (customDisplayValues?.values?.includes(v)) {
      return customDisplayValues.displayAs;
    }

    return v;
  }, [durationValue, customDisplayValues]);

  return (
    <StyledNumberInputGroup className={className} size={props.size}>
      <Form.Control
        {...rest}
        type="number"
        onKeyDown={(event: any) => {
          if (!allowDecimals && event.key === ".") {
            event.preventDefault();
          }
        }}
        placeholder={placeholder}
        name={fieldKey}
        disabled={disabled}
        isInvalid={error != null}
        value={displayValue}
        onChange={handleChange}
      />
      {postfix ? (
        <InputGroup.Append>
          <InputGroup.Text id="inputGroupAppend">{postfix}</InputGroup.Text>
        </InputGroup.Append>
      ) : null}
    </StyledNumberInputGroup>
  );
}
