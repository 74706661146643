import { useMemo, useState } from "react";

import { debounce } from "lodash";

export function useDebouncedValue<T>(initialValue: T, debounceWaitTime = 700) {
  const [value, setValue] = useState(initialValue);
  const [debouncedValue, setDebouncedValue] = useState(initialValue);

  const setDebouncedValueAfterDebouncing = useMemo(
    () =>
      debounce(
        (nextValue: T) => setDebouncedValue(nextValue),
        debounceWaitTime,
      ),
    [debounceWaitTime],
  );

  const setValueAndDebounce = (newValue: T, shouldDebounce = true) => {
    setValue(newValue);
    if (shouldDebounce) {
      setDebouncedValueAfterDebouncing(newValue);
    } else {
      setDebouncedValue(newValue);
    }
  };

  return [value, debouncedValue, setValueAndDebounce] as const;
}
