import React from "react";

import Row from "react-bootstrap/Row";
import styled from "styled-components";

import DateTimePicker from "./DateTimePicker";
import Field from "./Field";

const StyledRow = styled(Row)`
  .form-group {
    margin: 0 0 2px 0;
  }
`;

type Props = {
  startFieldKey: string;
  endFieldKey: string;
  disabled: boolean;
};

export function TimeRangeInput({
  startFieldKey,
  endFieldKey,
  disabled,
}: Props) {
  return (
    <StyledRow className="align-content-center mb-0">
      <Field
        md={4}
        lg={4}
        xs={4}
        hideLabel
        disabled={disabled}
        hideDescription
        fieldKey={startFieldKey}
        component={DateTimePicker}
        componentProps={{
          showTimeSelect: true,
          showTimeSelectOnly: true,
          timeIntervals: 30,
          valueFormat: null,
          displayFormat: "hh:mm aa",
        }}
      />
      <span className="mt-1 ml-1 mr-1 flex-shrink-0">-</span>
      <Field
        md={4}
        lg={4}
        xs={4}
        disabled={disabled}
        hideLabel
        hideDescription
        fieldKey={endFieldKey}
        component={DateTimePicker}
        componentProps={{
          showTimeSelect: true,
          showTimeSelectOnly: true,
          timeIntervals: 30,
          valueFormat: null,
          displayFormat: "hh:mm aa",
        }}
      />
    </StyledRow>
  );
}
