import React, { useState } from "react";

import isFunction from "lodash/isFunction";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import AsyncSelect from "react-select/async";
import { toast } from "react-toastify";

import { useBusinessContext } from "../../../contexts/BusinessContext";
import { Id } from "../../../data/models/common";
import ServerError from "../../../utils/server-error";
import { TodoSelectValueType } from "../../common/Form/models";
import UserService from "../../IDM/internal/Account/AccountService";
import { LinkUserToEmploymentMutation$data as LinkUserToEmploymentMutationResponse } from "../mutations/__generated__/LinkUserToEmploymentMutation.graphql";
import LinkUserToEmploymentMutation from "../mutations/LinkUserToEmploymentMutation";
import { EmploymentLayoutInfo, User } from "./models";

type Props = {
  onOk?: (e: React.MouseEvent<HTMLButtonElement>, user: User) => void;
  employment?: EmploymentLayoutInfo;
  linkedUser: User | null;
  businessId: Id;
  hideModal: () => void;
};

function LinkToUserModal(props: Props) {
  const businessContext = useBusinessContext();
  const { t } = useTranslation();

  const { linkedUser, employment, businessId, hideModal } = props;

  const [user, setUser] = useState<User | undefined>(undefined);
  const [error, setError] = useState<string | undefined>(undefined);

  function onSubmit(e: React.MouseEvent<HTMLButtonElement>) {
    if (!user || !user.id) {
      return;
    }

    if (employment) {
      LinkUserToEmploymentMutation(
        businessContext.environment,
        businessId,
        user.id,
        employment.id,
        (response: LinkUserToEmploymentMutationResponse) => {
          const { linkUserToEmployment } = response;
          if (linkUserToEmployment) {
            toast(t("employment:linkUserModal.linkSuccess"));
            if (isFunction(props.onOk)) {
              props.onOk?.(e, user);
            }
            hideModal();
          }
        },
        (err: Error) => {
          const { source } = (err as any) || {};
          if (!source) {
            return;
          }
          const serverError = new ServerError(source);
          setError(serverError.getErrorDetails());
        },
      );
    }
  }

  function onChange(newUser: TodoSelectValueType<User, false>) {
    if (!newUser) {
      setUser(undefined);
      setError(undefined);
      return null;
    }

    setUser(newUser as User);
    setError(undefined);

    return newUser.id;
  }

  async function getUsers(searchValue: string) {
    if (!searchValue) {
      return Promise.resolve([]);
    }

    const data = await UserService.searchUsersByEmail(searchValue);
    return Array.from(data ? data.values() : []);
  }

  const getStatus = (statusUser: User) => {
    if (statusUser.confirmedAt == null) {
      return t("employment:unconfirmed");
    }

    if (statusUser.deleted) {
      return t("employment:deleted");
    }

    return "";
  };

  return (
    <Modal show onHide={hideModal}>
      <Modal.Header closeButton>
        <Modal.Title>Link User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {linkedUser ? (
          <i>
            {t("employment:linkUserModal.linkedToUser", {
              email: linkedUser.email,
              status: getStatus(linkedUser),
            })}
          </i>
        ) : null}
        <Form.Group>
          <Form.Label>{t("employment:linkUserModal.userLabel")}</Form.Label>
          <AsyncSelect<User>
            isClearable
            cacheOptions
            defaultOptions
            isSearchable
            onChange={onChange}
            getOptionLabel={(userOption) =>
              t("employment:linkUserModal.userOption", {
                email: userOption.email,
                status: getStatus(userOption),
              })
            }
            getOptionValue={(d) => d.id}
            loadOptions={getUsers}
            backspaceRemovesValue
            placeholder={t("employment:linkUserModal.userPlaceholder")}
          />
          {error ? (
            <Form.Control.Feedback type="invalid" className="d-block">
              {error}
            </Form.Control.Feedback>
          ) : null}
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={hideModal}>
          {t("form.actions.cancel")}
        </Button>
        <Button variant="primary" onClick={onSubmit}>
          {t("form.actions.ok")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default LinkToUserModal;
