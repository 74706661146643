/**
 * @generated SignedSource<<a38712eb6449f888d574b057a1b05005>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type KpiComponentsQueries_GetKpis_InternalQuery$variables = {
  businessId: string;
};
export type KpiComponentsQueries_GetKpis_InternalQuery$data = {
  readonly kpis: {
    readonly nodes: ReadonlyArray<{
      readonly code: string;
      readonly createdAt: string;
      readonly description: string;
      readonly id: string;
      readonly isCostRelated: boolean;
      readonly name: string;
      readonly updatedAt: string;
    }>;
  };
};
export type KpiComponentsQueries_GetKpis_InternalQuery = {
  response: KpiComponentsQueries_GetKpis_InternalQuery$data;
  variables: KpiComponentsQueries_GetKpis_InternalQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "businessId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "businessId",
        "variableName": "businessId"
      }
    ],
    "concreteType": "KpiConnection",
    "kind": "LinkedField",
    "name": "kpis",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Kpi",
        "kind": "LinkedField",
        "name": "nodes",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isCostRelated",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "updatedAt",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "KpiComponentsQueries_GetKpis_InternalQuery",
    "selections": (v1/*: any*/),
    "type": "InternalQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "KpiComponentsQueries_GetKpis_InternalQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "ec952459fa0cfc041f6d7c36df222ed7",
    "id": null,
    "metadata": {},
    "name": "KpiComponentsQueries_GetKpis_InternalQuery",
    "operationKind": "query",
    "text": "query KpiComponentsQueries_GetKpis_InternalQuery(\n  $businessId: ID!\n) {\n  kpis(businessId: $businessId) {\n    nodes {\n      id\n      code\n      createdAt\n      description\n      isCostRelated\n      name\n      updatedAt\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "941b6972c54706de690a5499d1d777bc";

export default node;
