import React, { FunctionComponent, useEffect, useState } from "react";

import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";
import { useQueryLoader } from "react-relay";
import { LinkContainer } from "react-router-bootstrap";
import { Redirect, RouteComponentProps } from "react-router-dom";

import Loader from "../../common/Loader";
import HeaderPortal from "../../common/Portal/HeaderPortal";
import Searchable from "../../common/Searchable";
import { useAppContext } from "../../IDM/external/Context/AppContext";
import { BusinessQueries_InternalQuery } from "./__generated__/BusinessQueries_InternalQuery.graphql";
import { BusinessTablePageQuery } from "./BusinessQueries";
import BusinessTable from "./BusinessTable";

interface MatchParams {
  stack_id?: string;
}

type Props = RouteComponentProps<MatchParams> & {};

const Businesses: FunctionComponent<Props> = ({ match }) => {
  const { t } = useTranslation("businesses");
  const [search, setSearch] = useState<string>("");
  const onSearchChange = (value: string) => {
    setSearch(value);
  };

  const {
    params: { stack_id: stackId },
  } = match;

  const { myStacksById, stack } = useAppContext();

  const [queryReference, loadQuery, disposeQuery] =
    useQueryLoader<BusinessQueries_InternalQuery>(BusinessTablePageQuery);

  useEffect(() => {
    loadQuery(
      {
        searchValue: search,
        pageSize: 30,
      },
      { fetchPolicy: "network-only" },
    );
    return () => {
      disposeQuery();
    };
  }, [loadQuery, disposeQuery, search]);

  if (!stackId && myStacksById) {
    // get first stack if stack_id is not in url params
    const stacks = Array.from(myStacksById.values());
    if (stacks.length) {
      return <Redirect to={`/stack/${stacks[0].id}/businesses`} />;
    }
  }

  if (!stack) {
    return <Redirect to="/stacks" />;
  }

  return (
    <>
      <Row className="my-2">
        <Col>
          <Searchable
            className="businesses"
            searchValue={search}
            onSearchChange={onSearchChange}
            placeholder={t("table.search")}
          />
        </Col>
        <Col md="auto">
          <LinkContainer to={`/stack/${stack.id}/business/create`}>
            <Button>{t("table.new")}</Button>
          </LinkContainer>
        </Col>
      </Row>

      <Card body>
        <React.Suspense fallback={<Loader />}>
          {queryReference != null && (
            <BusinessTable queryReference={queryReference} />
          )}
        </React.Suspense>
      </Card>
      <HeaderPortal>{t("layout.title")}</HeaderPortal>
    </>
  );
};

export default Businesses;
