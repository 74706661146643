/**
 * @generated SignedSource<<1d04a74039482abef5850f8678e3f59c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EmploymentFeedbackTypeEnum = "commendation" | "disciplinary_action" | "%future added value";
export type FeedbackReasonsQueries_ReactivateEmploymentFeedbackReasonMutation$variables = {
  businessId: string;
  id: string;
};
export type FeedbackReasonsQueries_ReactivateEmploymentFeedbackReasonMutation$data = {
  readonly reactivateEmploymentFeedbackReason: {
    readonly defaultMessage: string | null;
    readonly deleted: boolean;
    readonly description: string;
    readonly feedbackType: EmploymentFeedbackTypeEnum;
    readonly id: string;
  };
};
export type FeedbackReasonsQueries_ReactivateEmploymentFeedbackReasonMutation = {
  response: FeedbackReasonsQueries_ReactivateEmploymentFeedbackReasonMutation$data;
  variables: FeedbackReasonsQueries_ReactivateEmploymentFeedbackReasonMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "businessId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "businessId",
        "variableName": "businessId"
      },
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "EmploymentFeedbackReason",
    "kind": "LinkedField",
    "name": "reactivateEmploymentFeedbackReason",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "description",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "defaultMessage",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deleted",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "feedbackType",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "FeedbackReasonsQueries_ReactivateEmploymentFeedbackReasonMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "FeedbackReasonsQueries_ReactivateEmploymentFeedbackReasonMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "e7cd6dcba9b67d47380314dbcb3d4449",
    "id": null,
    "metadata": {},
    "name": "FeedbackReasonsQueries_ReactivateEmploymentFeedbackReasonMutation",
    "operationKind": "mutation",
    "text": "mutation FeedbackReasonsQueries_ReactivateEmploymentFeedbackReasonMutation(\n  $id: ID!\n  $businessId: ID!\n) {\n  reactivateEmploymentFeedbackReason(id: $id, businessId: $businessId) {\n    description\n    defaultMessage\n    deleted\n    feedbackType\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "d82ee8b616becc95260dcba96f522100";

export default node;
