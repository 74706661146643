import React from "react";

import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";
import { graphql, QueryRenderer } from "react-relay";
import { RouteComponentProps } from "react-router-dom";

import { idmInternalEnvironment } from "../../../../environment";
import { formatDate } from "../../../../utils/utility";
import Loader from "../../../common/Loader";
import ValueField from "../../../common/ValueField";
import { AccountDetails_Query } from "./__generated__/AccountDetails_Query.graphql";

const AccountDetailsQuery = graphql`
  query AccountDetails_Query($userId: ID!) {
    users(ids: [$userId]) {
      edges {
        node {
          lockedAt
          lockedMinutesRemaining
          currentSignInAt
          currentSignInIp
          lastSignInAt
          lastSignInIp
          confirmedAt
          confirmationToken
          confirmationSentAt
          signInCount
          failedAttempts
          passwordChangedAt
          resetPasswordToken
          resetPasswordSentAt
          acceptedPolicyVersion
          acceptedPolicyAt
        }
      }
    }
  }
`;

export interface MatchParams {
  user_id: string;
}

type Props = RouteComponentProps<MatchParams> & {};

export default function AccountDetails(properties: Props) {
  const { t } = useTranslation("accounts");

  const {
    match: { params },
  } = properties;
  const userId = params.user_id;

  return (
    <QueryRenderer<AccountDetails_Query>
      environment={idmInternalEnvironment}
      query={AccountDetailsQuery}
      variables={{
        userId,
      }}
      render={({ error, props }) => {
        if (error) {
          return <div>{t("translation:error")}</div>;
        }
        if (!props) {
          return <Loader />;
        }

        const user = props.users?.edges?.[0]?.node ?? null;
        if (!user) {
          return null;
        }

        return (
          <Card body>
            <Row>
              <Col md={6}>
                <ValueField title={t("details.id")}>
                  <div>{userId}</div>
                </ValueField>
              </Col>
            </Row>
            <hr />
            {user.lockedMinutesRemaining ? (
              <>
                <Row>
                  <Col md={6}>
                    <ValueField title={t("details.locked")}>
                      {formatDate(user.lockedAt as string, {
                        toFormat: "dd MMM yyyy",
                        showRelative: true,
                      })}

                      <div>
                        {t("details.lockedRemaining", {
                          lockedMinutes: user.lockedMinutesRemaining,
                        })}
                      </div>
                    </ValueField>
                  </Col>
                </Row>
                <hr />
              </>
            ) : null}

            <Row>
              <Col md={6}>
                <ValueField title={t("details.currentSignedIn")}>
                  <div>{user.currentSignInIp}</div>
                  {formatDate(user.currentSignInAt as string, {
                    toFormat: "dd MMM yyyy",
                    showRelative: true,
                  })}
                </ValueField>
              </Col>
              <Col md={6}>
                <ValueField title={t("details.lastSignedIn")}>
                  {formatDate(user.lastSignInAt as string, {
                    toFormat: "dd MMM yyyy",
                    showRelative: true,
                  })}
                </ValueField>
              </Col>
              <Col md={6}>
                <ValueField title={t("details.confirmed")}>
                  {formatDate(user.confirmedAt as string, {
                    toFormat: "dd MMM yyyy",
                    showRelative: true,
                  })}
                </ValueField>
              </Col>
              <Col md={6}>
                <ValueField title={t("details.confirmationToken")}>
                  <div>{user.confirmationToken as string}</div>
                  {formatDate(user.confirmationSentAt as string, {
                    toFormat: "dd MMM yyyy",
                    showRelative: true,
                  })}
                </ValueField>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col md={6}>
                <ValueField title={t("details.signInCount")}>
                  {user.signInCount}
                </ValueField>
              </Col>
              <Col md={6}>
                <ValueField title={t("details.signInFailedAttempts")}>
                  {user.failedAttempts}
                </ValueField>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col md={6}>
                <ValueField title={t("details.passwordChangedAt")}>
                  {formatDate(user.passwordChangedAt as string, {
                    toFormat: "dd MMM yyyy",
                    showRelative: true,
                  })}
                </ValueField>
              </Col>
              <Col md={6}>
                <ValueField title={t("details.resetPasswordToken")}>
                  <div>{user.resetPasswordToken as string}</div>
                  {user.resetPasswordSentAt
                    ? t("details.resetPasswordTokenSent", {
                        date: formatDate(user.resetPasswordSentAt as string, {
                          toFormat: "dd MMM yyyy",
                          showRelative: true,
                        }),
                      })
                    : null}
                </ValueField>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col md={6}>
                <ValueField title={t("details.privacyPolicy")}>
                  <div>{user.acceptedPolicyVersion}</div>
                  {user.acceptedPolicyAt
                    ? t("details.privacyPolicyAccepted", {
                        date: formatDate(user.acceptedPolicyAt as string, {
                          toFormat: "dd MMM yyyy",
                          showRelative: true,
                        }),
                      })
                    : null}
                </ValueField>
              </Col>
            </Row>
          </Card>
        );
      }}
    />
  );
}
