import React from "react";

import { useTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";
import { Link } from "react-router-dom";

import { IStack } from "../../../../data/models/common";
import { toRelative } from "../../../../utils/utility";
import { AccountRow_viewer$data } from "./__generated__/AccountRow_viewer.graphql";
import AccountBadge from "./AccountBadge";

type Props = {
  viewer: AccountRow_viewer$data;
  stacksById: Map<string, IStack>;
};

export function AccountRow(props: Props) {
  const { t } = useTranslation("accounts");
  const { stacksById } = props;

  const user = props.viewer;
  return (
    <tr key={user.id}>
      <td>
        <Link to={`/account/${user.id}`}>{user.email}</Link>
      </td>
      <td>
        <AccountBadge user={user} />
      </td>
      <td>{user.locale}</td>
      <td>
        {user.userStacks.map((stack, index) => {
          const { stackId } = stack;
          const stackInfo = stacksById?.get(stackId);
          return (
            // eslint-disable-next-line react/no-array-index-key
            <div key={`${user.id}-${index}`}>
              <Link to={`/stack/${stackId}`}>{stackInfo?.stackCode}</Link>
            </div>
          );
        })}
      </td>
      <td>
        {user.acceptedPolicyAt
          ? t("table.rows.acceptedPrivacyPolicy", {
              relativeTime: toRelative(user.acceptedPolicyAt as string, {
                defaultValue: "-",
              }),
              version: user.acceptedPolicyVersion || "-",
            })
          : "-"}
      </td>
      <td>{toRelative(user.lastSignInAt as string, { defaultValue: "-" })}</td>
      <td>{toRelative(user.updatedAt as string, { defaultValue: "-" })}</td>
    </tr>
  );
}

export default createFragmentContainer(AccountRow, {
  viewer: graphql`
    fragment AccountRow_viewer on User {
      id
      email
      confirmedAt
      confirmationSentAt
      confirmationToken
      lockedMinutesRemaining
      deleted
      locale
      lastSignInAt
      acceptedPolicyAt
      acceptedPolicyVersion
      updatedAt
      userStacks {
        stackId
        synced
      }
    }
  `,
});
