import { graphql } from "react-relay";

export const GetAllBusinessEmploymentPerformanceRatingsQuery = graphql`
  query EmploymentPerformanceRatingsQueries_EmploymentPerformanceRatingsQuery(
    $businessId: ID!
  ) {
    employmentPerformanceReviewRatings(businessId: $businessId) {
      nodes {
        id
        name
        rank
        crewAdjustmentType
        managerAdjustmentType
        crewAdjustmentAmount
        managerAdjustmentAmount
        deleted
        updatedAt
      }
    }
  }
`;

export const DeleteEmploymentPerformanceRatingMutation = graphql`
  mutation EmploymentPerformanceRatingsQueries_DeleteEmploymentPerformanceRating_Mutation(
    $businessId: ID!
    $id: ID!
  ) {
    deleteEmploymentPerformanceReviewRating(businessId: $businessId, id: $id)
  }
`;

export const UpdateEmploymentPerformanceRatingMutation = graphql`
  mutation EmploymentPerformanceRatingsQueries_UpdateEmploymentPerformanceRating_Mutation(
    $businessId: ID!
    $id: ID!
    $input: EmploymentPerformanceReviewRatingUpdateInput!
    $force: Boolean!
  ) {
    updateEmploymentPerformanceReviewRating(
      businessId: $businessId
      id: $id
      force: $force
      input: $input
    ) {
      rating {
        id
        name
        rank
        crewAdjustmentType
        managerAdjustmentType
        crewAdjustmentAmount
        managerAdjustmentAmount
        deleted
        updatedAt
      }
    }
  }
`;

export const CreateEmploymentPerformanceRatingMutation = graphql`
  mutation EmploymentPerformanceRatingsQueries_CreateEmploymentPerformanceRating_Mutation(
    $businessId: ID!
    $input: EmploymentPerformanceReviewRatingCreateInput!
  ) {
    createEmploymentPerformanceReviewRating(
      businessId: $businessId
      input: $input
    ) {
      id
      name
      rank
      crewAdjustmentType
      managerAdjustmentType
      crewAdjustmentAmount
      managerAdjustmentAmount
      deleted
      updatedAt
    }
  }
`;

export const ChangeRankEmploymentPerformanceRatingMutation = graphql`
  mutation EmploymentPerformanceRatingsQueries_ChangeRankEmploymentPerformanceRating_Mutation(
    $businessId: ID!
    $id: ID!
    $input: RankChangeEnum!
  ) {
    changeEmploymentPerformanceReviewRatingRank(
      businessId: $businessId
      id: $id
      input: $input
    ) {
      nodes {
        id
        name
        rank
        crewAdjustmentType
        managerAdjustmentType
        crewAdjustmentAmount
        managerAdjustmentAmount
        deleted
        updatedAt
      }
    }
  }
`;
