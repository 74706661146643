import React, { useEffect } from "react";

import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";
import { useQueryLoader } from "react-relay";
import { useParams } from "react-router-dom";

import { useDebouncedValue } from "../../../../../../hooks/useDebouncedValue";
import Loader from "../../../../../common/Loader";
import Searchable from "../../../../../common/Searchable";
import { CorporatesQueries_ListQuery } from "../../__generated__/CorporatesQueries_ListQuery.graphql";
import { UserCorporatesQuery } from "../../CorporatesQueries";
import CorporateUsersTable from "./CorporateUsersTable";

export default function CorporatesUsers() {
  const { t } = useTranslation("corporates");
  const { corporate_id: corporateId } = useParams<{ corporate_id: string }>();

  const [searchValue, debouncedSearchValue, setSearchValue] =
    useDebouncedValue("");

  const [queryReference, loadQuery, disposeQuery] =
    useQueryLoader<CorporatesQueries_ListQuery>(UserCorporatesQuery);

  useEffect(() => {
    loadQuery({
      emailIncludes: debouncedSearchValue,
      corporateIds: [corporateId],
      pageSize: 40,
    });
    return () => {
      disposeQuery();
    };
  }, [debouncedSearchValue, loadQuery]);

  return (
    <>
      <Row className="mb-3">
        <Col xs={12} md={6} lg={4}>
          <Searchable
            searchValue={searchValue}
            onSearchChange={setSearchValue}
            placeholder={t("table.search")}
          />
        </Col>
      </Row>
      <Card body>
        <React.Suspense fallback={<Loader />}>
          {queryReference != null && (
            <CorporateUsersTable queryReference={queryReference} />
          )}
        </React.Suspense>
      </Card>
    </>
  );
}
