import React from "react";

import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";

import {
  DynamicFieldsLayout,
  DynamicFieldsLayoutView,
} from "../../../../../data/generated/stack_internal_schema";
import Field from "../../../../common/Form/Field";
import GroupHeader from "../../../../common/Form/GroupHeader";
import SubGroupHeader from "../../../../common/Form/SubGroupHeader";
import { MetadataFieldWithProps, MetadataUtility } from "../MetadataUtility";

type Props = {
  view: DynamicFieldsLayoutView | null;
  businessDynamicFieldsLayout: DynamicFieldsLayout;
  metadataTypeFields: MetadataFieldWithProps[];
};

export default function DynamicEmploymentMetadataInputGroup({
  view,
  businessDynamicFieldsLayout,
  metadataTypeFields,
}: Props) {
  const { t } = useTranslation("employment");

  // If view is null, assume it's the 'Others' category
  if (!view) {
    return (
      <>
        <GroupHeader>{t("metadata.layout.others")}</GroupHeader>
        <Row>
          {metadataTypeFields.map((businessMetadataTypeField) => (
            <Field
              key={businessMetadataTypeField.metadataType.id}
              isExternal={businessMetadataTypeField.metadataType.external}
              {...businessMetadataTypeField.field}
            />
          ))}
        </Row>
      </>
    );
  }

  const groupsForView = view.groups.map((viewGroupName) => {
    const group = businessDynamicFieldsLayout.groups.find(
      (dynamicFieldGroup) => dynamicFieldGroup.name === viewGroupName,
    );
    return {
      ...group,
      fieldData: MetadataUtility.getMetadataFieldsWithNames(
        metadataTypeFields,
        group?.fields.map((field) => field.metadataTypeName),
      ),
    };
  });

  if (!groupsForView.length) {
    return null;
  }

  return (
    <>
      <GroupHeader>{view.name}</GroupHeader>
      {groupsForView.map((group) => (
        <fieldset key={group.name} className="sub-group">
          <SubGroupHeader>
            {group?.label?.toLocaleUpperCase() ?? ""}
          </SubGroupHeader>
          <Row>
            {group.fieldData.map((metadataField) => {
              return (
                <Field
                  key={metadataField.metadataType.id}
                  isExternal={metadataField.metadataType.external}
                  {...metadataField.field}
                />
              );
            })}
          </Row>
        </fieldset>
      ))}
    </>
  );
}
