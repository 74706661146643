import React from "react";

import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { PreloadedQuery, usePreloadedQuery } from "react-relay";
import { Link } from "react-router-dom";
import { useTheme } from "styled-components";

import { EmploymentTypeConfig } from "../../../data/generated/stack_internal_schema";
import { useAppRouter } from "../../../hooks/useAppRouter";
import { Pillbox, PillboxVariantEnum } from "../../common/Pillbox";
import { EmploymentTypesQueries_GetEmploymentTypeConfigs_Query } from "./__generated__/EmploymentTypesQueries_GetEmploymentTypeConfigs_Query.graphql";
import { GetEmploymentTypeConfigsQuery } from "./EmploymentTypesQueries";

type EmploymentTypeConfigsQuery =
  PreloadedQuery<EmploymentTypesQueries_GetEmploymentTypeConfigs_Query>;
type Props = {
  queryReference: EmploymentTypeConfigsQuery;
};
type Header = {
  name: string;
  span?: number;
};

export default function EmploymentTypesTable({ queryReference }: Props) {
  const { t } = useTranslation("employment-types");
  const theme = useTheme();
  const router = useAppRouter();
  const header = (name: string, span = 1) => {
    return {
      name: name ? t(`table.headers.${name}`) : "",
      span,
    };
  };

  const headers: Header[] = [
    header("name"),
    header("shortName"),
    header("timeclockName"),
    header("fixedShifts"),
    header("timeOffCompliance"),
    header("guaranteedMinHours", 3),
    header(""), // "removed/deleted"
  ];

  const subheaders: Header[] = [
    header("", 4),
    header("enabled"),
    header("enabled"),
    header("warning"),
    header("error"),
    header(""),
  ];

  const employmentTypes = useEmploymentTypeData(queryReference);

  const secondsToHours = (seconds: number) => seconds / 3600;

  return (
    <Table hover size="sm">
      <thead>
        <tr>
          {headers.map((h, i) => (
            <th colSpan={h.span}>{h.name}</th>
          ))}
        </tr>
        <tr>
          {subheaders.map((h, i) => (
            <th colSpan={h.span}>{h.name}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {employmentTypes.map((employmentType) => (
          <tr>
            <td>
              <Link to={`${router.pathname}/edit/${employmentType.id}`}>
                {employmentType.name}
              </Link>
            </td>
            <td>{employmentType.shortName}</td>
            <td>{employmentType.timeClockName}</td>
            <td>{employmentType.fixedShiftRestriction}</td>
            <td>
              {employmentType.timeOffComplianceEnabled ? (
                <FontAwesomeIcon color={theme.bluePrimary} icon={faCheck} />
              ) : (
                <FontAwesomeIcon color={theme.grey300} icon={faTimes} />
              )}
            </td>
            <td>
              {employmentType.gmhEnabled ? (
                <FontAwesomeIcon color={theme.bluePrimary} icon={faCheck} />
              ) : (
                <FontAwesomeIcon color={theme.grey300} icon={faTimes} />
              )}
            </td>
            <td>
              {employmentType.gmhMinimumDurationWarning
                ? `< ${secondsToHours(
                    employmentType.gmhMinimumDurationWarning,
                  )}${t("table.hrs")}`
                : "-"}
            </td>
            <td>
              {employmentType.gmhMinimumDurationError
                ? `< ${secondsToHours(
                    employmentType.gmhMinimumDurationError,
                  )}${t("table.hrs")}`
                : "-"}
            </td>
            <td>
              {employmentType.deleted && (
                <Pillbox
                  variant={PillboxVariantEnum.Removed}
                  text={t("table.removed")}
                />
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

function useEmploymentTypeData(queryReference: EmploymentTypeConfigsQuery) {
  const query = usePreloadedQuery(
    GetEmploymentTypeConfigsQuery,
    queryReference,
  );
  const data = query.employmentTypeConfigs;
  return data.nodes as EmploymentTypeConfig[];
}
