import { SessionInfo } from "../data/models/common";

export default class Authentication {
  public static readonly SESSION_KEY = "session";

  public static restoreSession(): SessionInfo {
    let session = null;
    const sessionStr = sessionStorage.getItem(Authentication.SESSION_KEY);
    if (sessionStr !== null) {
      session = JSON.parse(sessionStr);
    }

    return session;
  }

  public static clearSession() {
    sessionStorage.removeItem(Authentication.SESSION_KEY);
  }

  public static storeSession(sessionInfo: SessionInfo) {
    sessionStorage.setItem(
      Authentication.SESSION_KEY,
      JSON.stringify(sessionInfo),
    );
  }
}
