/**
 * @generated SignedSource<<832aee1854c1ae585e96625a7d6fa16a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateGlobalBusinessStackInput = {
  isSelf?: boolean | null;
  stackDomainName?: string | null;
};
export type CreateGlobalBusinessStackMutation$variables = {
  input: CreateGlobalBusinessStackInput;
};
export type CreateGlobalBusinessStackMutation$data = {
  readonly createGlobalBusinessStack: {
    readonly id: string;
    readonly isSelf: boolean;
    readonly stackDomainName: string;
  };
};
export type CreateGlobalBusinessStackMutation = {
  response: CreateGlobalBusinessStackMutation$data;
  variables: CreateGlobalBusinessStackMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "GlobalBusinessStack",
    "kind": "LinkedField",
    "name": "createGlobalBusinessStack",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isSelf",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "stackDomainName",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateGlobalBusinessStackMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateGlobalBusinessStackMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "532e6f88bb30208a454809698449bbe6",
    "id": null,
    "metadata": {},
    "name": "CreateGlobalBusinessStackMutation",
    "operationKind": "mutation",
    "text": "mutation CreateGlobalBusinessStackMutation(\n  $input: CreateGlobalBusinessStackInput!\n) {\n  createGlobalBusinessStack(input: $input) {\n    id\n    isSelf\n    stackDomainName\n  }\n}\n"
  }
};
})();

(node as any).hash = "938807ee53d267f0305637a9dc35dd15";

export default node;
