/**
 * @generated SignedSource<<12e4acb4866a1cfd117a22c9bce664e1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type LinkUserToEmploymentMutation$variables = {
  businessId: string;
  employmentId: string;
  userId: string;
};
export type LinkUserToEmploymentMutation$data = {
  readonly linkUserToEmployment: {
    readonly firstName: string;
    readonly id: string;
    readonly lastName: string;
    readonly userId: string | null;
  };
};
export type LinkUserToEmploymentMutation = {
  response: LinkUserToEmploymentMutation$data;
  variables: LinkUserToEmploymentMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "businessId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "employmentId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userId"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "businessId",
        "variableName": "businessId"
      },
      {
        "kind": "Variable",
        "name": "employmentId",
        "variableName": "employmentId"
      },
      {
        "kind": "Variable",
        "name": "userId",
        "variableName": "userId"
      }
    ],
    "concreteType": "Employment",
    "kind": "LinkedField",
    "name": "linkUserToEmployment",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "firstName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "userId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "LinkUserToEmploymentMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "LinkUserToEmploymentMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "3877fbc6c875ae493e2e8acb04576368",
    "id": null,
    "metadata": {},
    "name": "LinkUserToEmploymentMutation",
    "operationKind": "mutation",
    "text": "mutation LinkUserToEmploymentMutation(\n  $businessId: ID!\n  $userId: ID!\n  $employmentId: ID!\n) {\n  linkUserToEmployment(businessId: $businessId, userId: $userId, employmentId: $employmentId) {\n    id\n    firstName\n    lastName\n    userId\n  }\n}\n"
  }
};
})();

(node as any).hash = "983802bc43c20cc1fd7d79638009474c";

export default node;
