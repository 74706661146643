/**
 * @generated SignedSource<<55d48c758dc0ea4d4423177f9888f1ad>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UnlinkUserFromEmploymentMutation$variables = {
  businessId: string;
  userId: string;
};
export type UnlinkUserFromEmploymentMutation$data = {
  readonly unlinkUserFromEmployment: boolean;
};
export type UnlinkUserFromEmploymentMutation = {
  response: UnlinkUserFromEmploymentMutation$data;
  variables: UnlinkUserFromEmploymentMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "businessId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userId"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "businessId",
        "variableName": "businessId"
      },
      {
        "kind": "Variable",
        "name": "userId",
        "variableName": "userId"
      }
    ],
    "kind": "ScalarField",
    "name": "unlinkUserFromEmployment",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UnlinkUserFromEmploymentMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "UnlinkUserFromEmploymentMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "adf1c31aeb27d40d3adc413062c6ea7a",
    "id": null,
    "metadata": {},
    "name": "UnlinkUserFromEmploymentMutation",
    "operationKind": "mutation",
    "text": "mutation UnlinkUserFromEmploymentMutation(\n  $userId: ID!\n  $businessId: ID!\n) {\n  unlinkUserFromEmployment(userId: $userId, businessId: $businessId)\n}\n"
  }
};
})();

(node as any).hash = "5eacb3c49dfd8a6ad85737223742b534";

export default node;
