import { commitMutation, Environment, graphql } from "react-relay";

const mutation = graphql`
  mutation SignInMutation($email: String!, $password: String!) {
    signIn(email: $email, password: $password) {
      authToken
      user {
        id
        email
      }
    }
  }
`;

export default (
  environment: Environment,
  email: string,
  password: string,
  callback: Function,
  onError: (error: Error) => void = (err: Error) => {
    alert(err);
  },
) => {
  const variables = {
    email,
    password,
  };

  return commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response, errors) => {
      callback(response, errors);
    },
    onError,
  });
};
