import React from "react";

import { FormikContextType, useFormikContext } from "formik";
import { Col, Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";

import { useModal } from "../../../../../contexts/ModalContext";
import properties from "../../../../../data/csv-settings/break-type-settings.json";
import {
  BreakType,
  LegacyBreakTypeEnum,
  ScheduleBreakType,
} from "../../../../../data/generated/stack_internal_schema";
import { useAppRouter } from "../../../../../hooks/useAppRouter";
import { ConfirmationModal } from "../../../../common/ConfirmationModal";
import Checkbox from "../../../../common/Form/Checkbox";
import DurationInput from "../../../../common/Form/DurationInput";
import DynamicInputGroup, {
  DynamicInputField,
} from "../../../../common/Form/DynamicInputGroup";
import DynamicSelect from "../../../../common/Form/DynamicSelect";
import Field from "../../../../common/Form/Field";
import FormLayout from "../../../../common/Form/FormLayout";
import FormLayoutFooter from "../../../../common/Form/FormLayoutFooter";
import {
  getFieldsByInputObjectName,
  getSettingsByGroup,
} from "../../../../common/Form/formUtilities";
import GroupHeader from "../../../../common/Form/GroupHeader";
import {
  BooleanFieldType,
  ComponentRule,
  IProperty,
  legacyBreakTypeOptions,
  TodoType,
} from "../../../../common/Form/models";
import MultiSelectList, {
  MultiSelectType,
} from "../../../../common/Form/MultiSelectList";
import RadioButtonGroup from "../../../../common/Form/RadioButtonGroup";
import SelectionGroup from "../../../../common/Form/SelectionGroup";
import Switch from "../../../../common/Form/Switch";
import { TimeRangeInput } from "../../../../common/Form/TimeRangeInput";
import HeaderPortal, {
  HeaderPortalBreadcrumbs,
} from "../../../../common/Portal/HeaderPortal";
import { useEmploymentTypeConfigs } from "../../../EmploymentTypes/EmploymentTypesQueries";
import {
  useBreakTypeFormData,
  useBreakTypeMutations,
} from "../../BreakTypeQueries";
import {
  BreakTypePaidThreshold,
  BreakTypePaymentDropdownValue,
  FormUpdateMethod,
  getDefaultRadioButtonValue,
  getPaidUnpaidRadioOptions,
  PaidThresholdContextProvider,
  shouldShowPaidAfter,
  shouldShowUnpaidAfter,
  usePaidThresholdContext,
} from "../../BreakTypeUtility";
import { PaidThresholdFormGroup } from "./PaidThresholdFormGroup";

type Props = {
  breakTypeId: string | undefined;
  scheduleBreakTypeId: string | undefined;
};

const breakTypeFields = getFieldsByInputObjectName(
  properties as unknown as IProperty[],
  "BreakTypeCreateInput",
);

export default function BreakTypesProfileForm({
  breakTypeId,
  scheduleBreakTypeId,
}: Props) {
  const { t } = useTranslation("break-types");
  const router = useAppRouter<{ business_id: string; schedule_id?: string }>();
  const { showModal, hideModal } = useModal();
  const {
    params: { business_id: businessId, schedule_id: scheduleId },
  } = router;

  const breakTypesRootUrl = router.getGoBackUrl("/break_types");
  const isScheduleContext = scheduleId != null;

  const [
    breakType,
    formUpdateMethod,
    { businessBreakType, scheduleBreakType },
  ] = useBreakTypeFormData(
    businessId,
    scheduleId,
    breakTypeId,
    scheduleBreakTypeId,
    isScheduleContext,
  );

  const [
    saveBreakType,
    { activateBreakType, deactivateBreakType, deleteScheduleBreakType },
  ] = useBreakTypeMutations(formUpdateMethod);

  const componentRules = useFormComponentRules(
    businessId,
    breakType,
    isScheduleContext,
    formUpdateMethod,
  );
  const validationRules = useBreakTypeValidationRules(
    isScheduleContext,
    breakType,
    businessBreakType,
  );

  const onSaveClick = (
    data: Partial<BreakType | ScheduleBreakType>,
    errorHandler: (error: Error) => void,
  ) => {
    // If we want to unset the availableRange (start & end time), we need to pass availableRange as null
    const removeAvailableRange =
      data.availableRange != null &&
      (!data.availableRange.rangeStartTime ||
        !data.availableRange.rangeEndTime);

    const input = {
      ...data,
      ...(removeAvailableRange && {
        availableRange: null,
      }),
      ...(formUpdateMethod === FormUpdateMethod.OverridingBreakType && {
        scheduleId,
        breakTypeId,
      }),
    };

    saveBreakType({
      variables: {
        businessId,
        ...((formUpdateMethod === FormUpdateMethod.UpdateBreakType ||
          formUpdateMethod === FormUpdateMethod.UpdateScheduleBreakType) && {
          id: breakType.id,
        }),
        input,
      },

      onCompleted() {
        toast(
          formUpdateMethod === FormUpdateMethod.CreateBreakType
            ? t("toast.created")
            : t("toast.updated"),
        );
        router.replace(breakTypesRootUrl);
      },
      onError(error: Error) {
        errorHandler(error);
      },
    });
  };

  const onActivateDeactivateClick = (activate: boolean) => {
    const i18nKey = activate ? "activate" : "deactivate";
    const activationMutation = activate
      ? activateBreakType
      : deactivateBreakType;

    showModal(
      <ConfirmationModal
        onClose={hideModal}
        okClicked={() => {
          activationMutation({
            variables: {
              businessId,
              id: breakType.id,
            },
            onCompleted() {
              toast(t(`toast.${i18nKey}`));
              hideModal();
              router.replace(breakTypesRootUrl);
            },
            onError(error: Error) {
              alert(error);
            },
          });
        }}
        variant={activate ? "primary" : "danger"}
        title={t(`activateDeactivateModal.${i18nKey}.title`)}
        okText={t(`activateDeactivateModal.${i18nKey}.ok_button`)}
      >
        <Trans
          i18nKey={`break-types:activateDeactivateModal.${i18nKey}.body`}
          values={{ breakTypeName: breakType.name }}
          components={{ bold: <strong /> }}
        />
      </ConfirmationModal>,
    );
  };

  const onSetToDefaultClick = () => {
    showModal(
      <ConfirmationModal
        onClose={hideModal}
        okClicked={() => {
          deleteScheduleBreakType({
            variables: {
              businessId,
              id: breakType.id,
            },
            onCompleted() {
              toast(t("toast.setToDefault"));
              hideModal();
              router.replace(breakTypesRootUrl);
            },
            onError(error: Error) {
              alert(error);
            },
          });
        }}
        title={t("resetToDefaultModal.title")}
        okText={t("resetToDefaultModal.okText")}
      >
        <p>{t("resetToDefaultModal.body")}</p>
      </ConfirmationModal>,
    );
  };

  return (
    <>
      <HeaderPortal as="span" elementId="sub-header-portal">
        <HeaderPortalBreadcrumbs
          breadcrumbs={[
            <Link to={breakTypesRootUrl}>
              <span>{t("nav.breakTypes")}</span>
            </Link>,
            <span>
              {breakTypeId ? breakType.name : t("form.create_title")}
            </span>,
          ]}
        />
      </HeaderPortal>

      <FormLayout<BreakType | ScheduleBreakType>
        isCreate={formUpdateMethod === FormUpdateMethod.CreateBreakType}
        base={breakType}
        onSave={onSaveClick}
        propertyList={properties as any}
        validationRules={validationRules}
        componentRules={componentRules}
      >
        {isScheduleContext && (
          <Row>
            <Field
              label={t("form.enableBreakType")}
              hideLabel
              md={6}
              lg={5}
              fieldKey="hidden"
              schemaFieldType={BooleanFieldType}
              component={Switch}
              componentProps={{
                boldLabels: true,
                // Slightly confusing, but we display this field as "Enable Break Type", but in the backend the flag is called 'hidden'
                toBoolean: (v: boolean) => !v,
                fromBoolean: (v: boolean) => !v,
              }}
            />
          </Row>
        )}

        <DynamicInputGroup
          fields={getSettingsByGroup(
            breakTypeFields.filter((x) => x.group === "Details"),
          )}
        />

        <PaidThresholdContextProvider
          defaultValue={
            isScheduleContext
              ? scheduleBreakType?.schedulePaidThreshold
              : breakType?.paidThreshold
          }
          businessValue={businessBreakType?.paidThreshold}
          showDefaultOption={isScheduleContext}
        >
          <PaymentGroup
            businessBreakType={businessBreakType}
            isScheduleContext={isScheduleContext}
            componentRules={componentRules}
          />
        </PaidThresholdContextProvider>

        <DynamicInputGroup
          fields={getSettingsByGroup(
            breakTypeFields.filter((x) => x.group === "Employment"),
          )}
        />

        <FormLayoutFooter
          isCreate={formUpdateMethod === FormUpdateMethod.CreateBreakType}
          rightActionButtons={
            formUpdateMethod === FormUpdateMethod.UpdateScheduleBreakType
              ? {
                  before: true,
                  buttons: (
                    <Button
                      variant="secondary"
                      onClick={onSetToDefaultClick}
                      className="mr-2"
                    >
                      {t("form.setToDefault")}
                    </Button>
                  ),
                }
              : undefined
          }
          leftActionButtons={
            formUpdateMethod === FormUpdateMethod.UpdateBreakType
              ? {
                  before: true,
                  buttons: (
                    <Button
                      variant={breakType.deleted ? "primary" : "danger"}
                      onClick={() =>
                        onActivateDeactivateClick(breakType.deleted)
                      }
                      className="mr-2"
                    >
                      {breakType.deleted
                        ? t("form.activate")
                        : t("form.deactivate")}
                    </Button>
                  ),
                }
              : undefined
          }
        />
      </FormLayout>
    </>
  );
}

function useBreakTypeValidationRules(
  isScheduleContext: boolean,
  data: any,
  businessBreakType: any,
) {
  const { t } = useTranslation("break-types");

  return yup.object({
    name: yup.string().required().label(t("form.property.name")),
    breakTypeCode: yup
      .string()
      .required()
      .label(t("form.property.breakTypeCode")),
    breakScreenOrdering: yup
      .number()
      .required()
      .label(t("form.property.breakScreenOrdering")),
    legacyBreakType: yup
      .string()
      .oneOf([
        LegacyBreakTypeEnum.Meal,
        LegacyBreakTypeEnum.Rest,
        LegacyBreakTypeEnum.Unknown,
      ])
      .required()
      .label(t("form.property.legacyBreakType")),

    // In the schedule context - paidThreshold can be null since null = business default
    paidThreshold: isScheduleContext
      ? yup
          .number()
          .min(0)
          .max(BreakTypePaidThreshold.AlwaysPaidDefault)
          .nullable(true)
          .label(t("form.property.paidThreshold"))
      : yup
          .number()
          .min(0)
          .max(BreakTypePaidThreshold.AlwaysPaidDefault)
          .required()
          .label(t("form.property.paidThreshold")),

    // The validation here is not intuitive as we're not just validating the form against its current
    // data model, but also the data at the business level
    paidAfter: yup
      .number()
      .nullable()
      .test(
        "paid_after_validation",
        t("form.paidAfter.validation_message"),
        (...options) => {
          const actualValue =
            options[1].parent.paidThreshold ?? businessBreakType.paidThreshold;
          const dropdownValue = getDefaultRadioButtonValue(actualValue, false);

          if (dropdownValue === BreakTypePaymentDropdownValue.Custom) {
            const value = options[0] ?? businessBreakType?.paidAfter ?? 0;
            if (value == null || value === 0) {
              return true;
            }
            return value > actualValue;
          }
          return true;
        },
      ),

    unpaidAfter: yup
      .number()
      .nullable()
      .test(
        "unpaid_after_validation",
        t("form.unpaidAfter.validation_message"),
        (...options) => {
          const value = options[0] ?? businessBreakType?.unpaidAfter ?? 0;
          const actualValue =
            options[1].parent.paidThreshold ?? businessBreakType.paidThreshold;
          const dropdownValue = getDefaultRadioButtonValue(actualValue, false);

          if (dropdownValue === BreakTypePaymentDropdownValue.Always) {
            if (value == null || value === 0) {
              return true;
            }
            return value < 1440;
          }
          return true;
        },
      ),
  });
}

function useFormComponentRules(
  businessId: string,
  breakTypeData: ScheduleBreakType,
  isScheduleContext: boolean,
  formUpdateMethod: FormUpdateMethod,
) {
  const [employmentTypeConfigsData] = useEmploymentTypeConfigs({
    businessId,
    deleted: false,
  });

  const { t } = useTranslation("break-types");
  // Determine which fields should be disabled based on the page context (schedule or business)
  const disabledComponentRules: Record<string, ComponentRule> = Object.keys(
    breakTypeData ?? {},
  ).reduce((acc, breakTypePropertyKey) => {
    let disabled = false;

    if (isScheduleContext) {
      // In schedule context, only these fields can be changed
      disabled = ![
        "hidden",
        "canOverrideClockedPaidBreak",
        "paidThreshold",
        "unpaidAfter",
        "paidAfter",
      ].includes(breakTypePropertyKey);
    } else if (formUpdateMethod !== FormUpdateMethod.CreateBreakType) {
      // Cannot be changed if updating (new break types only)
      disabled = ["breakTypeCode"].includes(breakTypePropertyKey);
    }

    return {
      ...acc,
      [breakTypePropertyKey]: {
        disabled,
      },
    };
  }, {});

  const employmentTypesOptions = employmentTypeConfigsData.map((config) => {
    return { label: config.name, value: config.employmentTypeCode };
  });

  const componentRules: Record<string, ComponentRule> = {
    ...disabledComponentRules,
    employmentTypeCodes: {
      ...disabledComponentRules.employmentTypeCodes,
      component: MultiSelectList,
      componentProps: {
        allOptions: employmentTypesOptions,
        selectableOptions: employmentTypesOptions,
        menuPlacement: "top",
        multiStyle: MultiSelectType.Pill,
        placeholder: t("form.employmentTypesPlaceholder"),
      },
    },
    legacyBreakType: {
      disabled:
        isScheduleContext ||
        breakTypeData.legacyBreakType === LegacyBreakTypeEnum.Unknown,
      component: DynamicSelect,
      componentProps: {
        options:
          // Disable field if value is "Unknown" and remove from the dropdown options if it isn't (we don't want users to select it)
          breakTypeData?.legacyBreakType === LegacyBreakTypeEnum.Unknown
            ? legacyBreakTypeOptions
            : legacyBreakTypeOptions.filter(
                (legacyBreakType) =>
                  legacyBreakType.value !== LegacyBreakTypeEnum.Unknown,
              ),
      },
    },
    description: {
      ...disabledComponentRules.description,
      md: 12,
      xs: 12,
      lg: 6,
      componentProps: {
        xs: 12,
        md: 12,
        lg: 12,
      },
    },

    availableRange: {
      ...disabledComponentRules.availableRange,
      component: TimeRangeInput,
      componentProps: {
        startFieldKey: "availableRange.rangeStartTime",
        endFieldKey: "availableRange.rangeEndTime",
      },
      md: 12,
      xs: 12,
      lg: 3,
    },
  };

  return componentRules;
}

/**
 * PaymentGroup was split out of a single DynamicInputGroup as its not convenient/easy to dynamically change the col widths
 * based on the current values in the formikContext (componentRules are outside of the form layout)
 */
function PaymentGroup({
  businessBreakType,
  isScheduleContext,
  componentRules,
}: {
  businessBreakType: TodoType | undefined;
  isScheduleContext: boolean;
  componentRules: Record<string, ComponentRule>;
}) {
  const { t } = useTranslation("break-types");
  const {
    values: { paidAfter, unpaidAfter },
  } = useFormikContext<TodoType>();

  const { dropdownValue, businessDropdownValue } = usePaidThresholdContext();

  const showThresholdField =
    dropdownValue === BreakTypePaymentDropdownValue.Custom ||
    (dropdownValue === BreakTypePaymentDropdownValue.DefaultToBusiness &&
      businessDropdownValue === BreakTypePaymentDropdownValue.Custom);

  const showPaidAfter = shouldShowPaidAfter(
    dropdownValue,
    businessDropdownValue,
  );
  const showUnpaidAfter = shouldShowUnpaidAfter(
    dropdownValue,
    businessDropdownValue,
  );

  // If the user has overridden a unpaid/paid after value at schedule level try to use that first, otherwise
  // default to the business default or 0 if all are null
  const paidAfterValue =
    (showPaidAfter ? paidAfter ?? businessBreakType?.paidAfter : paidAfter) ??
    0;
  const unpaidAfterValue =
    (showUnpaidAfter
      ? unpaidAfter ?? businessBreakType?.unpaidAfter
      : unpaidAfter) ?? 0;

  const disablePaymentOverride = paidAfterValue > 0 || unpaidAfterValue > 0;

  return (
    <>
      <GroupHeader>{t("form.headers.payment")}</GroupHeader>
      <Row>
        <Field
          md={showThresholdField ? 6 : 3}
          lg={showThresholdField ? 6 : 3}
          sm={showThresholdField ? 6 : 3}
          hideLabel
          hideDescription
          fieldKey="paidThreshold"
          component={PaidThresholdFormGroup}
          componentProps={{
            businessValue: businessBreakType?.paidThreshold,
            showDefaultOption: isScheduleContext,
          }}
          onValueChanged={(v, formikContext) => {
            const newDropdownValue = getDefaultRadioButtonValue(
              v,
              isScheduleContext,
            );

            formikContext.setFieldValue(
              "unpaidAfter",
              shouldShowUnpaidAfter(newDropdownValue, businessDropdownValue)
                ? formikContext.initialValues.unpaidAfter
                : 0,
            );
            formikContext.setFieldValue(
              "paidAfter",
              shouldShowPaidAfter(newDropdownValue, businessDropdownValue)
                ? formikContext.initialValues.paidAfter
                : 0,
            );
          }}
          hideError
        />

        <UnpaidPaidField
          name="paidAfter"
          visible={showPaidAfter}
          displayAsRadio={
            isScheduleContext &&
            businessDropdownValue !== BreakTypePaymentDropdownValue.Always
          }
          businessBreakType={businessBreakType}
        />

        <UnpaidPaidField
          name="unpaidAfter"
          visible={showUnpaidAfter}
          displayAsRadio={
            isScheduleContext &&
            businessDropdownValue === BreakTypePaymentDropdownValue.Always
          }
          businessBreakType={businessBreakType}
        />

        {disablePaymentOverride ? (
          <Col>
            <Form.Label className="form-label mb-0">
              {t("form.canOverrideClockedPaidBreak.paymentOverride")}
            </Form.Label>
            <Form.Text className="text-muted text-left mt-0">
              {t("form.canOverrideClockedPaidBreak.disabled_text")}
            </Form.Text>
          </Col>
        ) : (
          <DynamicInputField
            field={
              breakTypeFields.find(
                (x) => x.name === "canOverrideClockedPaidBreak",
              ) as IProperty
            }
            component={isScheduleContext ? SelectionGroup : Checkbox}
            componentProps={{
              options: isScheduleContext
                ? [
                    {
                      label: t("form.canOverrideClockedPaidBreak.default", {
                        value: businessBreakType?.canOverrideClockedPaidBreak
                          ? t("form.canOverrideClockedPaidBreak.always")
                          : t("form.canOverrideClockedPaidBreak.never"),
                      }),
                      value: null,
                    },
                    {
                      label: t("form.canOverrideClockedPaidBreak.always"),
                      value: true,
                    },
                    {
                      label: t("form.canOverrideClockedPaidBreak.never"),
                      value: false,
                    },
                  ]
                : undefined,
            }}
            hideLabel={!isScheduleContext}
            {...componentRules.canOverrideClockedPaidBreak}
          />
        )}
      </Row>
    </>
  );
}

function UnpaidPaidField(props: {
  visible: boolean;
  name: string;
  displayAsRadio: boolean;
  businessBreakType: TodoType;
}) {
  const { visible, name, displayAsRadio, businessBreakType } = props;

  const onPaidAfterOrUnpaidAfterChanged = React.useCallback(
    (v: number, formContext: FormikContextType<any>) => {
      formContext.setFieldValue(
        "canOverrideClockedPaidBreak",
        v != null && v > 0
          ? false
          : formContext.initialValues.canOverrideClockedPaidBreak,
      );
    },
    [],
  );

  if (!visible) {
    return null;
  }

  return (
    <DynamicInputField
      field={breakTypeFields.find((x) => x.name === name) as IProperty}
      component={displayAsRadio ? RadioButtonGroup : DurationInput}
      componentProps={{
        fieldKey: name,
        ...(displayAsRadio && {
          options: getPaidUnpaidRadioOptions(businessBreakType?.[name]),
        }),
        ...(!displayAsRadio && {
          postfix: "mins",
        }),
        placeholder: "-",
        customDisplayValues: {
          values: [0, ""],
          displayAs: "-",
        },
        fallbackValue: 0,
      }}
      onValueChanged={onPaidAfterOrUnpaidAfterChanged}
    />
  );
}
