/**
 * @generated SignedSource<<aeb0b1c2c5e0c88e76a769e9da3f38c9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StackPeopleTable_viewer$data = {
  readonly searchStackForEmployments: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly acceptedInvite: boolean;
        readonly business: {
          readonly businessName: string;
          readonly id: string;
        };
        readonly computedName: string;
        readonly deleted: boolean;
        readonly during: string | null;
        readonly email: string;
        readonly updatedAt: string;
        readonly userEmail: string | null;
        readonly userId: string | null;
      };
    }>;
    readonly pageInfo: {
      readonly endCursor: string | null;
      readonly hasNextPage: boolean;
    };
  };
  readonly " $fragmentType": "StackPeopleTable_viewer";
};
export type StackPeopleTable_viewer$key = {
  readonly " $data"?: StackPeopleTable_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"StackPeopleTable_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "acceptedInvite"
    },
    {
      "kind": "RootArgument",
      "name": "after"
    },
    {
      "kind": "RootArgument",
      "name": "currentlyEmployed"
    },
    {
      "kind": "RootArgument",
      "name": "deleted"
    },
    {
      "kind": "RootArgument",
      "name": "email"
    },
    {
      "kind": "RootArgument",
      "name": "pageSize"
    },
    {
      "kind": "RootArgument",
      "name": "unlinkedUser"
    },
    {
      "kind": "RootArgument",
      "name": "userId"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "pageSize",
        "cursor": "after",
        "direction": "forward",
        "path": [
          "searchStackForEmployments"
        ]
      }
    ]
  },
  "name": "StackPeopleTable_viewer",
  "selections": [
    {
      "alias": "searchStackForEmployments",
      "args": [
        {
          "kind": "Variable",
          "name": "acceptedInvite",
          "variableName": "acceptedInvite"
        },
        {
          "kind": "Variable",
          "name": "currentlyEmployed",
          "variableName": "currentlyEmployed"
        },
        {
          "kind": "Variable",
          "name": "deleted",
          "variableName": "deleted"
        },
        {
          "kind": "Variable",
          "name": "email",
          "variableName": "email"
        },
        {
          "kind": "Variable",
          "name": "unlinkedUser",
          "variableName": "unlinkedUser"
        },
        {
          "kind": "Variable",
          "name": "userId",
          "variableName": "userId"
        }
      ],
      "concreteType": "EmploymentConnection",
      "kind": "LinkedField",
      "name": "__StackPeopleTable_searchStackForEmployments_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "EmploymentEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Employment",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "computedName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "email",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "during",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "acceptedInvite",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "deleted",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "userId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "userEmail",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Business",
                  "kind": "LinkedField",
                  "name": "business",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "id",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "businessName",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "updatedAt",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "InternalQuery",
  "abstractKey": null
};

(node as any).hash = "a38e8d55943775a70a02dd87fb76ad4c";

export default node;
