/**
 * @generated SignedSource<<5cf3a98d42f3e038e6e1778fe480f312>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CorporatesQueries_ListFragment$data = {
  readonly userCorporates: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly business: {
          readonly businessName: string;
        };
        readonly corporate: {
          readonly id: string;
        };
        readonly id: string;
        readonly lastAccessedAt: string | null;
        readonly personKey: string;
        readonly user: {
          readonly email: string;
          readonly id: string;
        };
      };
    }>;
    readonly pageInfo: {
      readonly endCursor: string | null;
      readonly hasNextPage: boolean;
    };
  };
  readonly " $fragmentType": "CorporatesQueries_ListFragment";
};
export type CorporatesQueries_ListFragment$key = {
  readonly " $data"?: CorporatesQueries_ListFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CorporatesQueries_ListFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "userCorporates"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "after"
    },
    {
      "kind": "RootArgument",
      "name": "corporateIds"
    },
    {
      "kind": "RootArgument",
      "name": "emailIncludes"
    },
    {
      "kind": "RootArgument",
      "name": "pageSize"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "pageSize",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "pageSize",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./UserCorporatesPaginationQuery.graphql')
    }
  },
  "name": "CorporatesQueries_ListFragment",
  "selections": [
    {
      "alias": "userCorporates",
      "args": [
        {
          "kind": "Variable",
          "name": "corporateIds",
          "variableName": "corporateIds"
        },
        {
          "kind": "Variable",
          "name": "emailIncludes",
          "variableName": "emailIncludes"
        }
      ],
      "concreteType": "UserCorporateConnection",
      "kind": "LinkedField",
      "name": "__CorporatesTable_userCorporates_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UserCorporateEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "UserCorporate",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "User",
                  "kind": "LinkedField",
                  "name": "user",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "email",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "personKey",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "lastAccessedAt",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Business",
                  "kind": "LinkedField",
                  "name": "business",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "businessName",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Corporate",
                  "kind": "LinkedField",
                  "name": "corporate",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "InternalQuery",
  "abstractKey": null
};
})();

(node as any).hash = "cace93f5e23ee9b0f0ce08f6d2b349bf";

export default node;
