/**
 * @generated SignedSource<<c6a2b2e92682f7443b7d48face5c6d47>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type EmploymentMetadataQueries_GetEmploymentScheduleHistories_Query$variables = {
  businessId: string;
  employmentId: string;
};
export type EmploymentMetadataQueries_GetEmploymentScheduleHistories_Query$data = {
  readonly employmentScheduleHistories: {
    readonly nodes: ReadonlyArray<{
      readonly employmentId: string;
      readonly id: string;
      readonly isHomeSchedule: boolean;
      readonly schedule: {
        readonly dayStartTime: string | null;
        readonly timeZone: string | null;
      } | null;
    }>;
  };
};
export type EmploymentMetadataQueries_GetEmploymentScheduleHistories_Query = {
  response: EmploymentMetadataQueries_GetEmploymentScheduleHistories_Query$data;
  variables: EmploymentMetadataQueries_GetEmploymentScheduleHistories_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "businessId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "employmentId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "businessId",
    "variableName": "businessId"
  },
  {
    "kind": "Variable",
    "name": "employmentId",
    "variableName": "employmentId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isHomeSchedule",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "employmentId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dayStartTime",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timeZone",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EmploymentMetadataQueries_GetEmploymentScheduleHistories_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EmploymentScheduleHistoryConnection",
        "kind": "LinkedField",
        "name": "employmentScheduleHistories",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "EmploymentScheduleHistory",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Schedule",
                "kind": "LinkedField",
                "name": "schedule",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "InternalQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EmploymentMetadataQueries_GetEmploymentScheduleHistories_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EmploymentScheduleHistoryConnection",
        "kind": "LinkedField",
        "name": "employmentScheduleHistories",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "EmploymentScheduleHistory",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Schedule",
                "kind": "LinkedField",
                "name": "schedule",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a844124cdc15cae8f8e8b8b7e8a78cb7",
    "id": null,
    "metadata": {},
    "name": "EmploymentMetadataQueries_GetEmploymentScheduleHistories_Query",
    "operationKind": "query",
    "text": "query EmploymentMetadataQueries_GetEmploymentScheduleHistories_Query(\n  $businessId: ID!\n  $employmentId: ID!\n) {\n  employmentScheduleHistories(businessId: $businessId, employmentId: $employmentId) {\n    nodes {\n      id\n      isHomeSchedule\n      employmentId\n      schedule {\n        dayStartTime\n        timeZone\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "09161b9f37c3d2197c3fcd08e714090f";

export default node;
