import { commitMutation, Environment, graphql } from "react-relay";

const mutation = graphql`
  mutation UpdateRulesOnScheduleMutation(
    $businessId: ID!
    $scheduleId: ID!
    $ruleSetName: String!
    $ruleSetVersion: String!
    $ruleSetSubscriptionStatus: RuleSetSubscriptionStatusEnum!
  ) {
    updateRulesOnSchedule(
      businessId: $businessId
      scheduleId: $scheduleId
      ruleSetName: $ruleSetName
      ruleSetVersion: $ruleSetVersion
      ruleSetSubscriptionStatus: $ruleSetSubscriptionStatus
    ) {
      ruleSetName
      ruleSetVersion
      ruleSetSubscriptionStatus
      ruleSetMeta
    }
  }
`;

export type UpdateRulesOnScheduleMutationInput = {
  businessId: string;
  scheduleId: string;
  ruleSetName: string;
  ruleSetVersion: string;
  ruleSetSubscriptionStatus: string;
};

export default (
  environment: Environment,
  input: UpdateRulesOnScheduleMutationInput,
  callback: Function,
  onError: (error: Error) => void = (err: Error) => {
    alert(err);
  },
) => {
  const variables = input;

  return commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response: {}, errors: readonly any[] | null | undefined) => {
      callback(response, errors);
    },
    onError,
  });
};
