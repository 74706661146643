import { commitMutation, Environment, graphql } from "react-relay";

const mutation = graphql`
  mutation CreateGlobalBusinessMutation($input: CreateGlobalBusinessInput!) {
    createGlobalBusiness(input: $input) {
      id
      name
      employmentUidStart
    }
  }
`;

export default (
  environment: Environment,
  input: {
    name: string;
    employmentUidStart?: BigInt;
  },
  callback: Function,
  onError: (error: Error) => void = (err: Error) => {
    alert(err);
  },
) => {
  const variables = {
    input,
  };

  return commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response: {}, errors: readonly any[] | null | undefined) => {
      callback(response, errors);
    },
    onError,
  });
};
