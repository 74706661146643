/**
 * @generated SignedSource<<f25e5f7288b8d3163579a20f8c4e717b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type FixedShiftRestrictionEnum = "not_applicable" | "optional" | "required" | "%future added value";
export type EmploymentTypesQueries_GetSingleEmploymentTypeConfig_Query$variables = {
  businessId: string;
  deleted?: boolean | null;
  id: string;
};
export type EmploymentTypesQueries_GetSingleEmploymentTypeConfig_Query$data = {
  readonly employmentTypeConfigs: {
    readonly nodes: ReadonlyArray<{
      readonly code: string | null;
      readonly deleted: boolean;
      readonly employmentTypeCode: string;
      readonly fixedShiftRestriction: FixedShiftRestrictionEnum;
      readonly gmhEnabled: boolean;
      readonly gmhMinimumDurationError: any | null;
      readonly gmhMinimumDurationWarning: any | null;
      readonly id: string;
      readonly isDefault: boolean;
      readonly name: string;
      readonly shortName: string;
      readonly smallId: number;
      readonly timeClockName: string;
      readonly timeOffComplianceEnabled: boolean;
    }>;
  };
};
export type EmploymentTypesQueries_GetSingleEmploymentTypeConfig_Query = {
  response: EmploymentTypesQueries_GetSingleEmploymentTypeConfig_Query$data;
  variables: EmploymentTypesQueries_GetSingleEmploymentTypeConfig_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "businessId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "deleted"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "businessId",
        "variableName": "businessId"
      },
      {
        "kind": "Variable",
        "name": "deleted",
        "variableName": "deleted"
      },
      {
        "items": [
          {
            "kind": "Variable",
            "name": "ids.0",
            "variableName": "id"
          }
        ],
        "kind": "ListValue",
        "name": "ids"
      }
    ],
    "concreteType": "EmploymentTypeConfigConnection",
    "kind": "LinkedField",
    "name": "employmentTypeConfigs",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "EmploymentTypeConfig",
        "kind": "LinkedField",
        "name": "nodes",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "deleted",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "employmentTypeCode",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fixedShiftRestriction",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "gmhEnabled",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "gmhMinimumDurationError",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "gmhMinimumDurationWarning",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isDefault",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "shortName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "smallId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "timeClockName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "timeOffComplianceEnabled",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EmploymentTypesQueries_GetSingleEmploymentTypeConfig_Query",
    "selections": (v1/*: any*/),
    "type": "InternalQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EmploymentTypesQueries_GetSingleEmploymentTypeConfig_Query",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "02cff844bfce55cd8de3bcc661e66a32",
    "id": null,
    "metadata": {},
    "name": "EmploymentTypesQueries_GetSingleEmploymentTypeConfig_Query",
    "operationKind": "query",
    "text": "query EmploymentTypesQueries_GetSingleEmploymentTypeConfig_Query(\n  $businessId: ID!\n  $deleted: Boolean\n  $id: ID!\n) {\n  employmentTypeConfigs(businessId: $businessId, deleted: $deleted, ids: [$id]) {\n    nodes {\n      id\n      code\n      deleted\n      employmentTypeCode\n      fixedShiftRestriction\n      gmhEnabled\n      gmhMinimumDurationError\n      gmhMinimumDurationWarning\n      isDefault\n      name\n      shortName\n      smallId\n      timeClockName\n      timeOffComplianceEnabled\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "736533e460cc129e6d3605bac257198e";

export default node;
