/**
 * @generated SignedSource<<8cfc15ac4f1aaa64b51206dd6510729f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CorporatesQueries_ListQuery$variables = {
  after?: string | null;
  corporateIds?: ReadonlyArray<string> | null;
  emailIncludes?: string | null;
  pageSize: number;
};
export type CorporatesQueries_ListQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"CorporatesQueries_ListFragment">;
};
export type CorporatesQueries_ListQuery = {
  response: CorporatesQueries_ListQuery$data;
  variables: CorporatesQueries_ListQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "corporateIds"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "emailIncludes"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pageSize"
},
v4 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "corporateIds",
    "variableName": "corporateIds"
  },
  {
    "kind": "Variable",
    "name": "emailIncludes",
    "variableName": "emailIncludes"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "pageSize"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CorporatesQueries_ListQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "CorporatesQueries_ListFragment"
      }
    ],
    "type": "InternalQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "CorporatesQueries_ListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "UserCorporateConnection",
        "kind": "LinkedField",
        "name": "userCorporates",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserCorporateEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserCorporate",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "email",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "personKey",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastAccessedAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Business",
                    "kind": "LinkedField",
                    "name": "business",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "businessName",
                        "storageKey": null
                      },
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Corporate",
                    "kind": "LinkedField",
                    "name": "corporate",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v4/*: any*/),
        "filters": [
          "corporateIds",
          "emailIncludes"
        ],
        "handle": "connection",
        "key": "CorporatesTable_userCorporates",
        "kind": "LinkedHandle",
        "name": "userCorporates"
      }
    ]
  },
  "params": {
    "cacheID": "cc1680640d18bfc38bc9772e6e47fc34",
    "id": null,
    "metadata": {},
    "name": "CorporatesQueries_ListQuery",
    "operationKind": "query",
    "text": "query CorporatesQueries_ListQuery(\n  $emailIncludes: String\n  $corporateIds: [ID!]\n  $pageSize: Int!\n  $after: String\n) {\n  ...CorporatesQueries_ListFragment\n}\n\nfragment CorporatesQueries_ListFragment on InternalQuery {\n  userCorporates(corporateIds: $corporateIds, emailIncludes: $emailIncludes, first: $pageSize, after: $after) {\n    edges {\n      node {\n        id\n        user {\n          id\n          email\n        }\n        personKey\n        lastAccessedAt\n        business {\n          businessName\n          id\n        }\n        corporate {\n          id\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c36bde7800438ac3edc9274f342e92c6";

export default node;
