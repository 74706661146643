/**
 * @generated SignedSource<<0a90510dc83c0d2d2d82c500638cc7f9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PayPeriodTable_viewer$data = {
  readonly payPeriods: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly approvedAt: string | null;
        readonly approvedById: string | null;
        readonly endTime: string;
        readonly id: string;
        readonly payPeriodStatus: string;
        readonly postedAt: string | null;
        readonly postedById: string | null;
        readonly processedAt: string | null;
        readonly startTime: string;
      };
    }>;
    readonly pageInfo: {
      readonly endCursor: string | null;
      readonly hasNextPage: boolean;
    };
  };
  readonly " $fragmentType": "PayPeriodTable_viewer";
};
export type PayPeriodTable_viewer$key = {
  readonly " $data"?: PayPeriodTable_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"PayPeriodTable_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "after"
    },
    {
      "kind": "RootArgument",
      "name": "businessId"
    },
    {
      "kind": "RootArgument",
      "name": "endDateTime"
    },
    {
      "kind": "RootArgument",
      "name": "pageSize"
    },
    {
      "kind": "RootArgument",
      "name": "payPeriodStatus"
    },
    {
      "kind": "RootArgument",
      "name": "scheduleId"
    },
    {
      "kind": "RootArgument",
      "name": "sort"
    },
    {
      "kind": "RootArgument",
      "name": "startDateTime"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "pageSize",
        "cursor": "after",
        "direction": "forward",
        "path": [
          "payPeriods"
        ]
      }
    ]
  },
  "name": "PayPeriodTable_viewer",
  "selections": [
    {
      "alias": "payPeriods",
      "args": [
        {
          "kind": "Variable",
          "name": "businessId",
          "variableName": "businessId"
        },
        {
          "kind": "Variable",
          "name": "endDateTime",
          "variableName": "endDateTime"
        },
        {
          "kind": "Variable",
          "name": "payPeriodStatus",
          "variableName": "payPeriodStatus"
        },
        {
          "kind": "Variable",
          "name": "scheduleId",
          "variableName": "scheduleId"
        },
        {
          "kind": "Variable",
          "name": "sort",
          "variableName": "sort"
        },
        {
          "kind": "Variable",
          "name": "startDateTime",
          "variableName": "startDateTime"
        }
      ],
      "concreteType": "PayPeriodConnection",
      "kind": "LinkedField",
      "name": "__PayPeriodTable_payPeriods_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PayPeriodEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PayPeriod",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "startTime",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endTime",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "payPeriodStatus",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "postedAt",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "postedById",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "approvedAt",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "approvedById",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "processedAt",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "InternalQuery",
  "abstractKey": null
};

(node as any).hash = "fd8d8b1823e6a41aeb1401e316c3fbe9";

export default node;
