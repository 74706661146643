import React, { FunctionComponent, PropsWithChildren } from "react";

import { useFormikContext } from "formik";
import Form from "react-bootstrap/Form";
import styled from "styled-components";

type Props = {
  value: string;
  fieldKey: string;
  onChange: (v: any) => void;
};

const StyledFormControl = styled(Form.Control)`
  width: 120px;
`;

const Textarea: FunctionComponent<PropsWithChildren<Props>> = (
  p: PropsWithChildren<Props>,
) => {
  const { value, fieldKey, onChange } = p;
  const formikContext = useFormikContext();
  const meta = formikContext.getFieldMeta(fieldKey);
  const { error } = meta;
  return (
    <>
      <StyledFormControl
        type="text"
        className="d-inline"
        isInvalid={error != null}
        value={value || ""}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          onChange(e.currentTarget.value);
        }}
      />
      <input
        className="ml-2"
        type="color"
        id={fieldKey}
        name={fieldKey}
        value={value ? `#${value}` : ""}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          // remove leading "#"
          const v = e.currentTarget.value;
          const formattedValue = v.replace(/^#/, "");
          onChange(formattedValue);
        }}
      />
    </>
  );
};

export default Textarea;
