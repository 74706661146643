/**
 * @generated SignedSource<<9a3cb23ae8d7c6bd56d5163245b8885b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EmploymentPerformanceRatingsQueries_DeleteEmploymentPerformanceRating_Mutation$variables = {
  businessId: string;
  id: string;
};
export type EmploymentPerformanceRatingsQueries_DeleteEmploymentPerformanceRating_Mutation$data = {
  readonly deleteEmploymentPerformanceReviewRating: boolean;
};
export type EmploymentPerformanceRatingsQueries_DeleteEmploymentPerformanceRating_Mutation = {
  response: EmploymentPerformanceRatingsQueries_DeleteEmploymentPerformanceRating_Mutation$data;
  variables: EmploymentPerformanceRatingsQueries_DeleteEmploymentPerformanceRating_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "businessId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "businessId",
        "variableName": "businessId"
      },
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "kind": "ScalarField",
    "name": "deleteEmploymentPerformanceReviewRating",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EmploymentPerformanceRatingsQueries_DeleteEmploymentPerformanceRating_Mutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EmploymentPerformanceRatingsQueries_DeleteEmploymentPerformanceRating_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "0ad37a90a8069c29da02249f244ab901",
    "id": null,
    "metadata": {},
    "name": "EmploymentPerformanceRatingsQueries_DeleteEmploymentPerformanceRating_Mutation",
    "operationKind": "mutation",
    "text": "mutation EmploymentPerformanceRatingsQueries_DeleteEmploymentPerformanceRating_Mutation(\n  $businessId: ID!\n  $id: ID!\n) {\n  deleteEmploymentPerformanceReviewRating(businessId: $businessId, id: $id)\n}\n"
  }
};
})();

(node as any).hash = "bba2b51658e10f77fac3cbf8b8b50545";

export default node;
