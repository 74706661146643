import React from "react";

import Table from "react-bootstrap/Table";
import { useTranslation } from "react-i18next";
import {
  createPaginationContainer,
  graphql,
  RelayPaginationProp,
} from "react-relay";
import { Link, RouteComponentProps } from "react-router-dom";

import RelayLazyLoader from "../../common/RelayLazyLoader";
import { ScheduleTable_viewer$data } from "./__generated__/ScheduleTable_viewer.graphql";

const ScheduleTablePageQuery = graphql`
  query ScheduleTable_InternalQuery(
    $businessId: ID!
    $searchValue: String
    $pageSize: Int!
    $after: String
  ) {
    ...ScheduleTable_viewer
  }
`;

interface MatchParams {
  business_id: string;
  stack_id: string;
}

type Props = RouteComponentProps<MatchParams> & {
  stackId: string;
  businessId: string;
  searchValue: string;
  viewer: ScheduleTable_viewer$data;
  relay: RelayPaginationProp;
};

function ScheduleTableBase(props: Props) {
  const { stackId, businessId, viewer, relay } = props;
  const { t } = useTranslation("schedules");

  const nodes = (viewer.schedules.edges || []).map((edge: any) => (
    <tr key={edge.node.id}>
      <td>
        <Link
          to={`/stack/${stackId}/business/${businessId}/schedule/${edge.node.id}`}
        >
          {edge.node.scheduleName}
        </Link>
      </td>
      <td>{!edge.node.isGroup ? "schedule" : "group"}</td>
    </tr>
  ));

  return (
    <>
      <Table hover size="sm">
        <thead>
          <tr>
            <th>{t("table.headers.scheduleName")}</th>
            <th>{t("table.headers.groupSchedule")}</th>
          </tr>
        </thead>
        <tbody>{nodes}</tbody>
      </Table>
      <RelayLazyLoader relay={relay} />
    </>
  );
}

export default createPaginationContainer(
  ScheduleTableBase,
  {
    viewer: graphql`
      fragment ScheduleTable_viewer on InternalQuery {
        schedules(
          businessId: $businessId
          search: $searchValue
          first: $pageSize
          after: $after
          isGroup: false
          sort: [{ field: scheduleName, order: asc }]
        ) @connection(key: "ScheduleTable_schedules") {
          edges {
            node {
              id
              scheduleName
              isGroup
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    `,
  },
  {
    direction: "forward",
    query: ScheduleTablePageQuery,
    getConnectionFromProps: (props) => props.viewer.schedules,
    getFragmentVariables: (previousVars, pageSize) => ({
      ...previousVars,
      pageSize,
    }),
    getVariables: (props, paginationInfo) => ({
      businessId: props.businessId,
      searchValue: props.searchValue,
      pageSize: paginationInfo.count,
      after: paginationInfo.cursor,
    }),
  },
);
