import React from "react";

import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { PreloadedQuery, usePreloadedQuery } from "react-relay";
import { Link } from "react-router-dom";
import { useTheme } from "styled-components";

import { useAppRouter } from "../../../hooks/useAppRouter";
import { formatDate } from "../../../utils/utility";
import { Pillbox, PillboxVariantEnum } from "../../common/Pillbox";
import { TimeOffTypesQueries_GetTimeOffTypes_Query } from "./__generated__/TimeOffTypesQueries_GetTimeOffTypes_Query.graphql";
import { GetTimeOffTypesQuery } from "./TimeOffTypesQueries";

type Props = {
  queryReference: PreloadedQuery<TimeOffTypesQueries_GetTimeOffTypes_Query>;
  search: string;
};

export default function TimeOffTypesTable({ queryReference, search }: Props) {
  const { t } = useTranslation("time-off-types");
  const theme = useTheme();
  const router = useAppRouter();

  const { timeOffTypes } =
    usePreloadedQuery<TimeOffTypesQueries_GetTimeOffTypes_Query>(
      GetTimeOffTypesQuery,
      queryReference,
    );

  const timeOffTableRows = timeOffTypes.nodes.filter((timeOffType) => {
    // Client side searching - check contents of code/name
    if (search !== "") {
      const searchQuery = new RegExp(search, "i");
      return (
        timeOffType?.name.match(searchQuery) ||
        timeOffType?.code?.match(searchQuery)
      );
    }
    return true;
  });

  return (
    <Table hover size="sm">
      <thead>
        <tr>
          <th>{t("table.headers.name")}</th>
          <th>{t("table.headers.code")}</th>
          <th>{t("table.headers.paid")}</th>
          <th>{t("table.headers.excludeThreshold")}</th>
          <th>{t("table.headers.createdAt")}</th>
          <th>{t("table.headers.updatedAt")}</th>
          <th style={{ width: 130 }}>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        {timeOffTableRows.map(
          (timeOffType) =>
            timeOffType && (
              <tr key={timeOffType.id}>
                <td>
                  {!timeOffType.deleted ? (
                    <Link to={`${router.pathname}/edit/${timeOffType.id}`}>
                      {timeOffType.name}
                    </Link>
                  ) : (
                    timeOffType.name
                  )}
                </td>
                <td>{timeOffType.code}</td>
                <td>
                  {timeOffType.paid ? (
                    <FontAwesomeIcon color={theme.bluePrimary} icon={faCheck} />
                  ) : (
                    <FontAwesomeIcon color={theme.grey300} icon={faTimes} />
                  )}
                </td>
                <td>
                  {timeOffType.ignoreAutoReject ? (
                    <FontAwesomeIcon color={theme.bluePrimary} icon={faCheck} />
                  ) : (
                    <FontAwesomeIcon color={theme.grey300} icon={faTimes} />
                  )}
                </td>
                <td>
                  {formatDate(timeOffType.createdAt as string, {
                    toFormat: "dd MMM yyyy",
                    showRelative: true,
                  })}
                </td>
                <td>
                  {formatDate(timeOffType.updatedAt as string, {
                    toFormat: "dd MMM yyyy",
                    showRelative: true,
                  })}
                </td>
                <td className="text-left">
                  {timeOffType.deleted ? (
                    <Pillbox
                      variant={PillboxVariantEnum.Declined}
                      text={t("table.pills.removed")}
                    />
                  ) : (
                    " "
                  )}
                </td>
              </tr>
            ),
        )}
      </tbody>
    </Table>
  );
}
