import React, { Suspense } from "react";

import Card from "react-bootstrap/Card";
import Nav from "react-bootstrap/Nav";
import { useTranslation } from "react-i18next";
import { LinkContainer } from "react-router-bootstrap";
import { Route, RouteComponentProps } from "react-router-dom";
import styled from "styled-components";

import { ModalProvider } from "../../../../contexts/ModalContext";
import Loader from "../../../common/Loader";
import HeaderPortal, {
  HeaderPortalBreadcrumbs,
} from "../../../common/Portal/HeaderPortal";
import MetadataGroupsProfile from "./MetadataGroupsProfile";
import { useMetadataTypesForLayouts } from "./MetadataLayoutQueries";
import MetadataViewsProfile from "./MetadataViewsProfile";

type Props = RouteComponentProps & {};

const Wrapper = styled.div`
  min-height: calc(100vh - 135px);
`;

const StyledCard = styled(Card)`
  border: none;
  border-radius: 0;
`;

const StyledButtonWrapper = styled.div`
  margin-left: auto;
  height: 45px;
`;

export default function MetadataLayout({ match }: Props) {
  const { t } = useTranslation("metadata-types");

  return (
    <Wrapper>
      <HeaderPortal as="span">
        <HeaderPortalBreadcrumbs
          breadcrumbs={[<span>{t("nav.metadataLayout")}</span>]}
        />
      </HeaderPortal>
      <Nav variant="tabs" className="layout-tabs">
        <LinkContainer to={`${match.url}/metadata_groups`}>
          <Nav.Link className="nav-item">
            {t("metadataLayout.tabs.groups")}
          </Nav.Link>
        </LinkContainer>
        <LinkContainer exact to={`${match.url}`}>
          <Nav.Link className="nav-item">
            {t("metadataLayout.tabs.views")}
          </Nav.Link>
        </LinkContainer>
        <StyledButtonWrapper>
          <div id="metadata-layout-action" />
        </StyledButtonWrapper>
      </Nav>

      <StyledCard border="none" body>
        <Suspense fallback={<Loader />}>
          <MetadataLayoutRoutes match={match} />
        </Suspense>
      </StyledCard>
    </Wrapper>
  );
}

function MetadataLayoutRoutes({ match }: Pick<Props, "match">) {
  // Fetch this so it's at least in the relay cache for sub components to use
  useMetadataTypesForLayouts();

  return (
    <ModalProvider>
      <Route exact path={`${match.path}`} component={MetadataViewsProfile} />
      <Route
        path={`${match.path}/metadata_groups`}
        component={MetadataGroupsProfile}
      />
    </ModalProvider>
  );
}
