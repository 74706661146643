import React, { useCallback, useEffect } from "react";

import throttle from "lodash/throttle";
import { useInView } from "react-intersection-observer";
import { Disposable, LoadMoreFn } from "react-relay";

import Loader from "./Loader";

interface Props {
  isLoading: () => boolean;
  hasMore: () => boolean;
  loadMore:
    | ((pageSize: number) => Disposable | null | undefined)
    | LoadMoreFn<any>;
  throttleValue?: number;
  pageSize?: number;
}

const defaultProps: Partial<Props> = {
  throttleValue: 1000, // 1 second throttle by default
  pageSize: 50, // load 50 items by default
};

/**
 * Lazy loader component
 * @param isLoading function to return whether data is loading
 * @param hasMore function to return whether there are more data to load
 * @param LoadMore function to load more data
 * @returns loader component that handle loading more data when intersect
 */
const LazyLoader: React.FunctionComponent<Props> = ({
  isLoading,
  hasMore,
  loadMore,
  throttleValue,
  pageSize,
}) => {
  const [ref, inView] = useInView({
    threshold: 1,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadMoreThrottled = useCallback(
    throttle(
      (loadNextFn: (pageSize: number) => Disposable | null | undefined) => {
        pageSize && loadNextFn(pageSize);
      },
      throttleValue,
      {
        leading: true,
        trailing: false,
      },
    ),
    [],
  );

  useEffect(() => {
    if (inView && !isLoading() && hasMore()) {
      loadMoreThrottled(loadMore);
    }
  }, [inView, isLoading, hasMore, loadMore, loadMoreThrottled]);

  return <div ref={ref}>{isLoading() && <Loader />}</div>;
};

LazyLoader.defaultProps = defaultProps;

export default LazyLoader;
