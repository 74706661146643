import React, {
  FunctionComponent,
  Suspense,
  useCallback,
  useState,
} from "react";

import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";
import { FetchPolicy, RelayEnvironmentProvider } from "react-relay";
import { Link } from "react-router-dom";

import { useModal } from "../../../contexts/ModalContext";
import { Id } from "../../../data/models/common";
import { idmInternalEnvironment } from "../../../environment";
import Loader from "../../common/Loader";
import { useAppContext } from "../../IDM/external/Context/AppContext";
import {
  QueryOptions,
  useIdmBusinessData,
} from "../../IDM/internal/Businesses/BusinessesQueries";
import { GlobalBusiness } from "../Services/BusinessService";
import { BusinessListItem } from "./BusinessQueries";
import SetCorporateModal from "./SetCorporateModal";

type BusinessRowProps = {
  business: BusinessListItem;
  globalBusinesses: Map<Id, GlobalBusiness>;
};

const BusinessRow = (props: BusinessRowProps) => {
  const [refreshedQueryOptions, setRefreshedQueryOptions] = useState<{
    fetchKey?: string | number;
    fetchPolicy?: FetchPolicy;
  }>({
    fetchPolicy: "store-or-network",
  });

  const refresh = useCallback(() => {
    // get updated IDM business
    setRefreshedQueryOptions((prev) => ({
      fetchKey: ((prev?.fetchKey ?? 0) as number) + 1,
      fetchPolicy: "network-only",
    }));
  }, []);

  return (
    <tr className="hoverable">
      <BusinessCells
        {...props}
        queryOptions={refreshedQueryOptions}
        refresh={refresh}
      />
    </tr>
  );
};

type Props = BusinessRowProps & {
  queryOptions: QueryOptions;
  refresh: Function;
};

const BusinessCells: FunctionComponent<Props> = ({
  business,
  globalBusinesses,
  queryOptions,
  refresh,
}) => {
  const { businessName, id, shortDescription, globalBusinessId } = business;
  const { stack } = useAppContext();

  const { t } = useTranslation("businesses");
  const { showModal } = useModal();

  // load business data from IDM
  const [idmBusiness] = useIdmBusinessData(id, queryOptions);
  if (!idmBusiness) {
    return null;
  }

  const onLinkSuccess = () => {
    refresh();
  };

  const showLinkToCorporateDialog = () => {
    if (!idmBusiness) {
      return;
    }

    showModal(
      <RelayEnvironmentProvider environment={idmInternalEnvironment}>
        <Suspense fallback={<Loader />}>
          <SetCorporateModal
            idmBusiness={idmBusiness}
            onSuccess={onLinkSuccess}
          />
        </Suspense>
      </RelayEnvironmentProvider>,
    );
  };

  return (
    <>
      <td>
        <Link to={`/stack/${stack?.id}/business/${id}/profile`}>
          {businessName}
        </Link>
      </td>
      <td>{id}</td>
      <td>
        {idmBusiness.corporate && (
          <Link to={`/corporates/edit/${idmBusiness.corporate.id}`}>
            {idmBusiness.corporate.name}
          </Link>
        )}
      </td>
      <td>
        {globalBusinessId && globalBusinesses.has(globalBusinessId) && (
          <span>{globalBusinesses.get(globalBusinessId)?.name}</span>
        )}
      </td>
      <td>{shortDescription}</td>
      <td style={{ width: "160px" }}>
        <span className="show-inline-on-hover">
          <Button
            variant="link"
            className="ml-1 mr-2"
            onClick={() => showLinkToCorporateDialog()}
          >
            {t("table.actions.editCorporate")}
          </Button>
        </span>
      </td>
    </>
  );
};

export default BusinessRow;
