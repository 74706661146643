import React, { useState } from "react";

import Card from "react-bootstrap/Card";
import { useTranslation } from "react-i18next";
import { graphql, useLazyLoadQuery, useMutation } from "react-relay";
import { RouteComponentProps } from "react-router-dom";
import { toast } from "react-toastify";

import { useBusinessContext } from "../../../contexts/BusinessContext";
import { Profile_skillLevel$data } from "./__generated__/Profile_skillLevel.graphql";
import {
  SkillLevelProfile_CreateSkillLevelMutation,
  SkillLevelProfile_CreateSkillLevelMutation$data,
} from "./__generated__/SkillLevelProfile_CreateSkillLevelMutation.graphql";
import {
  SkillLevelProfile_Query,
  SkillLevelProfile_Query$data,
} from "./__generated__/SkillLevelProfile_Query.graphql";
import {
  SkillLevelProfile_UpdateSkillLevelMutation,
  SkillLevelProfile_UpdateSkillLevelMutation$data,
} from "./__generated__/SkillLevelProfile_UpdateSkillLevelMutation.graphql";
import Profile, { MatchParams } from "./Profile";

const SkillLevelProfileQuery = graphql`
  query SkillLevelProfile_Query(
    $ids: [ID!]
    $businessId: ID!
    $skip: Boolean!
  ) {
    skillLevels(ids: $ids, businessId: $businessId) @skip(if: $skip) {
      nodes {
        ...Profile_skillLevel
      }
    }
  }
`;

type ResponseType = {} & SkillLevelProfile_Query$data["skillLevels"];
type NodeType = {} & ResponseType["nodes"];
export type SkillLevel = {} & NodeType[number];

type Props = RouteComponentProps<MatchParams> & {};

function SkillLevelProfile(props: Props) {
  const { match, ...rest } = props;
  const {
    params: {
      skill_level_id: skillLevelId,
      business_id: businessId,
      stack_id: stackId,
    },
  } = match;

  const [createCommit] =
    useMutation<SkillLevelProfile_CreateSkillLevelMutation>(graphql`
      mutation SkillLevelProfile_CreateSkillLevelMutation(
        $businessId: ID!
        $input: SkillLevelInput!
      ) {
        createSkillLevel(businessId: $businessId, input: $input) {
          id
          ...Profile_skillLevel
        }
      }
    `);

  const [updateCommit] =
    useMutation<SkillLevelProfile_UpdateSkillLevelMutation>(graphql`
      mutation SkillLevelProfile_UpdateSkillLevelMutation(
        $businessId: ID!
        $id: ID!
        $input: SkillLevelUpdateInput!
      ) {
        updateSkillLevel(businessId: $businessId, id: $id, input: $input) {
          id
          ...Profile_skillLevel
        }
      }
    `);

  const { environment } = useBusinessContext();
  const { t } = useTranslation("skill-levels");

  const data = useLazyLoadQuery<SkillLevelProfile_Query>(
    SkillLevelProfileQuery,
    {
      businessId,
      ids: skillLevelId ? [skillLevelId] : [],
      skip: skillLevelId == null,
    },
  );

  const [base, setBase] = useState<SkillLevel | null>(
    data.skillLevels?.nodes?.[0] ?? null,
  );

  const navigateToListingPage = () => {
    props.history.push(
      `/stack/${stackId}/business/${businessId}/profile/settings/skill_levels`,
    );
  };

  const handleSave = (
    changes: Partial<Profile_skillLevel$data>,
    onError: (err: Error) => void,
    event: React.MouseEvent<HTMLButtonElement> | undefined,
    values: Profile_skillLevel$data | undefined,
  ) => {
    if (!values || !environment) {
      return;
    }

    if (skillLevelId) {
      updateCommit({
        variables: {
          businessId,
          id: skillLevelId,
          input: values,
        },
        onCompleted: (
          response: SkillLevelProfile_UpdateSkillLevelMutation$data,
        ) => {
          toast(t("form.savedSuccessfully"));
          setBase(response.updateSkillLevel);
          navigateToListingPage();
        },
        onError,
      });
    } else {
      createCommit({
        variables: {
          businessId,
          input: values,
        },
        onCompleted: (
          response: SkillLevelProfile_CreateSkillLevelMutation$data,
        ) => {
          toast(t("form.savedSuccessfully"));
          setBase(response.createSkillLevel);
          navigateToListingPage();
        },
        onError,
      });
    }
  };

  return (
    <div className="panel">
      <Card body>
        <Profile
          {...rest}
          match={match}
          skillLevel={base}
          onSave={handleSave}
          onDeleteSuccess={navigateToListingPage}
        />
      </Card>
    </div>
  );
}

export default SkillLevelProfile;
