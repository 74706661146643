import React from "react";

import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { faBars } from "@fortawesome/free-solid-svg-icons/faBars";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons/faTrashAlt";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FieldHelperProps } from "formik";
import cloneDeep from "lodash/cloneDeep";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";

const ID_SEPARATOR = "#";
const DEFAULT_ITEM_PREFIX = "sortable-list-item";

type Props = {
  // If multiples are encoded, separate using #'s
  data: any[];
  onDragEnd?: (e: DragEndEvent) => void;
  children: React.ReactNode;
  onSort: (value: any[]) => void;
};

export default function VerticalSortableContext(props: Props) {
  const sortableIdItems = props.data.map((item, index) => {
    // Default key if none is supplied
    return getSortableIdByIndex(index, DEFAULT_ITEM_PREFIX);
  });

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;
    if (!active || !over) {
      return;
    }

    if (active.id !== over.id) {
      const oldIndex = Number((active.id as string).split(ID_SEPARATOR)[1]);
      const newIndex = Number((over.id as string).split(ID_SEPARATOR)[1]);
      const newItems = arrayMove(cloneDeep(props.data), oldIndex, newIndex);

      props.onSort(newItems);
    }
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={props.onDragEnd ?? handleDragEnd}
    >
      <SortableContext
        items={sortableIdItems}
        strategy={verticalListSortingStrategy}
      >
        {props.children}
      </SortableContext>
    </DndContext>
  );
}

/**
 *
 * @param index The index of the item
 * @param fieldIdentifier (optional) string to prefix to the sortable id, useful for debugging
 * @returns A hash separated string to be used as an id for sorting, eg. sortable-list-item#<index>
 */
export function getSortableIdByIndex(
  index: number,
  fieldIdentifier?: string | undefined | null,
) {
  return `${fieldIdentifier ?? DEFAULT_ITEM_PREFIX}${ID_SEPARATOR}${index}`;
}

export function handleSortableDelete<T>(
  helpers: FieldHelperProps<T[]>,
  existingData: T[],
  sortableId: string,
) {
  const indexToDelete = Number(sortableId.split(ID_SEPARATOR)[1]);
  helpers.setValue(
    existingData.filter((d, idx: number) => indexToDelete !== idx),
  );
}

export function handleSortableAdd<T>(
  helpers: FieldHelperProps<T[]>,
  existingData: T[],
  defaultValue: T,
) {
  const newArr = [...existingData];
  newArr.push(defaultValue);
  helpers.setValue(newArr);
}

export function SortableDragHandle(props: any) {
  return (
    <Button {...props} variant="link">
      <span aria-hidden="true">
        <FontAwesomeIcon size="sm" color="#8C8C8C" icon={faBars} />
      </span>
    </Button>
  );
}

type SortableTrashIconProps = {
  onClick: () => void;
  className?: string;
  disabled?: boolean;
};

export function SortableTrashIcon({
  onClick,
  className,
  disabled = false,
}: SortableTrashIconProps) {
  const { t } = useTranslation();

  return (
    <Button
      variant="link"
      className={className}
      aria-label={t("form.actions.remove")}
      onClick={onClick}
      disabled={disabled}
    >
      <span aria-hidden="true">
        <FontAwesomeIcon size="sm" color="#8C8C8C" icon={faTrashAlt} />
      </span>
    </Button>
  );
}
