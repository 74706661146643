import { useAppRouter } from "./useAppRouter";

type MatchParams = {
  schedule_id?: string;
  business_id?: string;
};

export enum LayoutContextType {
  Business = "business-layout",
  Schedule = "schedule-layout",
}

// Simple check: Based on the url params, determine whether the page is in the business
// or schedule context
export function useScheduleBusinessContextCheck() {
  const { params } = useAppRouter<MatchParams>();

  if (params.schedule_id) {
    return {
      type: LayoutContextType.Schedule,
      businessId: params.business_id,
      scheduleId: params.schedule_id,
    };
  }

  if (params.business_id) {
    return {
      type: LayoutContextType.Business,
      businessId: params.business_id,
      scheduleId: null,
    };
  }

  return {
    type: null,
    businessId: null,
    scheduleId: null,
  };
}
