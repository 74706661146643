import { useMemo } from "react";

import {
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from "react-router-dom";

// Utility hook which provides most of the functionality needed to navigate using the location and history object along with
// the route match and url params
export function useAppRouter<MatchParams extends Record<string, any>>() {
  const history = useHistory();
  const params = useParams<MatchParams>();
  const location = useLocation();
  const match = useRouteMatch<MatchParams>();

  // Note: this could also include something for manipulating query strings

  return useMemo(() => {
    return {
      push: history.push,
      replace: history.replace,
      pathname: location.pathname,

      // Strips the url of anything after the specified match string
      // Example: my-application/business/12345/break-types/edit/123
      // getGoBackUrl("/break_types") =>  my-application/business/12345/break-types
      getGoBackUrl: (stringMatch: string, appendString?: string) => {
        const path = location.pathname.split(stringMatch)[0];
        const stringToAppend = appendString ?? stringMatch;

        return `${path}${stringToAppend}`;
      },

      params,
      match,
      location,
      history,
    };
  }, [params, match, location, history]);
}
