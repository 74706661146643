import React from "react";

import { useTranslation } from "react-i18next";
import { Environment, graphql, useFragment } from "react-relay";
import { RouteComponentProps } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";

import { useBusinessContext } from "../../../../contexts/BusinessContext";
import properties from "../../../../data/csv-settings/schedule-settings.json";
import DynamicInputGroup from "../../../common/Form/DynamicInputGroup";
import DynamicSelect from "../../../common/Form/DynamicSelect";
import FormLayout from "../../../common/Form/FormLayout";
import FormLayoutFooter from "../../../common/Form/FormLayoutFooter";
import {
  getFieldsByInputObjectName,
  getSettingsByGroup,
} from "../../../common/Form/formUtilities";
import {
  ComponentRule,
  daysOfWeekOptions,
  IProperty,
  payFrequencyOptions,
  PayFrequncies,
  punchSlipModeOptions,
  restScreenThemeOptions,
} from "../../../common/Form/models";
import PayDates from "../../../common/Form/PayDates";
import UpdateScheduleMutation from "../../mutations/UpdateScheduleMutation";
import {
  ScheduleProfileForm_schedule$data,
  ScheduleProfileForm_schedule$key,
} from "./__generated__/ScheduleProfileForm_schedule.graphql";
import ScheduleTimeClockPreShiftMessages from "./ScheduleTimeclockPreShiftMessages";

interface MatchParams {
  business_id: string;
}

type Props = RouteComponentProps<MatchParams> & {
  schedule: ScheduleProfileForm_schedule$key;
  environment: Environment;
};

export default function ScheduleProfileForm(props: Props) {
  const { t } = useTranslation();
  const { business } = useBusinessContext();

  const scheduleFragment = useFragment(
    graphql`
      # As a convention, we name the fragment as '<ComponentFileName>_<propName>'
      fragment ScheduleProfileForm_schedule on Schedule {
        id
        timeZone
        ### Replaceable content start
        scheduleName
        scheduleStatus
        code
        settings
        locale
        hrGoLiveDate
        timekeepingGoLiveDate
        firstDayOfWeek
        dayStartTime
        dayEndTime
        dayPartTemplateId
        dayPartInheritedScheduleId
        analyticsEnabled
        dataLakeId
        autoPublishEnabled
        autoPublishConfig
        ruleSetId
        complianceStartDate
        autoGfeEnabled
        autoGfeConfig
        payPeriodEnabled
        payPeriodRequiresApproval
        employeeMultiRateEnabled
        payrollCutoffTime
        payCycleStartDate
        payFrequency
        payDates
        shiftUnpublishConsentEnabled
        shiftUnpublishConsentThreshold
        shiftShowUpThreshold
        shiftCanOverlapLeaveRequest
        shiftCanOverlapUnavailability
        shiftMultiRateEnabled
        shiftSwapEnabled
        shiftSwapNeedsApproval
        shiftDropEnabled
        showEarnings
        idleTimeoutEnabled
        idleTimeoutLength
        voluntaryLateClockOutEnabled
        usesBiometrics
        unplannedShiftStartThreshold
        punchSlipMode
        restScreenTheme
        timeClockAppWorkflow
        timeClockAppPreShiftFormattedMessages {
          style
          configurable {
            version
            code
            title
            message
            buttons
          }
          predefined
        }
        approvalNeededForRoleRateChange
        timeClockRoleRateChangeAuthThreshold
        timeClockAppRestScreenSettings {
          shiftApproachingLowerBound
          shiftDueUpperBound
          shiftOverdueUpperBound
          shiftLateUpperBound
          shiftEndApproachingLowerBound
          shiftEndDueUpperBound
          mealBreakApproachingLowerBound
          mealBreakDueUpperBound
          mealBreakOverdueUpperBound
          mealBreakEndApproachingLowerBound
          mealBreakEndDueUpperBound
          breakCompletedUpperBound
          onTimeThreshold
        }
        monthlyCostLoading
        populateScheduleConfig
        ### Replaceable content finish
      }
    `,
    props.schedule,
  );

  const { params } = props.match;
  const businessId = params.business_id;

  const validationRules = yup.object({
    shiftUnpublishConsentThreshold: yup
      .number()
      .min(1)
      .label(t("property.shiftUnpublishConsentThreshold.value")),
    timeClockAppRestScreenSettings: yup.object({
      shiftApproachingLowerBound: yup
        .number()
        .min(0)
        .label(t("property.shiftApproachingLowerBound.value")),
      shiftDueUpperBound: yup
        .number()
        .min(0)
        .label(t("property.shiftDueUpperBound.value")),
      shiftOverdueUpperBound: yup
        .number()
        .min(0)
        .label(t("property.shiftOverdueUpperBound.value")),
      shiftLateUpperBound: yup
        .number()
        .min(0)
        .label(t("property.shiftLateUpperBound.value")),
      shiftEndApproachingLowerBound: yup
        .number()
        .min(0)
        .label(t("property.shiftEndApproachingLowerBound.value")),
      shiftEndDueUpperBound: yup
        .number()
        .min(0)
        .label(t("property.shiftEndDueUpperBound.value")),
      mealBreakApproachingLowerBound: yup
        .number()
        .min(0)
        .label(t("property.mealBreakApproachingLowerBound.value")),
      mealBreakDueUpperBound: yup
        .number()
        .min(0)
        .label(t("property.mealBreakDueUpperBound.value")),
      mealBreakOverdueUpperBound: yup
        .number()
        .min(0)
        .label(t("property.mealBreakOverdueUpperBound.value")),
      mealBreakEndApproachingLowerBound: yup
        .number()
        .min(0)
        .label(t("property.mealBreakEndApproachingLowerBound.value")),
      mealBreakEndDueUpperBound: yup
        .number()
        .min(0)
        .label(t("property.mealBreakEndDueUpperBound.value")),
      onTimeThreshold: yup
        .number()
        .min(0)
        .label(t("property.onTimeThreshold.value")),
      breakCompletedUpperBound: yup
        .number()
        .min(0)
        .label(t("property.breakCompletedUpperBound.value")),
    }),
    settings: yup.mixed().isValidJson(t("property.settings.value")),
    autoGfeConfig: yup.mixed().isValidJson(t("property.autoGfeConfig.value")),
    populateScheduleConfig: yup
      .mixed()
      .isValidJson(t("property.populateScheduleConfig.value")),
  });

  const onSaved = () => {
    toast(t("form.notifications.saved_successfully"));
  };

  // Add a new event handler that fires off the mutation
  const handleSave = (
    changes: Partial<ScheduleProfileForm_schedule$data>,
    onError: (err: Error) => void,
  ) => {
    const { environment } = props;
    UpdateScheduleMutation(
      environment,
      changes,
      scheduleFragment.id,
      businessId,
      onSaved,
      onError,
    );
  };

  // Custom component rules
  const componentRules: Record<string, ComponentRule> = {
    payDates: {
      component: PayDates,
      componentProps: {},
      disabled: (values: ScheduleProfileForm_schedule$data) =>
        values.payFrequency !== PayFrequncies.BI_MONTHLY,
    },
    payFrequency: {
      component: DynamicSelect,
      componentProps: {
        options: payFrequencyOptions,
        defaultValue: null,
      },
    },
    restScreenTheme: {
      component: DynamicSelect,
      componentProps: {
        options: restScreenThemeOptions,
        defaultValue: null,
      },
    },
    punchSlipMode: {
      component: DynamicSelect,
      componentProps: {
        options: punchSlipModeOptions,
        defaultValue: null,
      },
    },
    firstDayOfWeek: {
      component: DynamicSelect,
      componentProps: {
        options: daysOfWeekOptions,
        defaultValue: null,
      },
    },
    idleTimeoutLength: {
      disabled: (values: ScheduleProfileForm_schedule$data) =>
        !values.idleTimeoutEnabled,
    },
    payrollCutoffTime: {
      disabled: () => !business.aggregateToStartDay,
    },
    timeClockAppPreShiftFormattedMessages: {
      component: ScheduleTimeClockPreShiftMessages,
      componentProps: {
        properties,
      },
      hideError: true,
      hideLabel: true,
      hideDescription: true,
      xs: 12,
      md: 12,
      lg: 12,
    },
    shiftCanOverlapLeaveRequest: {
      disabled: () => business.shiftCanOverlapLeaveRequest !== true,
      tooltipText:
        business.shiftCanOverlapLeaveRequest !== true
          ? t("schedules:form.disabledAtBusinessLevel")
          : undefined,
    },
    shiftCanOverlapUnavailability: {
      disabled: () => business.shiftCanOverlapUnavailability !== true,
      tooltipText:
        business.shiftCanOverlapLeaveRequest !== true
          ? t("schedules:form.disabledAtBusinessLevel")
          : undefined,
    },
    shiftUnpublishConsentThreshold: {
      disabled: (values: ScheduleProfileForm_schedule$data) =>
        !values.shiftUnpublishConsentEnabled,
    },
  };

  if (scheduleFragment) {
    return (
      <div className="panel">
        <FormLayout<ScheduleProfileForm_schedule$data>
          base={scheduleFragment}
          onSave={handleSave}
          propertyList={properties as unknown as IProperty[]}
          validationRules={validationRules}
          componentRules={componentRules}
        >
          <DynamicInputGroup
            fields={getSettingsByGroup(
              getFieldsByInputObjectName(
                properties as unknown as IProperty[],
                "ScheduleUpdateInput",
              ),
            )}
          />
          <FormLayoutFooter />
        </FormLayout>
      </div>
    );
  }
  return <div>{t("schedules:form.scheduleNotFound")}</div>;
}
