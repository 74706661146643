import React from "react";

import { useFormikContext } from "formik";
import { isBoolean, isFunction, startCase } from "lodash";
import Row from "react-bootstrap/Row";

import { useDynamicFormContext } from "../../../contexts/DynamicFormContext";
import getComponentComponents from "./CommonComponents";
// eslint-disable-next-line import/no-cycle
import Field from "./Field";
import GroupHeader from "./GroupHeader";
import {
  CommonComponentRule,
  ComponentRule,
  FormGroupProperties,
  GroupName,
  IProperty,
  SubGroupName,
} from "./models";
import SubGroupHeader from "./SubGroupHeader";

export type DisabledRule = boolean | ((x: any) => boolean);

type Props = FormGroupProperties & {
  fieldKey?: string;
  fields: Map<GroupName, Map<SubGroupName, IProperty[]>>;
  disabled?: boolean;
  hideGroupName?: boolean;
  hideSubGroupName?: boolean;
};

export default function DynamicInputGroup({
  fields = new Map<GroupName, Map<SubGroupName, IProperty[]>>(),
  fieldKey,
  disabled,
  hideGroupName,
  hideSubGroupName,
  ...componentRuleProperties
}: Props) {
  const formsFields: JSX.Element[] = [];

  fields.forEach(
    (subGroups: Map<SubGroupName, IProperty[]>, fieldGroupName: GroupName) => {
      const subGroupsFields: JSX.Element[] = [];
      const displayGroupName = fieldGroupName || "";

      subGroups.forEach(
        (properties: IProperty[], fieldSubGroupName: SubGroupName) => {
          const displayedSubGroupName = fieldSubGroupName;
          subGroupsFields.push(
            <fieldset
              className="sub-group"
              key={`${displayGroupName}-${displayedSubGroupName}`}
            >
              {!hideSubGroupName && displayedSubGroupName && (
                <SubGroupHeader>{displayedSubGroupName}</SubGroupHeader>
              )}
              <Row>
                {properties.map((field: IProperty) => {
                  return (
                    <DynamicInputField
                      key={`${displayGroupName}-${field.key}`}
                      field={field}
                      disabled={disabled}
                      fields={fields}
                      fieldKey={fieldKey}
                      {...componentRuleProperties}
                    />
                  );
                })}
              </Row>
            </fieldset>,
          );
        },
      );

      formsFields.push(
        <fieldset className="group" key={displayGroupName}>
          {!hideGroupName && displayGroupName && (
            <GroupHeader>{displayGroupName}</GroupHeader>
          )}
          {subGroupsFields}
        </fieldset>,
      );
    },
  );

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{formsFields}</>;
}

export function DynamicInputField({
  field,
  disabled,
  fieldKey,
  fields,
  ...componentRuleProperties
}: FormGroupProperties &
  ComponentRule & {
    field: IProperty;
    fieldKey?: string;
    fields?: Map<GroupName, Map<SubGroupName, IProperty[]>>;
  }) {
  const dynamicFormContext = useDynamicFormContext();
  const { values } = useFormikContext();

  const {
    key,
    name,
    type,
    label,
    component: componentName,
    description,
  } = field;

  let mergedComponentRuleProperties = {
    ...componentRuleProperties,
  };

  // check if component name is registered in CommonComponents
  if (componentName != null) {
    const commonDefinition: CommonComponentRule =
      getComponentComponents()[componentName];

    if (commonDefinition && commonDefinition.component != null) {
      // merge with common component rules
      mergedComponentRuleProperties = {
        ...commonDefinition,
        // I think this makes more sense than the common definition overwriting the component rules provided
        ...mergedComponentRuleProperties,
      };
    }
  }

  // check if component is defined in custom componentRules
  if (dynamicFormContext.componentRules != null) {
    const profileComponentRule: ComponentRule =
      (dynamicFormContext.componentRules ?? componentRuleProperties)[key];

    if (profileComponentRule != null) {
      // Property is defined in componentRules
      const { disabled: profileComponentRuleDisabled } = profileComponentRule;

      // Process disabled option
      const disabledRule = {
        disabled,
      };
      if (isFunction(profileComponentRuleDisabled)) {
        disabledRule.disabled =
          disabled || profileComponentRuleDisabled(values);
      } else if (isBoolean(profileComponentRuleDisabled)) {
        disabledRule.disabled = disabled || profileComponentRuleDisabled;
      }

      mergedComponentRuleProperties = {
        ...mergedComponentRuleProperties,
        ...profileComponentRule,
        ...disabledRule,
      };
    }
  }

  const safeFieldKey = fieldKey ? `${fieldKey}.${name}` : key;

  return (
    type && (
      <Field
        key={key}
        schemaFieldType={type}
        fieldKey={safeFieldKey}
        label={label || startCase(name)}
        description={description}
        fields={fields}
        {...mergedComponentRuleProperties}
      />
    )
  );
}
