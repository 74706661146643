import React from "react";

import isBoolean from "lodash/isBoolean";
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

type Props<T> = {
  value?: boolean | null;
  defaultValue?: boolean;
  fieldKey: string;
  onChange: (newValue: boolean) => any;
  disabled?: boolean;
  error?: string;
  label?: string;
  toBoolean?: (v: T) => boolean;
  fromBoolean?: (v: boolean) => T;
  boldLabels?: boolean;

  onLabel?: string;
  offLabel?: string;
};

const StyledCheck = styled(Form.Switch)`
  margin-top: 0px;
  font-weight: ${(props) => (props.$boldLabels ? "bold" : "regular")};

  .custom-switch-label {
    margin-left: 11px;
  }
  .custom-control-label {
    padding-top: 1px;
  }

  .custom-control-input ~ .custom-control-label::after {
    top: calc(0.15625em + 0.13em);
  }

  .custom-control-input ~ .custom-control-label::before {
    height: 20px;
    width: 40px;
    border-radius: 23px;
    background-color: ${(props) => props.theme.generic.border.default};

    mix-blend-mode: normal;
    opacity: 0.5;
    box-shadow: inset 0px 1px 3px #979797;
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    background-color: ${(props) => props.theme.generic.primary.default};
    mix-blend-mode: normal;
    opacity: 0.45;
    box-shadow: inset 0px 1px 3px
      ${(props) => props.theme.generic.primary.default};
  }

  .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: ${(props) => props.theme.generic.border.default};

    mix-blend-mode: normal;
    box-shadow: inset 0px 1px 3px #979797;
    border-color: unset;
  }

  .custom-control-input ~ .custom-control-label::after {
    height: 17px;
    width: 17px;
    background-color: ${(props) => props.theme.generic.foreground.icon.primary};
  }

  .custom-control-input:checked ~ .custom-control-label::after {
    background-color: ${(props) => props.theme.generic.primary.default};
    transform: translateX(1.2rem);
  }

  .custom-control-input:disabled ~ .custom-control-label::after {
    background-color: ${(props) => props.theme.white};
  }
`;

const StyledLabel = styled(Form.Label)`
  font-weight: ${(props) => (props.$boldLabels ? "bold" : "regular")};
  font-size: 13px;
`;

function Switch({
  fieldKey,
  value,
  defaultValue,
  onChange,
  error,
  label,
  toBoolean,
  fromBoolean,
  boldLabels,
  onLabel,
  offLabel,
  ...rest
}: Props<any>) {
  const { t } = useTranslation("translation");
  const switchValue =
    value == null && defaultValue != null ? defaultValue : value;
  const transformedValue = toBoolean ? toBoolean(switchValue) : switchValue;

  return (
    <>
      {label && <StyledLabel $boldLabels={boldLabels}>{label}</StyledLabel>}
      <StyledCheck
        {...rest}
        $boldLabels={boldLabels}
        key={fieldKey}
        id={fieldKey}
        isInvalid={error != null}
        checked={isBoolean(transformedValue) ? transformedValue : false}
        label={
          <span className="custom-switch-label">
            {transformedValue
              ? onLabel ?? t("switch.on")
              : offLabel ?? t("switch.off")}
          </span>
        }
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          const v = e.target.checked;
          onChange(fromBoolean ? fromBoolean(v) : v);
        }}
        custom
      />
    </>
  );
}

export default Switch;
