import React, { Suspense } from "react";

import { Formik } from "formik";
import { t } from "i18next";
import { Button, Modal, Table } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { Disposable, useMutation, UseMutationConfig } from "react-relay";
import { toast } from "react-toastify";
import * as yup from "yup";

import { useBusinessContext } from "../../../../contexts/BusinessContext";
import { useModal } from "../../../../contexts/ModalContext";
import {
  EmploymentPerformanceReviewRating,
  RankChangeEnum,
} from "../../../../data/generated/stack_internal_schema";
import { useRefreshableLazyLoadQuery } from "../../../../hooks/useRefreshableLazyLoadQuery";
import ServerError from "../../../../utils/server-error";
import { formatDate } from "../../../../utils/utility";
import { ConfirmationModal } from "../../../common/ConfirmationModal";
import Loader from "../../../common/Loader";
import HeaderPortal, {
  HeaderPortalBreadcrumbs,
} from "../../../common/Portal/HeaderPortal";
import { EmploymentPerformanceRatingsQueries_ChangeRankEmploymentPerformanceRating_Mutation } from "./__generated__/EmploymentPerformanceRatingsQueries_ChangeRankEmploymentPerformanceRating_Mutation.graphql";
import { EmploymentPerformanceRatingsQueries_CreateEmploymentPerformanceRating_Mutation } from "./__generated__/EmploymentPerformanceRatingsQueries_CreateEmploymentPerformanceRating_Mutation.graphql";
import { EmploymentPerformanceRatingsQueries_DeleteEmploymentPerformanceRating_Mutation } from "./__generated__/EmploymentPerformanceRatingsQueries_DeleteEmploymentPerformanceRating_Mutation.graphql";
import { EmploymentPerformanceRatingsQueries_EmploymentPerformanceRatingsQuery } from "./__generated__/EmploymentPerformanceRatingsQueries_EmploymentPerformanceRatingsQuery.graphql";
import { EmploymentPerformanceRatingsQueries_UpdateEmploymentPerformanceRating_Mutation } from "./__generated__/EmploymentPerformanceRatingsQueries_UpdateEmploymentPerformanceRating_Mutation.graphql";
import {
  ChangeRankEmploymentPerformanceRatingMutation,
  CreateEmploymentPerformanceRatingMutation,
  DeleteEmploymentPerformanceRatingMutation,
  GetAllBusinessEmploymentPerformanceRatingsQuery,
  UpdateEmploymentPerformanceRatingMutation,
} from "./EmploymentPerformanceRatingsQueries";

type Header = {
  name: string;
};

export default function PerformanceRatings() {
  const { showModal, hideModal } = useModal();
  const { business } = useBusinessContext();

  const [data, { refresh }] =
    useRefreshableLazyLoadQuery<EmploymentPerformanceRatingsQueries_EmploymentPerformanceRatingsQuery>(
      GetAllBusinessEmploymentPerformanceRatingsQuery,
      {
        businessId: business.id,
      },
    );

  const performanceRatings = data.employmentPerformanceReviewRatings.nodes;

  const [deleteMutation] =
    useMutation<EmploymentPerformanceRatingsQueries_DeleteEmploymentPerformanceRating_Mutation>(
      DeleteEmploymentPerformanceRatingMutation,
    );

  const [updateMutation] =
    useMutation<EmploymentPerformanceRatingsQueries_UpdateEmploymentPerformanceRating_Mutation>(
      UpdateEmploymentPerformanceRatingMutation,
    );

  const [createMutation] =
    useMutation<EmploymentPerformanceRatingsQueries_CreateEmploymentPerformanceRating_Mutation>(
      CreateEmploymentPerformanceRatingMutation,
    );

  const [changeRankMutation] =
    useMutation<EmploymentPerformanceRatingsQueries_ChangeRankEmploymentPerformanceRating_Mutation>(
      ChangeRankEmploymentPerformanceRatingMutation,
    );

  const headers: Header[] = [
    { name: t("performance-ratings:table.headers.name") },
    { name: t("performance-ratings:table.headers.order") },
    { name: t("performance-ratings:table.headers.lastUpdate") },
    { name: "" }, // "move up / move down / remove"
  ];

  const onEditPerformanceRating = (performanceRating: any) => {
    showModal(
      <EditPerformanceRatingModal
        businessId={business.id}
        performanceRating={performanceRating}
        onClose={hideModal}
        updateMutation={updateMutation}
      />,
    );
  };

  const onCreatePerformanceRating = () => {
    showModal(
      <EditPerformanceRatingModal
        businessId={business.id}
        onClose={hideModal}
        handleRefresh={refresh}
        createMutation={createMutation}
      />,
    );
  };

  const onRankChangePerformanceRating = (
    performanceRating: Pick<EmploymentPerformanceReviewRating, "id">,
    rankChange: RankChangeEnum,
  ) => {
    changeRankMutation({
      variables: {
        businessId: business.id,
        id: performanceRating.id,
        input: rankChange,
      },
      onCompleted: () => {
        refresh();
      },
    });
  };

  const onRemovePerformanceRating = (
    performanceRating: Pick<EmploymentPerformanceReviewRating, "id">,
  ) => {
    showModal(
      <RemovePerformanceRatingModal
        performanceRating={performanceRating}
        onClose={hideModal}
        onDelete={() => {
          deleteMutation({
            variables: {
              businessId: business.id,
              id: performanceRating.id,
            },
            onCompleted: () => {
              hideModal();
              refresh();
              toast(t("performance-ratings:toast.removed"));
            },
            onError(e) {
              showModal(
                <ConfirmationModal
                  okClicked={hideModal}
                  onClose={hideModal}
                  variant="danger"
                  title={t("performance-ratings:error")}
                  okText={t("form.actions.ok")}
                  hideCancel
                >
                  {e.message}
                </ConfirmationModal>,
              );
            },
          });
        }}
      />,
    );
  };

  const onTableRowSelect = (e: any, performanceRating: any) => {
    // Ignore table row select if selected element contains an ignored class.
    if (e.target.classList.contains("ignore-td-click")) {
      return;
    }
    onEditPerformanceRating(performanceRating);
  };

  return (
    <>
      <HeaderPortal as="span">
        <HeaderPortalBreadcrumbs
          breadcrumbs={[
            <span>{t("businesses:layout.nav.performanceRatings")}</span>,
          ]}
        />
      </HeaderPortal>

      <Suspense fallback={<Loader />}>
        <Row>
          <Col md={4}>
            <span>{t("performance-ratings:subTitle")}</span>
          </Col>
          <Col />
          <Col md="auto">
            <Button
              variant="primary btn-block"
              onClick={onCreatePerformanceRating}
            >
              {t("performance-ratings:newRatingButton")}
            </Button>
          </Col>
        </Row>
        <br />
        <Card body>
          <Table hover size="sm" className="mt-3">
            <thead>
              <tr>
                {headers.map((h) => (
                  <th key={h.name}>{h.name}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {performanceRatings.map((performanceRating) => (
                <tr
                  onClick={(e) => {
                    onTableRowSelect(e, performanceRating);
                  }}
                  key={performanceRating.id}
                  className="hoverable"
                >
                  <td>{performanceRating.name}</td>
                  <td>{performanceRating.rank}</td>
                  <td>
                    {formatDate(performanceRating.updatedAt, {
                      toFormat: "dd MMM yyyy",
                      showRelative: true,
                    })}
                  </td>
                  <td>
                    <span className="visible-on-hover">
                      <button
                        onClick={() =>
                          onRankChangePerformanceRating(
                            performanceRating,
                            RankChangeEnum.Decrease,
                          )
                        }
                        type="button"
                        className="btn btn-link ignore-td-click"
                      >
                        {t("performance-ratings:table.action.moveUp")}
                      </button>
                      {" | "}
                      <button
                        onClick={() =>
                          onRankChangePerformanceRating(
                            performanceRating,
                            RankChangeEnum.Increase,
                          )
                        }
                        type="button"
                        className="btn btn-link ignore-td-click"
                      >
                        {t("performance-ratings:table.action.moveDown")}
                      </button>
                      {" | "}
                      <button
                        onClick={() =>
                          onRemovePerformanceRating(performanceRating)
                        }
                        type="button"
                        className="btn btn-link ignore-td-click"
                      >
                        {t("performance-ratings:table.action.remove")}
                      </button>
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>
      </Suspense>
    </>
  );
}

type EditableEmploymentPerformanceReviewRating =
  Partial<EmploymentPerformanceReviewRating>;

type ModalProps = {
  businessId?: string;
  onClose: () => void;
  onDelete?: () => void;
  updateMutation?: (config: UseMutationConfig<any>) => Disposable;
  createMutation?: (config: UseMutationConfig<any>) => Disposable;
  handleRefresh?: () => void;
  performanceRating?: any | null;
};

export function EditPerformanceRatingModal({
  businessId,
  performanceRating,
  updateMutation,
  createMutation,
  handleRefresh,
  onClose,
}: ModalProps) {
  const handleFormSubmit = (values: any) => {
    if (performanceRating) {
      if (updateMutation) {
        updateMutation({
          variables: {
            businessId,
            id: performanceRating.id,
            input: { name: values.name },
            force: false,
          },
          onCompleted: () => {
            onClose();
            toast(t("performance-ratings:toast.saved"));
          },
          onError(error) {
            const serverError = new ServerError(error);
            alert(serverError.getErrorDetails());
          },
        });
      }
    } else {
      if (createMutation) {
        createMutation({
          variables: {
            businessId,
            input: { name: values.name },
          },
          onCompleted: () => {
            if (handleRefresh) {
              handleRefresh();
            }
            onClose();
            toast(t("performance-ratings:toast.created"));
          },
          onError(error) {
            const serverError = new ServerError(error);
            alert(serverError.getErrorDetails());
          },
        });
      }
    }
  };

  const initialFormValues: any = {
    id: performanceRating?.id,
    name: performanceRating?.name,
  };

  const validationRules = yup.object({
    name: yup
      .string()
      .max(50)
      .required()
      .label(t("performance-ratings:editModal.fields.name")),
  });

  return (
    <Formik<EditableEmploymentPerformanceReviewRating>
      onSubmit={handleFormSubmit}
      validationSchema={validationRules}
      initialValues={initialFormValues}
    >
      {({ handleSubmit, handleChange, values, errors }) => (
        <Modal show onHide={onClose}>
          <Form onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>
                <h3>
                  {values.id
                    ? t("performance-ratings:headers.edit")
                    : t("performance-ratings:headers.new")}
                </h3>
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <Form.Group controlId="description">
                <Form.Label>
                  {t("performance-ratings:editModal.fields.name")}
                </Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name}
                </Form.Control.Feedback>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="link" className="mr-4" onClick={onClose}>
                {t("translation:form.actions.cancel")}
              </Button>
              <Button variant="primary" className="pl-5 pr-5" type="submit">
                {t("translation:form.actions.save")}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      )}
    </Formik>
  );
}

export function RemovePerformanceRatingModal({
  performanceRating,
  onDelete,
  onClose,
}: ModalProps) {
  return (
    <Modal show onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          <h3>{t("performance-ratings:removeModal.title")}</h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {t("performance-ratings:removeModal.description")}
        <b>{performanceRating.name}</b>?
      </Modal.Body>
      <Modal.Footer>
        <Button variant="link" className="mr-4" onClick={onClose}>
          {t("translation:form.actions.cancel")}
        </Button>
        <Button variant="primary" className="pl-5 pr-5" onClick={onDelete}>
          {t("performance-ratings:removeModal.confirmation")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
