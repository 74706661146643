/**
 * @generated SignedSource<<b95117c7a210a31d1a5778371a61a552>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type PeopleSettingsQueries_GetBusinessRoles_Query$variables = {
  businessId: string;
};
export type PeopleSettingsQueries_GetBusinessRoles_Query$data = {
  readonly businessRoles: {
    readonly nodes: ReadonlyArray<{
      readonly businessRoleName: string;
      readonly id: string;
      readonly replaceByAos: boolean;
    }>;
  };
};
export type PeopleSettingsQueries_GetBusinessRoles_Query = {
  response: PeopleSettingsQueries_GetBusinessRoles_Query$data;
  variables: PeopleSettingsQueries_GetBusinessRoles_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "businessId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "businessId",
        "variableName": "businessId"
      }
    ],
    "concreteType": "BusinessRoleConnection",
    "kind": "LinkedField",
    "name": "businessRoles",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BusinessRole",
        "kind": "LinkedField",
        "name": "nodes",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "businessRoleName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "replaceByAos",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PeopleSettingsQueries_GetBusinessRoles_Query",
    "selections": (v1/*: any*/),
    "type": "InternalQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PeopleSettingsQueries_GetBusinessRoles_Query",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f1bc32ea3c359862c36bf57ebbf55ee1",
    "id": null,
    "metadata": {},
    "name": "PeopleSettingsQueries_GetBusinessRoles_Query",
    "operationKind": "query",
    "text": "query PeopleSettingsQueries_GetBusinessRoles_Query(\n  $businessId: ID!\n) {\n  businessRoles(businessId: $businessId) {\n    nodes {\n      id\n      businessRoleName\n      replaceByAos\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "98b2770192d245d6f85a6cf0405a53bd";

export default node;
