import React from "react";

import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  PreloadedQuery,
  usePaginationFragment,
  usePreloadedQuery,
} from "react-relay";
import { Link } from "react-router-dom";

import { formatDate } from "../../../../../../utils/utility";
import LazyLoader from "../../../../../common/LazyLoader";
import {
  CorporatesQueries_ListFragment$data,
  CorporatesQueries_ListFragment$key,
} from "../../__generated__/CorporatesQueries_ListFragment.graphql";
import { CorporatesQueries_ListQuery } from "../../__generated__/CorporatesQueries_ListQuery.graphql";
import { UserCorporatesPaginationQuery } from "../../__generated__/UserCorporatesPaginationQuery.graphql";
import {
  UserCorporateFragment,
  UserCorporatesQuery,
} from "../../CorporatesQueries";

type EdgeType =
  {} & CorporatesQueries_ListFragment$data["userCorporates"]["edges"];
type Edge = {} & EdgeType[number];
type CorporateUser = {} & Exclude<Edge["node"], null>;

type Props = {
  queryReference: PreloadedQuery<CorporatesQueries_ListQuery>;
};

export default function CorporateUsersTable({ queryReference }: Props) {
  const { t } = useTranslation("corporates");

  const [userCorporates, { loadNext, hasNext, isLoadingNext }] =
    usePaginatedCorporateUsers({ queryReference });

  return (
    <>
      <Table hover size="sm">
        <thead>
          <tr>
            <th>{t("users.table.headers.email")}</th>
            <th>{t("users.table.headers.business")}</th>
            <th>{t("users.table.headers.personKey")}</th>
            <th>{t("users.table.headers.lastAccessed")}</th>
          </tr>
        </thead>
        <tbody>
          {userCorporates.map((corporateUser: CorporateUser) => (
            <tr>
              <td>
                <Link to={`/account/${corporateUser.user.id}`}>
                  {corporateUser.user.email}
                </Link>
              </td>
              <td>{corporateUser.business.businessName}</td>
              <td>{corporateUser.personKey}</td>
              <td>
                {formatDate(corporateUser.lastAccessedAt, {
                  toFormat: "dd MMM yyyy hh:mm a z",
                  showRelative: false,
                })}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <LazyLoader
        isLoading={() => isLoadingNext}
        hasMore={() => hasNext}
        loadMore={loadNext}
      />
    </>
  );
}

function usePaginatedCorporateUsers({
  queryReference,
}: Pick<Props, "queryReference">) {
  const query = usePreloadedQuery(UserCorporatesQuery, queryReference);

  const { data, loadNext, hasNext, isLoadingNext } = usePaginationFragment<
    UserCorporatesPaginationQuery,
    CorporatesQueries_ListFragment$key
  >(UserCorporateFragment, query);

  const userCorporates = (data.userCorporates.edges || []).map((edge) => {
    return edge?.node;
  }, []) as CorporateUser[];

  return [
    userCorporates,
    {
      loadNext,
      hasNext,
      isLoadingNext,
    },
  ] as const;
}
