import React from "react";

import { FormikHelpers } from "formik";
import { Button } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import { PreloadedQuery, useMutation, usePreloadedQuery } from "react-relay";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import { useModal } from "../../../../contexts/ModalContext";
import properties from "../../../../data/csv-settings/employment-type-settings.json";
import {
  EmploymentTypeConfigCreateInput,
  FixedShiftRestrictionEnum,
} from "../../../../data/generated/stack_internal_schema";
import { useAppRouter } from "../../../../hooks/useAppRouter";
import { useScheduleBusinessContextCheck } from "../../../../hooks/useScheduleBusinessContextCheck";
import { ConfirmationModal } from "../../../common/ConfirmationModal";
import DurationInput from "../../../common/Form/DurationInput";
import DynamicInputGroup from "../../../common/Form/DynamicInputGroup";
import FormLayout from "../../../common/Form/FormLayout";
import FormLayoutFooter from "../../../common/Form/FormLayoutFooter";
import {
  getFieldsByInputObjectName,
  getSettingsByGroup,
} from "../../../common/Form/formUtilities";
import {
  ComponentRule,
  employmentFixedShiftTypeOptions,
  IProperty,
} from "../../../common/Form/models";
import SelectionGroup from "../../../common/Form/SelectionGroup";
import Switch from "../../../common/Form/Switch";
import HeaderPortal, {
  HeaderPortalBreadcrumbs,
} from "../../../common/Portal/HeaderPortal";
import {
  CreateEmploymentTypeConfigMutation,
  DeleteEmploymentTypeConfigMutation,
  GetSingleEmploymentTypeConfigQuery,
  UpdateEmploymentTypeConfigMutation,
} from "../EmploymentTypesQueries";

enum FormUpdateMethod {
  OverridingEmploymentType,
  UpdateEmploymentType,
  UpdateScheduleEmploymentType,
  CreateEmploymentType,
}

type Props = {
  employmentTypeId: string | undefined;
  queryReference: PreloadedQuery<any>;
};
export default function EmploymentTypesProfileForm({
  employmentTypeId,
  queryReference,
}: Props) {
  const { t } = useTranslation("employment-types");
  const router = useAppRouter();
  const { showModal, hideModal } = useModal();
  const employmentTypesRootUrl = router.getGoBackUrl("/employment_types");
  const { businessId } = useScheduleBusinessContextCheck();
  const isCreate = employmentTypeId == null;
  const formUpdateMethod: FormUpdateMethod = isCreate
    ? FormUpdateMethod.CreateEmploymentType
    : FormUpdateMethod.UpdateEmploymentType;
  const [
    saveEmploymentTypeConfig,
    { deactivateEmploymentType, activateEmploymentType },
  ] = useEmploymentTypeMutations(formUpdateMethod);
  const [employmentTypeConfig] = useEmploymentTypeData(
    queryReference,
    employmentTypeId,
  );
  const componentRules = useFormComponentRules(
    employmentTypeConfig,
    formUpdateMethod,
  );

  const onSaveClick = (
    data: Partial<EmploymentTypeConfigCreateInput>,
    errorHandler: (error: Error) => void,
    event?: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    values?: EmploymentTypeConfigCreateInput,
    helpers?: FormikHelpers<EmploymentTypeConfigCreateInput>,
  ) => {
    showModal(
      <ConfirmationModal
        okClicked={() => {
          saveEmploymentTypeConfig({
            variables: {
              businessId,
              ...(formUpdateMethod ===
                FormUpdateMethod.UpdateEmploymentType && {
                id: employmentTypeId,
              }),
              input: {
                ...data,
              },
            },

            onCompleted() {
              toast(t("toast.created"));
              hideModal();
              router.replace(employmentTypesRootUrl);
            },
            onError(error: Error) {
              hideModal();
              errorHandler(error);
            },
          });
        }}
        onClose={() => {
          helpers?.setSubmitting(false);
          hideModal();
        }}
        variant="warning"
        title={t(`saveModal.title`)}
        okText={t(`saveModal.ok_button`)}
      >
        <Trans i18nKey={t(`saveModal.body`)} />
      </ConfirmationModal>,
    );
  };

  const onActivateDeactivateClick = (activate: boolean) => {
    const i18nKey = activate ? "activate" : "deactivate";
    const activationMutation = activate
      ? activateEmploymentType
      : deactivateEmploymentType;
    showModal(
      <ConfirmationModal
        okClicked={() => {
          activationMutation({
            variables: {
              businessId,
              id: employmentTypeId,
              ...(activate && {
                input: {
                  deleted: false,
                },
              }),
            },
            onCompleted() {
              toast(t(`toast.${i18nKey}`));
              hideModal();
              router.replace(employmentTypesRootUrl);
            },
            onError(error: Error) {
              alert(error);
            },
          });
        }}
        onClose={hideModal}
        variant={activate ? "primary" : "danger"}
        title={t(`activateDeactivateModal.${i18nKey}.title`)}
        okText={t(`activateDeactivateModal.${i18nKey}.ok_button`)}
      >
        <Trans i18nKey={t(`activateDeactivateModal.${i18nKey}.body`)} />
      </ConfirmationModal>,
    );
  };

  return (
    <>
      <HeaderPortal as="span" elementId="sub-header-portal">
        <HeaderPortalBreadcrumbs
          breadcrumbs={[
            <Link to={employmentTypesRootUrl}>
              <span>{t("nav.employmentTypes")}</span>
            </Link>,
            <span>
              {isCreate ? t("form.create_title") : t("form.update_title")}
            </span>,
          ]}
        />
      </HeaderPortal>

      <FormLayout<EmploymentTypeConfigCreateInput>
        base={employmentTypeConfig}
        propertyList={properties as any}
        onSave={onSaveClick}
        componentRules={componentRules}
      >
        <DynamicInputGroup
          fields={getSettingsByGroup(
            getFieldsByInputObjectName(
              properties as unknown as IProperty[],
              "EmploymentTypeConfigCreateInput",
            ),
          )}
        />
        <FormLayoutFooter
          leftActionButtons={
            formUpdateMethod === FormUpdateMethod.UpdateEmploymentType
              ? {
                  before: true,
                  buttons: (
                    <Button
                      variant={
                        employmentTypeConfig.deleted ? "primary" : "danger"
                      }
                      className="mr-2"
                      onClick={() =>
                        onActivateDeactivateClick(employmentTypeConfig.deleted)
                      }
                    >
                      {employmentTypeConfig.deleted
                        ? t("form.activate")
                        : t("form.deactivate")}
                    </Button>
                  ),
                }
              : undefined
          }
        />
      </FormLayout>
    </>
  );
}

function useEmploymentTypeData(
  queryReference: PreloadedQuery<any>,
  employmentTypeId: string | undefined,
) {
  const newEmploymentTypeConfig: EmploymentTypeConfigCreateInput = {
    deleted: false,
    employmentTypeCode: "",
    fixedShiftRestriction: FixedShiftRestrictionEnum.Optional,
    gmhEnabled: false,
    gmhMinimumDurationError: 0,
    gmhMinimumDurationWarning: 0,
    isDefault: false,
    name: "",
    shortName: "",
    timeClockName: "",
    timeOffComplianceEnabled: false,
  };

  const { employmentTypeConfigs } = usePreloadedQuery(
    GetSingleEmploymentTypeConfigQuery,
    queryReference,
  );

  const isCreate = employmentTypeId == null;
  const employmentTypeConfig = isCreate
    ? newEmploymentTypeConfig
    : employmentTypeConfigs.nodes[0] ?? null;

  return [employmentTypeConfig] as const;
}

function useFormComponentRules(
  employmentTypeConfigData: EmploymentTypeConfigCreateInput,
  formUpdateMethod: FormUpdateMethod,
) {
  const { t } = useTranslation("employment-types");
  const disabledComponentRules: Record<string, ComponentRule> = Object.keys(
    employmentTypeConfigData ?? {},
  ).reduce((acc, employmentTypeConfigPropertyKey) => {
    let disabled = false;
    if (formUpdateMethod === FormUpdateMethod.UpdateEmploymentType) {
      disabled = ["timeClockName", "employmentTypeCode"].includes(
        employmentTypeConfigPropertyKey,
      );
    }
    return {
      ...acc,
      [employmentTypeConfigPropertyKey]: {
        disabled,
      },
    };
  }, {});

  const componentRules: Record<string, ComponentRule> = {
    ...disabledComponentRules,
    fixedShiftRestriction: {
      component: SelectionGroup,
      componentProps: {
        options: employmentFixedShiftTypeOptions,
        formCheckType: "radio",
      },
    },
    gmhEnabled: {
      component: Switch,
      label: undefined,
      hideLabel: true,
      componentProps: {
        onLabel: t("translation:switch.enable"),
        offLabel: t("translation:switch.disable"),
      },
    },
    gmhMinimumDurationWarning: {
      component: DurationInput,
      componentProps: {
        postfix: t("form.seconds_abbreviation"),
        allowDecimals: false,
      },
    },
    gmhMinimumDurationError: {
      component: DurationInput,
      componentProps: {
        postfix: t("form.seconds_abbreviation"),
        allowDecimals: false,
      },
    },
  };

  return componentRules;
}

function useEmploymentTypeMutations(formUpdateMethod: FormUpdateMethod) {
  const [updateMutation] = useMutation(UpdateEmploymentTypeConfigMutation);
  const [createMutation] = useMutation(CreateEmploymentTypeConfigMutation);

  // we can reactivate by setting "deleted" to false.
  const [activateEmploymentType] = useMutation(
    UpdateEmploymentTypeConfigMutation,
  );
  const [deactivateEmploymentType] = useMutation(
    DeleteEmploymentTypeConfigMutation,
  );

  const activationMutations = {
    deactivateEmploymentType,
    activateEmploymentType,
  };

  switch (formUpdateMethod) {
    case FormUpdateMethod.UpdateEmploymentType:
      return [updateMutation, activationMutations] as const;
    case FormUpdateMethod.CreateEmploymentType:
    default:
      return [createMutation, activationMutations] as const;
  }
}
