import React from "react";

import Table from "react-bootstrap/Table";
import { useTranslation } from "react-i18next";
import {
  PreloadedQuery,
  useLazyLoadQuery,
  usePreloadedQuery,
} from "react-relay";
import { Link } from "react-router-dom";

import { useBusinessContext } from "../../../contexts/BusinessContext";
import { ScheduleBreakType } from "../../../data/generated/stack_internal_schema";
import { useAppRouter } from "../../../hooks/useAppRouter";
import {
  LayoutContextType,
  useScheduleBusinessContextCheck,
} from "../../../hooks/useScheduleBusinessContextCheck";
import { formatDate } from "../../../utils/utility";
import { legacyBreakTypeOptions } from "../../common/Form/models";
import { Pillbox, PillboxVariantEnum } from "../../common/Pillbox";
import { EmploymentTypesQueries_GetEmploymentTypeConfigs_Query } from "../EmploymentTypes/__generated__/EmploymentTypesQueries_GetEmploymentTypeConfigs_Query.graphql";
import {
  EmploymentTypeConfigNode,
  GetEmploymentTypeConfigsQuery,
} from "../EmploymentTypes/EmploymentTypesQueries";
import {
  BreakTypeQueries_GetBusinessBreakTypes_Query,
  BreakTypeQueries_GetBusinessBreakTypes_Query$data,
} from "./__generated__/BreakTypeQueries_GetBusinessBreakTypes_Query.graphql";
import {
  BreakTypeQueries_GetScheduleBreakTypes_Query,
  BreakTypeQueries_GetScheduleBreakTypes_Query$data,
} from "./__generated__/BreakTypeQueries_GetScheduleBreakTypes_Query.graphql";
import {
  GetBusinessBreakTypesQuery,
  GetScheduleBreakTypesQuery,
} from "./BreakTypeQueries";
import { getPaidThresholdDescription } from "./BreakTypeUtility";

type ScheduleOrBusinessBreakTypesQuery =
  | PreloadedQuery<BreakTypeQueries_GetScheduleBreakTypes_Query>
  | PreloadedQuery<BreakTypeQueries_GetBusinessBreakTypes_Query>;

type Props = {
  queryReference: ScheduleOrBusinessBreakTypesQuery;
  isScheduleContext: boolean;
};

export default function BreakTypesTable({
  queryReference,
  isScheduleContext,
}: Props) {
  const { t } = useTranslation("break-types");
  const router = useAppRouter();
  const [breakTypes, employmentTypeConfigs] = useBreakTypeData(queryReference);

  const headers = [
    t("table.headers.order"),
    t("table.headers.name"),
    t("table.headers.type"),
    t("table.headers.employmentTypes"),
    t("table.headers.employmentMetaData"),
    t("table.headers.timeRange"),
    t("table.headers.schedulable"),
    t("table.headers.paymentOverride"),
    t("table.headers.paid"),
    t("table.headers.enabled"),
  ];

  const formatTimeRange = (breakType: ScheduleBreakType) => {
    const { availableRange } = breakType;
    if (availableRange?.rangeStartTime && availableRange?.rangeEndTime) {
      return `${formatDate(availableRange?.rangeStartTime, {
        toFormat: "HH:mm",
      })} - ${formatDate(availableRange?.rangeEndTime, {
        toFormat: "HH:mm",
      })}`;
    }
    return "-";
  };

  const formatEmploymentTypes = (breakType: ScheduleBreakType) => {
    const hasEmploymentTypes = breakType.employmentTypeCodes?.length > 0;

    const employmentTypes = !hasEmploymentTypes
      ? [...employmentTypeConfigs]
      : employmentTypeConfigs.filter((x) =>
          breakType.employmentTypeCodes?.includes(x?.employmentTypeCode),
        );

    if (employmentTypes.length > 0) {
      return employmentTypes
        .sort((a, b) => a?.name.localeCompare(b?.name))
        .map((employmentType: EmploymentTypeConfigNode) => employmentType.name)
        .join(", ");
    }

    return "-";
  };

  const checkCanOverridePaidBreak = (breakType: ScheduleBreakType) => {
    return (
      breakType.scheduleCanOverrideClockedPaidBreak ??
      breakType.canOverrideClockedPaidBreak
    );
  };

  return (
    <Table hover size="sm">
      <thead>
        <tr>
          {headers.map((headerName) => (
            <th key={headerName}>{headerName}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {breakTypes.map((breakType: ScheduleBreakType) => (
          <tr key={breakType.id}>
            <td>{breakType.breakScreenOrdering}</td>
            <td>
              {isScheduleContext && (
                <Link
                  to={`${router.pathname}/edit/${breakType.breakTypeId}/${breakType.id}`}
                >
                  {breakType.name}
                </Link>
              )}
              {!isScheduleContext && (
                <Link to={`${router.pathname}/edit/${breakType.id}`}>
                  {breakType.name}
                </Link>
              )}
            </td>
            <td>
              {legacyBreakTypeOptions.find(
                (legacyBreakType) =>
                  legacyBreakType.value === breakType.legacyBreakType,
              )?.label ?? "-"}
            </td>
            <td>{formatEmploymentTypes(breakType)}</td>
            <td>{breakType.employmentMetadata ?? "-"}</td>
            <td>{formatTimeRange(breakType)}</td>
            <td>{breakType.schedulable ? t("table.yes") : t("table.no")}</td>
            <td>
              {checkCanOverridePaidBreak(breakType) ? t("table.allow") : "-"}
            </td>

            <td>
              {getPaidThresholdDescription(
                breakType.schedulePaidThreshold ?? breakType.paidThreshold,
              )}
            </td>
            <td className="text-left">
              <span className="d-flex">
                {breakType.scheduleOverride && (
                  <Pillbox
                    className={breakType.hidden ? "mr-2" : ""}
                    text={t("table.overwritten")}
                    variant={PillboxVariantEnum.Overwritten}
                  />
                )}
                {breakType.hidden && (
                  <Pillbox
                    variant={PillboxVariantEnum.Removed}
                    text={t("table.disabled")}
                  />
                )}
                {breakType.deleted && (
                  <Pillbox
                    variant={PillboxVariantEnum.Removed}
                    text={t("table.removed")}
                  />
                )}
              </span>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

function useBreakTypeData(queryReference: ScheduleOrBusinessBreakTypesQuery) {
  const pageContext = useScheduleBusinessContextCheck();
  const businessContext = useBusinessContext();
  const isScheduleContext = pageContext.type === LayoutContextType.Schedule;

  const employmentTypes =
    useLazyLoadQuery<EmploymentTypesQueries_GetEmploymentTypeConfigs_Query>(
      GetEmploymentTypeConfigsQuery,
      {
        businessId: businessContext.business.id ?? "",
        deleted: false,
      },
      { fetchPolicy: "network-only" },
    );

  const employmentTypeConfigsData = employmentTypes.employmentTypeConfigs
    .nodes as EmploymentTypeConfigNode[];

  const breakTypeQueryData = usePreloadedQuery<any>(
    isScheduleContext ? GetScheduleBreakTypesQuery : GetBusinessBreakTypesQuery,
    queryReference,
  );

  const data = isScheduleContext
    ? (breakTypeQueryData as BreakTypeQueries_GetScheduleBreakTypes_Query$data)
        .scheduleBreakTypes
    : (breakTypeQueryData as BreakTypeQueries_GetBusinessBreakTypes_Query$data)
        .breakTypes;

  return [
    data.nodes as any as ScheduleBreakType[],
    employmentTypeConfigsData,
  ] as const;
}
