import { graphql, useLazyLoadQuery } from "react-relay";

import { useBusinessContext } from "../../../../contexts/BusinessContext";
import { MetadataTypeDataTypeEnum } from "../../../../data/generated/stack_internal_schema";
import { MetadataTypesQueries_MetaDataTypesQuery } from "./__generated__/MetadataTypesQueries_MetaDataTypesQuery.graphql";

export const GetAllBusinessMetadataTypesQuery = graphql`
  query MetadataTypesQueries_MetaDataTypesQuery(
    $businessId: ID!
    $search: String
  ) {
    metadataTypes(businessId: $businessId, search: $search) {
      nodes {
        id
        displayName
        name
        dataType
        encrypted
        external
        internalAccess
        isTimeboxed
        maximum
        minimum
        validValues
        obfuscatedNumber
        objectClass
        required
        description
        updatedAt
        createdAt
      }
    }
  }
`;

export const GetSingleBusinessMetadataTypeQuery = graphql`
  query MetadataTypesQueries_GetSingleBusinessMetadataTypeQuery(
    $businessId: ID!
    $id: ID!
    $skip: Boolean!
  ) {
    metadataTypes(businessId: $businessId, ids: [$id]) @skip(if: $skip) {
      nodes {
        id
        ### Replaceable content start
        name
        displayName
        objectClass
        required
        description
        dataType
        minimum
        maximum
        validValues
        isTimeboxed
        external
        internalAccess
        encrypted
        obfuscatedNumber
        regulatingMetadataTypeId
        regulatingMetadataTypeValues
        regulatingMetadataTypeRequired
        regulatingMetadataTypeShown
        ### Replaceable content finish
      }
    }
  }
`;

export const CreateBusinessMetadataTypeMutation = graphql`
  mutation MetadataTypesQueries_CreateBusinessMetadataType_Mutation(
    $businessId: ID!
    $input: MetadataTypeCreateInput!
  ) {
    createMetadataType(businessId: $businessId, input: $input) {
      id
      ### Replaceable content start
      name
      displayName
      objectClass
      required
      description
      dataType
      minimum
      maximum
      validValues
      isTimeboxed
      external
      internalAccess
      encrypted
      obfuscatedNumber
      regulatingMetadataTypeId
      regulatingMetadataTypeValues
      regulatingMetadataTypeRequired
      regulatingMetadataTypeShown
      ### Replaceable content finish
    }
  }
`;

export const UpdateBusinessMetadataTypeMutation = graphql`
  mutation MetadataTypesQueries_UpdateBusinessMetadataType_Mutation(
    $businessId: ID!
    $id: ID!
    $input: MetadataTypeUpdateInput!
  ) {
    updateMetadataType(businessId: $businessId, id: $id, input: $input) {
      id
      ### Replaceable content start
      name
      displayName
      objectClass
      required
      description
      dataType
      minimum
      maximum
      validValues
      isTimeboxed
      external
      internalAccess
      encrypted
      obfuscatedNumber
      regulatingMetadataTypeId
      regulatingMetadataTypeValues
      regulatingMetadataTypeRequired
      regulatingMetadataTypeShown
      ### Replaceable content finish
    }
  }
`;

export const DeleteBusinessMetadataTypeMutation = graphql`
  mutation MetadataTypesQueries_DeleteBusinessMetadataType_Mutation(
    $businessId: ID!
    $id: ID!
  ) {
    deleteMetadataType(businessId: $businessId, id: $id)
  }
`;

/** Return the types of metadata that can be used as regulating types (optionally include an id to exclude) */
export function useRegulatingMetadataTypes(id?: string) {
  const { business } = useBusinessContext();

  const data = useLazyLoadQuery<MetadataTypesQueries_MetaDataTypesQuery>(
    GetAllBusinessMetadataTypesQuery,
    {
      businessId: business.id,
    },
    {
      fetchPolicy: "network-only",
    },
  );

  const supportedMetadataTypes = [
    MetadataTypeDataTypeEnum.Boolean,
    MetadataTypeDataTypeEnum.Number,
    MetadataTypeDataTypeEnum.String,
  ].map((x) => x as string);

  const metadataTypes = data.metadataTypes?.nodes.filter((x) => {
    if (id != null && x.id === id) {
      return false;
    }
    return supportedMetadataTypes.includes(x.dataType);
  });

  return [metadataTypes] as const;
}
