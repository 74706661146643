/**
 * @generated SignedSource<<4bad8ec5da2343548bfbf3ae99c5dea0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EmploymentTypeEnum = "casual" | "full_time" | "part_time" | "permanent" | "%future added value";
export type TimeOffTypeUpdateInput = {
  code?: string | null;
  employmentTypeCodes?: ReadonlyArray<string> | null;
  employmentTypeSmallIds?: ReadonlyArray<number> | null;
  employmentTypes?: ReadonlyArray<EmploymentTypeEnum> | null;
  ignoreAutoReject?: boolean | null;
  isManagerByProxyOnly: boolean;
  name?: string | null;
  paid?: boolean | null;
  shortName?: string | null;
};
export type TimeOffTypesQueries_UpdateTimeOffType_Mutation$variables = {
  businessId: string;
  id: string;
  input: TimeOffTypeUpdateInput;
};
export type TimeOffTypesQueries_UpdateTimeOffType_Mutation$data = {
  readonly updateTimeOffType: {
    readonly code: string | null;
    readonly employmentTypeCodes: ReadonlyArray<string>;
    readonly id: string;
    readonly ignoreAutoReject: boolean;
    readonly isManagerByProxyOnly: boolean;
    readonly name: string;
    readonly paid: boolean;
    readonly shortName: string | null;
  };
};
export type TimeOffTypesQueries_UpdateTimeOffType_Mutation = {
  response: TimeOffTypesQueries_UpdateTimeOffType_Mutation$data;
  variables: TimeOffTypesQueries_UpdateTimeOffType_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "businessId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "businessId",
        "variableName": "businessId"
      },
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "TimeOffType",
    "kind": "LinkedField",
    "name": "updateTimeOffType",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "shortName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "code",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "paid",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ignoreAutoReject",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isManagerByProxyOnly",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "employmentTypeCodes",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TimeOffTypesQueries_UpdateTimeOffType_Mutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TimeOffTypesQueries_UpdateTimeOffType_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "620a9b3f0d0d187c2811fb4b404eb655",
    "id": null,
    "metadata": {},
    "name": "TimeOffTypesQueries_UpdateTimeOffType_Mutation",
    "operationKind": "mutation",
    "text": "mutation TimeOffTypesQueries_UpdateTimeOffType_Mutation(\n  $businessId: ID!\n  $id: ID!\n  $input: TimeOffTypeUpdateInput!\n) {\n  updateTimeOffType(businessId: $businessId, id: $id, input: $input) {\n    id\n    name\n    shortName\n    code\n    paid\n    ignoreAutoReject\n    isManagerByProxyOnly\n    employmentTypeCodes\n  }\n}\n"
  }
};
})();

(node as any).hash = "b6b66ea61e5e59caecb74f9219277e19";

export default node;
