/**
 * @generated SignedSource<<4b9137041c7094ffd1aa00a7c7d7300e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type RankChangeEnum = "decrease" | "increase" | "%future added value";
export type SkillLevels_ChangeSkillLevelRankMutation$variables = {
  businessId: string;
  id: string;
  input: RankChangeEnum;
};
export type SkillLevels_ChangeSkillLevelRankMutation$data = {
  readonly changeSkillLevelRank: {
    readonly nodes: ReadonlyArray<{
      readonly id: string;
    }>;
  };
};
export type SkillLevels_ChangeSkillLevelRankMutation = {
  response: SkillLevels_ChangeSkillLevelRankMutation$data;
  variables: SkillLevels_ChangeSkillLevelRankMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "businessId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "businessId",
        "variableName": "businessId"
      },
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SkillLevelConnection",
    "kind": "LinkedField",
    "name": "changeSkillLevelRank",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SkillLevel",
        "kind": "LinkedField",
        "name": "nodes",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SkillLevels_ChangeSkillLevelRankMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SkillLevels_ChangeSkillLevelRankMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "defefa8e10b8a448ee66c2d84f2e61e4",
    "id": null,
    "metadata": {},
    "name": "SkillLevels_ChangeSkillLevelRankMutation",
    "operationKind": "mutation",
    "text": "mutation SkillLevels_ChangeSkillLevelRankMutation(\n  $businessId: ID!\n  $id: ID!\n  $input: RankChangeEnum!\n) {\n  changeSkillLevelRank(businessId: $businessId, id: $id, input: $input) {\n    nodes {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "67d71ebba5596d6b66990ba79adde3da";

export default node;
