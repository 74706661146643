/**
 * @generated SignedSource<<1257aa27ea30311e719f598f4f52e88c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type RuleSetSubscriptionStatusEnum = "not_subscribed" | "production" | "staging" | "%future added value";
export type RulesPage_Query$variables = {
  businessId: string;
  scheduleId: string;
};
export type RulesPage_Query$data = {
  readonly schedules: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly ruleSetMeta: any;
        readonly ruleSetName: string | null;
        readonly ruleSetSubscriptionStatus: RuleSetSubscriptionStatusEnum;
        readonly ruleSetVersion: string | null;
      };
    }>;
  };
};
export type RulesPage_Query = {
  response: RulesPage_Query$data;
  variables: RulesPage_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "businessId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "scheduleId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "businessId",
    "variableName": "businessId"
  },
  {
    "items": [
      {
        "kind": "Variable",
        "name": "ids.0",
        "variableName": "scheduleId"
      }
    ],
    "kind": "ListValue",
    "name": "ids"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ruleSetName",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ruleSetVersion",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ruleSetSubscriptionStatus",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ruleSetMeta",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RulesPage_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ScheduleConnection",
        "kind": "LinkedField",
        "name": "schedules",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ScheduleEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Schedule",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "InternalQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RulesPage_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ScheduleConnection",
        "kind": "LinkedField",
        "name": "schedules",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ScheduleEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Schedule",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e03d0cc17683c710722da859d590524e",
    "id": null,
    "metadata": {},
    "name": "RulesPage_Query",
    "operationKind": "query",
    "text": "query RulesPage_Query(\n  $businessId: ID!\n  $scheduleId: ID!\n) {\n  schedules(businessId: $businessId, ids: [$scheduleId]) {\n    edges {\n      node {\n        ruleSetName\n        ruleSetVersion\n        ruleSetSubscriptionStatus\n        ruleSetMeta\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e9d061fff5b14160582e752499f19cf0";

export default node;
