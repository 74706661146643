import React, { Reducer, useEffect, useReducer } from "react";

import { DateTime } from "luxon";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";
import { useQueryLoader } from "react-relay";
import { RouteComponentProps } from "react-router-dom";
import AsyncSelect from "react-select/async";
import styled from "styled-components";

import { useBusinessContext } from "../../../contexts/BusinessContext";
import {
  AuditLogActionTypeEnum,
  AuditLogSortField,
  Order,
} from "../../../data/generated/stack_internal_schema";
import DateTimePicker from "../../common/Form/DateTimePicker";
import DynamicSelect from "../../common/Form/DynamicSelect";
import { TodoSelectValueType } from "../../common/Form/models";
import Loader from "../../common/Loader";
import HeaderPortal from "../../common/Portal/HeaderPortal";
import {
  EmploymentSearchResult,
  EmploymentService,
} from "../Services/EmploymentService";
import {
  ScheduleByNameType,
  ScheduleService,
} from "../Services/ScheduleService";
import { AuditLogsQueries_ListQuery } from "./__generated__/AuditLogsQueries_ListQuery.graphql";
import { auditLogsQuery } from "./AuditLogsQueries";
import AuditLogTable from "./AuditLogTable";

const StyledButton = styled(Button)`
  margin-top: 28px;
`;

const SmallCol = styled(Col)`
  max-width: 200px;
`;

interface FilterValues {
  schedule?: ScheduleByNameType;
  performedBy?: EmploymentSearchResult;
  relatedTo?: EmploymentSearchResult;
  actionType?: AuditLogActionTypeEnum;
  objectType?: string;
  specificAction?: string;
  startDateTime?: string;
  endDateTime?: string;
  objId?: string;
  requestId?: string;
}

type FilterState = FilterValues & {
  error?: string;
};

enum FilterStateActionTypeEnum {
  Clear,
  SetSchedule,
  SetActionType,
  SetObjectType,
  SetSpecificAction,
  SetObjectId,
  SetPerformedBy,
  SetRelatedTo,
  SetDateRange,
  SetError,
  SetRequestId,
}

type FilterStateAction = {
  type: FilterStateActionTypeEnum;
  newState?: FilterState;
  error?: string;
} & FilterValues;

interface MatchParams {
  business_id: string;
  stack_id: string;
}

type Props = RouteComponentProps<MatchParams> & {};

const AuditLogs = (props: Props) => {
  const { params } = props.match;
  const { business_id: businessId } = params;
  const [queryReference, loadQuery, disposeQuery] =
    useQueryLoader<AuditLogsQueries_ListQuery>(auditLogsQuery);

  const { environment } = useBusinessContext();
  const { t } = useTranslation("audit-logs");
  const initialState: FilterState = {};

  const filterActionReducer = (
    state: FilterState,
    action: FilterStateAction,
  ) => {
    const {
      error,
      schedule,
      performedBy,
      relatedTo,
      actionType,
      objectType,
      specificAction,
      startDateTime,
      endDateTime,
      objId,
      requestId,
    } = action;

    switch (action.type) {
      case FilterStateActionTypeEnum.Clear:
        return initialState;
      case FilterStateActionTypeEnum.SetSchedule: {
        return {
          ...state,
          schedule,
          error: undefined,
        } as FilterState;
      }
      case FilterStateActionTypeEnum.SetActionType: {
        return {
          ...state,
          actionType: actionType ?? null,
          error: undefined,
        } as FilterState;
      }
      case FilterStateActionTypeEnum.SetSpecificAction: {
        return {
          ...state,
          specificAction: specificAction ?? null,
          error: undefined,
        } as FilterState;
      }
      case FilterStateActionTypeEnum.SetObjectType: {
        return {
          ...state,
          objectType: objectType ?? null,
          error: undefined,
        } as FilterState;
      }
      case FilterStateActionTypeEnum.SetPerformedBy: {
        return {
          ...state,
          performedBy,
          error: undefined,
        } as FilterState;
      }
      case FilterStateActionTypeEnum.SetRelatedTo: {
        return {
          ...state,
          relatedTo,
          error: undefined,
        } as FilterState;
      }
      case FilterStateActionTypeEnum.SetDateRange: {
        return {
          ...state,
          startDateTime,
          endDateTime,
          error: undefined,
        } as FilterState;
      }
      case FilterStateActionTypeEnum.SetObjectId: {
        return {
          ...state,
          objId,
          error: undefined,
        } as FilterState;
      }
      case FilterStateActionTypeEnum.SetError: {
        return {
          ...state,
          error,
        } as FilterState;
      }
      case FilterStateActionTypeEnum.SetRequestId: {
        return {
          ...state,
          requestId,
          error: undefined,
        } as FilterState;
      }
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer<
    Reducer<FilterState, FilterStateAction>,
    FilterState
  >(filterActionReducer, initialState, () => initialState);

  const getSchedule = async (searchValue: string) => {
    const data = await ScheduleService.searchScheduleByName(
      environment,
      businessId,
      searchValue,
    );
    return Array.from(data ? data.values() : []);
  };

  const getEmployee = async (searchValue: string) => {
    if (!environment) {
      // eslint-disable-next-line no-console
      console.assert("environment not found");
      return [];
    }

    const data = await EmploymentService.searchEmployment(
      environment,
      businessId,
      searchValue,
    );
    return Array.from(data ? data.values() : []);
  };

  const onClearFiltersClicked = () => {
    dispatch({
      type: FilterStateActionTypeEnum.Clear,
    });
  };

  const search = (event?: any) => {
    event?.preventDefault();
    const {
      schedule,
      startDateTime,
      endDateTime,
      actionType,
      specificAction,
      objectType,
      performedBy,
      relatedTo,
      objId,
      requestId,
    } = state;
    loadQuery(
      {
        businessId,
        scheduleId: schedule?.id,
        startDateTime,
        endDateTime,
        actionType: actionType || undefined,
        specificAction: specificAction || undefined,
        objectType: objectType || undefined,
        performedById: performedBy?.id || undefined,
        relatedToId: relatedTo?.id || undefined,
        pageSize: 30,
        objId: objId || undefined,
        requestId: requestId || undefined,
        sort: [
          {
            field: AuditLogSortField.OccurredAt,
            order: Order.Desc,
          },
        ],
      },
      { fetchPolicy: "network-only" },
    );
  };

  useEffect(() => {
    search();
    return () => {
      disposeQuery();
    };
    // eslint-disable-next-line
  }, [loadQuery, disposeQuery, businessId]);

  const { startDateTime, endDateTime } = state;
  const startJSDate = startDateTime
    ? DateTime.fromISO(startDateTime).toJSDate()
    : null;
  const endJSDate = endDateTime
    ? DateTime.fromISO(endDateTime).toJSDate()
    : null;

  return (
    <>
      <HeaderPortal as="span" elementId="sub-header-portal">
        <span className="ml-2 mr-2">&gt;</span>
        <span>{t("breadcrumb")}</span>
      </HeaderPortal>
      <Form onSubmit={search}>
        <Row className="mt-2 mb-2">
          <SmallCol className="mr-md-n3">
            <Form.Group>
              <Form.Label>{t("table.filters.schedule.label")}</Form.Label>
              <AsyncSelect<ScheduleByNameType>
                isClearable
                cacheOptions
                defaultOptions
                isSearchable
                value={state.schedule ?? null}
                defaultValue={null}
                onChange={(
                  i: TodoSelectValueType<ScheduleByNameType, false>,
                ) => {
                  const v = i as ScheduleByNameType;
                  dispatch({
                    type: FilterStateActionTypeEnum.SetSchedule,
                    schedule: v ?? undefined,
                  });
                }}
                getOptionLabel={(i: ScheduleByNameType) => i.scheduleName}
                getOptionValue={(i) => i.id}
                loadOptions={getSchedule}
                backspaceRemovesValue
                placeholder={t("table.filters.schedule.placeholder")}
              />
            </Form.Group>
          </SmallCol>
          <SmallCol className="mr-md-n3">
            <Form.Group>
              <Form.Label>{t("table.filters.performedBy.label")}</Form.Label>
              <AsyncSelect<EmploymentSearchResult>
                isClearable
                cacheOptions
                defaultOptions
                isSearchable
                value={state.performedBy ?? null}
                defaultValue={null}
                onChange={(
                  i: TodoSelectValueType<EmploymentSearchResult, false>,
                ) => {
                  const v = i as EmploymentSearchResult;
                  dispatch({
                    type: FilterStateActionTypeEnum.SetPerformedBy,
                    performedBy: v,
                  });
                }}
                getOptionLabel={(i: EmploymentSearchResult) => i.computedName}
                getOptionValue={(i) => i.id}
                loadOptions={getEmployee}
                backspaceRemovesValue
                placeholder={t("table.filters.performedBy.placeholder")}
              />
            </Form.Group>
          </SmallCol>
          <SmallCol className="mr-md-n3">
            <Form.Group>
              <Form.Label>{t("table.filters.relatedTo.label")}</Form.Label>
              <AsyncSelect<EmploymentSearchResult>
                isClearable
                cacheOptions
                defaultOptions
                isSearchable
                value={state.relatedTo ?? null}
                defaultValue={null}
                onChange={(
                  i: TodoSelectValueType<EmploymentSearchResult, false>,
                ) => {
                  const v = i as EmploymentSearchResult;
                  dispatch({
                    type: FilterStateActionTypeEnum.SetRelatedTo,
                    relatedTo: v,
                  });
                }}
                getOptionLabel={(i: EmploymentSearchResult) => i.computedName}
                getOptionValue={(i) => i.id}
                loadOptions={getEmployee}
                backspaceRemovesValue
                placeholder={t("table.filters.relatedTo.placeholder")}
              />
            </Form.Group>
          </SmallCol>

          <SmallCol className="mr-md-n3">
            <Form.Group>
              <Form.Label>{t("table.filters.actionType.label")}</Form.Label>
              <DynamicSelect<AuditLogActionTypeEnum | null>
                options={Object.values(AuditLogActionTypeEnum).map((i) => ({
                  label: i.toString(),
                  value: i,
                }))}
                value={state.actionType || null}
                defaultValue={null}
                isClearable
                name="pay-period-status"
                onChange={(
                  newValue: AuditLogActionTypeEnum | null | undefined,
                ) => {
                  dispatch({
                    type: FilterStateActionTypeEnum.SetActionType,
                    actionType: newValue || undefined,
                  });
                }}
                placeholder={t("table.filters.actionType.placeholder")}
              />
            </Form.Group>
          </SmallCol>
          <SmallCol className="mr-md-n3">
            <Form.Group>
              <Form.Label>{t("table.filters.specificAction.label")}</Form.Label>
              <Form.Control
                type="text"
                name="specificAction"
                placeholder={t("table.filters.specificAction.placeholder")}
                value={state.specificAction || ""}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const { target } = event;
                  const { value } = target;
                  dispatch({
                    type: FilterStateActionTypeEnum.SetSpecificAction,
                    specificAction: value,
                  });
                }}
              />
            </Form.Group>
          </SmallCol>
          <Col md="auto" className="mr-md-n3">
            <Form.Label>{t("table.filters.performedAt.label")}</Form.Label>
            <div>
              <DateTimePicker
                isClearable
                dateTimePickerClassName="d-inline-block"
                fieldKey="startTime"
                displayFormat="dd MMM yyyy"
                value={startDateTime}
                onChange={(newValue: string | number | null) => {
                  dispatch({
                    type: FilterStateActionTypeEnum.SetDateRange,
                    startDateTime: newValue?.toString() ?? undefined,
                    endDateTime,
                  });
                }}
                selectsStart
                startDate={startJSDate}
                endDate={endJSDate}
                maxDate={endJSDate}
                placeholderText={t("table.filters.performedAt.placeholder")}
              />
              <span className="mx-1">-</span>
              <DateTimePicker
                isClearable
                dateTimePickerClassName="d-inline-block"
                fieldKey="endTime"
                displayFormat="dd MMM yyyy"
                value={endDateTime}
                onChange={(newValue: string | number | null) => {
                  dispatch({
                    type: FilterStateActionTypeEnum.SetDateRange,
                    startDateTime,
                    endDateTime: newValue?.toString() ?? undefined,
                  });
                }}
                selectsEnd
                startDate={startJSDate}
                endDate={endJSDate}
                minDate={startJSDate}
                placeholderText={t("table.filters.performedAt.placeholder")}
              />
            </div>
          </Col>
          <Col md="auto">
            <StyledButton
              variant="outline-primary"
              onClick={onClearFiltersClicked}
            >
              {t("table.clearFilters")}
            </StyledButton>
          </Col>
        </Row>
        <Row>
          <SmallCol className="mr-md-n3">
            <Form.Group>
              <Form.Label>{t("table.filters.objectType.label")}</Form.Label>
              <Form.Control
                type="text"
                name="objectType"
                placeholder={t("table.filters.objectType.placeholder")}
                value={state.objectType || ""}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const { target } = event;
                  const { value } = target;
                  dispatch({
                    type: FilterStateActionTypeEnum.SetObjectType,
                    objectType: value || undefined,
                  });
                }}
              />
            </Form.Group>
          </SmallCol>
          <Col md={3} className="mr-md-n3">
            <Form.Group>
              <Form.Label>{t("table.filters.objectId.label")}</Form.Label>
              <Form.Control
                type="text"
                name="objectId"
                placeholder={t("table.filters.objectId.placeholder")}
                value={state.objId || ""}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const { target } = event;
                  const { value } = target;
                  dispatch({
                    type: FilterStateActionTypeEnum.SetObjectId,
                    objId: value,
                  });
                }}
              />
            </Form.Group>
          </Col>
          <Col md={3} className="mr-md-n3">
            <Form.Group>
              <Form.Label>{t("table.filters.requestId.label")}</Form.Label>
              <Form.Control
                type="text"
                name="requestId"
                placeholder={t("table.filters.requestId.placeholder")}
                value={state.requestId || ""}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const { target } = event;
                  const { value } = target;
                  dispatch({
                    type: FilterStateActionTypeEnum.SetRequestId,
                    requestId: value,
                  });
                }}
              />
            </Form.Group>
          </Col>
          <Col md="auto">
            <StyledButton type="submit" variant="primary" onSubmit={search}>
              Search
            </StyledButton>
          </Col>
        </Row>
      </Form>

      <Card body>
        <React.Suspense fallback={<Loader />}>
          {queryReference != null && (
            <AuditLogTable queryReference={queryReference} {...props} />
          )}
        </React.Suspense>
      </Card>
    </>
  );
};

export default AuditLogs;
