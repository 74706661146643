/**
 * @generated SignedSource<<fb12056bb1b9cb31d3ac8c15bccc0657>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ScreenCodeEnum = "schedule" | "timekeeping" | "%future added value";
export type KpiScreenConfigUpdateInput = {
  kpiDisplayConfig: ReadonlyArray<KpiDisplayConfigUpdateInput>;
};
export type KpiDisplayConfigUpdateInput = {
  displayLabel: string;
  displayOrder: number;
  displayTooltip?: string | null;
  kpiId: string;
  targetId?: string | null;
};
export type KpiComponentsQueries_UpdateKpiScreenConfig_InternalQuery$variables = {
  businessId: string;
  id: string;
  input: KpiScreenConfigUpdateInput;
};
export type KpiComponentsQueries_UpdateKpiScreenConfig_InternalQuery$data = {
  readonly updateKpiScreenConfig: {
    readonly id: string;
    readonly kpiDisplayConfig: ReadonlyArray<{
      readonly displayLabel: string;
      readonly displayOrder: number;
      readonly displayTooltip: string | null;
      readonly kpi: {
        readonly code: string;
        readonly createdAt: string;
        readonly description: string;
        readonly id: string;
        readonly isCostRelated: boolean;
        readonly name: string;
        readonly updatedAt: string;
      } | null;
      readonly kpiId: string;
      readonly targetId: string | null;
    }>;
    readonly screenCode: ScreenCodeEnum;
    readonly updatedAt: string;
    readonly updatedById: string | null;
    readonly updatedByName: string | null;
  };
};
export type KpiComponentsQueries_UpdateKpiScreenConfig_InternalQuery = {
  response: KpiComponentsQueries_UpdateKpiScreenConfig_InternalQuery$data;
  variables: KpiComponentsQueries_UpdateKpiScreenConfig_InternalQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "businessId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updatedAt",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "businessId",
        "variableName": "businessId"
      },
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "KpiScreenConfig",
    "kind": "LinkedField",
    "name": "updateKpiScreenConfig",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "screenCode",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "updatedByName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "updatedById",
        "storageKey": null
      },
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "KpiDisplayConfig",
        "kind": "LinkedField",
        "name": "kpiDisplayConfig",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "displayLabel",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "displayOrder",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "displayTooltip",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "kpiId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "targetId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Kpi",
            "kind": "LinkedField",
            "name": "kpi",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "code",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "createdAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isCostRelated",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "KpiComponentsQueries_UpdateKpiScreenConfig_InternalQuery",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "KpiComponentsQueries_UpdateKpiScreenConfig_InternalQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "5de71ddc0b8c8969a2b9b0313dc7d371",
    "id": null,
    "metadata": {},
    "name": "KpiComponentsQueries_UpdateKpiScreenConfig_InternalQuery",
    "operationKind": "mutation",
    "text": "mutation KpiComponentsQueries_UpdateKpiScreenConfig_InternalQuery(\n  $businessId: ID!\n  $id: ID!\n  $input: KpiScreenConfigUpdateInput!\n) {\n  updateKpiScreenConfig(businessId: $businessId, id: $id, input: $input) {\n    id\n    screenCode\n    updatedByName\n    updatedById\n    updatedAt\n    kpiDisplayConfig {\n      displayLabel\n      displayOrder\n      displayTooltip\n      kpiId\n      targetId\n      kpi {\n        id\n        code\n        createdAt\n        description\n        isCostRelated\n        name\n        updatedAt\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d4eacf7ae629571a13b1c4aa2bb4233b";

export default node;
