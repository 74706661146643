/**
 * @generated SignedSource<<5b8f7098ae4e0392c5cac5f0e3cfef06>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SkillLevelAssignmentActionEnum = "none" | "warn" | "%future added value";
export type SkillLevelInput = {
  aosLevel?: number | null;
  assignmentAction?: SkillLevelAssignmentActionEnum | null;
  isDefault?: boolean | null;
  level: string;
  name: string;
};
export type SkillLevelProfile_CreateSkillLevelMutation$variables = {
  businessId: string;
  input: SkillLevelInput;
};
export type SkillLevelProfile_CreateSkillLevelMutation$data = {
  readonly createSkillLevel: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"Profile_skillLevel">;
  };
};
export type SkillLevelProfile_CreateSkillLevelMutation = {
  response: SkillLevelProfile_CreateSkillLevelMutation$data;
  variables: SkillLevelProfile_CreateSkillLevelMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "businessId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "businessId",
    "variableName": "businessId"
  },
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SkillLevelProfile_CreateSkillLevelMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SkillLevel",
        "kind": "LinkedField",
        "name": "createSkillLevel",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Profile_skillLevel"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SkillLevelProfile_CreateSkillLevelMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SkillLevel",
        "kind": "LinkedField",
        "name": "createSkillLevel",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isDefault",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "level",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "aosLevel",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "assignmentAction",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "95bdc3c5e1901957f32c684283d99bb4",
    "id": null,
    "metadata": {},
    "name": "SkillLevelProfile_CreateSkillLevelMutation",
    "operationKind": "mutation",
    "text": "mutation SkillLevelProfile_CreateSkillLevelMutation(\n  $businessId: ID!\n  $input: SkillLevelInput!\n) {\n  createSkillLevel(businessId: $businessId, input: $input) {\n    id\n    ...Profile_skillLevel\n  }\n}\n\nfragment Profile_skillLevel on SkillLevel {\n  name\n  isDefault\n  level\n  aosLevel\n  assignmentAction\n}\n"
  }
};
})();

(node as any).hash = "86f3a7be563b902b783b51dafd1928c6";

export default node;
