/**
 * @generated SignedSource<<5213c9221f19a9fe831d243b4e804d08>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type EmploymentTypeEnum = "casual" | "full_time" | "part_time" | "permanent" | "%future added value";
export type LegacyBreakTypeEnum = "meal" | "rest" | "unknown" | "%future added value";
export type BreakTypeQueries_GetScheduleBreakTypes_Query$variables = {
  businessId: string;
  scheduleId: string;
  search?: string | null;
};
export type BreakTypeQueries_GetScheduleBreakTypes_Query$data = {
  readonly scheduleBreakTypes: {
    readonly nodes: ReadonlyArray<{
      readonly availableRange: {
        readonly rangeEndTime: any;
        readonly rangeStartTime: any;
      } | null;
      readonly breakScreenOrdering: number;
      readonly breakTypeCode: string;
      readonly breakTypeId: string;
      readonly canOverrideClockedPaidBreak: boolean;
      readonly code: string | null;
      readonly createdAt: string;
      readonly defaultDuration: any | null;
      readonly deleted: boolean;
      readonly description: string | null;
      readonly employmentMetadata: string | null;
      readonly employmentTypeCodes: ReadonlyArray<string>;
      readonly employmentTypes: ReadonlyArray<EmploymentTypeEnum>;
      readonly hidden: boolean;
      readonly id: string;
      readonly internalAccess: boolean;
      readonly legacyBreakType: LegacyBreakTypeEnum;
      readonly name: string;
      readonly paidThreshold: any;
      readonly schedulable: boolean;
      readonly scheduleId: string | null;
      readonly scheduleOverride: boolean;
      readonly updatedAt: string;
    }>;
  };
};
export type BreakTypeQueries_GetScheduleBreakTypes_Query = {
  response: BreakTypeQueries_GetScheduleBreakTypes_Query$data;
  variables: BreakTypeQueries_GetScheduleBreakTypes_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "businessId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "scheduleId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "search"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "businessId",
        "variableName": "businessId"
      },
      {
        "kind": "Variable",
        "name": "scheduleId",
        "variableName": "scheduleId"
      },
      {
        "kind": "Variable",
        "name": "search",
        "variableName": "search"
      }
    ],
    "concreteType": "ScheduleBreakTypeConnection",
    "kind": "LinkedField",
    "name": "scheduleBreakTypes",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ScheduleBreakType",
        "kind": "LinkedField",
        "name": "nodes",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "HoursMinutesRange",
            "kind": "LinkedField",
            "name": "availableRange",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "rangeStartTime",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "rangeEndTime",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "breakScreenOrdering",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "breakTypeCode",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "breakTypeId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "canOverrideClockedPaidBreak",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "employmentTypeCodes",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "defaultDuration",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "deleted",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "employmentMetadata",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "employmentTypes",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hidden",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "legacyBreakType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "paidThreshold",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "schedulable",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "scheduleId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "scheduleOverride",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "updatedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "internalAccess",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BreakTypeQueries_GetScheduleBreakTypes_Query",
    "selections": (v1/*: any*/),
    "type": "InternalQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BreakTypeQueries_GetScheduleBreakTypes_Query",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "df768a4e73d70ef1bb9b43f39b5829e2",
    "id": null,
    "metadata": {},
    "name": "BreakTypeQueries_GetScheduleBreakTypes_Query",
    "operationKind": "query",
    "text": "query BreakTypeQueries_GetScheduleBreakTypes_Query(\n  $businessId: ID!\n  $scheduleId: ID!\n  $search: String\n) {\n  scheduleBreakTypes(businessId: $businessId, scheduleId: $scheduleId, search: $search) {\n    nodes {\n      availableRange {\n        rangeStartTime\n        rangeEndTime\n      }\n      breakScreenOrdering\n      breakTypeCode\n      breakTypeId\n      canOverrideClockedPaidBreak\n      employmentTypeCodes\n      code\n      createdAt\n      defaultDuration\n      deleted\n      description\n      employmentMetadata\n      employmentTypes\n      hidden\n      id\n      legacyBreakType\n      name\n      paidThreshold\n      schedulable\n      scheduleId\n      scheduleOverride\n      updatedAt\n      internalAccess\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "fdc27caca3af5505c4bf02abba3bdc0e";

export default node;
