/**
 * @generated SignedSource<<089843b5f8e033f771acfe465ec99ca3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CorporatesAuthMethodsQueries_GetAuthMethodByIdQuery$variables = {
  id: string;
  skip: boolean;
};
export type CorporatesAuthMethodsQueries_GetAuthMethodByIdQuery$data = {
  readonly ssoAuthMethods?: {
    readonly nodes: ReadonlyArray<{
      readonly authType: string;
      readonly code: string;
      readonly corporateId: string;
      readonly customConfigMeta: any;
      readonly displayName: string;
      readonly employmentAttrToSearch: string;
      readonly employmentIdAttributes: ReadonlyArray<string>;
      readonly employmentScheduleAttributes: ReadonlyArray<string> | null;
      readonly id: string;
      readonly metadataConfig: string | null;
      readonly metadataIssuer: string | null;
      readonly metadataOverrides: any | null;
      readonly metadataUrl: string | null;
      readonly permissionMapping: any | null;
      readonly schedulePermissionAttributes: ReadonlyArray<string> | null;
      readonly updatedAt: string;
    }>;
  };
};
export type CorporatesAuthMethodsQueries_GetAuthMethodByIdQuery = {
  response: CorporatesAuthMethodsQueries_GetAuthMethodByIdQuery$data;
  variables: CorporatesAuthMethodsQueries_GetAuthMethodByIdQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "skip"
  }
],
v1 = [
  {
    "condition": "skip",
    "kind": "Condition",
    "passingValue": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "items": [
              {
                "kind": "Variable",
                "name": "ids.0",
                "variableName": "id"
              }
            ],
            "kind": "ListValue",
            "name": "ids"
          }
        ],
        "concreteType": "SSOAuthMethodTypeConnection",
        "kind": "LinkedField",
        "name": "ssoAuthMethods",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SSOAuthMethodType",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "displayName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "code",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "authType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "metadataIssuer",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "metadataUrl",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "metadataConfig",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "metadataOverrides",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "employmentAttrToSearch",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "employmentIdAttributes",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "employmentScheduleAttributes",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "schedulePermissionAttributes",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "permissionMapping",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "corporateId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "customConfigMeta",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "updatedAt",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CorporatesAuthMethodsQueries_GetAuthMethodByIdQuery",
    "selections": (v1/*: any*/),
    "type": "InternalQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CorporatesAuthMethodsQueries_GetAuthMethodByIdQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "e4aa76a1aeaf9f708fc8d5c97cd08ae1",
    "id": null,
    "metadata": {},
    "name": "CorporatesAuthMethodsQueries_GetAuthMethodByIdQuery",
    "operationKind": "query",
    "text": "query CorporatesAuthMethodsQueries_GetAuthMethodByIdQuery(\n  $id: ID!\n  $skip: Boolean!\n) {\n  ssoAuthMethods(ids: [$id]) @skip(if: $skip) {\n    nodes {\n      id\n      displayName\n      code\n      authType\n      metadataIssuer\n      metadataUrl\n      metadataConfig\n      metadataOverrides\n      employmentAttrToSearch\n      employmentIdAttributes\n      employmentScheduleAttributes\n      schedulePermissionAttributes\n      permissionMapping\n      corporateId\n      customConfigMeta\n      updatedAt\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6331dd7a3c4299c60875c0f7d5115ea2";

export default node;
