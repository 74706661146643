/**
 * @generated SignedSource<<b61158ea2d27a3b2590fe7de4a6b3251>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AosQueries_AdvancedProfile_Fragment$data = {
  readonly analyticsEnabled: boolean;
  readonly aosConfig: {
    readonly aosCombinedRoles: any | null;
    readonly aosOrchestratorConfig: any | null;
    readonly aosTimeout: number | null;
    readonly replanningOrchestratorConfig: any | null;
    readonly replanningTimeout: number | null;
  } | null;
  readonly aosConfigSchema: any;
  readonly id: string;
  readonly populateScheduleEnabled: boolean;
  readonly populateScheduleReplanningEnabled: boolean;
  readonly " $fragmentType": "AosQueries_AdvancedProfile_Fragment";
};
export type AosQueries_AdvancedProfile_Fragment$key = {
  readonly " $data"?: AosQueries_AdvancedProfile_Fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AosQueries_AdvancedProfile_Fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AosQueries_AdvancedProfile_Fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "analyticsEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "populateScheduleEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "aosConfigSchema",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "populateScheduleReplanningEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AosConfig",
      "kind": "LinkedField",
      "name": "aosConfig",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "aosTimeout",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "aosCombinedRoles",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "aosOrchestratorConfig",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "replanningTimeout",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "replanningOrchestratorConfig",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Schedule",
  "abstractKey": null
};

(node as any).hash = "a80818ac34656b5de9e5f428fff4e893";

export default node;
