import React, { PureComponent } from "react";

import without from "lodash/without";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import ListGroup from "react-bootstrap/ListGroup";
import { Translation } from "react-i18next";
import styled from "styled-components";

import { BaseOption } from "../../../data/models/common";

enum CheckboxStateEnum {
  CHECKED = "checked",
  UNCHECKED = "unchecked",
  INDETERMINATE = "indeterminate",
}

type Props<T> = typeof CheckboxList.defaultProps & {
  options: BaseOption<T, string>[];
  value: T[];
  fieldKey: string;
  onChange: (newValue: T[]) => void;
  disabled?: boolean;
  placeholder?: string;
  hideSelectAll?: boolean;
};

type State = {
  searchValue: string;
  selectAllCheckboxState: CheckboxStateEnum;
};

const StyledCard = styled(Card)`
  max-height: 400px;
  overflow: auto;

  .list-group-item {
    padding-top: 8px;
    padding-bottom: 8px;
  }
`;

export default class CheckboxList<T> extends PureComponent<Props<T>, State> {
  static defaultProps = {
    placeholder: "search...",
  };

  private readonly checkboxRef: React.RefObject<HTMLInputElement>;

  constructor(props: any) {
    super(props);
    this.state = {
      searchValue: "",
      selectAllCheckboxState: this.getSelectAllChecboxState(props.value),
    };

    this.checkboxRef = React.createRef();
  }

  componentDidUpdate() {
    const { selectAllCheckboxState } = this.state;
    const selectAllCheckbox = this.checkboxRef.current;
    if (selectAllCheckbox) {
      switch (selectAllCheckboxState) {
        case CheckboxStateEnum.UNCHECKED:
          selectAllCheckbox.dataset.checked = CheckboxStateEnum.UNCHECKED;
          selectAllCheckbox.indeterminate = false;
          selectAllCheckbox.checked = false;
          break;

        case CheckboxStateEnum.INDETERMINATE:
          selectAllCheckbox.dataset.checked = CheckboxStateEnum.INDETERMINATE;
          selectAllCheckbox.indeterminate = true;
          break;

        // "checked"
        default:
          selectAllCheckbox.dataset.checked = CheckboxStateEnum.CHECKED;
          selectAllCheckbox.indeterminate = false;
          selectAllCheckbox.checked = true;
      }
    }
  }

  getSelectAllChecboxState = (value: any[]) => {
    const { options } = this.props;
    switch ((value || []).length) {
      case 0:
        return CheckboxStateEnum.UNCHECKED;
      case options.length:
        return CheckboxStateEnum.CHECKED;
      default:
        return CheckboxStateEnum.INDETERMINATE;
    }
  };

  getOptionFromValue = (value: any[]) => {
    return (value || []).map((v) =>
      this.props.options.find((i) => i.value === v),
    );
  };

  render() {
    const {
      value,
      onChange,
      fieldKey,
      options,
      disabled,
      placeholder,
      hideSelectAll,
      ...rest
    } = this.props;
    const { searchValue } = this.state;

    let filteredOptions = options || [];
    if (searchValue !== null) {
      filteredOptions = filteredOptions.filter(
        (item) =>
          item.label
            .toLocaleLowerCase()
            .indexOf(searchValue.toLocaleLowerCase()) !== -1,
      );
    }

    return (
      <Translation ns="translation">
        {(t) => (
          <>
            <Form.Control
              type="text"
              className="mb-1"
              disabled={disabled}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const v = e.target.value;
                this.setState({
                  searchValue: v,
                });
              }}
              placeholder={placeholder}
            />
            {!hideSelectAll && (
              <ListGroup>
                <ListGroup.Item className="border-0">
                  <Form.Check
                    ref={this.checkboxRef}
                    type="checkbox"
                    label={t("form.actions.select_all")}
                    disabled={disabled}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const { currentTarget } = e;
                      const { dataset } = currentTarget;
                      switch (dataset.checked) {
                        case CheckboxStateEnum.CHECKED:
                          this.setState({
                            selectAllCheckboxState: CheckboxStateEnum.UNCHECKED,
                          });
                          onChange([]);
                          break;
                        default:
                          this.setState({
                            selectAllCheckboxState: CheckboxStateEnum.CHECKED,
                          });
                          onChange(options.map((option) => option.value));
                      }
                    }}
                  />
                </ListGroup.Item>
              </ListGroup>
            )}
            <StyledCard>
              <ListGroup variant="flush">
                {filteredOptions.map((option, index: number) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <ListGroup.Item key={`option-${index}`}>
                    <Form.Check
                      {...rest}
                      name={fieldKey}
                      type="checkbox"
                      disabled={disabled}
                      // isInvalid={error != null}
                      checked={
                        (value || []).find((v) => v === option.value) != null
                      }
                      label={option.label}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const { checked } = e.target;
                        const defaultValue = value || [];
                        const newValue = checked
                          ? defaultValue.concat([option.value])
                          : without(defaultValue, option.value);
                        onChange(newValue);

                        this.setState({
                          selectAllCheckboxState:
                            this.getSelectAllChecboxState(newValue),
                        });
                      }}
                    />
                  </ListGroup.Item>
                ))}
                {filteredOptions.length === 0 ? (
                  <ListGroup.Item>
                    {t("translation:generic.no_results")}
                  </ListGroup.Item>
                ) : null}
              </ListGroup>
            </StyledCard>
          </>
        )}
      </Translation>
    );
  }
}
