import React, { useState } from "react";

import styled from "styled-components";

const StyledDiv = styled("div")`
  width: 0px;
  animation: fullView 0.5s forwards cubic-bezier(0.25, 0.46, 0.45, 0.94);
  .shimmer-bar {
    height: 10px;
    background: #777;
    animation: shimmer 2s infinite linear;
    background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 60%);
    background-size: 1000px 100%;
    border-radius: 4px;
  }
`;

export interface WithLoadingProps {
  isLoading: boolean;
}

export function withLoading<T extends WithLoadingProps = WithLoadingProps>(
  WrappedComponent: React.ComponentType<T>,
) {
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || "Component";

  const ComponentWithLoading = (props: Omit<T, keyof WithLoadingProps>) => {
    const loading = useState<boolean>(false);

    return loading ? <Loader /> : <WrappedComponent {...(props as T)} />;
  };

  ComponentWithLoading.displayName = `withLoading(${displayName})`;

  return ComponentWithLoading;
}

export interface LoaderProps {
  isLoading?: boolean;
}

export default function Loader({ isLoading = true }: LoaderProps) {
  return (
    <StyledDiv className="wrapper" role="status">
      {isLoading ? (
        <div className="shimmer-bar">
          <span className="sr-only">Loading</span>
        </div>
      ) : null}
    </StyledDiv>
  );
}
