import { commitMutation, Environment, graphql } from "react-relay";

const mutation = graphql`
  mutation UpdateUserMutation($input: UserInput!, $id: ID!) {
    updateUser(id: $id, input: $input) {
      id
      email
      locale
    }
  }
`;

export default (
  environment: Environment,
  id: string,
  input: {},
  callback: Function,
  onError: (error: Error) => void = (err: Error) => {
    alert(err);
  },
) => {
  const variables = {
    id,
    input,
  };

  return commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response: {}, errors: readonly any[] | null | undefined) => {
      callback(response, errors);
    },
    onError,
  });
};
