/**
 * @generated SignedSource<<6112a4fd09c73435c2a0383196600eb6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type RulesPage_AllRules_Query$variables = {};
export type RulesPage_AllRules_Query$data = {
  readonly ruleSets: ReadonlyArray<{
    readonly contents: ReadonlyArray<{
      readonly level: string;
      readonly version: string;
    }>;
    readonly description: string;
    readonly name: string;
  }>;
};
export type RulesPage_AllRules_Query = {
  response: RulesPage_AllRules_Query$data;
  variables: RulesPage_AllRules_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "RuleSet",
    "kind": "LinkedField",
    "name": "ruleSets",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "description",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "RuleSetContents",
        "kind": "LinkedField",
        "name": "contents",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "version",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "level",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "RulesPage_AllRules_Query",
    "selections": (v0/*: any*/),
    "type": "InternalQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "RulesPage_AllRules_Query",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "601a955814d1a75ec7097873dbd6350b",
    "id": null,
    "metadata": {},
    "name": "RulesPage_AllRules_Query",
    "operationKind": "query",
    "text": "query RulesPage_AllRules_Query {\n  ruleSets {\n    name\n    description\n    contents {\n      version\n      level\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "62337ddc0f1657b116babd478196ab3e";

export default node;
