/**
 * @generated SignedSource<<011d7b2003269d8c4ac80d960444a91a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EmploymentFeedbackTypeEnum = "commendation" | "disciplinary_action" | "%future added value";
export type EmploymentFeedbackReasonUpdateInput = {
  defaultMessage?: string | null;
  description?: string | null;
};
export type FeedbackReasonsQueries_UpdateEmploymentFeedbackReasonMutation$variables = {
  businessId: string;
  id: string;
  input: EmploymentFeedbackReasonUpdateInput;
};
export type FeedbackReasonsQueries_UpdateEmploymentFeedbackReasonMutation$data = {
  readonly updateEmploymentFeedbackReason: {
    readonly defaultMessage: string | null;
    readonly deleted: boolean;
    readonly description: string;
    readonly feedbackType: EmploymentFeedbackTypeEnum;
    readonly id: string;
  };
};
export type FeedbackReasonsQueries_UpdateEmploymentFeedbackReasonMutation = {
  response: FeedbackReasonsQueries_UpdateEmploymentFeedbackReasonMutation$data;
  variables: FeedbackReasonsQueries_UpdateEmploymentFeedbackReasonMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "businessId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "businessId",
        "variableName": "businessId"
      },
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "EmploymentFeedbackReason",
    "kind": "LinkedField",
    "name": "updateEmploymentFeedbackReason",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "description",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "defaultMessage",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deleted",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "feedbackType",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "FeedbackReasonsQueries_UpdateEmploymentFeedbackReasonMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "FeedbackReasonsQueries_UpdateEmploymentFeedbackReasonMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "b4c884f2ca267f16e6551f25a95e348f",
    "id": null,
    "metadata": {},
    "name": "FeedbackReasonsQueries_UpdateEmploymentFeedbackReasonMutation",
    "operationKind": "mutation",
    "text": "mutation FeedbackReasonsQueries_UpdateEmploymentFeedbackReasonMutation(\n  $id: ID!\n  $businessId: ID!\n  $input: EmploymentFeedbackReasonUpdateInput!\n) {\n  updateEmploymentFeedbackReason(id: $id, businessId: $businessId, input: $input) {\n    description\n    defaultMessage\n    deleted\n    feedbackType\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "607fa5ea3bcbe41a733699cce0a32873";

export default node;
