import React, { FormEventHandler } from "react";

import { Formik, FormikHelpers, FormikState } from "formik";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import * as yup from "yup";

import { useModal } from "../../../../contexts/ModalContext";
import { IUser } from "../../../../data/models/common";
import { idmInternalEnvironment } from "../../../../environment";
import ServerError from "../../../../utils/server-error";
import UpdateUserMutation from "../mutations/UpdateUserMutation";

type FormValues = {
  password?: string;
};

const validationRules = yup.object({
  password: yup.string().required(),
});

const initialFormValues: FormValues = {
  password: undefined,
};

type Props = {
  user: IUser;
};

function ChangePasswordDialog({ user }: Props) {
  const { t } = useTranslation();
  const { hideModal } = useModal();

  const handleFormSubmit = (
    values: FormValues,
    { setSubmitting, setFieldError, resetForm }: FormikHelpers<FormValues>,
  ) => {
    setSubmitting(true);

    UpdateUserMutation(
      idmInternalEnvironment,
      user.id,
      values,
      () => {
        setSubmitting(false);
        toast(t("form.notifications.saved_successfully"));
        resetForm(initialFormValues as Partial<FormikState<FormValues>>);
        hideModal();
      },
      (error: Error) => {
        setSubmitting(false);
        if (!error) {
          return;
        }
        const serverError = new ServerError(error);
        setFieldError("password", serverError.getErrorDetails());
      },
    );
  };

  return (
    <Formik<FormValues>
      validationSchema={validationRules}
      onSubmit={handleFormSubmit}
      initialValues={initialFormValues}
    >
      {({ handleSubmit, handleChange, values, errors, resetForm }) => (
        <Modal show onHide={hideModal}>
          <Form onSubmit={handleSubmit as unknown as FormEventHandler}>
            <Modal.Header closeButton>
              <Modal.Title>
                {t("change_user_password_dialog.title", {
                  email: user.email,
                })}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group controlId="new-password">
                <Form.Label>
                  {t("change_user_password_dialog.password_label")}
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t("change_user_password_dialog.new_password")}
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  isInvalid={errors.password != null}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.password}
                </Form.Control.Feedback>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => {
                  resetForm(
                    initialFormValues as Partial<FormikState<FormValues>>,
                  );
                  hideModal();
                }}
              >
                {t("form.actions.cancel")}
              </Button>
              <Button variant="primary" type="submit">
                {t("form.actions.ok")}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      )}
    </Formik>
  );
}

export default ChangePasswordDialog;
