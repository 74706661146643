/**
 * @generated SignedSource<<ba5de166960d71a9e4371c193279a791>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type BusinessService_Employments_Query$variables = {
  businessId: string;
  userId?: string | null;
};
export type BusinessService_Employments_Query$data = {
  readonly employments: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly acceptedInvite: boolean;
        readonly createdAt: string;
        readonly deleted: boolean;
        readonly during: string | null;
        readonly email: string;
        readonly firstName: string;
        readonly id: string;
        readonly lastName: string;
        readonly updatedAt: string;
      };
    }>;
  };
};
export type BusinessService_Employments_Query = {
  response: BusinessService_Employments_Query$data;
  variables: BusinessService_Employments_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "businessId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "businessId",
        "variableName": "businessId"
      },
      {
        "kind": "Variable",
        "name": "userId",
        "variableName": "userId"
      }
    ],
    "concreteType": "EmploymentConnection",
    "kind": "LinkedField",
    "name": "employments",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "EmploymentEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Employment",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "firstName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "acceptedInvite",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "deleted",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "during",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "createdAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "updatedAt",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BusinessService_Employments_Query",
    "selections": (v1/*: any*/),
    "type": "InternalQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BusinessService_Employments_Query",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "da2bb5e562ef8b3fb0a2377c01960a25",
    "id": null,
    "metadata": {},
    "name": "BusinessService_Employments_Query",
    "operationKind": "query",
    "text": "query BusinessService_Employments_Query(\n  $businessId: ID!\n  $userId: ID\n) {\n  employments(businessId: $businessId, userId: $userId) {\n    edges {\n      node {\n        id\n        firstName\n        lastName\n        acceptedInvite\n        deleted\n        email\n        during\n        createdAt\n        updatedAt\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "73b14bf4b51d355268bbc9b2df37a455";

export default node;
