/**
 * @generated SignedSource<<19a97143cdd6bc08e5607c013cf0a079>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CorporatesQueries_GetCorporatesById_InternalQuery$variables = {
  ids: ReadonlyArray<string>;
  skip: boolean;
};
export type CorporatesQueries_GetCorporatesById_InternalQuery$data = {
  readonly corporates?: {
    readonly nodes: ReadonlyArray<{
      readonly corporateStacks: ReadonlyArray<{
        readonly id: string;
        readonly stack: {
          readonly id: string;
          readonly stackCode: string;
        };
      }>;
      readonly createdAt: string;
      readonly deleted: boolean;
      readonly id: string;
      readonly name: string;
      readonly ssoAuthMethods: ReadonlyArray<{
        readonly authType: string;
        readonly code: string;
        readonly corporateId: string;
        readonly createdAt: string;
        readonly displayName: string;
        readonly employmentAttrToSearch: string;
        readonly employmentIdAttributes: ReadonlyArray<string>;
        readonly id: string;
        readonly metadataConfig: string | null;
        readonly metadataIssuer: string | null;
        readonly metadataOverrides: any | null;
        readonly metadataSyncedAt: string | null;
        readonly metadataUrl: string | null;
        readonly updatedAt: string;
      }>;
      readonly updatedAt: string;
    }>;
  };
};
export type CorporatesQueries_GetCorporatesById_InternalQuery = {
  response: CorporatesQueries_GetCorporatesById_InternalQuery$data;
  variables: CorporatesQueries_GetCorporatesById_InternalQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ids"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "skip"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updatedAt",
  "storageKey": null
},
v4 = [
  {
    "condition": "skip",
    "kind": "Condition",
    "passingValue": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "ids",
            "variableName": "ids"
          }
        ],
        "concreteType": "CorporateConnection",
        "kind": "LinkedField",
        "name": "corporates",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Corporate",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SSOAuthMethodType",
                "kind": "LinkedField",
                "name": "ssoAuthMethods",
                "plural": true,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "authType",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "code",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "corporateId",
                    "storageKey": null
                  },
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "displayName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "employmentIdAttributes",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "employmentAttrToSearch",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "metadataConfig",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "metadataIssuer",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "metadataOverrides",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "metadataSyncedAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "metadataUrl",
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CorporateStack",
                "kind": "LinkedField",
                "name": "corporateStacks",
                "plural": true,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Stack",
                    "kind": "LinkedField",
                    "name": "stack",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "stackCode",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "deleted",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CorporatesQueries_GetCorporatesById_InternalQuery",
    "selections": (v4/*: any*/),
    "type": "InternalQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CorporatesQueries_GetCorporatesById_InternalQuery",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "f9063bcf0a793ffee6f6b2a8480ba3e0",
    "id": null,
    "metadata": {},
    "name": "CorporatesQueries_GetCorporatesById_InternalQuery",
    "operationKind": "query",
    "text": "query CorporatesQueries_GetCorporatesById_InternalQuery(\n  $ids: [ID!]!\n  $skip: Boolean!\n) {\n  corporates(ids: $ids) @skip(if: $skip) {\n    nodes {\n      id\n      name\n      ssoAuthMethods {\n        id\n        authType\n        code\n        corporateId\n        createdAt\n        displayName\n        employmentIdAttributes\n        employmentAttrToSearch\n        metadataConfig\n        metadataIssuer\n        metadataOverrides\n        metadataSyncedAt\n        metadataUrl\n        updatedAt\n      }\n      corporateStacks {\n        id\n        stack {\n          id\n          stackCode\n        }\n      }\n      createdAt\n      updatedAt\n      deleted\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "656473d32446992361990069d260a838";

export default node;
