import React from "react";

import Card from "react-bootstrap/Card";
import { useTranslation } from "react-i18next";
import { Environment, graphql, QueryRenderer } from "react-relay";
import { RouteComponentProps } from "react-router-dom";

import Loader from "../../../common/Loader";
import { AccountProfile_Query } from "./__generated__/AccountProfile_Query.graphql";
import Profile from "./Profile";

const AccountProfileQuery = graphql`
  query AccountProfile_Query($userId: ID!) {
    users(ids: [$userId]) {
      edges {
        node {
          ...Profile_user
        }
      }
    }
  }
`;

export interface MatchParams {
  user_id: string;
}

type Props = RouteComponentProps<MatchParams> & {
  environment: Environment;
};

export default function AccountProfile({ match, environment }: Props) {
  const { params } = match;
  const userId = params.user_id;
  const { t } = useTranslation();

  return (
    <QueryRenderer<AccountProfile_Query>
      environment={environment}
      query={AccountProfileQuery}
      variables={{
        userId,
      }}
      render={({ error, props }) => {
        if (error) {
          return <div>{t("translation:error")}</div>;
        }
        if (!props) {
          return <Loader />;
        }

        return (
          <Card body>
            <Profile user={(props as any).users.edges[0].node} />
          </Card>
        );
      }}
    />
  );
}
