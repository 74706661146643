import { commitMutation, Environment, graphql } from "react-relay";

const mutation = graphql`
  mutation RemoveRulesOnScheduleMutation($businessId: ID!, $scheduleId: ID!) {
    removeRulesOnSchedule(businessId: $businessId, scheduleId: $scheduleId) {
      ruleSetName
      ruleSetVersion
      ruleSetSubscriptionStatus
      ruleSetMeta
    }
  }
`;

export type RemoveRulesOnScheduleMutationInput = {
  businessId: string;
  scheduleId: string;
};

export default (
  environment: Environment,
  input: RemoveRulesOnScheduleMutationInput,
  callback: Function,
  onError: (error: Error) => void = (err: Error) => {
    alert(err);
  },
) => {
  const variables = input;

  return commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response: {}, errors: readonly any[] | null | undefined) => {
      callback(response, errors);
    },
    onError,
  });
};
