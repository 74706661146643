/**
 * @generated SignedSource<<4b39d09e4c444109fa2bc79c68d3bb9b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type LegacyBreakTypeEnum = "meal" | "rest" | "unknown" | "%future added value";
export type BreakTypeQueries_GetSingleScheduleBreakType_Query$variables = {
  businessId: string;
  id: string;
  scheduleId: string;
  skip: boolean;
};
export type BreakTypeQueries_GetSingleScheduleBreakType_Query$data = {
  readonly scheduleBreakTypes?: {
    readonly nodes: ReadonlyArray<{
      readonly availableRange: {
        readonly rangeEndTime: any;
        readonly rangeStartTime: any;
      } | null;
      readonly breakScreenOrdering: number;
      readonly breakTypeCode: string;
      readonly canOverrideClockedPaidBreak: boolean;
      readonly code: string | null;
      readonly defaultDuration: any | null;
      readonly deleted: boolean;
      readonly description: string | null;
      readonly employmentMetadata: string | null;
      readonly employmentTypeCodes: ReadonlyArray<string>;
      readonly hidden: boolean;
      readonly id: string;
      readonly internalAccess: boolean;
      readonly legacyBreakType: LegacyBreakTypeEnum;
      readonly name: string;
      readonly paidAfter: any;
      readonly paidThreshold: any;
      readonly schedulable: boolean;
      readonly scheduleCanOverrideClockedPaidBreak: boolean | null;
      readonly schedulePaidAfter: any | null;
      readonly schedulePaidThreshold: any | null;
      readonly scheduleUnpaidAfter: any | null;
      readonly unpaidAfter: any;
    }>;
  };
};
export type BreakTypeQueries_GetSingleScheduleBreakType_Query = {
  response: BreakTypeQueries_GetSingleScheduleBreakType_Query$data;
  variables: BreakTypeQueries_GetSingleScheduleBreakType_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "businessId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "scheduleId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "skip"
},
v4 = [
  {
    "condition": "skip",
    "kind": "Condition",
    "passingValue": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "businessId",
            "variableName": "businessId"
          },
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
          },
          {
            "kind": "Variable",
            "name": "scheduleId",
            "variableName": "scheduleId"
          }
        ],
        "concreteType": "ScheduleBreakTypeConnection",
        "kind": "LinkedField",
        "name": "scheduleBreakTypes",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ScheduleBreakType",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hidden",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "deleted",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "schedulePaidThreshold",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "scheduleCanOverrideClockedPaidBreak",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "scheduleUnpaidAfter",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "schedulePaidAfter",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "breakTypeCode",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "legacyBreakType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "breakScreenOrdering",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "code",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "internalAccess",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "defaultDuration",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "HoursMinutesRange",
                "kind": "LinkedField",
                "name": "availableRange",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "rangeStartTime",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "rangeEndTime",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "schedulable",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "paidThreshold",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "unpaidAfter",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "paidAfter",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "canOverrideClockedPaidBreak",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "employmentTypeCodes",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "employmentMetadata",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "BreakTypeQueries_GetSingleScheduleBreakType_Query",
    "selections": (v4/*: any*/),
    "type": "InternalQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "BreakTypeQueries_GetSingleScheduleBreakType_Query",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "3245ea29cb36246ec0025a774c814d56",
    "id": null,
    "metadata": {},
    "name": "BreakTypeQueries_GetSingleScheduleBreakType_Query",
    "operationKind": "query",
    "text": "query BreakTypeQueries_GetSingleScheduleBreakType_Query(\n  $businessId: ID!\n  $scheduleId: ID!\n  $id: ID!\n  $skip: Boolean!\n) {\n  scheduleBreakTypes(businessId: $businessId, scheduleId: $scheduleId, id: $id) @skip(if: $skip) {\n    nodes {\n      hidden\n      id\n      deleted\n      schedulePaidThreshold\n      scheduleCanOverrideClockedPaidBreak\n      scheduleUnpaidAfter\n      schedulePaidAfter\n      name\n      breakTypeCode\n      legacyBreakType\n      breakScreenOrdering\n      description\n      code\n      internalAccess\n      defaultDuration\n      availableRange {\n        rangeStartTime\n        rangeEndTime\n      }\n      schedulable\n      paidThreshold\n      unpaidAfter\n      paidAfter\n      canOverrideClockedPaidBreak\n      employmentTypeCodes\n      employmentMetadata\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "7739f79e6799a997713d8960816d057e";

export default node;
