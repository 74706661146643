import React from "react";

import { Col, Row, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { graphql, useLazyLoadQuery } from "react-relay";

import { useBusinessContext } from "../../../contexts/BusinessContext";
import Field from "../../common/Form/Field";
import { BooleanFieldType } from "../../common/Form/models";
import {
  BusinessEmploymentStatusChangeReason_StatusChangeReasons_Query,
  EmploymentHistoryStatusEnum,
} from "./__generated__/BusinessEmploymentStatusChangeReason_StatusChangeReasons_Query.graphql";

type Props = {
  fieldKey: string;
  label: string;
  status: EmploymentHistoryStatusEnum;
};

function BusinessEmploymentStatusChangeReason({
  fieldKey,
  label,
  status,
}: Props) {
  const { business } = useBusinessContext();
  const { t } = useTranslation("businesses");

  const data =
    useLazyLoadQuery<BusinessEmploymentStatusChangeReason_StatusChangeReasons_Query>(
      graphql`
        query BusinessEmploymentStatusChangeReason_StatusChangeReasons_Query(
          $businessId: ID!
          $status: EmploymentHistoryStatusEnum!
          $skip: Boolean!
        ) {
          employmentStatusChangeReasons(
            businessId: $businessId
            deleted: false
            status: [$status]
          ) @skip(if: $skip) {
            nodes {
              id
              deleted
              code
              status
              displayText
            }
          }
        }
      `,
      {
        businessId: business.id ?? "",
        status,
        skip: business.id == null,
      },
    );

  const statusChangeReasons = data?.employmentStatusChangeReasons?.nodes ?? [];
  const statusChangeReasonsIngested = statusChangeReasons.length > 0;

  return (
    <Row>
      <Field
        sm={12}
        md={12}
        lg={3}
        xl={3}
        fieldKey={fieldKey}
        label={label}
        schemaFieldType={BooleanFieldType}
        description={
          !statusChangeReasonsIngested
            ? t(
                `profile.mandatoryReasonsForEmploymentStatus.${status}.noneIngested`,
              )
            : undefined
        }
      />
      {statusChangeReasonsIngested && (
        <Col sm={12} md={12} lg={6} xl={4}>
          <Table size="md">
            <thead>
              <tr>
                <th>
                  {t(
                    `profile.mandatoryReasonsForEmploymentStatus.${status}.tableHeaders.code`,
                  )}
                </th>
                <th>
                  {t(
                    `profile.mandatoryReasonsForEmploymentStatus.${status}.tableHeaders.reason`,
                  )}
                </th>
              </tr>
            </thead>
            <tbody>
              {statusChangeReasons.map((statusChangeReason) => {
                if (statusChangeReason) {
                  return (
                    <tr key={statusChangeReason.id}>
                      <td>{statusChangeReason.code ?? ""}</td>
                      <td>{statusChangeReason.displayText}</td>
                    </tr>
                  );
                }
                return null;
              })}
            </tbody>
          </Table>
        </Col>
      )}
    </Row>
  );
}

export default BusinessEmploymentStatusChangeReason;
