import i18next from "i18next";

import { GraphQLDateFormat } from "../../../data/models/common";
import ColorInput from "./ColorInput";
import DateTimePicker from "./DateTimePicker";
import DurationInput from "./DurationInput";
import JsonEditor from "./JsonEditor";
import Textarea from "./Textarea";

// Register common components
// return function so that i18n strings are initialised
const getCommonComponents: () => Record<string, any> = () => ({
  Date: {
    component: DateTimePicker,
    componentProps: {
      displayFormat: "yyyy-MM-dd",
      valueFormat: "yyyy-MM-dd",
    },
  },
  Time: {
    component: DateTimePicker,
    componentProps: {
      showTimeSelect: true,
      showTimeSelectOnly: true,
      timeIntervals: 30,
      valueFormat: GraphQLDateFormat,
      displayFormat: "hh:mm aa",
    },
  },
  SecondsToHourDuration: {
    component: DurationInput,
    componentProps: {
      from: "seconds",
      to: "hours",
      postfix: i18next.t("translation:unitOfTime.hours_shorthand_other"),
    },
  },
  MinuteToHourDuration: {
    component: DurationInput,
    componentProps: {
      from: "minutes",
      to: "hours",
      postfix: i18next.t("translation:unitOfTime.hours_shorthand_other"),
    },
  },
  SecondsToMinutesDuration: {
    component: DurationInput,
    componentProps: {
      from: "seconds",
      to: "minutes",
      postfix: i18next.t("translation:unitOfTime.minutes_shorthand_other"),
    },
  },
  MinuteDuration: {
    component: DurationInput,
    componentProps: {
      postfix: i18next.t("translation:unitOfTime.minutes_shorthand_other"),
      allowDecimals: false,
    },
  },
  DaysDuration: {
    component: DurationInput,
    componentProps: {
      postfix: i18next.t("translation:unitOfTime.days_shorthand_other"),
      allowDecimals: false,
    },
  },
  HourDuration: {
    component: DurationInput,
    componentProps: {
      postfix: i18next.t("translation:unitOfTime.hours_shorthand_other"),
      allowDecimals: false,
    },
  },
  DayDuration: {
    component: DurationInput,
    componentProps: {
      postfix: i18next.t("translation:unitOfTime.days_shorthand_other"),
      allowDecimals: false,
    },
  },
  MonthDuration: {
    component: DurationInput,
    componentProps: {
      postfix: i18next.t("translation:unitOfTime.months"),
      allowDecimals: false,
    },
  },
  YearDuration: {
    component: DurationInput,
    componentProps: {
      postfix: i18next.t("translation:unitOfTime.years"),
      allowDecimals: false,
    },
  },
  Textarea: {
    lg: 12, // col-lg-12
    md: 12, // col-md-12
    xs: 12, // col-xs-12
    component: Textarea,
    componentProps: {
      rows: 3,
    },
  },
  JsonEditor: {
    lg: 12, // col-lg-12
    md: 12, // col-md-12
    xs: 12, // col-xs-12
    component: JsonEditor,
  },
  ColorInput: {
    component: ColorInput,
    lg: 3, // col-lg-3
    md: 6, // col-md-6
    xs: 12, // col-xs-12
  },
});

export default getCommonComponents;
