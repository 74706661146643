import React from "react";

import Card from "react-bootstrap/Card";
import { useTranslation } from "react-i18next";
import { graphql, QueryRenderer } from "react-relay";
import { RouteComponentProps } from "react-router-dom";

import { useBusinessContext } from "../../../contexts/BusinessContext";
import { useAppRouter } from "../../../hooks/useAppRouter";
import Loader from "../../common/Loader";
import { AosConfigBasic } from "../Schedule/AOS/models";
import {
  AOSTemplateProfile_Query,
  AOSTemplateProfile_Query$data,
} from "./__generated__/AOSTemplateProfile_Query.graphql";
import Profile from "./Profile";

const AOSTemplateProfileQuery = graphql`
  query AOSTemplateProfile_Query($businessId: ID!, $id: ID!) {
    aosConfigTemplates(businessId: $businessId, ids: [$id]) {
      edges {
        node {
          ...Profile_template
        }
      }
    }
  }
`;

export type NodeType =
  AOSTemplateProfile_Query$data["aosConfigTemplates"]["edges"][number]["node"];

interface MatchParams {
  stack_id: string;
  business_id: string;
  aos_template_id?: string;
  schedule_id?: string;
  clone_from_id?: string;
}

type Props = RouteComponentProps<MatchParams> & {
  location: {
    query?: {
      aosConfig?: AosConfigBasic;
    };
  };
};

export default function AOSTemplateProfile(props: Props) {
  const {
    params: { aos_template_id: aosTemplateId, business_id: businessId },
  } = useAppRouter();
  const { environment } = useBusinessContext();
  const { t } = useTranslation();

  const getProfile = (item: NodeType | null) => {
    return (
      <Card body>
        <Profile template={item} environment={environment} />
      </Card>
    );
  };

  if (aosTemplateId == null || !environment) {
    // create new
    return getProfile(null);
  }

  return (
    <QueryRenderer<AOSTemplateProfile_Query>
      environment={environment}
      query={AOSTemplateProfileQuery}
      variables={{
        businessId,
        id: aosTemplateId,
      }}
      render={({ error, props: queryProps }) => {
        if (error) {
          return <div>{t("translation:error")}</div>;
        }
        if (!queryProps) {
          return <Loader />;
        }

        return getProfile(
          queryProps?.aosConfigTemplates.edges[0]?.node ?? null,
        );
      }}
    />
  );
}
