import { useLazyLoadQuery, useMutation } from "react-relay";
import { graphql } from "relay-runtime";

import {
  KpiComponentsQueries_GetKpis_InternalQuery,
  KpiComponentsQueries_GetKpis_InternalQuery$data,
} from "./__generated__/KpiComponentsQueries_GetKpis_InternalQuery.graphql";
import {
  KpiComponentsQueries_GetKpiScreenConfigs_InternalQuery,
  KpiComponentsQueries_GetKpiScreenConfigs_InternalQuery$data,
  ScreenCodeEnum,
} from "./__generated__/KpiComponentsQueries_GetKpiScreenConfigs_InternalQuery.graphql";
import {
  KpiComponentsQueries_GetKpiTargets_InternalQuery,
  KpiComponentsQueries_GetKpiTargets_InternalQuery$data,
} from "./__generated__/KpiComponentsQueries_GetKpiTargets_InternalQuery.graphql";

export const GetKpiTargets = graphql`
  query KpiComponentsQueries_GetKpiTargets_InternalQuery($businessId: ID!) {
    setValues(businessId: $businessId) {
      nodes {
        id
        code
        createdAt
        description
        details
        displayOrder
        setName {
          createdAt
          description
          id
          name
          updatedAt
        }
        setNameId
        updatedAt
      }
    }
  }
`;

export const GetKpiScreenConfigs = graphql`
  query KpiComponentsQueries_GetKpiScreenConfigs_InternalQuery(
    $businessId: ID!
    $screenCode: ScreenCodeEnum
  ) {
    kpiScreenConfigs(businessId: $businessId, screenCode: $screenCode) {
      nodes {
        id
        screenCode
        updatedByName
        updatedById
        updatedAt
        kpiDisplayConfig {
          displayLabel
          displayOrder
          displayTooltip
          kpiId
          targetId
          kpi {
            id
            code
            createdAt
            description
            isCostRelated
            name
            updatedAt
          }
        }
      }
    }
  }
`;

export const GetKpis = graphql`
  query KpiComponentsQueries_GetKpis_InternalQuery($businessId: ID!) {
    kpis(businessId: $businessId) {
      nodes {
        id
        code
        createdAt
        description
        isCostRelated
        name
        updatedAt
      }
    }
  }
`;

export const UpdateKpiScreenConfig = graphql`
  mutation KpiComponentsQueries_UpdateKpiScreenConfig_InternalQuery(
    $businessId: ID!
    $id: ID!
    $input: KpiScreenConfigUpdateInput!
  ) {
    updateKpiScreenConfig(businessId: $businessId, id: $id, input: $input) {
      id
      screenCode
      updatedByName
      updatedById
      updatedAt
      kpiDisplayConfig {
        displayLabel
        displayOrder
        displayTooltip
        kpiId
        targetId
        kpi {
          id
          code
          createdAt
          description
          isCostRelated
          name
          updatedAt
        }
      }
    }
  }
`;

/** The type of nodes returned by the queries with nulls stripped out */

export type GetKpiScreenConfigs_Node = Exclude<
  (Record<string, never> &
    KpiComponentsQueries_GetKpiScreenConfigs_InternalQuery$data["kpiScreenConfigs"]["nodes"])[number],
  null
>;

export type GetKpiTargets_Node = Exclude<
  (Record<string, never> &
    KpiComponentsQueries_GetKpiTargets_InternalQuery$data["setValues"]["nodes"])[number],
  null
>;

export type GetKpiScreenConfigs_KpiDisplayConfig = Exclude<
  GetKpiScreenConfigs_Node["kpiDisplayConfig"][number],
  null
>;

export type GetKpis_Node = Exclude<
  (Record<string, never> &
    KpiComponentsQueries_GetKpis_InternalQuery$data["kpis"]["nodes"])[number],
  null
>;

export function useKpis(variables: { businessId: string }) {
  const kpisData = useLazyLoadQuery<KpiComponentsQueries_GetKpis_InternalQuery>(
    GetKpis,
    {
      businessId: variables.businessId,
    },
  );

  return kpisData.kpis.nodes;
}

export function useKpiTargets(variables: { businessId: string }) {
  const targetsData =
    useLazyLoadQuery<KpiComponentsQueries_GetKpiTargets_InternalQuery>(
      GetKpiTargets,
      {
        businessId: variables.businessId,
      },
    );

  const targets = targetsData.setValues.nodes;

  return targets;
}

export function useKpiScreenConfigs(variables: {
  businessId: string;
  screenCode?: ScreenCodeEnum;
}) {
  const kpisData =
    useLazyLoadQuery<KpiComponentsQueries_GetKpiScreenConfigs_InternalQuery>(
      GetKpiScreenConfigs,
      {
        businessId: variables.businessId,
        ...(variables.screenCode && { screenCode: variables.screenCode }),
      },
    );

  const kpis = kpisData.kpiScreenConfigs.nodes;

  return kpis;
}

export function useKpiScreenConfig(variables: {
  businessId: string;
  screenCode: ScreenCodeEnum;
}) {
  const kpiScreenConfigs = useKpiScreenConfigs(variables);
  const [updateKpiMutation] = useMutation(UpdateKpiScreenConfig);

  const kpiScreen = kpiScreenConfigs?.find(
    (config) => config?.screenCode === variables.screenCode,
  );

  const updateMutation = ({
    data,
    onError,
    onCompleted,
  }: {
    data: GetKpiScreenConfigs_Node;
    onCompleted: () => void;
    onError: (error: Error) => void;
  }) => {
    if (kpiScreen) {
      const input = {
        // Ensure that we remove the kpi subobject from this when sending to the backend
        kpiDisplayConfig: data.kpiDisplayConfig.map((displayConfig) => ({
          displayLabel: displayConfig.displayLabel,
          displayOrder: displayConfig.displayOrder,
          displayTooltip: displayConfig.displayTooltip,
          kpiId: displayConfig.kpiId,
          targetId: displayConfig.targetId,
        })),
      };

      updateKpiMutation({
        variables: {
          businessId: variables.businessId,
          id: kpiScreen.id,
          input,
        },
        onCompleted,
        onError,
      });
    }
  };

  return [kpiScreen, updateMutation] as const;
}
