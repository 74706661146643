import React, { Suspense, useEffect } from "react";

import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useTranslation } from "react-i18next";
import {
  graphql,
  PreloadedQuery,
  usePreloadedQuery,
  useQueryLoader,
} from "react-relay";
import { LinkContainer } from "react-router-bootstrap";
import { Link, Redirect, Route } from "react-router-dom";

import { useBusinessContext } from "../../../contexts/BusinessContext";
import { ScheduleContextProvider } from "../../../contexts/ScheduleContext";
import { useAppRouter } from "../../../hooks/useAppRouter";
import Loader from "../../common/Loader";
import HeaderPortal from "../../common/Portal/HeaderPortal";
import BreakTypes from "../BreakTypes/BreakTypes";
import BreakTypesProfile from "../BreakTypes/BreakTypesProfile/BreakTypesProfile";
import { ScheduleLayout_Query } from "./__generated__/ScheduleLayout_Query.graphql";
import AOSSettingsLayout from "./AOS/AOSSettingsLayout";
import RulesPage from "./Rules/RulesPage";
import ScheduleProfile from "./ScheduleProfile/ScheduleProfile";
import PayPeriods from "./PayPeriods";

const query = graphql`
  query ScheduleLayout_Query($businessId: ID!, $scheduleId: ID!) {
    schedules(businessId: $businessId, ids: [$scheduleId]) {
      nodes {
        id
        scheduleName
        isGroup
        timeZone
        payPeriodEnabled
        populateScheduleEnabled
        dayStartTime
        dayEndTime
        firstDayOfWeek
      }
    }
  }
`;

interface MatchParams {
  business_id: string;
  schedule_id: string;
  stack_id: string;
}

type Props = {
  initialQueryRef: PreloadedQuery<ScheduleLayout_Query>;
};

// Page active within the router of this component
enum ActiveSubRouteLocation {
  General = "general",
  BreakType = "breakTypes",
  BreakTypeEdit = "breakTypesEdit",
  AOS = "aos",
  PayPeriod = "payPeriods",
  Rules = "rules",
}

export default function ScheduleLayout({ initialQueryRef }: Props) {
  const [queryReference, loadQuery] = useQueryLoader(query, initialQueryRef);
  const {
    params: { business_id: businessId, schedule_id: scheduleId },
  } = useAppRouter<MatchParams>();

  useEffect(() => {
    loadQuery({ scheduleId, businessId });
  }, [scheduleId, businessId, loadQuery]);

  return (
    <Suspense fallback={<Loader />}>
      {queryReference != null && (
        <ScheduleLayoutWithData queryReference={queryReference} />
      )}
    </Suspense>
  );
}

function ScheduleLayoutWithData({
  queryReference,
}: {
  queryReference: PreloadedQuery<ScheduleLayout_Query>;
}) {
  const { t } = useTranslation("schedules");
  const { business } = useBusinessContext();
  const { schedules } = usePreloadedQuery<ScheduleLayout_Query>(
    query,
    queryReference,
  );

  const router = useAppRouter<MatchParams>();
  const { match } = router;

  const [selectedSubRoute, subRouteOptions] = useScheduleLayoutNavigation();

  const dropdownIsSelected = [
    ActiveSubRouteLocation.BreakType,
    ActiveSubRouteLocation.BreakTypeEdit,
    ActiveSubRouteLocation.General,
  ].includes(selectedSubRoute as ActiveSubRouteLocation);

  if (!schedules.nodes.length || !schedules.nodes[0]) {
    // return <div>Cannot find schedule with id {scheduleId}</div>;
    return <Redirect to="/stacks" />;
  }

  const schedule = schedules.nodes[0];

  return (
    <ScheduleContextProvider schedule={schedule}>
      <HeaderPortal>
        <Link
          to={`/stack/${match.params.stack_id}/business/${business.id}/profile/schedules`}
        >
          <span>{business.businessName}</span>
        </Link>
        <span className="ml-2 mr-2">&gt;</span>

        {selectedSubRoute ? (
          <Link
            to={`/stack/${match.params.stack_id}/business/${business.id}/schedule/${schedule.id}`}
          >
            <span>{schedule.scheduleName}</span>
          </Link>
        ) : (
          <span id="schedule-header">{schedule.scheduleName}</span>
        )}

        <span id="sub-header-portal" />
      </HeaderPortal>
      <header className="sub-header-container">
        {subRouteOptions?.hideNav && (
          <Nav variant="tabs">
            <LinkContainer
              className={dropdownIsSelected ? "active" : ""}
              exact
              to="#"
            >
              <Nav.Link>{t("layout.nav.settings")}</Nav.Link>
            </LinkContainer>
          </Nav>
        )}

        {!subRouteOptions?.hideNav && (
          <Nav variant="tabs">
            <NavDropdown
              title={t("layout.nav.settings")}
              id="nav-dropdown"
              className={dropdownIsSelected ? "active" : ""}
            >
              <LinkContainer exact to={`${match.url}`}>
                <NavDropdown.Item>{t("layout.nav.general")}</NavDropdown.Item>
              </LinkContainer>
              <NavDropdown.Divider />
              <LinkContainer exact to={`${match.url}/break_types`}>
                <NavDropdown.Item>
                  {t("layout.nav.breakTypes")}
                </NavDropdown.Item>
              </LinkContainer>
            </NavDropdown>
            <LinkContainer exact to={`${match.url}/rules`}>
              <Nav.Link>{t("layout.nav.rules")}</Nav.Link>
            </LinkContainer>

            <LinkContainer to={`${match.url}/aos`}>
              <Nav.Link>{t("layout.nav.aos")}</Nav.Link>
            </LinkContainer>

            {schedule.payPeriodEnabled ? (
              <LinkContainer to={`${match.url}/pay-periods`}>
                <Nav.Link>{t("layout.nav.payPeriods")}</Nav.Link>
              </LinkContainer>
            ) : null}
          </Nav>
        )}
      </header>
      <div className="mt-3">
        <Route exact path={`${match.path}`} component={ScheduleProfile} />
        <Route path={`${match.path}/rules`} component={RulesPage} />
        <Route
          exact
          path={`${match.path}/break_types`}
          component={BreakTypes}
        />
        <Route
          exact
          path={`${match.path}/break_types/edit/:break_type_id/:schedule_break_type_id`}
          component={BreakTypesProfile}
        />
        <Route path={`${match.path}/aos`} component={AOSSettingsLayout} />
        {schedule.payPeriodEnabled ? (
          <Route path={`${match.path}/pay-periods`} component={PayPeriods} />
        ) : null}
      </div>
    </ScheduleContextProvider>
  );
}

function useScheduleLayoutNavigation() {
  const router = useAppRouter<MatchParams>();

  const defaultOptions = {
    hideNav: false,
  };

  if (router.pathname.includes("/break_types")) {
    if (router.pathname.endsWith("/break_types")) {
      return [ActiveSubRouteLocation.BreakType, defaultOptions] as const;
    }
    return [
      ActiveSubRouteLocation.BreakTypeEdit,
      {
        hideNav: true,
      },
    ] as const;
  }

  if (router.pathname.endsWith("/rules")) {
    return [ActiveSubRouteLocation.Rules, defaultOptions] as const;
  }

  if (router.pathname.includes("/aos")) {
    return [
      ActiveSubRouteLocation.AOS,
      {
        hideNav: false,
      },
    ] as const;
  }

  if (router.pathname.endsWith("/pay-periods")) {
    return [ActiveSubRouteLocation.PayPeriod, defaultOptions] as const;
  }

  return [ActiveSubRouteLocation.General, defaultOptions] as const;
}
