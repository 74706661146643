/**
 * @generated SignedSource<<5705c74c348d94ba443ec2cb25f67812>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ValueTypeEnum = "currency" | "float" | "hours" | "int" | "percentage" | "seconds" | "string" | "%future added value";
export type EmploymentPerformanceReviewRatingUpdateInput = {
  crewAdjustmentAmount?: any | null;
  crewAdjustmentType?: ValueTypeEnum | null;
  managerAdjustmentAmount?: any | null;
  managerAdjustmentType?: ValueTypeEnum | null;
  name?: string | null;
};
export type EmploymentPerformanceRatingsQueries_UpdateEmploymentPerformanceRating_Mutation$variables = {
  businessId: string;
  force: boolean;
  id: string;
  input: EmploymentPerformanceReviewRatingUpdateInput;
};
export type EmploymentPerformanceRatingsQueries_UpdateEmploymentPerformanceRating_Mutation$data = {
  readonly updateEmploymentPerformanceReviewRating: {
    readonly rating: {
      readonly crewAdjustmentAmount: any | null;
      readonly crewAdjustmentType: ValueTypeEnum;
      readonly deleted: boolean;
      readonly id: string;
      readonly managerAdjustmentAmount: any | null;
      readonly managerAdjustmentType: ValueTypeEnum;
      readonly name: string;
      readonly rank: number;
      readonly updatedAt: string | null;
    } | null;
  };
};
export type EmploymentPerformanceRatingsQueries_UpdateEmploymentPerformanceRating_Mutation = {
  response: EmploymentPerformanceRatingsQueries_UpdateEmploymentPerformanceRating_Mutation$data;
  variables: EmploymentPerformanceRatingsQueries_UpdateEmploymentPerformanceRating_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "businessId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "force"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "businessId",
        "variableName": "businessId"
      },
      {
        "kind": "Variable",
        "name": "force",
        "variableName": "force"
      },
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateEmploymentPerformanceReviewRatingResult",
    "kind": "LinkedField",
    "name": "updateEmploymentPerformanceReviewRating",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "EmploymentPerformanceReviewRating",
        "kind": "LinkedField",
        "name": "rating",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rank",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "crewAdjustmentType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "managerAdjustmentType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "crewAdjustmentAmount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "managerAdjustmentAmount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "deleted",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "updatedAt",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "EmploymentPerformanceRatingsQueries_UpdateEmploymentPerformanceRating_Mutation",
    "selections": (v4/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "EmploymentPerformanceRatingsQueries_UpdateEmploymentPerformanceRating_Mutation",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "fb01c0a0dac26914379b486b1c26e54f",
    "id": null,
    "metadata": {},
    "name": "EmploymentPerformanceRatingsQueries_UpdateEmploymentPerformanceRating_Mutation",
    "operationKind": "mutation",
    "text": "mutation EmploymentPerformanceRatingsQueries_UpdateEmploymentPerformanceRating_Mutation(\n  $businessId: ID!\n  $id: ID!\n  $input: EmploymentPerformanceReviewRatingUpdateInput!\n  $force: Boolean!\n) {\n  updateEmploymentPerformanceReviewRating(businessId: $businessId, id: $id, force: $force, input: $input) {\n    rating {\n      id\n      name\n      rank\n      crewAdjustmentType\n      managerAdjustmentType\n      crewAdjustmentAmount\n      managerAdjustmentAmount\n      deleted\n      updatedAt\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0386bf2402f822344d17f8445d204e7d";

export default node;
